import {
  SHIPMENT_STATUSES,
} from '~/Configurations/constants';
/* eslint-disable import/prefer-default-export */

export const OPERATIONS = {
  APPROVE_REASSIGNMENT_REQUEST: 'approve_reassignment_request',
  CANCEL_SHIPMENT: 'cancel_shipment',
  CHANGE_SHIPMENT_STATUS: 'change_shipment_status',
  CREATE_JOB_ZENDESK_TICKET: 'create_job_zendesk_ticket',
  REASSIGN_SHIPMENT: 'reassign_shipment',
  REVIEW_SHIPMENT_DOCUMENT: 'review_shipment_document',
  SUBMIT_REASSIGNMENT_REQUEST: 'submit_reassignment_request',
  UPDATE_SHIPMENT_ACTIVITY_LOG: 'update_shipment_activity_log',
  UPDATE_SHIPMENT_TIMESTAMP: 'update_shipment_timestamp',
  EDIT_PROOF_OF_DELIVERY_COMPLETION: 'edit_proof_of_delivery_completion',

  // added for BT-1496
  CREATE_DELIVERY_ORDER: 'create_delivery_order',
  POST_TO_MARKETPLACE_DELIVERY_ORDER: 'post_to_marketplace_delivery_order',
  BTMS_BLAST_NOTIFICATION_EXCLUSIVE_TRANSPORTER: 'btms_blast_notification_exclusive_transporter',
  CANCEL_MARKETPLACE_DELIVERY_ORDER: 'cancel_marketplace_delivery_order',
  REOPEN_MARKETPLACE_DELIVERY_ORDER: 'reopen_marketplace_delivery_order',

  // BT-1545
  ASSIGN_SHIPMENT: 'assign_shipment',
  CANCEL_DELIVERY_ORDER: 'cancel_delivery_order',
  UNFULFILL_DELIVERY_ORDER: 'unfulfill_delivery_order',
  ALLOCATE_DELIVERY_ORDER: 'allocate_delivery_order',

  // BT-1556
  CHOOSE_WINNING_BID: 'choose_winning_bid',
  TRANSPORTER_CONFIRM_ALLOCATION: 'transporter_confirm_allocation',
  REALLOCATE_DELIVERY_ORDER: 'reallocate_delivery_order',
  ASSIGN_TRUCK_DRIVER_TO_SHIPMENT: 'assign_truck_driver_to_shipment',
  // APPROVE_REASSIGNMENT_REQUEST already declared
  // SUBMIT_REASSIGNMENT_REQUEST already declared

  // BT-1557
  SHIPMENT_GEOFENCE_TRIGGER: 'shipment_geofence_trigger',
  // CHANGE_SHIPMENT_STATUS already declared
  CREATE_PROOF_OF_DELIVERY_COMPLETION: 'create_proof_of_delivery_completion',

  // BT-1502
  UPDATE_DELIVERY_ORDER: 'update_delivery_order',
  UPDATED_PRICING_DUE_TO_SHIPPER_PRICING: 'updated_pricing_due_to_shipper_pricing',
  UPDATED_SHIPPER_PRICING_INVALID_FOR_DELIVERY_ORDER: 'updated_shipper_pricing_invalid_for_delivery_order',

  // BT-1825
  ...(window.QUEUED_SHIPMENTS && { QUEUE_SHIPMENT: 'queue_shipment' }),

  // BT-2056
  BUMP_DOWN_ACTIVE_SHIPMENT: 'bump_down_active_shipment',
  BUMP_UP_QUEUED_SHIPMENT: 'bump_up_queued_shipment',

  // SE-6168 https://kargox.atlassian.net/browse/SE-6168
  SME_CONTRACTLESS_SHIPPER_CONFIRMATION: 'sme_contractless_referral_do_sent_for_shipper_confirmation',
  SME_CONTRACTLESS_CONFIRMED_BY_SHIPPER: 'sme_contractless_referral_do_confirmed_by_shipper',
  SME_CONTRACTLESS_CANCELLED_BY_SHIPPER: 'sme_contractless_referral_do_cancelled_by_shipper',

  // BT-2651 Bulk Assign
  BULK_ASSIGN_SHIPMENT: 'bulk_assign_shipment',
  BULK_ALLOCATE_SHIPMENT: 'bulk_allocate_shipment',

  // BT-3689 View Activity Log DA table
  ADD_DOORING_DO_UNLOADING_DATE: 'add_dooring_do_unloading_date',

  UPDATE_DOORING_PICKUP_DATE_FROM_ACTUAL_BERTHING: 'update_dooring_pickup_date_from_actual_berthing',

  // BT-4246 View Activity Log Trucking
  POST_DTD_PRIVATE_MP: 'btms_post_dtd_job_to_private_marketplace',
  UPDATE_CONTAINER_RO: 'btms_update_container_ro',
  UPDATE_CONTAINER_DETAILS: 'update_container_details',
  ADD_APPOINTMENT_NUMBER: 'btms_add_appointment_number',
  UPDATE_APPOINTMENT_NUMBER: 'btms_update_appointment_number',
  ADD_SPM: 'btms_add_spm',
  UPDATE_SPM: 'btms_update_spm',
  UPLOAD_RECEIVING_CARD: 'upload_receiving_card',
  EDIT_RECEIVING_CARD: 'edit_receiving_card',
  UPLOAD_SHIPMENT_DOCUMENT: 'upload_shipment_document',
  EDIT_SHIPMENT_DOCUMENT: 'edit_shipment_document',
  ADD_CONTAINER_DETAILS: 'add_container_details',
  REJECT_REASSIGNMENT_REQUEST: 'reject_reassignment_request',
  UPDATE_SHIPMENT_POINTS: 'update_shipment_points',
  UPDATE_PROOF_OF_DELIVERY_COMPLETION_DTD: 'update_proof_of_delivery_completion_dtd',
  UPDATE_UNLOADING_FROM_SHIPPING_LINE_DETAILS: 'update_unloading_from_shipping_line_details',

  // BT-4568 Choose Container Edit Route
  DTD_EDIT_SHIPMENT_ROUTE: 'dtd_edit_shipment_route',
  EDIT_DEMURRAGE_FROM_TIMESTAMP: 'edit_demurrage_from_timestamp',

  BTMS_EDIT_FM_CONTAINERS_BILL_OF_LADING: 'btms_edit_fm_containers_bill_of_lading',
  BTMS_ACCEPT_NOW_TRUCKING_JOB_TAPP: 'btms_accept_now_trucking_job_tapp',

  SHIPMENT_GPS_LOCATION_CHECK_POINT: 'shipment_gps_location_check_point',
  ASKED_DRIVER_LOCATION_VIA_WHATSAPP: 'asked_driver_location_via_whatsapp',
};

export const overridenOperations = [
  OPERATIONS.REVIEW_SHIPMENT_DOCUMENT,
  OPERATIONS.UPDATE_SHIPMENT_ACTIVITY_LOG,
  OPERATIONS.UPDATE_SHIPMENT_TIMESTAMP,
];

export const CUSTOM_OPERATIONS = {
  UPDATE_CONTAINER_NUMBER: 'update_container_number',
  UPDATE_SEAL_NUMBER: 'update_seal_number',
  UPDATE_CONTAINER_AND_SEAL_NUMBER: 'update_container_and_seal_number',
};

export const LOGS_PER_PAGE_SCROLL = 7;

export const ACTORS = {
  SHIPPER: 'shipper',
  TRANSPORTER: 'transporter',
  INTERNAL: 'internal',
  BROKERAGE: 'brokerage',
  DRIVER: 'driver',
  CANVASSER: 'canvasser',
  UNKNOWN: 'unknown',
};

export const POINT_NUMBER = [
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.ONGOING_TO_DESTINATION,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
];

export const DEVICE_LOCATION_UPDATE_SOURCES = {
  MOBILE: 'MOBILE',
  WHATSAPP: 'WHATSAPP',
  GPS_DEVICE: 'GPS_DEVICE',
};
