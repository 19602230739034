export const BANK_ACCOUNT_OPTIONS = [
  {
    label: 'Bank Central Asia',
    value: 'BCA',
  },
  {
    label: 'Bank Negara Indonesia',
    value: 'BNI',
  },
  {
    label: 'Bank Mandiri',
    value: 'MANDIRI',
  },
  {
    label: 'Bank Permata',
    value: 'PERMATA',
  },
  {
    label: 'Bank Sahabat Sampoerna',
    value: 'SAHABAT_SAMPOERNA',
  },
  {
    label: 'Bank Rakyat Indonesia',
    value: 'BRI',
  },
  {
    label: 'Bank CIMB Niaga',
    value: 'CIMB',
  },
  {
    label: 'Bank Syariah Indonesia',
    value: 'BSI',
  },
  {
    label: 'Bank Jabar Banten',
    value: 'BJB',
  },
];

export const BANK_ACCOUNT_HOLDER_OPTIONS = [
  {
    label: 'PT. Panthera Biru Indonesia',
    value: 'PBI',
  },
  {
    label: 'PT. Kargo Online System',
    value: 'KOS',
  },
  {
    label: 'PT. Panthera Usaha Satu',
    value: 'PUS',
  },
];

export const VA_TRANSACTION_STATUS = {
  SHIPPER_PAID: 'SHIPPER_PAID',
  PARTIALLY_RECONCILED: 'PARTIALLY_RECONCILED',
  RECONCILED: 'RECONCILED',
};

export const VA_TRANSACTION_STATUS_COLOR_SCHEME = {
  [VA_TRANSACTION_STATUS.SHIPPER_PAID]: 'warning',
  [VA_TRANSACTION_STATUS.PARTIALLY_RECONCILED]: 'warning',
  [VA_TRANSACTION_STATUS.RECONCILED]: 'success',
};

export const VA_TAB_TYPE = {
  INVOICE: 'INVOICE',
  NON_INVOICE: 'NON_INVOICE',
};

export const RECONCILED_ITEM_TYPE_ENUM = {
  INVOICE: 'INVOICE',
  RECEIVABLE: 'RECEIVABLE',
};

export const DO_INVOICE_STATUS_ENUM = {
  UNPAID: 'UNPAID',
  PAID: 'PAID',
  PARTIAL_PAID: 'PARTIAL_PAID',
  SHIPPER_APPROVED: 'SHIPPER_APPROVED',
};

export default {};
