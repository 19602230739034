/* eslint-disable max-len */
import { FIRST_MILE_CONTAINER_SIZES,
  CONTAINER_STATUSES,
  FIRST_MILE_DOCUMENT_TYPES,
  FIRST_MILE_INVOICE_STATUSES,
  SHIPPING_LINE_STATUS,
  DOORING_AGENT_STATUS,
  CHARGED_REASON,
  DA_SHIPMENT_ACTIVITY_LOG_STATUSES,
  CANCEL_DO_REASON,
  SPL_SHIPMENT_ACTIVITY_LOG_STATUSES,
  ALL_DOCUMENT_TYPES_SPL,
  INVOICE_DOCUMENT_TYPES,
  INVOICE_ADD_DOCUMENT_TYPES,
  TRUCKING_SHIPMENT_ACTIVITY_LOG_STATUSES,
  SHIPMENT_POINT_TYPE,
  SHIPMENT_STATUSES,
  DERIVED_SHIPMENT_STATUSES,
  JOB_STATUSES,
  FM_SHIPMENT_STATUSES } from '~/Configurations/constants';
import { DTD_SHIPMENT_TYPES } from '~/Pages/Payment/FeeRequests/constants';

import { FIRST_MILE_SHIPMENT_TYPE, NFS_PO_REJECTION_REASON, NFS_PO_STATUS } from '../DoorToDoorPO/constants';
import { MONITORING_POSTS, SHIPMENT_POINT_STATUSES, FIRST_MILE_TRUCKING_SHIPMENT_TYPE } from '../Trucking/constants';
import { CONTAINER_DETAILS_DOCUMENT_TYPE } from '../utilities/constants';

export default {
  add_container: 'Add Container',
  add_pickup_location: 'Add Pickup Location',
  appt_no_or_spm: 'Appt. No. / SPM',
  appt_number_or_spm: 'Appt. Number/SPM',
  dashboard: 'Dashboard',
  bill_of_lading: 'Bill of Lading',
  bill_of_lading_summary: 'Bill of Lading Summary',
  b_of_l: 'B/L',
  closing_date: 'Closing Date',
  complete_route: 'Complete Route',
  container_do: 'Container DO',
  container_number: 'Container Number',
  destination_wh: 'Destination WH',
  empty_pickup_origin: 'Empty Pickup Origin',
  seal_no: 'Seal No.',
  seal_number: 'Seal Number',
  container_type: 'Container Type',
  container_weight: 'Weight',
  terminal: 'Terminal',
  container_arrival_eta: 'Store Arrival',
  port_detail: 'Port Details',
  location_detail: 'Location Details',
  container_all: 'All containers',
  container_detail: 'Container Details',
  container_in_holding: 'In Holding',
  container_use_holding: 'Use Holding',
  container_using_holding: 'Using Holding',
  container_holding_area: 'Holding Area',
  container_return_depo: 'Return Depo',
  container_yard: 'Container Yard',
  container_yard_location: 'Container Yard Location',
  container_yard_or_terminal: 'Container Yard or Terminal',
  cy_or_terminal: 'CY/Terminal',
  date_placed_in_holding: 'Date Placed in Holding',
  door_to_door_details: 'Door To Door Details',
  duplicate_po: 'Duplicate PO',
  port_address: 'Port Address',
  bill_of_lading_number: '$t(first_mile:bill_of_lading) Number',
  store_arrival_eta: 'Store Arrival',
  store_holding: 'Use Holding',
  stuffing_origin_1: 'Stuffing Origin 1',
  origin_1_pickup_date: 'Origin 1 Pickup Date',
  origin_1: 'Origin 1',
  origin_2_pickup_date: 'Origin 2 Pickup Date',
  origin_2: 'Origin 2',
  stuffing_origin_2: 'Stuffing Origin 2',
  table_status: 'Status',
  container_unloading_capacity: 'Daily Unloading Capacity',
  other_documents: 'Other Documents',
  store_arrival_date: 'Store Arrival Date',
  reports: 'Reports',
  schedule_report: 'Schedule Report',
  volume_report: 'Volume Report',
  download_report: 'Download Report',
  create_batch_container_for: 'Add containers for {{name}}',
  review_batch_container_for: 'Review containers for {{name}}',
  number_of_containers: 'Number of Containers',
  delivery_order_attachments: 'Delivery Order Attachments',
  container_attachments: 'Container Attachments',
  bulk_edit: 'Bulk Edit',
  choose_container_if_bl: 'You must choose container if you have selected Bill of Lading',
  choose_route: 'You must choose route',
  rc: 'RC',
  rc_yc: 'RC / YC',
  ro: 'RO',
  receiving_card: 'Receiving Card',
  release_order: 'Release Order (RO)',
  shipping_line: 'Shipping Line',
  shipping_line_do: 'Shipping Line DO',
  shipping_line_notes: 'Shipping Line Notes',
  s_of_i: 'S/I',
  pickup_stuffing: 'Pick Up Stuffing',
  transit_vessel_or_voyage: 'Transit Vessel/Voyage',
  vessel_or_voyage: 'Vessel/Voyage',
  vessel_voyage_list: 'Vessel Voyage List',
  vessel_voyage_name: 'vessel or voyage name',
  vessel_remark: 'Vessel Remark',
  container_sizes: {
    [FIRST_MILE_CONTAINER_SIZES['20_FEET']]: '20"',
    [FIRST_MILE_CONTAINER_SIZES['20_FR']]: '20" FR',
    [FIRST_MILE_CONTAINER_SIZES['20_GP']]: '20" GP',
    [FIRST_MILE_CONTAINER_SIZES['20_OT']]: '20" OT',
    [FIRST_MILE_CONTAINER_SIZES['20_OW']]: '20" OW',
    [FIRST_MILE_CONTAINER_SIZES['40_FEET']]: '40"',
    [FIRST_MILE_CONTAINER_SIZES['40_FR']]: '40" FR',
    [FIRST_MILE_CONTAINER_SIZES['40_GP']]: '40" GP',
    [FIRST_MILE_CONTAINER_SIZES['40_HC']]: '40" HC',
    [FIRST_MILE_CONTAINER_SIZES['40_OT']]: '40" OT',
    [FIRST_MILE_CONTAINER_SIZES['40_OW']]: '40" OW',
    [FIRST_MILE_CONTAINER_SIZES['45_FEET']]: '45"',
  },
  container_status: {
    [CONTAINER_STATUSES.ARRIVED_AT_PORT]: 'Arrived at Port',
    [CONTAINER_STATUSES.ON_SHIPMENT]: 'On Shipment',
    [CONTAINER_STATUSES.PAYMENT_PENDING]: 'Pending',
    [CONTAINER_STATUSES.INVOICED]: 'Invoiced',
    [CONTAINER_STATUSES.PAID]: 'Paid',
    [CONTAINER_STATUSES.MOVED_TO_HOLDING]: 'Moved to holding',
    [CONTAINER_STATUSES.PIB]: 'PIB',
    [CONTAINER_STATUSES.NO_NOA]: 'No NOA',
    [CONTAINER_STATUSES.WAITING_FOR_SPPB]: 'Waiting for SPPB',
    [CONTAINER_STATUSES.WAITING_FOR_ORIGINAL_DOC]: 'Waiting for Original Doc',
    [CONTAINER_STATUSES.NOA]: 'NOA',
    [CONTAINER_STATUSES.SPPB]: 'SPPB',
    [CONTAINER_STATUSES.QUARANTINED]: 'Quarantined',
    // Used in current BTMS First-Mile
    [CONTAINER_STATUSES.UPCOMING]: 'Upcoming',
    [CONTAINER_STATUSES.UNASSIGNED]: 'Unassigned',
    [CONTAINER_STATUSES.BIDDING]: 'Bidding',
    [CONTAINER_STATUSES.TRANSPORTER_CONFIRMED]: 'Transporter Confirmed',
    [CONTAINER_STATUSES.READY_TO_BOOK]: 'Ready to Book',
    [CONTAINER_STATUSES.VESSEL_BOOKED]: 'Vessel Booked',
    [CONTAINER_STATUSES.ASSIGNED]: 'Assigned',
    [CONTAINER_STATUSES.ONGOING_PORT_OF_LOADING]: 'Ongoing to Port of Loading',
    [CONTAINER_STATUSES.AT_PORT_OF_LOADING]: 'At Port of Loading',
    [CONTAINER_STATUSES.ONGOING_PORT_OF_DISCHARGE]: 'Ongoing to Port of Discharge',
    [CONTAINER_STATUSES.AT_PORT_OF_DISCHARGE]: 'At Port of Discharge',
    [CONTAINER_STATUSES.ONGOING_DESTINATION_WAREHOUSE]: 'Ongoing to Destination WH',
    [CONTAINER_STATUSES.AT_DESTINATION_WAREHOUSE]: 'At Destination WH',
    [CONTAINER_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    // Terminal statuses
    [CONTAINER_STATUSES.UNFULFILLED]: 'Unfulfilled',
    [CONTAINER_STATUSES.REASSIGNED_BY_SHIPPER]: 'Reassigned by Shipper',
    [CONTAINER_STATUSES.REASSIGNED_BY_BROKERAGE]: 'Reassigned by Brokerage',
    [CONTAINER_STATUSES.CONFIRMATION_EXPIRED]: 'Confirmation Expired',
    [CONTAINER_STATUSES.REJECTED_BY_TRANSPORTER]: 'Rejected by Transporter',
    [CONTAINER_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Transporter',
    [CONTAINER_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper',
    [CONTAINER_STATUSES.CANCELLED_BY_INTERNAL]: 'Cancelled by Internal',
  },
  bill_of_lading_document_type: {
    [FIRST_MILE_DOCUMENT_TYPES.HOUSE_BILL_OF_LADING]: 'House Bill of Lading',
    [FIRST_MILE_DOCUMENT_TYPES.BILL_OF_LADING]: 'Bill of Lading',
    [FIRST_MILE_DOCUMENT_TYPES.NOTICE_OF_ARRIVAL]: 'NOA',
    [FIRST_MILE_DOCUMENT_TYPES.PIB]: 'PIB',
    [FIRST_MILE_DOCUMENT_TYPES.QUARANTINE]: 'Quarantine',
    [FIRST_MILE_DOCUMENT_TYPES.EBILLING]: 'E-Billing',
    [FIRST_MILE_DOCUMENT_TYPES.SHIPPING_LINE_DELIVERY_ORDER]:
        'Shipping Line Delivery Order',
    [FIRST_MILE_DOCUMENT_TYPES.MATERIAL_SAFETY_AND_DATA_SHEET]:
        'Material Data Sheet',
    [FIRST_MILE_DOCUMENT_TYPES.CERTIFICATE_OF_ORIGIN]:
        'Certificate of Origin',
    [FIRST_MILE_DOCUMENT_TYPES.STORAGE_CHARGES]: 'Storage Charges',
  },
  container_document_type: {
    [FIRST_MILE_DOCUMENT_TYPES.TILA]: 'TILA',
    [FIRST_MILE_DOCUMENT_TYPES.CONTAINER_RENTAL]: 'Container Rental',
    [FIRST_MILE_DOCUMENT_TYPES.PACKING_LIST]: 'Packing List',
    [FIRST_MILE_DOCUMENT_TYPES.PROOF_OF_DELIVERY]: 'Surat Jalan',
    [FIRST_MILE_DOCUMENT_TYPES.MATERIAL_SAFETY_AND_DATA_SHEET]:
        'Material Data Sheet',
    [FIRST_MILE_DOCUMENT_TYPES.CERTIFICATE_OF_ORIGIN]:
        'Certificate of Origin',
    [FIRST_MILE_DOCUMENT_TYPES.LOLO]: 'LOLO',
  },
  container_details_document_type: {
    [CONTAINER_DETAILS_DOCUMENT_TYPE.BILL_OF_LADING]: 'Bill of Lading',
    [CONTAINER_DETAILS_DOCUMENT_TYPE.DTD_RECEIVING_CARD]: 'Receiving Card',
    [CONTAINER_DETAILS_DOCUMENT_TYPE.DTD_RECEIVING_ORDER]: 'EIR',
    [CONTAINER_DETAILS_DOCUMENT_TYPE.LOADING_INSTRUCTION]: 'Surat Perintah Muat',
    [CONTAINER_DETAILS_DOCUMENT_TYPE.OCEAN_FREIGHT]: 'Invoice Shipping Line',
    [CONTAINER_DETAILS_DOCUMENT_TYPE.PROOF_OF_DELIVERY]: 'Surat Jalan',
    [CONTAINER_DETAILS_DOCUMENT_TYPE.POL]: 'Proof of Loading Store {{sequence}}',
    [CONTAINER_DETAILS_DOCUMENT_TYPE.RELEASE_ORDER]: 'Release Order',
    [CONTAINER_DETAILS_DOCUMENT_TYPE.SHIPPING_INSTRUCTION]: 'Shipping Instruction',
  },
  invoice_status: {
    [FIRST_MILE_INVOICE_STATUSES.DUE]: 'Due',
    [FIRST_MILE_INVOICE_STATUSES.OVERDUE]: 'Overdue',
    [FIRST_MILE_INVOICE_STATUSES.VERY_OVERDUE]: 'Very Overdue',
    [FIRST_MILE_INVOICE_STATUSES.PAID]: 'Paid',
  },
  shipping_line_status_type: {
    [SHIPPING_LINE_STATUS.VESSEL_BOOKED]: 'Vessel Booked',
    [SHIPPING_LINE_STATUS.DEPARTURE]: 'Departure',
    [SHIPPING_LINE_STATUS.ARRIVAL]: 'Arrival',
    [SHIPPING_LINE_STATUS.SHIPPING_LINE_SHIPMENT_COMPLETE]: 'Shipment Completed',
    [SHIPPING_LINE_STATUS.CANCELLED_BY_INTERNAL]: 'Booking Cancelled',
  },
  dooring_agent_status_type: {
    [DOORING_AGENT_STATUS.UPCOMING]: 'Unassigned',
    [DOORING_AGENT_STATUS.PLANNED]: 'Planned',
    [DOORING_AGENT_STATUS.ASSIGNED]: 'Assigned',
    // Temporarily Assigned to At Destination
    [DOORING_AGENT_STATUS.ARRIVE_AT_DESTINATION]: 'At Destination WH',
    [DOORING_AGENT_STATUS.START_UNLOADING]: 'At Destination WH',
    [DOORING_AGENT_STATUS.FINISH_UNLOADING]: 'At Destination WH',
    [DOORING_AGENT_STATUS.WAITING_FOR_CONFIRMATION]: 'At Destination WH',

    [DOORING_AGENT_STATUS.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [DOORING_AGENT_STATUS.CANCELLED_BY_INTERNAL]: 'Cancelled By Internal',

    // Temporarily Assigned to Assigned
    [DOORING_AGENT_STATUS.ONGOING_TO_PICKUP]: 'Assigned',
    [DOORING_AGENT_STATUS.ARRIVE_AT_PICKUP]: 'Assigned',
    [DOORING_AGENT_STATUS.START_LOADING]: 'Assigned',
    [DOORING_AGENT_STATUS.FINISH_LOADING]: 'Assigned',
    [DOORING_AGENT_STATUS.ON_SHIPMENT]: 'Assigned',
    [DOORING_AGENT_STATUS.ONGOING_TO_DESTINATION]: 'Assigned',
  },
  charge_reason_type: {
    [CHARGED_REASON.LATE_BILL_OF_LADING]: 'Late Bill of Lading',
    [CHARGED_REASON.DOORING_AGENT_PROBLEM]: 'Problem with Dooring Agent (e.g. truck availability)',
    [CHARGED_REASON.BAD_WEATHER]: 'Bad Weather',
    [CHARGED_REASON.LONG_QUEUE_AT_WAREHOUSE]: 'Long Queue at Warehouse',
    [CHARGED_REASON.FULL_AT_DESTINATION_WAREHOUSE]: 'Full at Destination Warehouse',
  },
  shipping_instruction: 'Shipping Instruction',
  free_time_demurrage: 'Free Time Demurrage',
  free_time_storage: 'Free Time Storage',
  free_time_demurrage_deadline: 'Free Time Demurrage Deadline',
  free_time_storage_deadline: 'Free Time Storage Deadline',
  upload_bill_of_lading: 'Upload Bill of Lading',
  dooring_agent_consignee: 'Dooring Agent (consignee)',
  bl: 'BL',
  pod_send_date: 'POD Send Date',
  planned_dooring_date: 'Planned Dooring Date',
  add_planning_dooring_date: 'Add Planned Dooring Date',
  edit_planning_dooring_date: 'Edit Planned Dooring Date',
  wh_arrival_date: 'WH Arrival Date',
  finish_unloading: 'Finish Unloading',
  demurrage_status: 'Demurrage Status',
  demurrage_day: 'Demurrage Day(s)',
  estimated_time_of_berthing: 'Estimated Time of Berthing',
  charged_demurrage_reason: 'Charged Demurrage Reason',
  additional_description: 'Additional Description',
  destination_warehouse: 'Destination Warehouse',
  pol_send_date: 'POL Send Date',
  pol_received_date: 'POL Received Date',
  dooring_agent_do: 'Dooring Agent DO',
  dooring_do: 'Dooring DO',
  dooring_agent_remark: 'Dooring Agent Remark',
  trucking_shipment_activity_log_map: {
    update_via: {
      [TRUCKING_SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_CALL]: 'Update via call',
      [TRUCKING_SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_WHATSAPP]: 'Update via whatsapp',
      [TRUCKING_SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_TRANSPORTER]: 'Update via Transporter',
      [TRUCKING_SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_DRIVER]: 'Update via Driver',
    },
    shipment_stage: {
      [TRUCKING_SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.PRE_SHIPMENT]: 'Pre Shipment',
      [TRUCKING_SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.ON_SHIPMENT]: 'On Shipment',
      [TRUCKING_SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.POST_SHIPMENT]: 'Post Shipment',
    },
    problem: {
      [TRUCKING_SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.TPT_NO_RESPONSE]: 'TPT no response',
      [TRUCKING_SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.LATE_SHIPMENT]: 'Late shipment',
      [TRUCKING_SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.ISSUES]: 'Issues',
    },
  },
  da_shipment_activity_log_map: {
    update_via: {
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_CALL]: 'Update via call',
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_WHATSAPP]: 'Update via whatsapp',
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_TRANSPORTER]: 'Update via Transporter',
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_DRIVER]: 'Update via Driver',
    },
    shipment_stage: {
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.PRE_SHIPMENT]: 'Pre Shipment',
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.ON_SHIPMENT]: 'On Shipment',
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.POST_SHIPMENT]: 'Post Shipment',
    },
    problem: {
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.TPT_NO_RESPONSE]: 'TPT no response',
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.LATE_SHIPMENT]: 'Late shipment',
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.ISSUES]: 'Issues',
    },
  },
  spl_shipment_activity_log_map: {
    update_via: {
      [SPL_SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_CALL]: 'Update via call',
      [SPL_SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_WHATSAPP]: 'Update via whatsapp',
      [SPL_SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_DOORING]: 'Update via dooring',
    },
    shipment_stage: {
      [SPL_SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.BOOKED]: 'Booked',
      [SPL_SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.ARRIVED]: 'Arrived',
      [SPL_SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.BERTHING]: 'Berthing',
      [SPL_SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.DEPARTURE]: 'Departure',
    },
    problem: {
      [SPL_SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.INCORRECT_POSITION]: 'Incorrect Position',
      [SPL_SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.ISSUES]: 'Issues',
      [SPL_SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.LATE_SHIPMENT]: 'Late Shipment',
    },
  },
  charged: 'Charged',
  charged_reason: 'Charged Reason',
  free: 'Free',
  container_details: 'Container Details',
  proof_of_loading_trucking_do: 'Proof of Loading - Trucking ({{doNumber}})',
  trucking_license_plate: 'Trucking License Plate',
  dooring_license_plate: 'Dooring License Plate',
  door_to_door: 'Door-to-Door',
  door_to_door_notes: 'Door-to-door Notes',
  proof_of_loading_trucking: 'Proof of Loading - Trucking',
  cancel_do_reason: {
    [CANCEL_DO_REASON.CONTAINER_NOT_AVAILABLE]: 'Container Not Available',
    [CANCEL_DO_REASON.CANCELLED_BY_SHIPPER]: 'Request From Shipper',
    [CANCEL_DO_REASON.OTHER_REASON]: 'Others',
  },
  shipment_document_shipping_line: 'Shipment Document - Shipping Line ({{doNumber}})',
  pod_dooring: 'Proof of Delivery - Dooring Agent ({{doNumber}})',
  demurrage_details: 'Demurrage Details',
  storage_details: 'Storage Details',
  trucking: 'Trucking',
  dooring: 'Dooring',
  container_type_summary: 'Container Type Summary',
  ocean_freight_invoice: 'Ocean Freight Invoice',
  trucking_shipment: 'Trucking Shipment',
  dooring_shipment: 'Dooring Shipment',
  b_tms: 'B-TMS',
  update_ro: 'Update Release Order (RO)',
  early_pickup_date: 'Early Pickup Date',
  latest_pickup_date: 'Latest Pickup Date',
  all_document_types_spl: {
    [ALL_DOCUMENT_TYPES_SPL.DTD_BILL_OF_LADING]: 'Bill of Lading',
    [ALL_DOCUMENT_TYPES_SPL[INVOICE_DOCUMENT_TYPES.OCEAN_FREIGHT]]: 'Ocean Freight',
    [ALL_DOCUMENT_TYPES_SPL[INVOICE_ADD_DOCUMENT_TYPES.LSS]]: 'LSS',
    [ALL_DOCUMENT_TYPES_SPL[INVOICE_ADD_DOCUMENT_TYPES.THC_D]]: 'THC-D',
    [ALL_DOCUMENT_TYPES_SPL[INVOICE_ADD_DOCUMENT_TYPES.THC_L]]: 'THC-L',
    [ALL_DOCUMENT_TYPES_SPL[INVOICE_ADD_DOCUMENT_TYPES.OTHER_FEE]]: 'Additional Fee',
  },
  voyage_name: 'Voyage Name',
  requested_stuffing_date: 'Requested Stuffing Date',
  requested_unloading_date_time: 'Requested Unloading Date Time',
  cy_closing_date_latest_dropoff: 'CY Closing Date (Latest Drop Off Date)',
  shipment_status: 'Shipment Status',
  shipment_route: 'Shipment Route',
  shipment_point: {
    [SHIPMENT_POINT_TYPE.PICKUP]: 'Pickup {{sequence}}',
    [SHIPMENT_POINT_TYPE.DROPOFF]: 'Dropoff {{sequence}}',
  },
  shipment_point_date: {
    [SHIPMENT_POINT_TYPE.PICKUP]: 'Pickup Date {{sequence}}',
    [SHIPMENT_POINT_TYPE.DROPOFF]: 'Dropoff Date {{sequence}}',
  },
  change_route_reason: 'Change Route Reason',
  update_si: 'Update SI',
  container_have_no_da: '{{x}} Container Have No Dooring Agent',
  container_have_no_da_msg: 'Please add dooring agent to the additional container to continue edit shipping line status from Vessel Booked to Departure',
  shipping_instruction_cannot_created: 'Shipping Instruction Cannot be Created',
  shipping_instruction_cannot_updated: 'Shipping Instruction Cannot be Updated',
  cant_created_si_dialog_message: 'Shipping Instruction requires Container Number and Seal Number.\nFor more information, please contact related Trucking Traffic Controller',
  shipping_instruction_not_created: 'Shipping Instruction Not Created Yet',
  create_shipping_instruction_before: 'Create Shipping Instruction before you upload Invoice or Bill of Lading',
  create_shipping_instruction_before_noa: 'To create Notice of Arrival, Shipping Instruction must be created',
  first_mile_shipment_type: {
    [FIRST_MILE_SHIPMENT_TYPE.DTD]: 'DTD',
    [FIRST_MILE_SHIPMENT_TYPE.FM_EXPORT]: 'Export',
    [FIRST_MILE_SHIPMENT_TYPE.FM_OUTBOUND]: 'Outbound',
    [FIRST_MILE_SHIPMENT_TYPE.FM_IMPORT]: 'Import',
    [FIRST_MILE_SHIPMENT_TYPE.FM_INBOUND]: 'Inbound',
  },
  first_mile_shipment_type_extension: {
    [FIRST_MILE_SHIPMENT_TYPE.DTD]: 'Outbound DTD',
    [FIRST_MILE_SHIPMENT_TYPE.FM_EXPORT]: 'Export FM Trucking',
    [FIRST_MILE_SHIPMENT_TYPE.FM_OUTBOUND]: 'Outbound FM Trucking',
    [FIRST_MILE_SHIPMENT_TYPE.FM_IMPORT]: 'Import FM Trucking',
    [FIRST_MILE_SHIPMENT_TYPE.FM_INBOUND]: 'Inbound FM Trucking',
  },
  first_mile_trucking_shipment_type: {
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.TRUCKING_DTD]: 'DTD',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_EXPORT]: 'Export',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_OUTBOUND]: 'Outbound',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_IMPORT]: 'Import',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_INBOUND]: 'Inbound',
  },
  first_mile_trucking_shipment_type_non_abbreviation: {
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.TRUCKING_DTD]: 'Outbound DTD',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_EXPORT]: 'Export FM Trucking',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_OUTBOUND]: 'Outbound FM Trucking',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_IMPORT]: 'Import FM Trucking',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_INBOUND]: 'Inbound FM Trucking',
  },
  surat_jalan_shipment_type: {
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.TRUCKING_DTD]: 'Outbound Domestik',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_OUTBOUND]: 'Outbound Domestik',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_EXPORT]: 'Ekspor',
  },
  origin_label: 'Origin {{pointIndex}} : {{nameLocation}}',
  empty_depo: 'Empty Depo',
  return_empty_depo: 'Return Empty Depo',
  drop_off: 'Drop Off',
  drop_off_date: 'Drop Off Date',
  drop_off_label: 'Drop Off {{pointIndex}} : {{nameLocation}}',
  stuffing_x: 'Stuffing {{x}}',
  shipper_warehouse_x: 'Shipper Warehouse {{x}}',
  location_fees: 'Location Fees',
  purchase_order_successfully_created: 'Purchase order successfully created!',
  store: 'Store',
  terminal_or_cy: 'Terminal / CY',
  job_or_order_number: 'Job / Order Number',
  ro_bon_muat_expired_date: 'RO/Bon Muat Expired Date Time',
  ro_bon_muat_release_date: 'RO/Bon Muat Release Date',
  pickup_empty_depo_title: 'Pickup - Empty Depo',
  pickup_stuffing_title: 'Pickup - Stuffing',
  destination_pol_title: 'Destination — Port of Loading',
  pic_phone_number: 'PIC Name & Phone Number',
  monitoring_status_filter: {
    [MONITORING_POSTS.PRE_SHIPMENT]: 'Pre Shipment',
    [MONITORING_POSTS.ON_SHIPMENT]: 'On Shipment',
    [MONITORING_POSTS.COMPLETED]: 'Shipment Completed',
  },
  shipment_status_map: {
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'At Destination',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'At Origin',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Assigned',
    [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'Behind Schedule',
    [SHIPMENT_STATUSES.BIDDING]: 'Bidding',
    [SHIPMENT_STATUSES.CANCELLED]: 'Cancelled',
    [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'Cancelled by Internal',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Expired',
    [SHIPMENT_STATUSES.DOCUMENT_UPLOADED]: 'Document Uploaded',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Finish Loading',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Finish Unloading',
    [SHIPMENT_STATUSES.JOB_BIDDING]: 'Bidding',
    [SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED]: 'Transporter Confirmed',
    [SHIPMENT_STATUSES.JOB_UNASSIGNED]: 'Unassigned',
    [SHIPMENT_STATUSES.LATE_TO_DESTINATION]: 'Late to Destination',
    [SHIPMENT_STATUSES.LATE_TO_ORIGIN]: 'Late to Origin',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Ongoing to Destination',
    [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'On Time',
    [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'Ongoing to Destination',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Ongoing to Origin',
    [SHIPMENT_STATUSES.PLANNED]: 'Planned',
    [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_DESTINATION_LATE]: 'Queue at Destination Late',
    [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_ORIGIN_LATE]: 'Queue at Origin Late',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_DESTINATION]: 'Queueing At Destination',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_ORIGIN]: 'Queueing At Origin',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'Queueing Overtime',
    [SHIPMENT_STATUSES.REASSIGNED_BY_BROKERAGE]: 'Reassigned',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Reassigned',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Rejected by vendor',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [SHIPMENT_STATUSES.START_LOADING]: 'Start Loading',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start Unloading',
    [SHIPMENT_STATUSES.UNASSIGNED]: 'Unassigned',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Unfulfilled by Internal',
    [SHIPMENT_STATUSES.UPCOMING]: 'Upcoming',
    [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'POD Uploaded',
    [SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION]: 'Waiting For Job Confirmation',
    [SHIPMENT_STATUSES.QUEUED]: 'Queued',
    [SHIPMENT_STATUSES.QUEUED_AND_BTMS_PLANNED]: 'Queued',
    [SHIPMENT_STATUSES.JOB_WAITING_FOR_SHIPPER_CONFIRMATION]: 'Waiting For Shipper Confirmation',
    [JOB_STATUSES.TRANSPORTER_CONFIRMED]: 'Transporter Confirmed',
    [FM_SHIPMENT_STATUSES.GATE_IN_DEPO]: 'Gate In Depo',
    [FM_SHIPMENT_STATUSES.GATE_IN_CY]: 'Gate In CY',
    [FM_SHIPMENT_STATUSES.GATE_IN_STORE]: 'Gate In Store',
    [FM_SHIPMENT_STATUSES.GATE_OUT_DEPO]: 'Gate Out Depo',
    [FM_SHIPMENT_STATUSES.GATE_OUT_CY]: 'Gate Out CY',
    [FM_SHIPMENT_STATUSES.GATE_OUT_STORE]: 'Gate Out Store',
  },
  shipment_status_multi: {
    [SHIPMENT_STATUSES.UPCOMING]: 'Upcoming',
    [SHIPMENT_STATUSES.JOB_WAITING_FOR_SHIPPER_CONFIRMATION]: 'Waiting For Shipper Confirmation',
    [SHIPMENT_STATUSES.PLANNED]: 'Planned',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Assigned',
    [FM_SHIPMENT_STATUSES.GATE_IN_DEPO]: 'Gate In Depo',
    [FM_SHIPMENT_STATUSES.GATE_IN_CY]: 'Gate In CY',
    [FM_SHIPMENT_STATUSES.GATE_IN_STORE]: 'Gate In Store {{sequence}}',
    [FM_SHIPMENT_STATUSES.GATE_OUT_DEPO]: 'Gate Out Depo',
    [FM_SHIPMENT_STATUSES.GATE_OUT_CY]: 'Gate Out CY',
    [FM_SHIPMENT_STATUSES.GATE_OUT_STORE]: 'Gate Out Store {{sequence}}',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'Cancelled by Internal',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper',
    [SHIPMENT_STATUSES.CANCELLED]: 'Cancelled',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Unfulfilled',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Reassigned',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Unfulfilled',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Expired',
    [SHIPMENT_STATUSES.JOB_BIDDING]: 'Bidding',
    [SHIPMENT_STATUSES.JOB_UNASSIGNED]: 'Unassigned',
    [SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED]: 'Transporter Confirmed',
    [SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION]: 'Waiting For Job Confirmation',
    [SHIPMENT_STATUSES.REASSIGNED_BY_BROKERAGE]: 'Reassigned By Brokerage',
    [SHIPMENT_STATUSES.QUEUED_AND_BTMS_PLANNED]: 'Planned',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Ongoing to Pickup',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start Unloading',
  },
  shipment_status_map_multi_pickup: {
    // PLEASE DO NOT SORT ALPHABETICALLY FOR THESE TRANSLATIONS BECAUSE THEY ARE BASED ON REAL CONDITION
    [`FIRST_${SHIPMENT_POINT_STATUSES.ONGOING_TO_PICKUP}`]: 'Ongoing to Depo',
    [`FIRST_${SHIPMENT_POINT_STATUSES.ARRIVE_AT_PICKUP}`]: 'Gate In Depo',
    [`FIRST_${SHIPMENT_POINT_STATUSES.START_LOADING}`]: 'Start Loading',
    [SHIPMENT_POINT_STATUSES.ONGOING_TO_PICKUP]: 'Gate Out Depo',
    [SHIPMENT_POINT_STATUSES.ARRIVE_AT_PICKUP]: 'Gate In Store {{sequence}}',
    [SHIPMENT_POINT_STATUSES.START_LOADING]: 'Start Loading {{sequence}}',
    [SHIPMENT_POINT_STATUSES.FINISH_LOADING]: 'Gate Out Store {{sequence}}',
    [SHIPMENT_POINT_STATUSES.ARRIVE_AT_DESTINATION]: 'Gate In CY',
    [SHIPMENT_POINT_STATUSES.COMPLETE]: 'Shipment Complete',
    [FM_SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Complete',

    // SECTION BELOW IS NOT RELATED TO ABOVE SECTION. THAT IS WHY I NEED TO SEPARATE IT
    [`FIRST_${SHIPMENT_POINT_STATUSES.FINISH_LOADING}`]: 'Gate Out Depo',
    [SHIPMENT_POINT_STATUSES.START_UNLOADING]: 'Start Unloading {{sequence}}',
    [SHIPMENT_POINT_STATUSES.ON_SHIPMENT]: 'Gate Out Store {{sequence}}',
    [SHIPMENT_POINT_STATUSES.FINISH_UNLOADING]: 'Gate Out CY',
  },
  shipment_status_map_multi_dropoff: {
    // PLEASE DO NOT SORT ALPHABETICALLY FOR THESE TRANSLATIONS BECAUSE THEY ARE BASED ON REAL CONDITION
    [SHIPMENT_POINT_STATUSES.ONGOING_TO_PICKUP]: 'Ongoing to CY',
    [SHIPMENT_POINT_STATUSES.ARRIVE_AT_PICKUP]: 'Gate In CY',
    [SHIPMENT_POINT_STATUSES.START_LOADING]: 'Start Loading {{sequence}}',
    [SHIPMENT_POINT_STATUSES.FINISH_LOADING]: 'Gate Out CY',
    [SHIPMENT_POINT_STATUSES.ON_SHIPMENT]: 'On Shipment',
    [SHIPMENT_POINT_STATUSES.ARRIVE_AT_DESTINATION]: 'Gate In Store {{sequence}}',
    [SHIPMENT_POINT_STATUSES.START_UNLOADING]: 'Start Unloading {{sequence}}',
    [SHIPMENT_POINT_STATUSES.FINISH_UNLOADING]: 'Gate Out Store {{sequence}}',
    [`LAST_${SHIPMENT_POINT_STATUSES.ON_SHIPMENT}`]: 'Ongoing to Depo',
    [`LAST_${SHIPMENT_POINT_STATUSES.ARRIVE_AT_DESTINATION}`]: 'Gate In Depo',
    [`LAST_${SHIPMENT_POINT_STATUSES.START_UNLOADING}`]: 'Start Unloading',
    [`LAST_${SHIPMENT_POINT_STATUSES.FINISH_UNLOADING}`]: 'Gate Out Depo',
    [`LAST_${SHIPMENT_POINT_STATUSES.WAITING_FOR_CONFIRMATION}`]: 'POD Uploaded',
    [SHIPMENT_POINT_STATUSES.COMPLETE]: 'Shipment Complete',
    [FM_SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Complete',
  },
  first_mile_shipment_type_trucking_table: {
    [DTD_SHIPMENT_TYPES.TRUCKING_DTD]: 'DTD',
    [FIRST_MILE_SHIPMENT_TYPE.FM_EXPORT]: 'Export',
    [FIRST_MILE_SHIPMENT_TYPE.FM_OUTBOUND]: 'Outbound',
    [FIRST_MILE_SHIPMENT_TYPE.FM_IMPORT]: 'Import',
    [FIRST_MILE_SHIPMENT_TYPE.FM_INBOUND]: 'Inbound',
  },
  bl_or_do_number: 'B/L / DO No.',
  empty_depo_not_specified: 'Undefined Empty Depo',
  add_dropoff_location: 'Add Drop Off Location',
  estimated_dropoff_date: 'Estimated Drop Off Date Time',
  dropoff_store: 'Drop off - Store {{index}}',
  job_number: 'Job Number',
  pickup_pod: 'Pickup - Port of Discharge',
  latest_dropoff_date: 'Latest Dropoff Date',
  dropoff_empty_depo: 'Drop Off — Return Empty Depo',
  dropoff_warehouse: 'Drop Off - Shipper Warehouse {{x}}',
  store_x: 'Store {{x}}',
  store_origin_x: 'Store/Origin {{x}}',
  update_container_docs: 'Update Container Docs',
  dialog_update_container_doc: 'Update Container Document',
  dialog_update_container_docs: 'Update Container Documents',
  bl_do_number: 'BL / DO Number',
  bl_do_placeholder: 'Enter Bill of lading or DO numbers',
  cannot_update_bl: 'Cannot update {{x}} BL/Job Number',
  container_update_success: 'Container info successfully updated!',
  bl_no: 'BL / DO No.',
  gate_pass: 'Gate Pass',
  receiving_card_or_yc: 'Receiving Card / Yellow Card',
  tila_sp2_expired_date_time: 'TILA/SP2 Expired Date Time (Latest Pickup)',
  destination_dropoff_date: 'Destination - Drop Off Date',
  upload_rc_yc: 'Upload RC / YC',
  location_pickup_date: '{{location}} Pickup Date',
  location_dropoff_date: '{{location}} Drop Off Date',
  generated_surat_jalan: 'Generated Surat Jalan',
  support_document_change_route_fm_trucking: 'TILA / SP2/ Bon Muat / RO',
  cy_closing_date_time: 'CY Closing Date Time (Latest Drop Off)',
  total_store_location: 'Total of Store Location',
  manual_allocate_restricted_title: 'Unable to Allocate this Shipment',
  manual_allocate_restricted_description: 'This shipment need to have all of the shipment points filled. Please check the Empty Depo data',
  use_one_bol: 'Use 1 BL / DO Number',
  do_expired_date: 'DO Expired Date',
  do_expired_date_time: 'DO Expired Date Time',
  previous_status_and_timestamp_warning: 'You Are Selecting Previous Shipment Status with Backdated Timestamps!',
  previous_status_and_timestamp_warning_message: 'The selected status, date and time are Backdated compared to the previous shipment status. Please recheck and make sure your selected Status and Timestamp is correct.',
  select_backdated_timestamp_warning: 'You Are Selecting Backdated Timestamps!',
  select_backdated_timestamp_warning_message: 'The selected date and time are backdated compared to the previous shipment status. Please recheck and make sure your selected Timestamp is correct.',
  previous_status_warning: 'You are selecting previous Shipment Status!',
  previous_status_warning_message: 'The selected status are backdated compared to the previous shipment status. Please recheck and make sure your selected Status is correct.',
  nfs_po_status: {
    [NFS_PO_STATUS.CANCELLED]: 'Cancelled',
    [NFS_PO_STATUS.ORDER_PENDING]: 'Need Confirmation',
    [NFS_PO_STATUS.ORDER_CONFIRMED]: 'Confirmed',
    [NFS_PO_STATUS.REJECTED_BY_TRANSPORTER]: 'Rejected',
  },
  register_truck_requirement: 'Register Truck Requirement',
  register_location_fee: 'Register Location Fee',
  register_shipper_price: 'Register Shipper Price',
  the_shipper_price_isnt_registered_yet: 'The Shipper Price Isn’t Registered Yet',
  the_location_fee_isnt_registered_yet: 'The Location Fee Isn’t Registered Yet',
  please_register_the_shipper_price_to_proceed_your_dtd_po: 'Please register shipper price to proceed your Door-to-door Purchase Order. Refresh this page after register the shipper price',
  please_register_the_location_to_proceed_your_dtd_po: 'Please register the location to proceed your Door-to-door Purchase Order. Refresh this page after register the location fee',
  port_of_discharge: 'Port of Discharge',
  port_of_loading: 'Port of Loading',
  preferable_vessel_schedule: 'Preferable Vessel Schedule',
  preferable_vessel_voyage_schedule: 'Preferable Vessel Voyage Schedule',
  preferable_vessel_voyage_description_info: 'If the preferred schedule is not available, please inform the shipper before booking.',
  preferable_vessel_voyage_title_info: 'Inform TP shipping line to prioritize booking for selected shipping line',
  rejection_reason: 'Rejection Reason',
  shipment_successfully_confirmed: 'Shipment successfully confirmed',
  select_rejection_reason: 'Select rejection reason',
  nfs_po_rejection_reason_map: {
    [NFS_PO_REJECTION_REASON.DRIVER_NOT_AVAILABLE]: 'Driver is not available',
    [NFS_PO_REJECTION_REASON.OTHER_REASON]: 'Other',
    [NFS_PO_REJECTION_REASON.SCHEDULE_NOT_AVAILABLE]: 'Schedule not available',
    [NFS_PO_REJECTION_REASON.TRANSPORTER_NOT_AVAILABLE]: 'Transporter is not available',
  },
  shipment_successfully_rejected: 'Shipment successfully rejected',
  stuffing_date: 'Stuffing Date',
  total_containers: 'Total Containers',
  vessel_voyage: 'Vessel Voyage',
  vessel_voyage_schedule: 'Vessel Voyage Schedule',
  dooring_transporter_name: 'Dooring Transporter Name',
  origin_transporter_name: 'Origin Transporter Name',
};
