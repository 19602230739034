/* eslint-disable import/prefer-default-export */

export const JOB_TYPE_WITHOUT_DTD = {
  SME: 'SME',
  ENTERPRISE: 'ENTERPRISE',
  PODF: 'PODF',
};

export const JOB_TYPE = {
  SME: 'SME',
  ENTERPRISE: 'ENTERPRISE',
  PODF: 'PODF',
  DTD: 'DTD',
};
