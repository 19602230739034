import { CUSTOM_OPERATIONS } from '~/Components/DeliveryOrderActivityLog/constants';
import {
  DOCUMENT_TYPE_ADD_DOCUMENT,
  SHIPMENT_STATUSES,
  DERIVED_SHIPMENT_STATUSES,
  DO_INVOICE_FEE_TYPES,
  INVOICE_DOCUMENT_TYPES,
  INVOICE_ADD_DOCUMENT_TYPES,
  SUPPORTING_DOCUMENT_TYPES,
} from '~/Configurations/constants';
import { DOORING_AGENT_OTHER_FEES } from '~/Pages/Receivable/PODForm/ProofOfDeliveryPopup/Utilities/constants';

export default {
  sales: 'Sales {{number}}',
  mark_stages_payment: 'Mark {{stage}} Payment {{paymentStatus}}?',
  quantity: 'Quantity {{no}}',
  claim: 'Claim {{no}}',
  edit: 'Edit {{type}}',
  timestamp_at: 'Timestamp at {{type}}',
  timestamp_edited: 'Timestamp {{doNumber}} sucessfully Edited!',
  form_error_add_fee: {
    doc_warning: 'You haven’t collected {{no}} document.',
  },
  document_type_add_document: {
    [DOCUMENT_TYPE_ADD_DOCUMENT.CLAIM_AND_RETURN]: 'Claims & Returns {{suffix}}',
    [DOCUMENT_TYPE_ADD_DOCUMENT.LOADING_FEE]: 'Loading Fee {{suffix}}',
    [DOCUMENT_TYPE_ADD_DOCUMENT.UNLOADING_FEE]: 'Unloading Fee {{suffix}}',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OVERNIGHT_LOADING]: 'Overnight Loading {{suffix}}',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OVERNIGHT_UNLOADING]: 'Overnight Unloading {{suffix}}',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OTHER_LOCATION_FEE]: 'Other Location Fee {{suffix}}',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OTHER_FEE]: 'Additional Fee {{suffix}}',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OVERNIGHT_LOADING]: 'Overnight Loading {{suffix}}',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OVERNIGHT_UNLOADING]: 'Overnight Unloading {{suffix}}',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OTHER_LOCATION_FEE]: 'Other Location Fee {{suffix}}',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OTHER_FEE]: 'Additional Fee {{suffix}}',
  },
  review_document_heading_map: {
    [DOCUMENT_TYPE_ADD_DOCUMENT.CLAIM_AND_RETURN]: 'Upload Claims & Returns for {{doNumber}}',
  },
  total_hours_at: 'Total Hours at {{point}}',
  overnight_fee_for: 'Overnight fee for {{doNumber}}',
  overnight_type_info: 'Overnight {{type}} Fee',
  company_overnight_type_info: '{{company}} Overnight {{type}} Info',
  escalated_at: 'Escalated at {{time}}',
  escalate_different_data_for: 'Escalate Different Data for {{doNumber}}',
  called_at: 'Called at {{time}}',
  mark_payment_as: 'Mark as {{payment}}',
  duplicate_field_error: 'Duplicate {{field}} are not allowed! {{message}}',
  upload_receiving_card_for_trucking_do: 'Upload Receiving Card for {{doNumber}}',
  upload_the_KTP_with_a_maximum_size: 'Upload KTP with maximum {{size}}.',
  existing_location_field_message: 'Another location with this {{field}} already exists',
  total_locations: '{{total}} Locations',
  upload_the_NPWP_with_a_maximum_size: 'Upload NPWP with maximum {{size}}.',
  upload_the_SPPKP_with_a_maximum_size: 'Upload SPPKP with maximum {{size}}.',
  KTP_uploaded_successfully: 'KTP successfully uploaded',
  NPWP_uploaded_successfully: 'NPWP successfully uploaded',
  SPPKP_uploaded_successfully: 'SPPKP successfully uploaded',
  document_uploaded_successfully: 'Dokumen successfully uploaded',
  request_type_empty_info: 'Request Type can not be empty',
  ordinal_shipment_payment: 'Payment {{ordinal}} shipment fee',
  shipment_down_payment: 'Down payment {{percentage}}% shipment fee',
  ordinal_tax_payment: '{{taxType}} payment {{ordinal}}',
  pr_submit_succefully: '{{prNumber}} has been created',
  general_submit_success_message: '{{name}} successfully submitted.',
  general_creation_success_message: '{{name}} successfully added.',
  general_newly_creation_success_message: '{{name}} successfully created',
  general_edit_success_message: '{{name}} successfully updated.',
  general_upload_success_message: '{{name}} successfully uploaded.',
  user_successfully_created: '{{name}} successfully created.',
  user_successfully_updated: '{{name}} successfully updated.',
  sme_sales_successfully_updated: '{{name}} successfully updated.',
  sme_sales_successfully_created: '{{name}} successfully created.',
  sme_sales_team_leader_successfully_updated: 'SME Sales Team Leader for {{name}} successfully updated.',
  vehicle_successfully_updated: '{{license_plate}} successfully updated.',
  vehicle_successfully_created: '{{license_plate}}  successfully added.',
  warehouse_successfully_created: '{{name}} successfully added.',
  warehouse_successfully_updated: '{{name}} successfully updated.',
  warehouse_successfully_uploaded: '{{name}} successfully uploaded',
  location_successfully_created: 'Location {{name}} added.',
  location_successfully_edited: 'Location {{name}} edited.',
  location_successfully_deactivated: 'Location {{name}} deactivated.',
  location_successfully_uploaded: '{{name}} successfully uploaded',
  generic_successfully_uploaded: '{{name}} successfully uploaded',
  generic_failed_uploaded: '{{name}} failed uploaded',
  general_change_details: 'Change {{name}} Details',
  deactivate_location_warning: 'Deactivate {{name}}?',
  general_deactivate_location_confirmation: 'Are you sure you want to deactivate {{name}}?',
  general_request: '{{name}} request',
  general_choose: 'Choose {{name}}',
  general_assign: 'Assign {{name}}',
  general_reassign: 'Reassign {{name}}',
  general_confirmation: '{{name}} Confirmation',
  general_list: 'List {{name}}',
  general_create: 'Create {{name}}',
  general_new_item: 'New {{name}}',
  general_summary: '{{name}} Summary',
  general_shipper: '{{name}} Enterprise Shipper',
  general_shipper_group: '{{name}} Shipper Group',
  general_sme_shipper: '{{name}} SME Shipper',
  general_search: 'Search {{name}}',
  general_add: 'Add {{name}}',
  general_edit: 'Edit {{name}}',
  general_edit_detail: 'Update {{name}} detail',
  general_remove: 'Delete {{name}}',
  general_deactivate: 'Deactivate {{name}}',
  general_activate: 'Activate {{name}}',
  general_deactivation_confirmation: 'Deactivate {{name}}?',
  general_reactivation_confirmation: 'Reactivate {{name}}?',
  general_edit_confirmation: 'Edit {{name}}\'s Shipper Status',
  sales_pic_edit_confirmation: 'Edit {{name}}\'s Sales PIC',
  general_edit_pic: 'Edit {{name}}\'s PIC',
  general_field_already_exist: '{{name}} already exist',
  general_deactivation_warning_message: 'Are you sure you want to deactivate {{name}}',
  general_reactivation_warning_message: 'Are you sure you want to reactivate {{name}}',
  general_truck_requirement_deletion_warning_message:
    'Are you sure you want to delete Truck Requirements information for {{name}}?',
  general_truck_requirement_deletion_confirmation_message:
    'Proceeding will delete Truck Requirements information for {{name}}.',
  general_deactivation_success_message: '{{name}} successfully deactivated',
  general_activation_success_message: '{{name}} successfully activated',
  general_status_update: 'successfully Updated Status',
  general_removal_confirmation: 'Delete {{name}}?',
  general_remove_confirmation: 'Remove {{name}}?',
  general_removal_warning_message: 'Are you sure to delete {{name}}?',
  general_removal_success_message: '{{name}} successfully deleted',
  user_activation_confirmation: 'Activate {{name}}?',
  user_activation_warning_message: 'Activating user can ennable their access in using the Shipper Platform. ',
  user_activation_success_message: '{{name}} activated',
  user_deactivation_confirmation: 'Deactivate {{name}}?',
  user_deactivation_warning_message:
    '{{name}} will not be able to access Kargo Vendor Central,'
    + ' but it\'s information will still be saved. This action is permanent.',
  user_deactivation_success_message: '{{name}} successfully deactivated',
  driver_deactivation_confirmation: 'Deactivate {{name}}?',
  driver_deactivation_warning_message: 'Are you sure?',
  truck_deactivation_warning_message: 'You will deactivate {{name}}',
  form_error_message: {
    mandatory: '{{field}} is mandatory',
    required: 'Insert {{field}}',
    invalid_format: 'Insert valid {{field}}',
    min_length: 'Minimum length for {{field}} is {{min}}',
    max_length: 'Maximum length for {{field}} is {{max}}',
    max_value: '{{field}} must be less than {{max}}',
    min_value: '{{field}} must be greater than {{min}}',
    num_with_max_decimal_value: '{{field}} must be a number with maximum of {{fraction}} decimal value',
    number_with_decimals: 'Use 0 or positive number with up to 8 decimal points here.',
    strong_password_rule:
      '{{field}} should at least have one uppercase, one lowercase, one number and one special character',
    document_is_required: 'This document has to be uploaded.',
    field_already_exist: '{{ field }} already exist',
  },
  cancel_adding_fee: 'Cancel adding this fee?',
  lost_fee_warning_msg: 'This fee will be lost.',
  voyage_vessel_success_edit: 'Vessel Voyage {{doNumber}} successfully edited!',
  leave_this_page_title: 'Leave this page?',
  leave_page_warning_msg: 'Every fee and detail in this proforma invoice will be lost.',
  refresh_page_warning: 'Data will be lost if you leave the page, are you sure?',
  invoicing_without_tax_applied: 'Continue invoice this customer without tax detail?',
  invoicing_without_tax_applied_desc:
    'This invoice doesn\'t include tax yet because you haven\'t'
    + ' included the payment and the tax for {{name}}. Are you sure you want to continue?',
  add_account_helper_text: 'Add bank account to be added to invoice',
  delete_do_from_invoice: 'Delete {{doNumber}} from this invoice?',
  contact_kargo_to_change_status_of_fast_payment: 'Contact Kargo to change the status of the fast payment',
  not_listed: 'Not listed',
  delete_this_bank_account: 'Delete this bank account?',
  account_deactivation_warning_message: 'You will not be able to use this bank account to create invoice',
  generic_text_input_placeholder: 'Type {{field}}',
  resend_otp_countdown_message: 'Resend OTP on {{countdown}}',
  pagination_indicator: 'Page {{page}} from {{total}}',
  select_item_indicator: '{{item}} of {{total}} Docs',
  x_accounts_has_been_selected_with_total_balance_of:
    '{{total_selected}} {{businessLine}} Accounts has been Selected with total balance of {{total_amount}}',
  x_shipment_selected: '{{total_selected}} shipments selected from {{shipper}}',
  x_shipment_from_shipper_selected: '{{total_selected}} Shipments from {{shipper}} has been selected',
  x_shipment_selected_to_be_marked_as_not_invoiceable:
    '{{total_selected}} Shipments selected to be Marked as Not Invoiceable',
  x_DOS_selected: '{{total_selected}} DOs from {{shipper}} has been selected',
  x_item_selected_total_amount: '{{total_selected}} Items Selected with total amount of {{total_amount}}',
  x_container_selected: '{{total_selected}} containers selected',
  x_others: '{{x}} Others',
  x_payment_request_for_amount_disburse: '{{payment_request}} PR for {{amount}} are selected',
  x_payment_request_for_amount_mark_as_disbursed: '{{payment_request}} PR for {{amount}} will be marked as disbursed',
  x_payment_request_for_amount_schedule_disburse:
    '{{payment_request}} PR for {{amount}} will be scheduled for disbursement',
  uploaded_file_subtitle: 'Uploaded by {{actor}} at {{datetime}} from {{source}}',
  uploaded_file_on_subtitle: 'Uploaded on {{datetime}}',
  uploaded_file_short_subtitle: 'Uploaded at {{datetime}}',
  uploaded_file_medium_subtitle: 'Uploaded at {{datetime}} from {{source}}',
  uploaded_file_x_at_y: '{{name}} uploaded at {{datetime}}',
  pic_sequence: 'PIC {{sequence}}',
  customer_successfully_created: '{{name}} successfully added.',
  customer_successfully_updated: '{{name}} successfully updated.',
  last_update_at: 'Last update at {{datetime}}',
  update_activity_log: 'Update Activity Log for {{doNumber}}',
  activity_log: 'Activity Log for {{doNumber}}',
  edit_shipment_status: 'Edit Status for {{doNumber}}',
  create_zendesk_ticket: 'Create Zendesk Ticket for {{doNumber}}',
  edit_timestamps: 'Edit Timestamps for {{doNumber}}',
  change_shipment_status: 'Change {{do_number}} status?',
  change_status_time: 'Change {{do_number}} status time?',
  change_shipment_status_message: 'Change status to {{status}}?',
  change_invoice_status: 'Change invoice {{invoice_number}} status?',
  change_invoice_status_message: 'Change invoice status to {{status}}?',
  cancel_do: 'Cancel {{do_number}}?',
  do_cancelled: '{{do_number}} cancelled.',
  delete_bill_of_lading: 'Delete {{blNumber}}?',
  price_rate_type: 'Per {{unit}}',
  review_pod_for: 'Review {{point}} Proof of Delivery for {{doNumber}}',
  pod_for: 'Proof of Delivery for {{doNumber}}',
  pol_of: 'Proof of Loading of {{doNumber}}',
  invoice_document_type_for: {
    [INVOICE_DOCUMENT_TYPES.OCEAN_FREIGHT]: 'Ocean Freight for {{doNumber}}',
    [INVOICE_ADD_DOCUMENT_TYPES.THC_D]: 'THC-D for {{doNumber}}',
    [INVOICE_ADD_DOCUMENT_TYPES.THC_L]: 'THC-L for {{doNumber}}',
    [INVOICE_ADD_DOCUMENT_TYPES.LSS]: 'LSS for {{doNumber}}',
    [INVOICE_ADD_DOCUMENT_TYPES.OTHER_FEE]: 'Other Fee for {{doNumber}}',
    [DOORING_AGENT_OTHER_FEES.THC_LOLO]: 'THC-LOLO for {{doNumber}}',
    [DOORING_AGENT_OTHER_FEES.STORAGE_FEE]: 'Storage Fee for {{doNumber}}',
    [DOORING_AGENT_OTHER_FEES.DEMURRAGE_FEE]: 'Demurrage Fee for {{doNumber}}',
    [SUPPORTING_DOCUMENT_TYPES.PROOF_OF_ROUTES_CHANGES]: 'Proof of Routes Changes for {{doNumber}}',
  },
  invoice_fee_info: 'Fee Info - {{document}}',
  preview_pod_for: 'Preview POD for {{doNumber}}',
  loading_fee_for: 'Loading Fee for {{doNumber}}',
  unloading_fee_for: 'Unloading Fee for {{doNumber}}',
  other_fee_for: '{{type}} for {{doNumber}}',
  claim_and_return_for: 'Claims & Returns for {{doNumber}}',
  overnight_loading_for: 'Overnight Loading for {{doNumber}}',
  overnight_unloading_for: 'Overnight Unloading for {{doNumber}}',
  payment_sequence_with_time: 'Payment {{sequence}}{{secondaryWording}} ({{datetime}})',
  payment_sequence: 'Payment {{sequence}}',
  review_pol_for: 'Review {{point}} Proof of Loading for {{doNumber}}',
  review_claim_return_document_for: 'Review {{point}} Claim/Return Document for {{doNumber}}',
  review_claim_return_goods_for: 'Review {{point}} Claim/Return Goods for {{doNumber}}',
  file_error: {
    1: 'Cannot Find Company',
    9: 'Cannot Find Warheouse',
    13: 'Invalid Order Type',
    45: 'Location Fee already exists with same Fee Type, Shipment Type, Truck Type, and Body Type',
    46: 'Location Fee already exists with same Fee Type, Shipment Type, Truck Type, and Body Type',
    55: 'Invalid Vehicle ID',
    56: 'Invalid SKU',
    58: 'Invalid Capacity Unit',
    60: 'Address ({{column_name}}) is Outside Indonesia',
    61: 'Cannot Find Address ({{column_name}})',
    62: 'Invalid Loading Date',
    63: 'Invalid Unloading Date',
    64: 'Cannot find truck',
    65: 'Invalid SKU',
    66: 'Cannot Find Customer',
    67: 'Cannot Find Driver',
    96: 'BL not found',
    97: 'Invalid container status',
    98: 'Invalid container type',
    99: 'Warehouse not found',
    105: 'Partnership pricing with given transporter does not exist',
    113: 'Invalid Order ID',
    114: 'Invalid Shipment Type',
    115: 'Invalid Latest Loading Date Time',
    116: 'Invalid Location ID',
    117: 'Invalid Truck Type',
    118: 'Invalid Truck Subtype',
    119: 'Truck Type or Subtruck Type Not Found',
    120: 'Invalid Loads Capacity',
    121: 'Shipment Type Not Found',
    122: 'Multiple Company Shipment Type Found',
    123: 'Company Order Type Not Found',
    124: 'Multiple Company Order Type Found',
    125: 'Invalid Transporter ID or Name',
    126: 'requested_pickup_datetime must be earlier than latest_pickup_datetime',
    127: 'requested_dropoff_datetime must be later than requested_pickup_datetime',
    128: 'latest_pickup_datetime must be earlier than requested_dropoff_datetime',
    140: 'Invalid window time',
    142: 'Invalid SKU',
    143: 'Invalid SKU quantity',
    474: 'Invalid Header CSV',
    475: 'Invalid column {{column_name}}',
    476: 'Invalid CSV File',
    477: 'Empty CSV Header',
    478: 'Unknown Error',
    479: 'Invalid Escape Sequence',
    480: 'Invalid Row Length',
    481: 'Invalid Row With Stray Quotes',
    483: 'Vehicle License Plate Already Registered',
    1001: 'Mandatory field {{columnName}} is missing',
    1101: 'Invalid Vendor ID',
    1102: 'Invalid Vendor Name',
    1103: 'Invalid Effective Date',
    1104: 'Invalid Expiration Date',
    1105: 'Invalid Truck Type',
    1106: 'Invalid Amount',
    1107: 'Invalid Shipment Type',
    1108: 'Invalid Lane ID',
    2001: 'Invalid Operational Day',
    2002: 'Invalid Available Facility',
    2003: 'Invalid radius',
    2004: 'Invalid Fee Name',
    2005: 'Invalid Longitude',
    2006: 'Invalid Latitude',
    2007: 'Invalid province',
    2008: 'Invalid City',
    2009: 'Invalid District',
    2010:
      'Must input operational hours for at least one operational hours column'
      + ' (operational_monday, operational_tuesday, operational_wednesday, operational_thursday,'
      + ' operational_friday, operational_saturday, operational_sunday, operational_holiday)',
    2011: 'Invalid Name',
    2012: 'Invalid Address',
    2013: 'Invalid Location ID',
    2014: 'Invalid Location Code',
    2015: 'Invalid region',
    2016: 'Duplicate city found',
    2017: 'Duplicate region found',
    2018: 'Duplicate location group found',
    2501: 'Location ID Not Found',
    2502: 'Fee type is invalid',
    2503: 'Shipment type is invalid',
    2504: 'Max amount is invalid number',
    2505: 'Reimbursement percentage is invalid',
    2506: 'Reimbursement requirement is invalid',
    2507: 'Hmm, this truck type doesn\'t exist. Let\'s pick another one',
    2508: 'Reimbursement type is invalid',

    // BT-1636 podf upload
    74: 'Company does not exist',
    141: 'Invalid timezone',
    422: 'Invalid parameter',
    556: 'Required field',
    513: 'Invalid shipment type',
    557: 'Invalid datetime',
    518: 'Invalid goods type',
    519: 'Invalid capacity unit',
    57: 'Invalid pricing rate type',
    558: 'Invalid decimal',
    517: 'Invalid sub truck type',
    486: 'Invalid shipper name',
    484: 'Invalid pickup location',
    485: 'Invalid dropoff location',
    559: 'Invalid transporter name',
    560: 'Vehicle not found for transporter',
    526: 'Shipper pricing not found',
  },
  form_dialog: {
    upload_entity_from_csv_file: 'Upload {{entity}} from CSV',
    fill_in_this_CSV_file_to_upload_a_entity: 'Fill this CSV to upload a {{entity}}.',
  },
  destination_to_port: 'Destination: {{port}}',
  choose_shipment_from_shipper: 'Choose shipment from {{shipper}}',
  only_choose_from_same_x: 'You can only choose from the same {{x}}',
  delete_payment_request: 'Delete {{shipment_number}} from Payment Request?',
  load_time: 'Loading {{datetime}}',
  shipment_date_on: 'on {{datetime}}',
  update_appointment_number_or_spm_for_do: 'Update Appointment Number/SPM for {{doNumber}}',
  upload_pod_for_shipment: 'Upload POD for {{shipment_number}}',
  upload_pol_for_shipment: 'Upload Proof of Loading of {{shipment_number}}',
  upload_receipt_with_maximum_size: 'Upload receipt with maximum size of {{max_size}}.',
  detail_of_shipment: 'Details of {{shipment_number}}',
  pod_from_source: 'POD from {{source}}',
  add_additional_fee_for_shipment: 'Add Other Fees for {{shipment_number}}',
  update_additional_fee_for_shipment: 'Update additional fee for {{shipment_number}}',
  add_additional_fee: 'Add {{payment_type}}',
  update_additional_fee: 'Edit {{payment_type}}',
  add_additional_detail: '{{payment_type}} details',
  additional_fee_successfully_added: '{{payment_type}} successfully added.',
  additional_fee_successfully_updated: '{{payment_type}} successfully updated.',
  delete_payment_warning: 'This fee will not be included in this invoice if you continue',
  payment_type_ppn: 'VAT {{ppn_percentage}}%',
  payment_type_pph23: 'WHT 23 {{pph23_percentage}}%',
  interest_percentage: '{{interest_percentage}}% Interest',
  invoice_subject_template: 'Proforma Invoice No. {{invoice_number}} dari {{publisher}}',
  invoice_sent_successfully: 'Invoice {{invoice_number}} berhasil terkirim.',
  invoice_date_is: 'Tanggal Invoice {{date}}',
  proforma_invoice_date_is: 'Proforma Invoice Date {{date}}',
  invoice_for: 'Invoice For {{shippingLineDoNumber}}',
  revise_invoice: 'Revisi Invoice ke-{{sequence}}',
  confirm_revise_invoice: 'Confirm revised invoice number{{sequence}}',
  email_revised_invoice: 'Email Revised Invoice number {{sequence}}',
  shipment_status_tracker_map: {
    [SHIPMENT_STATUSES.UPCOMING]: 'Shipment Created by {{actor}}',
    [SHIPMENT_STATUSES.PLANNED]: 'Planned',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truck Assigned',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'In Transit to Origin',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Arrive at Origin',
    [SHIPMENT_STATUSES.START_LOADING]: 'Start Loading',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Finish Loading',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'In Transit to Destination',
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Arrive at Destination',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start Unloading',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Finish Unloading',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Unfulfilled',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Reassigned',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Rejected by vendor',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Expired',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_ORIGIN]: 'Queueing At Origin',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_DESTINATION]: 'Queueing At Destination',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'Queueing Overtime',
    [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'Behind Schedule',
    [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'On Time',
  },
  arrived_at_datetime_map: 'Arrived at {{type}} Datetime',
  departed_from_datetime_map: 'Departed from {{type}} Datetime',
  shipment_status_manual_update_log: 'Status changed to {{updated_status}} by {{actor}}',
  shipment_status_updateby__updateto_log: 'Activity update , {{issue_type}}',
  shipment_status_updateby__updateto_log_old:
    'Activity update by {{actor}}, {{issue_type}}, {{updated_to}} {{additional_information}}',
  shipment_activity_log: {
    create_zendesk_ticket: 'Zendesk Created',
    create_zendesk_ticket_with_actor: 'Zendesk Created by {{actor}}',
    single_shipment_update_shipment_timestamp:
      '{{status}} Timestamp updated by {{actor}} from {{from_timestamp}} to {{to_timestamp}}',
    multi_shipment_update_shipment_timestamp:
      '{{point_name}} {{status}} Timestamp updated by {{actor}} from {{from_timestamp}} to {{to_timestamp}}',
    reassign_shipment: 'Truck reassignment',
    reassign_shipment_with_actor: 'Truck reassignment made',
    submit_reassignment_request: 'Truck reassignment requested by Transporter {{transporter}} via {{platform}}',
    approve_reassignment_request: 'Truck reassignment approved via {{platform}}',
    approve_reassignment_request_with_actor: 'Truck reassignment approved by {{actor}} via {{platform}}',
    cancel_shipment: 'Cancelled by Transporter',
    cancel_shipment_with_actor: 'Cancelled by Transporter, {{actor}}',
    create_delivery_order: 'Shipment is created via {{platform}}',
    create_delivery_order_with_actor: 'Shipment is created by {{actor}} via {{platform}}',
    post_to_marketplace_delivery_order: 'Shipment posted to Marketplace via {{platform}}',
    btms_blast_notification_exclusive_transporter: 'Shipment blasted to Tiered Transporter via {{platform}}',
    cancel_marketplace_delivery_order: 'Shipment cancelled from Marketplace via {{platform}}',
    reopen_marketplace_delivery_order: 'Shipment reopened to Marketplace via {{platform}}',
    assign_shipment: 'Shipment assigned via {{platform}}',
    cancel_delivery_order: 'Shipment cancelled via {{platform}}',
    unfulfill_delivery_order: 'Shipment unfulfilled via {{platform}}',
    allocate_delivery_order: 'Shipment allocated via {{platform}}',
    choose_winning_bid: '{{transporter}} chosen as winner via {{platform}}',
    transporter_confirm_allocation: '{{transporter}} confirmed the shipment allocation via {{platform}}',
    reallocate_delivery_order: 'Shipment reallocated to {{transporter}} via {{platform}}',
    reject_reassignment_request: 'Truck Reassignment Request Rejected',
    reject_reassignment_reason: 'Truck reassignment request for {{licensePlate}}'
    + ' rejected with reason: {{reason}}',
    assign_truck_driver_to_shipment:
      '{{transporter}} allocated {{license_plate}} and {{driver_name}} for this shipment via {{platform}}',
    shipment_geofence_trigger:
      'Geofence triggers {{license_plate}} going {{direction}} {{warehouse_name}} via {{platform}}',
    create_proof_of_delivery_completion: 'Proof of Delivery has been uploaded via {{platform}}',
    update_proof_of_delivery_completion_dtd: 'Proof of Delivery has been updated via {{platform}}',
    edit_proof_of_delivery_completion: 'Proof of Delivery has been edited via {{platform}}',
    create_proof_of_delivery_completion_spl: 'OF, THC-L, THC-D, and LSS Uploaded has been uploaded via {{platform}}',
    change_shipment_status: '{{action}} {{pointNumber}} via {{platform}}',
    updated_pricing_due_to_shipper_pricing: 'Shipment Pricing has been edited via {{platform}}',
    updated_shipper_pricing_invalid_for_delivery_order:
      'But the pricing updated cannot be reflected into '
      + 'this shipment as the detail changed for this shipment does not match the shipment',
    update_delivery_order: 'Shipment Details has been edited via {{platform}}',
    queue_shipment: 'Shipment has been queued via {{platform}}',
    bump_down_active_shipment:
      '{{currentDoNumber}} has been replaced by {{previousDoNumber}} in the queue via {{platform}}',
    bump_up_queued_shipment:
      '{{currentDoNumber}} has become Assigned and Active for {{license_plate}} replacing {{previousDoNumber}}',
    review_shipment_document: '{{documentType}} {{reviewStatus}} {{pointNumber}} by {{actor}}',
    sme_contractless_referral_do_sent_for_shipper_confirmation:
      'New shipment confirmation link has been sent to Shipper',
    sme_contractless_referral_do_confirmed_by_shipper: 'Shipment has been confirmed by Shipper',
    sme_contractless_referral_do_cancelled_by_shipper: 'Shipment has been canceled by Shipper',
    add_dooring_do_unloading_date: 'Added Planned Dooring Date',
    update_dooring_pickup_date_from_actual_berthing: 'Actual Berthing at Port of Discharge',
    btms_post_dtd_job_to_private_marketplace: 'Successfully Post job to Marketplace',
    btms_update_container_ro: 'Release order number and expired date updated',
    btms_add_appointment_number: 'Appointment Number created as {{x}}',
    btms_update_appointment_number: 'Appointment Number updated to {{x}}',
    btms_add_spm: 'Surat Perintah Muat created',
    btms_update_spm: 'Surat Perintah Muat updated',
    upload_receiving_card: 'Receiving Card Uploaded and Container Yard updated as {{x}}',
    edit_receiving_card: 'Receiving Card Uploaded and Container Yard edited to {{x}}',
    upload_shipment_document: 'You have uploaded {{x}}',
    edit_shipment_document: '{{x}} updated',
    add_container_details: 'Container number & Seal number added',
    [CUSTOM_OPERATIONS.UPDATE_CONTAINER_NUMBER]: 'Container number updated to {{containerNumber}}',
    [CUSTOM_OPERATIONS.UPDATE_SEAL_NUMBER]: 'Seal number updated to {{sealNumber}}',
    [CUSTOM_OPERATIONS.UPDATE_CONTAINER_AND_SEAL_NUMBER]:
      'Container number updated to {{containerNumber}} '
      + '& Seal number updated to {{sealNumber}}',
    dtd_edit_shipment_route: 'Shipment Route Updated',
    dtd_edit_shipment_route_item: '{{x}} edited to {{y}}',
    dooring_demurrage_reason: 'Demurrage Reason: {{chargedReason}}',
    demurrage_description: 'Description: {{notes}}',
    update_shipment_points: 'Shipment Point for DO created/updated',
    update_unloading_from_shipping_line_details: 'Unloading date updated from editing Shipping Line DO details',
    btms_edit_fm_containers_bill_of_lading: 'Bill of Lading Number has been updated to {{x}}',
    btms_accept_now_trucking_job_tapp: 'BTMS job have been accepted by TApp',
    shipment_gps_location_check_point: 'Location Updated at {{location}} via {{source}}',
    asked_driver_location_via_whatsapp: 'System asked Driver Location via Whatsapp',
  },
  shipment_activity_log_spl: {
    btms_create_shipping_line_booking: 'Shipment is created via {{platform}}',
    create_delivery_order_with_actor: 'Shipment is created by {{actor}} via {{platform}}',
    cancel_delivery_order: 'Shipment cancelled via {{platform}}',
    upload_proof_of_delivery_completion_dtd: '{{uploadDocument}} Uploaded',
    delete_proof_of_delivery_completion_dtd: '{{uploadDocument}} Deleted',
    edited_proof_of_delivery_completion_dtd: '{{uploadDocument}} Edited',
    change_shipment_status: '{{action}} {{pointNumber}} via {{platform}}',
    btms_add_container_to_existing_shipping_line: '{{numContainers}} Container Added',
    add_shipping_line_remarks: 'Remarks added',
    edit_shipping_line_remarks: 'Remarks updated',
    add_shipping_line_transit_vessel_voyage: 'Transit Vessel Voyage added',
    edit_shipping_line_transit_vessel_voyage: 'Transit Vessel Voyage updated',
    create_shipping_instruction: 'Shipping Instruction {{shipperName}} - {{destinationWh}} Created',
    edit_shipping_instruction: 'Shipping Instruction {{shipperName}} - {{destinationWh}} Updated',
    btms_confirm_shipping_line_container: '{{x}} Containers Confirmed',
    upsert_dtd_bill_of_lading: 'Bill of Lading (BL) Uploaded',
    btms_edit_shipping_line_closing_date: 'Your Closing date updated to {{time}}',
    btms_edit_shipping_line_estimated_time_arrival: 'Your Estimated Time of Arrival (ETA) updated to {{time}}',
    btms_edit_shipping_line_estimated_time_berthing: 'Your Estimated Time of Berthing (ETB) updated to {{time}}',
    btms_edit_shipping_line_estimated_time_departure: 'Your Estimated Time Departure (ETD) updated to {{time}}',
    btms_remove_shipping_line_container: '{{numContainers}} Containers Removed',
    edit_document: '{{document}} Edited',
  },
  rejection_with_reason: 'Rejection Reason: {{reason}}',
  date_range_invalid: 'Date range {{field_start}} to {{field_end}} is invalid',
  date_should_be_earlier: '{{field_start}} should be earlier than {{field_end}}',
  account_successfully_updated_as_default: 'Account {{account_number}} saved as main account',
  x_thing_for_y: '{{x}} for {{y}}',
  dof_request_successfully_submitted: 'Pengajuan {{dof_number}} Berhasil ditambahkan.',
  podf_request_successfully_submitted: 'Pengajuan {{podf_number}} Berhasil ditambahkan.',
  dof2_request_successfully_submitted: 'Pengajuan {{dof2_number}} Berhasil ditambahkan.',
  dof_request_review_successfully_submitted: 'Hasil review {{dof_number}} berhasil dikirim ke {{applicant}}',
  podf_request_review_successfully_submitted: 'Hasil review {{podf_number}} berhasil dikirim ke {{applicant}}',
  dof_request_successfully_disbursed: 'Detail Pencairan {{dof_number}} berhasil dikirim ke {{applicant}}',
  podf_request_successfully_disbursed: 'Detail Pencairan {{podf_number}} berhasil dikirim ke {{applicant}}',
  submitted_at: 'Submitted at {{timestamp}}',
  general_search_placeholder: 'Find {{fields}}',
  review_request_submission: 'Review submission {{request_name}}',
  fill_disbursement_details_dof_request: 'Fill disbursement details {{request_name}}',
  fill_disbursement_details_podf_request: 'Fill disbursement details {{request_name}}',
  done_review_with_progress: '{{progress}}/{{total}} Reviews done',
  dof_first_disbursement_summary: '{{percentage}} of {{total}} Shipment',
  podf_first_disbursement_summary: '{{percentage}} of {{total}} Invoice',
  percentage_from_kargo_fee: '{{percentage}}% from Kargo Fee',
  x_invoice: '{{x}} Invoice',
  created_at: 'Created at {{date}}',
  podf_invoice_keyword_not_found: 'Did not find invoice {{keyword}}',
  dof2_invoice_keyword_not_found: 'Did not find invoice {{keyword}}',
  detail_of: '{{object}} Details',
  x_shipment_selected_short: '{{x}} Shipments selected',
  x_shipment_from_y_dof_selected: '{{x}} Shipments from {{y}} DOF selected',
  load_at: 'Loading at {{date}}',
  x_shipments: '{{x}} Shipment',
  x_invoices: '{{x}} Invoice',
  x_DOF: '{{x}} DOF',
  delete_entity_warning: 'Do you want to delete this {{entity}}',
  review_entity: 'Review {{entity}}',
  submitted_on: 'Submitted on {{date}}',
  total_cost_is: 'Total Cost {{cost}}',
  dof2_eligibility_message_map: {
    1: 'Shipment is not completed',
    2: 'Shipment is already registered in {{dof_2_number}}',
    3: 'Shipment is already registered in {{do_invoice_number}}',
  },
  x_out_of_y_shipments: '{{x}} out of {{y}} Shipments',
  first_disbursement_calculation: '{{shipment_fee_percentage}}% of Shipment - Kargo Fee - VAT',
  invoice_successfully_added: 'Invoice {{invoice_number}} successfully added',
  last_updated_at: 'Last Updated at {{updated_at}}',
  last_seen: 'Last seen {{updated_at}}',
  last_updated_at_and_by: 'Last Updated at {{updated_at}} by {{updated_by}}',
  created_on_and_by: 'Created on {{inserted_at}} by {{actor}}',
  updated_on_and_by: 'Last Updated on {{updated_at}} by {{actor}}',
  review_fees: 'Review {{doNumber}} Fees',
  delete_adjustment: 'Delete adjustment?',
  adjustment_will_be_deleted_from_review: 'This adjustment will be deleted from your review',
  view_n_files: 'View {{n}} Files',
  n_files: '{{n}} Files',
  companys_documents: '{{company}}\'s Documents',
  upload_companys_documents: 'Upload {{company}}\'s Documents',
  review_companys_documents: 'Review {{company}}\'s Documents',
  expires_on: 'Expires {{date}}',
  tax_expires_on: 'Tax Expires {{date}}',
  account_number: 'Bank Account {{number}}',
  shipper_notes_claims: {
    cond_e:
      'Untuk transporter dengan asuransi, driver harus memiliki bukti'
      + ' klaim seperti foto bongkar dan muat untuk dokumentasi. Jika bukti tidak mencukupi,'
      + ' klaim akan dibebankan sepenuhnya ke pihak transporter.'
      + ' Akan ada form tersendiri untuk menginput bukti-bukti ini.',
    cond_a:
      'Apabila ada barang muatan yang {{damageType}} maka barang harus dikembalikan ke pihak pengirim.'
      + '\nHarap menghubungi pihak Kargo di WhatsApp +6281213116433 (hanya melalui chat, tidak bisa melalui telepon)'
      + ' untuk tujuan pengembalian barang (pitstop terdekat/gudang bongkar/muat)'
      + ' dan konfirmasi jenis penolakan yang lain.',
    cond_b:
      'Apabila ada barang muatan yang ditolak karena {{damageType}} maka klaim'
      + ' tersebut menjadi tanggung jawab anda.\nHarap menghubungi pihak Kargo di WhatsApp'
      + ' +6281213116433 (hanya melalui chat, tidak bisa melalui telepon) jika ada pertanyaan.',
    cond_c:
      'Pengembalian barang / penyelesaian klaim di tempat dapat mengurangi'
      + ' jumlah total klaim bila dapat di gunakan ulang oleh shipper.'
      + ' Hal ini akan sepenuhnya diputuskan oleh shipper.',
    cond_d:
      'Pastikan driver mendapatkan keterangan klaim lengkap di {{showClaimsIn}}.'
      + ' Tanda tangan driver di dokumen tersebut menandakan bahwa driver menyetujui keterangan klaim tersebut.',
  },
  truck_sequence: 'Truck {{n}}',
  general_created: '{{name}} Created',
  general_added: '{{name}} Added',
  general_updated: '{{name}} Edited',
  general_save: 'Save {{name}}',
  general_verify: 'Verify {{name}}',
  inform_expiry_date: 'Expires {{date}}',
  x_out_of_y_documents_reviewed: '{{x}} out of {{y}} Documents Reviewed',
  activity_log_time: 'Activity Log: {{time}} {{timezone}}',
  transporter_sequence: 'Transporter {{sequence}}',
  capacity_in_kg: '{{capacity}} Kg',
  capacity_in_ton: '{{capacity}} Ton',
  capacity_in_cbm: '{{capacity}} CBM',
  capacity_in_unit: '{{capacity}} unit',
  weight_tolerance_in_ton: '{{weightTolerance}} ton',
  general_uploading: 'Uploading {{name}}',
  general_uploaded: '{{name}} uploaded',
  general_verified: '{{name}} verified',
  general_deactivated: '{{name}} deactivated',
  general_activated: '{{name}} activated',
  hold_pr: 'Hold {{prNumber}}',
  reject_pr: 'Reject {{prNumber}}',
  cancel_pr: 'Cancel {{prNumber}}',
  payment_request_banner: 'PR {{status}} {{secondaryText}}',
  invoice_detail_banner: '{{status}} Reason',
  will_be_shown_here: '{{field}} will be shown here',
  general_price: '{{name}} Price',
  assign_shipment: 'Assign {{doNumber}}?',
  cannot_assign_shipment: '{{doNumber}} cannot be assigned',
  change_do_status_to_assigned: 'Change {{doNumber}} status to be Assigned',
  shipment_assigned: 'Shipment {{doNumber}} successfully assigned',
  chosen_winner: 'Chosen {{winner}} as winner',
  create_reminder_for_shipment: 'Create Reminder for {{doNumber}}',
  for_units_with_no_pol_driver_can_be_navigate_to_load:
    'Untuk Unit Dengan No Pol Driver {{licensePlate}} Bisa di'
    + ' Arahkan Untuk Muat Hari ini ({{date}}​) Jam {{time}} di :',
  shipment_quality_score: 'Shipment quality {{score}}%',
  assignment_reminder_message:
    '*{{doNumber}}* \n Driver {{driver}} \n\n          Untuk Unit Dengan'
    + ' No Pol Driver {{licensePlate}} Bisa di Arahkan Untuk Muat Tanggal {{date}} Jam {{time}} di : \n'
    + '\n\n          *Muat*{{origins}}\n             \n\n\n          *Bongkar*'
    + '{{destinations}}\n            \n\n\n          *Notes*'
    + ' \n {{jobDescription}} \n\n\n          *Kelengkapan Armada* \n {{jobVehicleRequirement}} \n'
    + '\n\n          *Kelengkapan Supir* \n {{jobDriverRequirement}}\n        ',
  reopen_job_to_marketplace: 'Reopen {{doNumber}} to maketplace?',
  reopened_marketplace_job: 'Reopened {{doNumber}}',
  cancel_shipment: 'Cancel {{doNumber}}?',
  unfulfill_shipment: 'Unfulfill {{doNumber}}?',
  cancel_job_from_marketplace: 'Cancel {{doNumber}} from marketplace?',
  job_cancelled_from_marketplace: 'Cancelled {{doNumber}} from Marketplace',
  shipment_quality: 'Shipment Quality {{quality}}',
  reminder_message_copied: 'Reminder for {{doNumber}} copied.',
  reminder_for_shipper_sent: 'Reminder for {{shipperName}} has sent',
  soa_for_customer_is_downloaded: 'SoA for {{shipperName}} has been downloaded',
  fee_for_invoice_type: '{{invoiceType}} for {{doNumber}}',
  add_fee_for_invoice_type: 'Add {{invoiceType}} for {{doNumber}}',
  edit_fee_for_invoice_type: 'Edit {{invoiceType}} for {{doNumber}}',
  soa_template_name: 'SoA - {{name}}',
  before_downpayment: '{{percent}}% Before DP',
  after_downpayment: '{{percent}}% After DP',
  sending_x_documents_to_recipient: 'Sending {{totalDos}} groupped DOs to {{recipient}}',
  documents_delivered_to_recipient: 'Documents Delivered to {{recipient}}',
  copy_to_clipboard: '{{item}} copied',
  updated_by: 'Updated by {{name}}',
  update_x: 'Update {{x}}',
  delete_tax: 'Delete {{taxType}}-{{taxPercentage}}% for {{doNumber}} from Payment Request',
  delete_tax_warning: 'The {{taxType}}-{{taxPercentage}}% for {{doNumber}} will be deleted from your Payment Request',
  delete_payment_from_invoice: 'Delete payment from Invoice {{doNumber}}?',
  csv_team_leader_uploaded: 'CSV uploaded, {{team_leaders}} team leaders and {{canvassers}} canvassers configured',
  invoice_type_info_map: {
    [DO_INVOICE_FEE_TYPES.LOADING_FEE]: 'Loading Fee Info',
    [DO_INVOICE_FEE_TYPES.UNLOADING_FEE]: 'Unloading Fee Info',
    [DO_INVOICE_FEE_TYPES.OTHER_FEE]: 'Other Fee Info',
  },
  leave_invoice_page_warning_msg:
    'This invoice will not be generated.You will lost all the data you have inputted so far',
  cancel_this_invoice: 'Cancel this invoice?',
  lose_all_the_data_inputted: 'You\'ll lose all the data you\'ve inputted so far.',
  activate_sme_sales_title: 'Activate {{name}} to use Sales App?',
  reassign_message_content: 'There are {{shippers}} shippers that need to be reassigned',
  replacement_label: '{{name}} Replacement',
  team_leader_reassign_explanation: 'The selected team leader replacement will lead {{sales}}\'s current sales member',
  sales_reassign_explanation: 'The selected sales replacement will handle all {{sales}}\'s shippers',
  leader_changed_message: '{{name}} leader changed',
  change_team_leader_title: 'Change {{name}}’s Team Leader',
  bid_slot_label: '{{slot}} Slot',
  custom_invoice_total_fee_message: 'Total Amount is calculated from Amount x {{unit}}',
  custom_invoice_edit_fee_warning: 'If you edit this fee, invoice {{invoice}} will be modified as a result',
  custom_invoice_delete_fee_disabled:
    'If you want to delete this fee, please edit invoice {{invoice}} and exclude this fee from the invoice',
  max_do_selection_exceeded: 'The limit of orders that can be selected is {{x}} Orders.',
  selected_do_count: '{{x}} Order(s)',
  remove_n_containers_from_po: 'remove {{n}} containers from {{po}}',
  mass_do_cancellation_confirmation: 'Are you sure you want to cancel {{x}} Orders?',
  exclusive_shipment_info_title: 'This shipment is exclusive and will be shown to {{count}} transporters',
  non_exclusive_shipment_info_title: 'This shipment is non-exclusive and will be shown to {{count}} transporters',
  total: 'Total {{name}}',
  price_per_pricing_type: 'Price {{pricing_type}}',
  ensure_total_do_adjusted_accordingly: 'Please ensure price of the {{total}} DOs are adjusted accordingly',
  review_do_manually: 'Review {{do_number}} Manually?',
  queue_shipment_for: 'Queue Shipment for {{licensePlate}}',
  cancel_uploading_pod: 'Cancel uploading POD for {{doNumber}}?',
  cancel_uploading_of: 'Cancel Ocean Freight for {{doNumber}}',
  change_automated_disbursement: '{{action}} Automated Disbursement',
  apply_to_all_fee_in_invoice: 'Apply it to all {{feeName}} in this Invoice',
  bulk_approval_item: 'Bulk {{action}} {{total}} items?',
  business_line_incentive: '{{business_line}} Incentive',
  download_selected_pod_files: 'Selected POD - {{podCount}} Files',
  at_date_by: 'at {{date}} by',
  do_numbers_selected: '{{number}} DO Numbers Selected',
  must_be_later_than: '{{firstDate}} must be later than {{secondDate}}',
  must_be_earlier_than: '{{firstDate}} must be earlier than {{secondDate}}',
  shipper_x: 'Shipper {{x}}',
  virtual_account_x: 'Virtual Account {{x}}',
  edit_tags_for_container: 'Edit Tags for {{jobNumber}}',
  input_x: 'Input {{x}}',
  search_x: 'Search {{x}}',
  select_x: 'Select {{x}}',
  origin_x: 'Origin {{x}}',
  store_x: 'Store {{x}}',
  x_purchase_order: '{{x}} Purchase Order',
  x_fm_trucking: '{{x}} FM Trucking',
  destination_x: 'Destination {{x}}',
  location_pickup_date: '{{location}} - Pickup Date',
  location_dropoff_date: '{{location}} - Drop Off Date',
  update_container_information: 'Update Container Information for {{doNumber}}',
  cannot_remove_shipper_from_group: 'Can\'t remove {{shipperName}} from {{shipperGroup}} ',
  shipper_group_exists: '\'{{groupName}}\' Shipper Group already exists',
  shipping_instruction_review_title: '{{vehicleName}}/{{licensePlate}} - TD: {{timeDeparture}}',
  shipping_instruction_review_port_info: '{{origin}} - {{destination}}',
  shipping_instruction_review_last_update_info: 'Last update: {{date}} by {{username}}',
  confirm_container: 'Confirm Container ({{amount}})',
  choose_shipment_from_shipper_group: 'Choose shipment from {{shipper_group}}',
  invoice_input_amount_warning:
    'Make sure the Total Amount is correct because '
    + 'it is not editable once it has been approved by Account Payable',
  template_name_exists: '\'{{templateName}}\' template already exists',
  add_transit_vessel_voyage_dialog_title: 'Add Transit Vessel/Voyage for {{id}}',
  add_remarks_dialog_title: 'Add Remark for {{id}}',
  add_transit_vessel_voyage_dialog_success_message: 'Successful to change transit vessel voyage for {{id}}',
  add_remarks_dialog_success_message: 'Successful to change remarks for {{id}}',
  revert_status_to_x: 'Revert to {{x}}',
  vat_x_percentage: 'VAT {{x}}%',
  wht21_x_percentage: 'WHT21 {{x}}%',
  wht23_x_percentage: 'WHT23 {{x}}%',
  edit_x: 'Edit {{x}}',
  edited_x: 'Edited {{x}}',
  review_x: 'Review {{x}}',
  download_x: 'Download {{x}}',
  cancel_x: 'Cancel {{x}}',
  preview_x: 'Preview {{x}}',
  x_at: '{{x}} At',
  x_date: '{{x}} Date',
  input_x_date: 'Input {{x}} Date',
  choose_x: 'Choose {{x}}',
  add_x: 'Add {{x}}',
  send_x: 'Send {{x}}',
  revert_invoice_to_x: 'Revert Invoice to {{x}}',
  x_selected_invoices_to_download: '{{x}} Selected Invoices To Download',
  edit_status_dialog_title: 'Edit Status for {{id}}',
  edit_status_success_message: '{{id}} status successfully edited!',
  edit_dooring_planned_date_success_message: '{{id}} Planned Dooring Date successfully edited!',
  bill_of_lading: 'Bill of Lading {{id}}',
  bill_of_lading_for: 'Bill of Lading for {{id}}',
  mark_as_x: 'Mark as {{x}}',
  must_be_more_than_zero: 'Must be more than zero',
  no_decimal: 'Cannot be decimal',
  mark_x_as_y: 'Mark {{x}} as {{y}}',
  record_x: 'Record {{x}}',
  x_proof_file: '{{x}} Proof File',
  x_proof: '{{x}} Proof',
  add_planned_dialog_title: 'Add Planned Dooring Date for {{id}}',
  edit_planned_dialog_title: 'Edit Planned Dooring Date for {{id}}',
  updated_by_x_at_y: 'Updated by {{x}} at {{y}}',
  view_x: 'View {{x}}',
  draft_detail_banner: '{{status}} Notes',
  adjustment_with_time: '{{adjustmentType}} ({{datetime}})',
  x_reason: '{{x}} Reason',
  submit_invoice_tracking_success: '{{invoiceNumber}} status has been updated',
  timestamp_used_for_x: 'Timestamp will be used as {{x}}',
  charged_for_x_days: 'Charged for {{x}} Day(s)',
  regenerate_x: 'Regenerate {{x}}',
  see_x: 'See {{x}}',
  see_x_details: 'See {{x}} Details',
  minimum_margin_in: 'Minimum Margin (in {{type}})',
  maximum_margin_in: 'Maximum Margin (in {{type}})',
  manage_x: 'Manage {{x}}',
  percentage_placeholder: 'e.g. {{number}}%',
  general_target: 'Target {{name}}',
  successfully_added: '{{name}} successfully added!',
  successfully_deleted: '{{name}} successfully deleted!',
  x_tracking_number: '{{x}} Tracking Number',
  please_add_at_least: 'Please add at least {{count}} {{object}}',
  please_select_the_field: 'Please select the {{field}}',
  please_enter_the_field: 'Please enter the {{field}}',
  please_enter_the_field_number: 'Please enter the {{field}} number',
  please_upload_the_field: 'Please upload the {{field}}',
  please_enter_all_data_field: 'Please enter all data for {{field}} or remove unused column',
  input_email_invalid: '{{inputEmail}} is invalid. Please make sure the email format is correct. E.g.: mail@kargo.tech',
  cancel_booking: 'Cancel Booking {{id}}',
  x_internal_id: '{{x}} Internal ID',
  cannot_upload_x: 'Cannot Upload {{x}}',
  requested_fee_info_x: 'Requested Fee Info - {{x}}',
  x_requested_fee_info: '{{x}} Requested Fee Info',
  x_successfully_updated: '{{x}} successfully updated!',
  x_successfully_edited: '{{x}} successfully edited!',
  update_shipment_date_for_x: 'Update Shipment Date for {{x}}',
  edit_vessel_voyage_x: 'Edit Vessel Voyage for {{x}}',
  x_existing_bookings: '{{x}} Existing Bookings',
  first_mile_x: 'First Mile ({{x}})',
  x_cancelled: '{{x}} cancelled',
  enter_x: 'Enter {{x}}',
  submit_x: 'Submit {{x}}',
  reject_x_from_y: 'Reject {{x}} from {{y}}',
  remove_container_from_x: 'Remove Container from {{x}}',
  remove_and_cancel_booking_x: 'Remove & Cancel Booking {{x}}',
  x_not_found: '{{x}} is not found',
  x_optional: '{{x}} (Optional)',
  created_date_is: 'Created date {{date}}',
  reject_x: 'Reject {{x}}',
  x_received_documents: '{{x}} Received Documents',
  general_upload: 'Upload {{name}}',
  general_enter: 'Enter {{name}}',
  general_plus_add: '+ Add {{name}}',
  remove_x_container_banner_title: 'You’re About to Remove {{x}} Container(s)',
  post_to_marketplace_delivery_order: '{{number}} DO(s) successfully posted to private marketplace!',
  cannot_post_to_marketplace: '{{number}} DO(s) Cannot be Posted to Private Marketplace',
  this_invoice_is: 'This invoice is {{status}}',
  new_pr_for: 'New Payment Request for {{invoice}}',
  x_containers_on_y_removed: '{{x}} Container(s) on {{y}} Removed',
  update_release_order: 'Update Release Order (RO) for {{doNumber}}',
  total_fees: '{{total}} Fees',
  record_payment_x: 'Record Payment {{x}}',
  print_x: 'Print {{x}}',
  generate_x: 'Generate {{x}}',
  shipping_instruction_output: '{{amount}} Shipping Instruction Output for {{shipper}}',
  dooring_agent_destination: 'Dooring Agent {{consignee}} to Destination {{destination}}',
  add_new_x: 'Add New {{x}}',
  edited_x_to_y: '{{x}} edited to {{y}}',
  changed_x_to_y: '{{x}} changed to {{y}}',
  edit_shipment_route_for_x: 'Edit Shipment for {{x}}',
  x_container_shipment_route_edited: '{{x}} Container shipment route successfully edited!',
  bulk_upload: {
    column_code: 'Column {{column_code}}',
    column_error: 'Error {{ column_name }}',
    column_error_count: 'There are {{count}} errors:',
    column_line: 'Line {{ line }}',
    column_trace_id: 'trace-id: {{column_code}}',
  },
  preview_change_routes_for_x: 'Preview Proof of Routes Changes for {{x}}',
  last_x_months: 'Last {{x}} Months',
  last_month: 'Last Month',
  confirm_x: 'Confirm {{x}}',
  continue_x: 'Continue {{x}}',
  x_info: '{{x}} Info',
  x_will_be_deleted_permanently: 'This {{x}} will be deleted permanently and cannot be undone.',
  change_kargo_wallet: '{{action}} Kantong Kargo',
  by_x: 'By {{x}}',
  force_withdraw_x: 'Force Withdraw {{x}}',
  create_x: 'Create {{x}}',
  approve_x: 'Approve {{x}}',
  hold_x: 'Hold {{x}}',
  change_x: 'Change {{x}}',
  mark_x: 'Mark as {{x}}',
  change_payment_method_pr: 'Change Payment Method {{prNumber}}',
  percentage_calculation: 'Percentage Calculation ({{percentage}}%)',
  counted_x_percentage_from_pr_total: 'Counted {{x}}% from total Payment Request',
  payment_request_form_title: {
    REQUEST: 'Preview Payment Request',
    REVIEW: 'Review {{x}}',
    REVISE: 'Revise {{x}}',
  },
  upload_x: 'Upload {{x}}',
  reupload_x: 'Reupload {{x}}',
  checking_job_list_id_on: 'Checking Job List ID on',
  checking_x_of_the_list: 'Checking {{x}}% of the list',
  total_x: 'Total {{x}}',
  x_adjustment_successfully_registered: '{{x}} Adjustment successfully registered',
};
