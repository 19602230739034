/* eslint-disable camelcase */
/* eslint-disable max-len */
import { TRUCK_REQUIREMENT_TAGS_PRIORITY_LEVELS, TRUCK_REQUIREMENT_VALUE_TYPE } from '~/Configurations/constants';
import { BID_PRICE_CHANGE_REASON, UNCHOSEN_REASON } from '~/Pages/shipment-choose-bid-winner-matching-engine/constants';
import whatsapp_template from './whatsapp_template';
import { DIGITAL_DOCUMENT_STATUS, PHYSICAL_DOCUMENT_STATUS, REQUIRED_DOCUMENT_RETURN_TYPE } from '../Utilities/constants';
import { FILE_REJECTION_REASON } from '~/Pages/shipment-review-document-returns/constant';

export default {
  add_whitelisted_transporter_info_1: 'Do you want to add',
  add_whitelisted_transporter_info_2: 'a whitelisted transporter to participate in the bidding,',
  add_whitelisted_transporter_info_3: 'even if they don\'t meet requirements or have pricing for the route?',
  additional_documents_of_do_number: 'Additional Documents of {{doNumber}}',
  approval: 'Approval',
  arrived_at_x: 'Arrived at {{x}}',
  banner_podc_sidebar: `We'll calculate this for invoicing. To get the correct payment, make sure you input the right quantity.`,
  bid_price: 'Bid Price',
  bid_price_change_reason: {
    [BID_PRICE_CHANGE_REASON.ASAM_MEL_MELAN_FEE]: 'Asam / Mel-melan Fee',
    [BID_PRICE_CHANGE_REASON.LOCATION_CHANGES]: 'Location Changes',
    [BID_PRICE_CHANGE_REASON.MULTI_PICKUP_DROPOFF]: 'Multi Pickup/Dropoff Fee',
    [BID_PRICE_CHANGE_REASON.TKBM_FEE]: 'TKBM Fee',
    [BID_PRICE_CHANGE_REASON.OTHER]: 'Other',
  },
  cancel_download_confirmation_title: 'Are you sure you want to cancel the download?',
  cancel_download_confirmation_message: 'Your ongoing download will be interrupted and any progress made will be lost if you proceed.',
  choose_as_winner: 'Choose as Winner',
  choose_reason: 'Choose reason',
  choose_transporter_success: 'You have successfully chosen {{transporterName}} as the winner!',
  choose_transporter_as_winner: 'Choose Transporter as Winner',
  choose_winner_for_x: 'Choose a Winner for {{x}}',
  click_here_to_manually_download: 'click here to manually download',
  click_here_to_review_immediately: 'Click here to review immediately',
  company_detail: 'Company Detail',
  compiling_into_single_pdf: 'Cancelling this activity will stop the PDF generation process.\nCompiling into single PDF....',
  completed_x_shipment: 'Completed {{x}} shipments for this route',
  confirm_winner: '<p>Do you confirm to choose <strong>{{transporterName}}</strong> as the winner for {{doNumber}}?</p>',
  confirmation_from_shipper: 'Confirmation from Shipper',
  copy_text: 'Copy Text',
  date_reviewed: 'Date Reviewed',
  departed_from_x: 'Departed from {{x}}',
  digital_document: 'Digital Document',
  digital_document_status: {
    [DIGITAL_DOCUMENT_STATUS.NOT_UPLOADED]: 'Not Uploaded',
    [DIGITAL_DOCUMENT_STATUS.APPROVED]: 'Pass Review',
    [DIGITAL_DOCUMENT_STATUS.REJECTED]: 'Rejected',
    [DIGITAL_DOCUMENT_STATUS.PENDING]: 'To Be Reviewed',
    [DIGITAL_DOCUMENT_STATUS.DRAFT]: 'Not Uploaded',
  },
  disabled_physical_status_tooltip: 'Change Physical Document Status only available for limited user roles after shipment is completed',
  document_return: 'Document Return',
  document_return_only_for_midmile: 'Only available for mid mile shipment',
  document_details: 'Document Details',
  document_incomplete: 'Document Incomplete',
  document_is_incomplete: 'Document is Incomplete',
  document_incomplete_detail: 'Document Incomplete Detail',
  document_incomplete_detail_instruction: 'Please Specify the missing image from this document',
  download_document_for_do: 'Download {{documentSubType}} for {{doNumber}}',
  download_documents_for_do: 'Download {{count}} Documents for {{doNumber}}',
  download_will_automatically_done_info: 'Download will automatically done in your browser, if the download doesn’t start',
  duplicate_shipment: 'Duplicate Shipment',
  epod: 'ePOD',
  physical_document: 'Physical Document',
  physical_document_status: {
    [PHYSICAL_DOCUMENT_STATUS.NOT_RECEIVED]: 'Not Received',
    [PHYSICAL_DOCUMENT_STATUS.RECEIVED]: 'Received',
  },
  edit_bid_description: 'If you have negotiated the bid price with the transporter, you can change it here:',
  edit_bid_success: 'Bid price edited successfully',
  empty_data_matching_engine:
    '<p>This table of recommended transporters contains <strong>no data</strong> because <strong>there is no transporter</strong> with matching requirement or pricing</p>',
  feedback_info:
    'Now it would be awesome if you can help us understand why you didn’t choose the other transporters, so we can improve the matching engine.',
  file_rejection_reason: {
    [FILE_REJECTION_REASON.DOCUMENT_IS_BLURRY]: 'Blurry',
    [FILE_REJECTION_REASON.DOCUMENT_IS_CROPPED]: 'Cropped',
    [FILE_REJECTION_REASON.WRONG_DOCUMENT]: 'Wrong Document',
  },
  give_feedback: 'Give Feedback',
  group_load: 'Group Load',
  historical_shipment: 'Historical Shipment',
  how_matching_engine_works: 'How matching engine works',
  how_matching_engine_works_item: {
    automatic_dispatch: 'Will automatically match DOs to transporters and automatically tier dispatch to T-app',
    list_of_recommended_transporters: 'List of recommended transporters will be shown in DO detail page',
    open_to_all_transporters:
      'Will eventually end up open to all transporters in T-app regardless whether shipper is exclusive or non exclusive',
  },
  if_matching_engine_active: 'If "Use matching engine" checkbox is active:',
  image: 'image',
  incomplete: 'incomplete',
  last_price: 'Last Price',
  mandatory_requirement: 'Mandatory Requirement',
  mark_document_as_incomplete: 'Mark Document as Incomplete',
  matching: 'Matching',
  matching_score: 'Matching Score',
  match_real_transporter_value: 'Match to Real-Time Transporter Value',
  never_served_route: 'Never served this route',
  new_price_notice: 'If you have negotiated you can adjust the final price here',
  not_using_matching_engine_info: '<p>This table of recommended transporters contains <strong>no data</strong> because the shipment <strong>does not use</strong> matching engine</p>',
  on_shipments_documents_reviewed_by_ops: 'On-shipments Documents Reviewed by Ops',
  on_shipments_documents_reviewed_by_ops_desc: 'Documents reviewed by Ops team to trigger first payment',
  optional_requirement: 'Optional Requirement',
  paper_color: 'Paper Color',
  pass_a_rejected_document_confirmation: 'Changing this into <strong>"Pass"</strong> will discard all rejected images. Are you sure about this?',
  physical_documents_are_received_on_x: 'The physical documents are received on {{x}}',
  post_shipments_documents_reviewed_by_finance: 'Post-shipments Documents Reviewed by Finance',
  post_shipments_documents_reviewed_by_finance_desc: 'Documents that are populed by POD/Finance team to ensure that this shipment is billable to shipper',
  post_to_marketplace_description:
    'Toggle on the "Post to Marketplace" to send notifications to transporters in your Transporter App and Nexus for Transporters (NFT) platform when there\'s an order.',
  preparing_x: 'Preparing {{x}}...',
  pricing_loc_point: 'Pricing Loc. Point',
  priority_levels: {
    [TRUCK_REQUIREMENT_TAGS_PRIORITY_LEVELS.MANDATORY]: 'Mandatory',
    [TRUCK_REQUIREMENT_TAGS_PRIORITY_LEVELS.MANDATORY_WITH_FOLLOW_UP]: 'Mandatory with Follow-Up',
    [TRUCK_REQUIREMENT_TAGS_PRIORITY_LEVELS.OPTIONAL]: 'Optional',
  },
  private_marketplace_ticker: 'Private marketplace: Only eligible & whitelisted transporters can bid on this shipment',
  real_time_requirements_detail: 'Real-Time Requirements Detail',
  reason_price_change: 'Reason for Price Change',
  reason_to_not_choose_transporter: '<p>Reason to not choose <strong>{{transporterName}}</strong></p>',
  recommended_transporters: 'Recommended Transporters',
  reject_document_instruction: 'Please Select Image you want to reject from the document to activate delete button, then select the reason to activate the reject button.',
  reject_document_instruction_title: 'Select Image to Reject',
  reject_page_x: 'Reject Page {{x}}',
  rejected_with_reason: 'Rejected - {{reason}}',
  replace_image: 'Replace Image',
  require_shipping_requirements_for_matching_engine: 'Loading location requires shipping requirements to use a matching engine',
  required_document_return_type: {
    [REQUIRED_DOCUMENT_RETURN_TYPE.REQUIRED_DOCUMENTS_TO_BE_RETURNED_TO_SHIPPER]: 'To Be Returned To Shipper',
    [REQUIRED_DOCUMENT_RETURN_TYPE.REQUIRED_DOCUMENTS_FOR_RECEIVER_AT_DESTINATION]: 'For Receiver at Destination',
    [REQUIRED_DOCUMENT_RETURN_TYPE.REQUIRED_DOCUMENTS_TO_BE_ATTACHED_IN_INVOICE]: 'To Be Attached To Invoice',
  },
  requirement_level: 'Requirement Level',
  requirement_name: 'Requirement Name',
  requirement_value: 'Requirement Value',
  requirements_detail: 'Requirements Detail',
  review_and_update: 'Review & Update',
  review_date: 'Review Date',
  review_document_of_x: 'Review Documents of {{x}}',
  shipment_documents: 'Shipment Documents',
  text_has_copied: 'The text has been copied to clipboard',
  tooltip_matching_score:
    'The final score of a transporter is determined by summing the requirement scores and margin score, indicating its suitability for the shipment.',
  tooltip_matching_score_no_requirement:
    'The final score of a transporter is determined by calculating margin score, indicating its suitability for the shipment.',
  transporter_detail: 'Transporter Detail',
  transporter_pricing: 'Transporter Pricing',
  transporter_value: 'Transporter\'s Value',
  unchosen_reason: {
    [UNCHOSEN_REASON.EXPENSIVE_PRICE]: 'Expensive Price',
    [UNCHOSEN_REASON.REQUIREMENTS_ARE_NOT_COMPLETE]: 'Requirements Are Not Complete',
    [UNCHOSEN_REASON.TRANSPORTER_IS_NOT_INVOLVED_IN_BIDDING]: 'Transporter Is Not Involved In Bidding',
    [UNCHOSEN_REASON.TRANSPORTER_INACTIVE_FOR_TOO_LONG]: 'Transporter Inactive For Too Long',
    [UNCHOSEN_REASON.TRUCK_IS_NOT_AVAILABLE]: 'Truck Is Not Available',
    [UNCHOSEN_REASON.OTHER]: 'Other',
  },
  unknown_reviewer: 'Unknown Reviewer',
  unknown_review_date: 'Unknown Review Date',
  uploaded_documents_is_processing: 'We\'re currently processing your uploads, so please check back shortly if the document is empty.',
  use_matching_engine: 'Use matching engine',
  value_type_options: {
    [TRUCK_REQUIREMENT_VALUE_TYPE.BOOLEAN]: 'Yes/No',
    [TRUCK_REQUIREMENT_VALUE_TYPE.INTEGER_MAX]: 'Maximum',
    [TRUCK_REQUIREMENT_VALUE_TYPE.INTEGER_MIN]: 'Minimum',
  },
  claims_return_amount_validator: 'Value must be less than Amount Claimed',
  view_podc_tooltip: 'The POD Detail Only available after the Physical Document Received',
  wa_disabled:
    'This transporter’s WhatsApp number has not been registered. Go to the transporter profile page to add it.',
  wa_request_tpt: 'WhatsApp Request Order to this Transporter',
  wa_this_tpt: 'WhatsApp this Transporter',
  warning_check_required_documents_below: 'Check if you\'ve collected these required documents or not, if you\'ve attached the docs please check the check box bellow.',
  whatsapp_message: 'Whatsapp Message',
  whatsapp_order_preview: 'Whatsapp Order Preview',
  whatsapp_template,
  whitelisted_transporter: 'Whitelisted Transporter',
  whitelisted_transporters: 'Whitelisted Transporters',
  write_reason: 'Write other reason',
  x_image_info: '{{x}} Image {{info}}',
  x_is_successfully_compiled: '{{x}} is successfully compiled',
  x_out_of_y: '{{x}} out of {{y}}',
  x_required_document_not_collected: 'You haven\'t collected {{x}} of the required Documents',
  you_can_edit_wa: 'You can edit this WhatsApp Message below',
  delete_x_images: 'Delete {{x}} Image',
  delete: 'Delete',
  delete_image: 'Delete Image',
  select_image_to_delete: 'Select Image to delete',
  select_image_delete_desc: 'Please Select Image you want to remove from the document to activate delete button',
  failed_to_delete_images: 'Failed to Delete {{n}} files',
  additional_document: 'Additional Document',
  additional_form_placeholder: {
    fee_desc: 'Add Description here',
    additional_notes: 'Add Notes here',
  },
};
