import { SHOW_CLAIMS_TO_LABEL } from '~/Pages/shippers-enterprises-project/constants';
import { CREDIT_LIMIT_STATUS } from '~/Pages/shippers-enterprises-project-credit-limit/constants';
import {
  FIRST_MILE_SHIPMENT_TYPE,
  PROJECT_STATUS,
  SHIPMENT_TYPE,
  FIRST_MILE_TRUCKING_SHIPMENT_TYPE,
} from '~/Configurations/constants';
import {
  REQUIRED_DOCUMENT_RETURN_TYPE_ENUM,
  REQUIREMENT_TAGS_PRIORITY_LEVELS,
  BE_DOCUMENT_REQUIREMENTS_TYPE,
} from '~/Pages/shippers/constants';
import { PROJECT_DETAIL_TAB_PATHS, PROJECT_DETAIL_TAB_PATHS_WITH_CREDIT } from '~/Routes/constants';
import { QUOTATION_STATUS, QUOTATION_STATUS_V2 } from '~/Pages/Shipper/QuotationListPage/constants';
import {
  PRICING_SCHEME as QUOTATION_FORM_PRICING_SCHEME,
  QUOTATION_TYPE as QUOTATION_FORM_TYPE,
} from '~/Pages/Shipper/QuotationForm/constants';

/* eslint-disable max-len */
export default {
  activity_log_type: {
    btms_create_project_basic_information: 'Project Created',
    btms_update_claims_project: 'Claims & Returns Updated',
    btms_update_contract_project: 'Contract Documents Updated',
    btms_update_payment_project: 'Payments Updated',
    btms_update_project_basic_information: 'Basic Information Updated',
    btms_update_project_status: 'Project Status Updated',
    btms_update_required_document_project: 'Required Document Returns Updated',
    btms_update_transport_requirements_project: 'Truck Requirements Updated',
    save_partnership_pricing: 'Prices Updated',
    save_warehouse_fee: 'Location Fee Updated',
    create_shipper_price_quotation: 'Quotation Created',
    update_shipper_price_quotation: 'Quotation Updated',
    update_quotation_status: 'Quotation {{status}}',
    update_quotation_number: 'Quotation Number Updated',
  },
  add_claims: 'Add Claims',
  agreement_details: 'Agreement Details',
  agreement_status: 'Agreement Status',
  agreement_type: 'Agreement Type',
  available_limit: 'Available Limit',
  basic_information: 'Basic Information',
  bd_kam_information: 'BD or KAM Information',
  bd_kam_email: 'BD or KAM Email',
  bd_kam_name: 'BD or KAM Name',
  bd_kam_signature: 'BD or KAM Signature',
  billing_address: 'Billing Address',
  billing_equal_company_address: 'Billing address is the same as the company address',
  billing_information: 'Billing Information',
  billing_receiver_name: 'Billing Receiver Name',
  branch_address: 'Branch Address',
  can_not_add_new_location_requirement: 'Cannot add new location because all loading locations has already been added',
  cancel_new_quotation: 'Cancel New Quotation?',
  cancel_new_quotation_warning: 'All changes you made so far will not be saved and you will have to create a new quotation again.',
  changes_successfully_saved: 'Changes are successfully saved!',
  check_npwp_number: 'Check NPWP Number',
  claim_label: {
    damage: 'Damage',
    goods_near_expire_date: 'Goods Near Expiry Date',
    missing_goods: 'Missing Goods',
    packaging_damage: 'Packaging Damage',
    shipper_fault: {
      too_few_goods: 'Shipper Issue - Goods Too Few',
      too_many_goods: 'Shipper Issue - Goods Too Many',
      wrong_goods: 'Shipper Issue - Wrong Goods',
    },
    show_claims_label: {
      [SHOW_CLAIMS_TO_LABEL.BERITA_ACARA]: 'Berita Acara',
      [SHOW_CLAIMS_TO_LABEL.POD]: 'POD',
      [SHOW_CLAIMS_TO_LABEL.LOAD_ITEMS]: 'Load Items',
    },
  },
  claim_letter: 'Claim Letter',
  claim_policies: 'Claim Policies',
  claim_submission_deadline_since_goods_received: 'Claim Submission Deadline Since Goods Received',
  claim_value_collection_sla: 'Claim Value Collection SLA',
  claims: 'Claims',
  claims_and_returns: 'Claims & Returns',
  company_address: 'Company Address',
  company_details: 'Company Details',
  company_details_sub: 'Please complete all required fields with the company information',
  company_documents: 'Company Documents',
  company_industry: 'Company Industry',
  company_information: 'Company Information',
  company_pic: 'Company PIC',
  company_profile: 'Company Profile',
  contract_agreement: 'Agreement',
  contract_document: 'Contract Document',
  contract_document_type: 'Contract Document Type',
  contract_documents: 'Contract Documents',
  contract_work_order: 'Work Order',
  corporate_entity: 'Corporate Entity',
  create_company_success: 'New company profile successfully added',
  credit_limit: 'Credit Limit',
  credit_limit_activity_log: 'Credit Limit Activity Log',
  credit_limit_statuses_map: {
    [CREDIT_LIMIT_STATUS.EXCEPTION]: 'EXCEPTION',
    [CREDIT_LIMIT_STATUS.ELIGIBLE]: 'Eligible',
    [CREDIT_LIMIT_STATUS.OVER_LIMIT]: 'Over Limit',
    [CREDIT_LIMIT_STATUS.HIGH_CONCERN]: 'High Concern',
    [CREDIT_LIMIT_STATUS.DISABLED]: 'Disabled',
    [CREDIT_LIMIT_STATUS.TERMINATED]: 'Terminated',
  },
  credit_limit_activity_log_type: {
    SAVE_CREDIT_LIMIT: {
      UPDATE: 'Update Credit Limit',
      INSERT: 'Save Credit Limit',
    },
    UPDATE_DO_CREATION_TOGGLE: {
      ENABLED: 'Enable DO Creation',
      DISABLED: 'Disable DO Creation',
    },
    TERMINATE_PROJECT: {
      DEFAULT: 'Terminate Project',
    },
  },
  day_and_time: 'Day and Time',
  damaged_goods_ownership: 'Damaged Goods Ownership',
  damaged_goods_ownership_help_text: 'Claimed damaged goods will belong to Panthera',
  delete_requirement: '{{requirement}} will be deleted permanently and cannot be undone.',
  details: 'Details',
  district_city_province: 'District, City, Province',
  document_attached: 'Document Attached',
  edit_claims: 'Edit Claims',
  edited_data_will_not_be_saved: 'Data you have edited will not be saved if you leave this page now',
  entered_data_will_not_be_saved:
    'Data you have entered will not be saved if you leave this page. If you want to go to a previous page, please use the menu on the left side',
  entity_selection: 'Entity Selection',
  error_claims_empty: 'Please add claim before continuing',
  error_invalid_email: 'Email is invalid. Please make sure the email format is correct. E.g.: mail@kargo.tech',
  error_invalid_number: 'Phone number is invalid. Please make sure the format is correct. E.g.: +628123456789',
  error_location_required: 'Please add a new Location',
  error_npwp_already_registered: 'This NPWP is already registered in PBI for another company profile',
  error_npwp_not_clicked: 'Please click ‘Check NPWP Number’ before continuing the profile creation process',
  error_npwp_not_valid: 'Insert NPWP Number, format nn.nnn.nnn.n-nnn.nnn',
  error_please_select: 'Please select one of the button above',
  error_truck_type_body_type_already_exists: 'Truck type and body type already exist',
  exclude_vat_free_on_truck: 'Exclude VAT (Free on Truck)',
  fee_for: 'Fee For {{name}}',
  fees_and_payment_terms: 'Fees and Payment Terms',
  fee_type_or_term_name: 'Fee Type or Term Name',
  fee_type_or_term_name_help_text: 'e.g. "Lokasi parkir unit", "Periode operasional unit", "Kondisi driver dan kernet".',
  for_receiver_at_destination: 'For Receiver at Destination',
  general_information: 'General Information',
  gmaps_com: 'gmaps.com',
  include_vat: 'Include VAT',
  invoice_details: 'Invoice Details',
  invoice_reminder_recipient: 'Invoice Reminder Recipient',
  invoicing_code: 'Invoicing Code',
  item_type: 'Item Type',
  ktp_number: 'KTP Number',
  latest_update: 'Latest Update',
  late_payment_penalty: 'Late Payment Penalty',
  leave_page_desc: 'All data that you have entered will not be saved if you exit the profile creation process now',
  leave_page_title: 'Are you sure to leave this page?',
  lets_add_shipping_requirements: 'Let\'s add Shipping Requirements to complete your project',
  listed_price: 'Listed Price',
  listed_price_vat_option: 'Listed Price VAT Option',
  loading_location: 'Loading Location',
  mandatory: 'Mandatory',
  max_ton: 'Max Ton',
  max_tonnage: 'Max Tonnage',
  message_confirm_save_shipping_requirements_tags:
    'The Requirement Name and Value settings will be saved. You can always edit them later when necessary.',
  no_cancel: 'No, Cancel',
  no_location_fee_registered: 'No location fee has been registered',
  npwp_available: 'NPWP number can be used to create a new company profile',
  npwp_document: 'NPWP Document',
  npwp_number: 'NPWP Number',
  npwp_tooltip:
    'NPWP number needs to be checked before creating a company profile to make sure that it\'s not yet registered in PBI',
  other_fee_or_term_detail_help_text: 'e.g. "Sesuai dengan ketentuan yang berlaku dari Shipper"',
  other_fees_or_terms: 'Other Fees or Terms',
  other_fees_or_terms_help_text: 'Other Fees or Terms will be listed in the quotation under new clause on "Biaya belum termasuk"',
  other_terms: 'Other Terms',
  outstanding_amount: 'Outstanding Amount',
  panthera_pic: 'Panthera PIC',
  parent_company_address: 'Parent Company Address',
  parent_company_name: 'Parent Company Name',
  payments: 'Payments',
  uninvoiced: 'Uninvoiced',
  per_days: 'Per Days',
  pkp_number: 'PKP Number',
  placeholder_brand_name: 'Enter company brand name',
  placeholder_company_name: 'Enter company name as stated in NPWP',
  placeholder_full_address: 'Enter full address including street name and number',
  placeholder_input_or_select: 'Input or select data',
  placeholder_ktp: 'Enter 16 digits of KTP number',
  placeholder_npwp: '00.000.000.0-000.000',
  placeholder_phone_number: '+6281234567890',
  placeholder_pic_name: 'Enter company PIC name',
  placeholder_pkp: 'Enter company PKP number',
  placeholder_postcode: 'Enter 5 digits of postcode',
  placeholder_return_schedule: 'Enter the day (e.g. Senin - Jumat) and time (e.g. 10:00-18:00)',
  placeholder_search_address: 'Search address from map',
  placeholder_search_company: 'Search Company Name',
  placeholder_search_district_city_prov: 'Search District, City, and Province',
  placeholder_search_project: 'Search project name',
  placeholder_shipper_type: 'Select Shipper Type',
  placeholder_siup: 'Select SIUP expiry date',
  'placeholder_siup/siujpt': 'Select SIUP expiry date',
  placeholder_tdp: 'Select TDP expiry date',
  please_click_npwp: 'Please click ‘Check NPWP Number’ before continuing the profile creation process',
  plus_create_new_project: '+ Create New Project',
  priority_levels: {
    [REQUIREMENT_TAGS_PRIORITY_LEVELS.MANDATORY]: 'Mandatory',
    [REQUIREMENT_TAGS_PRIORITY_LEVELS.MANDATORY_WITH_FOLLOW_UP]: 'Mandatory with Follow-Up',
    [REQUIREMENT_TAGS_PRIORITY_LEVELS.OPTIONAL]: 'Optional',
  },
  project_activity_log: 'Project Activity Log',
  project_creation: 'Project Creation',
  project_detail_tabs: {
    [PROJECT_DETAIL_TAB_PATHS.BASIC_INFORMATION]: 'Basic Information',
    [PROJECT_DETAIL_TAB_PATHS.CONTRACT_DOCUMENTS]: 'Contract Documents',
    [PROJECT_DETAIL_TAB_PATHS_WITH_CREDIT.CREDIT_LIMIT]: 'Credit Limit',
    [PROJECT_DETAIL_TAB_PATHS.PAYMENTS]: 'Payments',
    [PROJECT_DETAIL_TAB_PATHS.DOCUMENT_RETURNS]: 'Required Documents Returns',
    [PROJECT_DETAIL_TAB_PATHS.TRUCK_REQUIREMENTS]: 'Truck Requirements',
    [PROJECT_DETAIL_TAB_PATHS.LOCATION_FEE]: 'Location Fee',
    [PROJECT_DETAIL_TAB_PATHS.PRICES]: 'Prices',
    [PROJECT_DETAIL_TAB_PATHS.CLAIM_RETURNS]: 'Claims & Returns',
    [PROJECT_DETAIL_TAB_PATHS.SHIPPING_REQUIREMENT_TAGS]: 'Shipping Requirement Tags',
  },
  project_information: 'Project Information',
  project_list: 'Project List',
  project_name: 'Project Name',
  project_pic: 'Project PIC',
  project_pic_name: 'Project PIC Name',
  project_pic_name_lower: 'project PIC name',
  project_status: 'Project Status',
  project_statuses: {
    [PROJECT_STATUS.ACTIVE]: 'Active',
    [PROJECT_STATUS.DRAFT]: 'Draft',
    [PROJECT_STATUS.INACTIVE]: 'Inactive',
  },
  quotation_form_pricing_scheme: {
    [QUOTATION_FORM_PRICING_SCHEME.PER_CBM]: 'Per CBM',
    [QUOTATION_FORM_PRICING_SCHEME.PER_KG]: 'Per KG',
    [QUOTATION_FORM_PRICING_SCHEME.PER_PO]: 'Per PO',
    [QUOTATION_FORM_PRICING_SCHEME.PER_ROUTE]: 'Per Trip',
    [QUOTATION_FORM_PRICING_SCHEME.PER_UNIT]: 'Per Unit',
  },
  quotation_form_type: {
    [QUOTATION_FORM_TYPE.FM_DTD]: 'First Mile & DTD',
    [QUOTATION_FORM_TYPE.MID_MILE]: 'Mid Mile',
    [QUOTATION_FORM_TYPE.RENTAL]: 'Rental',
    [QUOTATION_FORM_TYPE.PROJECT]: 'Project',
  },
  quotation_pricing: 'Quotation Pricing',
  read_only_company_profile_data: 'This data is the same as in the Company Profile and cannot be changed here',
  registered_company_name: 'Registered Company Name',
  rejection_category: 'Rejection Category',
  required_document: 'Required Document',
  required_document_returns: 'Required Document Returns',
  required_document_returns_type_id: {
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BERITA_ACARA]: 'Berita Acara',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BUKTI_PENERIMAAN_BARANG]: 'Bukti Penerimaan Barang',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.DISPATCH_MEMO]: 'Dispatch Memo',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.FORM_ABSENSI]: 'Form Absensi',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.FORM_ABSENSI_BONGKAR]: 'Form Absensi (Bongkar)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.FORM_ABSENSI_MUAT]: 'Form Absensi (Muat)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.KWITANSI_TKBM]: 'Kwitansi TKBM',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.NOTA_TIMBANG]: 'Nota Timbang',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.NOTA_TIMBANG_CARBONIZE_BONGKAR]: 'Nota Timbang Carbonize (Bongkar)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.NOTA_TIMBANG_CARBONIZE_MUAT]: 'Nota Timbang Carbonize (Muat)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.NOTA_TIMBANG_ORIGINAL_BONGKAR]: 'Nota Timbang Original (Bongkar)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.NOTA_TIMBANG_ORIGINAL_MUAT]: 'Nota Timbang Original (Muat)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.PACKING_LIST]: 'Packing List',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_PERINTAH_JALAN]: 'Surat Perintah Jalan',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BILL_OF_LADING]: 'Bill of Lading',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_CARBONIZE]: 'Surat Jalan Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_ORIGINAL]: 'Surat Jalan Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BERITA_ACARA_MUAT]: 'Berita Acara Muat',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BERITA_ACARA_SERAH_TERIMA_BARANG]: 'Berita Acara Serah Terima Barang',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BERITA_SERAH_TERIMA_BARANG]: 'Berita Serah Terima Barang',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BON_TIMBANG]: 'Bon Timbang',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.COMMERCIAL_INVOICE]: 'Commercial Invoice',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.DELIVERY_LOCAL_ORDER_COPY]: 'Delivery Local Order Copy',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.DELIVERY_LOCAL_ORDER_ORIGINAL]: 'Delivery Local Order Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.DELIVERY_NOTE]: 'Delivery Note',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.EIR_DEPOT]: 'EIR Depot',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.EIR_PORT]: 'EIR Port',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.FAKTUR_PAJAK]: 'Faktur Pajak',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.FAKTUR_PAJAK_DEPOT]: 'Faktur Pajak Depot',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.GOOD_RECEIPT]: 'Good Receipt',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.LIVE_LOADING_DOCUMENT]: 'Live Loading Document',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.LOLO]: 'LOLO',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.PACKINGLIST_CARBONIZE]: 'Packinglist Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.PACKINGLIST_ORIGINAL]: 'Packinglist Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.PPFTZ_03]: 'PPFTZ-03',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.RBPB_CARBONIZE]: 'RBPB Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.RBPB_ORIGINAL]: 'RBPB Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.RECEIPT_DEURAGE]: 'Receipt Deurage',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.RECEIPT_EXTEND]: 'Receipt Extend',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.RECEIPT_REPAIR]: 'Receipt Repair',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.RECEIPT_STORAGE_PORT]: 'Receipt Storage Port',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_PABRIK_CARBONIZE]: 'Surat Jalan Pabrik Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_PABRIK_ORIGINAL]: 'Surat Jalan Pabrik Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_PANTHERA_CARBONIZE]: 'Surat Jalan Panthera Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_PANTHERA_ORIGINAL]: 'Surat Jalan Panthera Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_SHIPPER_CARBONIZE]: 'Surat Jalan Shipper Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_SHIPPER_ORIGINAL]: 'Surat Jalan Shipper Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_TRANSFER_CARBONIZE]: 'Surat Jalan Transfer Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_TRANSFER_ORIGINAL]: 'Surat Jalan Transfer Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_KETERANGAN_MUAT_BARANG]: 'Surat Keterangan Muat Barang',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_PENERIMAAN_BARANG]: 'Surat Penerimaan Barang',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_PERINTAH_EXPEDISI]: 'Surat Perintah Ekspedisi',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_PERINTAH_PENGELUARAN_BARANG]: 'Surat Perintah Pengeluaran Barang',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.TALLY_SHEET]: 'Tally Sheet',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.TKBM_BONGKAR]: 'TKBM (Bongkar)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.TKBM_MUAT]: 'TKBM (Muat)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.OTHER]: 'Other',
  },
  required_document_returns_type_en: {
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BERITA_ACARA]: 'Minutes of Meeting',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BUKTI_PENERIMAAN_BARANG]: 'Goods Receipt',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.DISPATCH_MEMO]: 'Dispatch Memo',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.FORM_ABSENSI]: 'Attendance Form',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.FORM_ABSENSI_BONGKAR]: 'Attendance Form (Unloading)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.FORM_ABSENSI_MUAT]: 'Attendance Form (Loading)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.KWITANSI_TKBM]: 'TKBM Receipt',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.NOTA_TIMBANG]: 'Weighing Note',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.NOTA_TIMBANG_CARBONIZE_BONGKAR]: 'Carbonized Weighing Note (Unloading)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.NOTA_TIMBANG_CARBONIZE_MUAT]: 'Carbonized Weighing Note (Loading)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.NOTA_TIMBANG_ORIGINAL_BONGKAR]: 'Original Weighing Note (Unloading)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.NOTA_TIMBANG_ORIGINAL_MUAT]: 'Original Weighing Note (Loading)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.PACKING_LIST]: 'Packing List',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_PERINTAH_JALAN]: 'Transport Order',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BILL_OF_LADING]: 'Bill of Lading',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_CARBONIZE]: 'Carbonized Delivery Order',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_ORIGINAL]: 'Proof of Delivery',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BERITA_ACARA_MUAT]: 'Loading Minutes of Meeting',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BERITA_ACARA_SERAH_TERIMA_BARANG]: 'Goods Handover Minutes of Meeting',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BERITA_SERAH_TERIMA_BARANG]: 'Goods Handover Report',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.BON_TIMBANG]: 'Weighing Voucher',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.COMMERCIAL_INVOICE]: 'Commercial Invoice',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.DELIVERY_LOCAL_ORDER_COPY]: 'Delivery Local Order Copy',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.DELIVERY_LOCAL_ORDER_ORIGINAL]: 'Delivery Local Order Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.DELIVERY_NOTE]: 'Delivery Note',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.EIR_DEPOT]: 'EIR Depot',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.EIR_PORT]: 'EIR Port',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.FAKTUR_PAJAK]: 'Tax Invoice',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.FAKTUR_PAJAK_DEPOT]: 'Depot Tax Invoice',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.GOOD_RECEIPT]: 'Goods Receipt',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.LIVE_LOADING_DOCUMENT]: 'Live Loading Document',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.LOLO]: 'LOLO',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.PACKINGLIST_CARBONIZE]: 'Carbonized Packing List',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.PACKINGLIST_ORIGINAL]: 'Original Packing List',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.PPFTZ_03]: 'PPFTZ-03',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.RBPB_CARBONIZE]: 'Carbonized RBPB',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.RBPB_ORIGINAL]: 'Original RBPB',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.RECEIPT_DEURAGE]: 'Demurrage Receipt',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.RECEIPT_EXTEND]: 'Extension Receipt',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.RECEIPT_REPAIR]: 'Repair Receipt',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.RECEIPT_STORAGE_PORT]: 'Storage Port Receipt',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_PABRIK_CARBONIZE]: 'Carbonized Factory Delivery Order',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_PABRIK_ORIGINAL]: 'Original Factory Delivery Order',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_PANTHERA_CARBONIZE]: 'Carbonized Panthera Delivery Order',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_PANTHERA_ORIGINAL]: 'Original Panthera Delivery Order',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_SHIPPER_CARBONIZE]: 'Carbonized Shipper Delivery Order',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_SHIPPER_ORIGINAL]: 'Original Shipper Delivery Order',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_TRANSFER_CARBONIZE]: 'Carbonized Transfer Delivery Order',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_JALAN_TRANSFER_ORIGINAL]: 'Original Transfer Delivery Order',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_KETERANGAN_MUAT_BARANG]: 'Loading Certificate',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_PENERIMAAN_BARANG]: 'Goods Receipt Note',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_PERINTAH_EXPEDISI]: 'Expedition Order',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.SURAT_PERINTAH_PENGELUARAN_BARANG]: 'Goods Release Order',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.TALLY_SHEET]: 'Tally Sheet',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.TKBM_BONGKAR]: 'TKBM (Unloading)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.TKBM_MUAT]: 'TKBM (Loading)',
    [REQUIRED_DOCUMENT_RETURN_TYPE_ENUM.OTHER]: 'Other',
  },
  requirement_category: 'Requirement Category',
  requirement_level: 'Requirement Level',
  requirement_level0_info:
    'Transporters not meeting the "Mandatory" requirement level will not appear in the recommendation list.',
  requirement_level1_info:
    'Transporters not meeting the "Mandatory with Follow-Up" and “Optional” requirement level will still appear in the recommendation list.',
  requirement_name: 'Requirement Name',
  return_deadline: 'Return Deadline',
  return_fee: 'Return Fee',
  return_schedule: 'Return Schedule',
  return_sla: 'Return SLA',
  route_details: 'Route Details',
  search_address: 'Search Address',
  search_district_city_prov: 'Search District, City, Province',
  send_invoice_reminder: 'Send invoice reminders to this contact',
  shipper_address: 'Shipper Address',
  shipper_details: 'Shipper Details',
  shipper_details_sub: 'Please complete all required fields with the information of the company as a shipper in PBI',
  shipper_insurance_coverage: 'shipper insurance coverage',
  shipper_pic_name: 'Shipper PIC Name',
  shipper_project_claim_insurance: 'Shipper Project Claim & insurance',
  shipper_quotation_form: 'Shipper Quotation Form',
  shipper_top: 'Shipper TOP',
  shipping_requirement: 'Shipping Requirement',
  shipping_requirements: 'Shipping Requirements',
  shipping_requirement_location: 'Shipping Requirement Location',
  shipping_requirement_tags: 'Shipping Requirement Tags',
  shipping_requirement_tags_saved: 'Shipping requirement tags saved',
  shipping_requirement_tags_will_not_be_saved:
    'Shipping requirement tags will not be saved if you leave this page now.',
  show_claims_in: 'Show Claims in',
  siup_expiry_date: 'SIUP Expiry Date',
  siup_number: 'SIUP Number',
  'siup/siujpt_expiry_date': 'SIUP Expiry Date',
  'siup/siujpt_number': 'SIUP Number',
  sla_ticker_message: 'Make sure the SLA Return day is suitable to the agreement',
  sppkp_number: 'PKP Number',
  tdp_expiry_date: 'TDP Expiry Date',
  tdp_number: 'TDP Number',
  term_details: 'Term Details',
  title_confirm_save_shipping_requirements_tags: 'Save Shipping Requirements?',
  tnc: 'Terms and Conditions',
  to_be_attached_to_invoice: 'To Be Attached to Invoice',
  to_be_returned_to_shipper: 'To Be Returned to Shipper',
  top_days: 'ToP (days)',
  top_x_days: 'ToP {{x}} Days',
  total_amount: 'Total Amount',
  total_days: 'Total Days',
  transport_rejection: 'Transport Rejection',
  truck_fee_types: {
    LOADING: 'Overnight Loading Fee',
    MULTIPLE_SHIPMENT: 'Multiple Shipment Fee',
    TRUCK_ARRIVAL: 'Cancellation After Truck Arrival at Origin',
    TRUCK_DETAILS_CONFIRMED: 'Cancellation after Truck Details are Confirmed',
    UNLOADING: 'Overnight Unloading Fee',
  },
  truck_requirements: 'Truck Requirements',
  unit_type: 'Unit Type',
  upload_signed_doc: 'Upload Signed Doc',
  yes_delete: 'Yes, Delete',
  yes_leave: 'Yes, Leave Page',
  page_color: 'Page Color',
  apply_to: 'Apply To',
  shipment_type: {
    [SHIPMENT_TYPE.GT]: 'GT',
    [SHIPMENT_TYPE.MT]: 'MT',
    [SHIPMENT_TYPE.W2W]: 'W2W',
    [SHIPMENT_TYPE.F2DEPO]: 'F2DEPO',
    [SHIPMENT_TYPE.F2DIST]: 'F2DIST',
    [SHIPMENT_TYPE.F2MT]: 'F2MT',
    [SHIPMENT_TYPE.F2W]: 'F2W',
    [SHIPMENT_TYPE.W2DEPO]: 'W2DEPO',
    [SHIPMENT_TYPE.W2DIST]: 'W2DIST',
    [SHIPMENT_TYPE.W2MT]: 'W2MT',
    [FIRST_MILE_SHIPMENT_TYPE.DOOR_TO_DOOR]: 'Single Pickup Door-to-Door',
    [FIRST_MILE_SHIPMENT_TYPE.MULTI_DOOR_TO_DOOR]: 'Multi Pickups Door-to-Door',
    [FIRST_MILE_SHIPMENT_TYPE.FIRST_MILE_TRUCKING]: 'First Mile Trucking',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FIRST_MILE_EXPORT]: 'Export FM Trucking',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FIRST_MILE_IMPORT]: 'Import FM Trucking',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FIRST_MILE_OUTBOUND]: 'Outbound FM Trucking',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FIRST_MILE_INBOUND]: 'Inbound FM Trucking',
  },
  input: 'Input',
  on_shipment: 'On Shipment',
  to_be_uploaded_to_driver_label: 'To Be Uploaded by Driver/Transporter using D-App/T-App',
  REQUIRED_DOCUMENT_RETURN_APPLY_TO_OPTIONS: {
    [BE_DOCUMENT_REQUIREMENTS_TYPE.FOR_RECEIVER_AT_DESTINATION]: 'For Receiver At Destination',
    [BE_DOCUMENT_REQUIREMENTS_TYPE.TO_BE_RETURNED_TO_SHIPPER]: 'To Be Returned To Shipper',
    [BE_DOCUMENT_REQUIREMENTS_TYPE.TO_BE_ATTACHED_TO_INVOICE]: 'To Be Attached To Invoice',
  },
  pending_allocation: 'Pending Allocation',
  unpaid_invoice: 'Unpaid Invoice',
  send_to_legal: 'Send to Legal',
  quotations: 'Quotations',
  pic_bd_or_kam_name: 'PIC, BD, or KAM Name',
  quotation_number: 'Quotation Number',
  quotation_status_map: {
    [QUOTATION_STATUS.PENDING]: 'Pending',
    [QUOTATION_STATUS.REJECTED]: 'Rejected',
    [QUOTATION_STATUS.ON_HOLD]: 'On Hold',
    [QUOTATION_STATUS.APPROVED]: 'Approved',
    [QUOTATION_STATUS.SIGNED]: 'Signed',
    [QUOTATION_STATUS.CANCELLED_BY_INTERNAL]: 'Cancelled by Internal',
    [QUOTATION_STATUS.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper',

    // Add the new version of status for backward compatibility
    [QUOTATION_STATUS_V2.LEGAL_APPROVED]: 'Approved',
    [QUOTATION_STATUS_V2.LEGAL_ON_HOLD]: 'On Hold',
    [QUOTATION_STATUS_V2.LEGAL_REJECTED]: 'Rejected',
    [QUOTATION_STATUS_V2.SALES_LEAD_APPROVED]: 'Need Legal Review',
    [QUOTATION_STATUS_V2.SALES_LEAD_REJECTED]: 'Rejected TOP',
  },
  quotation_status_map_v2: {
    [QUOTATION_STATUS_V2.PENDING]: 'Need Legal Review',
    [QUOTATION_STATUS_V2.LEGAL_APPROVED]: 'Need Lead Review',
    [QUOTATION_STATUS_V2.LEGAL_ON_HOLD]: 'On Hold',
    [QUOTATION_STATUS_V2.LEGAL_REJECTED]: 'Rejected',
    [QUOTATION_STATUS_V2.SALES_LEAD_APPROVED]: 'Need Legal Review',
    [QUOTATION_STATUS_V2.SALES_LEAD_REJECTED]: 'Rejected TOP',
    [QUOTATION_STATUS_V2.APPROVED]: 'Approved',
    [QUOTATION_STATUS_V2.SIGNED]: 'Signed',
    [QUOTATION_STATUS_V2.CANCELLED_BY_INTERNAL]: 'Cancelled by Internal',
    [QUOTATION_STATUS_V2.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper',

    // Add the previous version of status for backward compatibility
    [QUOTATION_STATUS.REJECTED]: 'Rejected',
    [QUOTATION_STATUS.ON_HOLD]: 'On Hold',
  },
  quotation_detail: 'Quotation Detail',
  rejection_reason: 'Rejection Reason',
  wa_number: 'WhatsApp Number',
  issuance_date: 'Created Date',
  quotation_status_successfully_changed_to_x: 'Quotation Status Successfully changed to {{x}}',
  final_quotation: 'Final Quotation',
  quotation_status: 'Quotation Status',
  final_quotation_file: 'Final Quotation File',
  quotation_number_successfully_updated: 'Quotation Number successsfully updated',
  quotation_signed_by_shipper: 'Quotation Signed by Shipper',
  signed_by_shipper: 'Signed by Shipper',
  please_upload_signed_quotation_as_proof_document: 'Please upload signed quotation as proof document',
  minimum_order_days: 'Minimum Order Day(s)',
  order_time: 'Order Time',
};
