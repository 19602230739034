export default {
  configuration_for_tax_percentage: 'Configuration for Tax Percentage Option',
  contact_details_notif: 'Each Contact Person Details and Transporter Details should be different',
  disable_phone_number_as_whatsapp_contact: 'Only one Contact Person can be set as WhatsApp contact',
  email_documents_and_notify_invoice_to_contacts:
    'Email financial documents and notify invoice update to this Contact Person',
  financial_documents_will_be_emailed: 'Financial documents will be emailed to the Contact Person',
  scheme_placeholder: 'e.g. EF Scheme B',
  set_phone_number_as_whatsapp_contact: 'Set phone number as WhatsApp contact for transporter availability inquiries',
};
