/* eslint-disable max-len */
import { LOG_ACTION_NAME, RADIUS_CATEGORY } from '~/Pages/fraud-details/helpers/FraudScoreHelper';
import { FRAUDULENT_STATUS_ENUM, FRAUD_COUNT_ITEMS } from '../Constants';

export default {
  action_mark_as_fraud_success: '{{status}} successful',
  activity_log_item: {
    default: 'Status Updated by {{user}}',
    [FRAUDULENT_STATUS_ENUM.FRAUD_BY_USER]: 'Marked as Fraud by {{user}}',
    [FRAUDULENT_STATUS_ENUM.NON_FRAUD_BY_USER]: 'Marked as Not Fraud by {{user}}',
  },
  fraud_count_item: {
    [FRAUD_COUNT_ITEMS.TOTAL_FRAUD_ITEM]: 'Fraud',
    [FRAUD_COUNT_ITEMS.TOTAL_NON_FRAUD_ITEM]: 'Non Fraud',
    [FRAUD_COUNT_ITEMS.TOTAL_SHIPMENT]: 'Shipment',
    [FRAUD_COUNT_ITEMS.TOTAL_TRANSPORTER]: 'Transporter',
  },
  fraud_dashboard: 'Fraud Dashboard',
  fraud_item_action_construct: {
    [LOG_ACTION_NAME.SUBMIT_POD]: 'POD - Drop Off Point {{pointName}} | {{score}}/{{maxScore}}',
    [LOG_ACTION_NAME.SUBMIT_POL]: 'POL - Pick Up Point {{pointName}} | {{score}}/{{maxScore}}',
  },
  fraud_status_map: {
    [FRAUDULENT_STATUS_ENUM.FRAUD_BY_SYSTEM]: 'Fraud by System',
    [FRAUDULENT_STATUS_ENUM.FRAUD_BY_USER]: 'Fraud by User',
    [FRAUDULENT_STATUS_ENUM.NON_FRAUD_BY_SYSTEM]: 'Non Fraud by System',
    [FRAUDULENT_STATUS_ENUM.NON_FRAUD_BY_USER]: 'Non Fraud by User',
  },
  mark_as_fraud: 'Mark as Fraud',
  mark_as_not_fraud: 'Mark as Not Fraud',
  overall_score: 'Overall Score',
  overall_score_status_message: {
    [FRAUDULENT_STATUS_ENUM.FRAUD_BY_SYSTEM]: 'Malicious actions detected. Please double check all document and historical data.',
    [FRAUDULENT_STATUS_ENUM.NON_FRAUD_BY_SYSTEM]: 'Fraud not detected. Please double check all document and historical data.',
  },
  radius_category: {
    default: 'No Data',
    [RADIUS_CATEGORY.FAR]: 'Far',
    [RADIUS_CATEGORY.NEAR]: 'Near',
    [RADIUS_CATEGORY.VERY_FAR]: 'Very Far',
    [RADIUS_CATEGORY.VERY_NEAR]: 'Very Near',
  },
  shipment_score: 'Shipment Score',
  search_shipment_number_placeholder: 'Search Shipment No.',
};
