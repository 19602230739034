import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { APP_CONTEXT_STORAGE_KEY } from '~/Configurations/constants';
import Routes from './Configurations/Routes';
import AppWrapper from './Components/AppWrapper';
import ErrorFallback from './Components/ErrorFallback';

require('dotenv').config();

localStorage.setItem(APP_CONTEXT_STORAGE_KEY, 'BROKERAGE');

export default function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AppWrapper>
        <Router>
          <Routes />
        </Router>
      </AppWrapper>
    </ErrorBoundary>
  );
}
