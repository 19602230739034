import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TmsUIProvider } from '@kargotech/tms-ui';

import theme from '~/Configurations/theme';
// We need to import services/i18n to access it's global modifications
import '~/Services/i18n';

export default function UIWrapper({ children }) {
  const { i18n } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <TmsUIProvider i18n={i18n}>
        {children}
      </TmsUIProvider>
    </ThemeProvider>
  );
}
