// Enum from BE
export const REQUIREMENT_VALUE_TYPE = {
  BOOLEAN: 'BOOLEAN',
  INTEGER_MAX: 'INTEGER_MAX',
  INTEGER_MIN: 'INTEGER_MIN',
};

export const EVENT_TRACKER_ELEMENT_ID = {
  BTN_ADD_NEW_REQUIREMENT: 'BTN_ADD_NEW_REQUIREMENT',
};

export const EVENT_TRACKER_MODULE_NAME = {
  COMPANY_REQUIREMENT_SETTINGS: 'COMPANY_REQUIREMENT_SETTINGS',
  EDIT_REQUIREMENT_STATUS_DIALOG: 'EDIT_REQUIREMENT_STATUS_DIALOG',
};
