import { PODC_PHYSICAL_STATUS } from '~/Configurations/constants';
import { BROKER_PODC_RECIPIENT_TYPE } from '~/Pages/Shipment/Utilities/constants';

export const DELIVERY_DOCUMENTS_SEQUENCE = {
  READY: 'READY',
  SELECT_DOCUMENTS: 'SELECT_DOCUMENTS',
  DOWNLOAD_DOCUMENTS: 'DOWNLOAD_DOCUMENTS',
  FILL_DELIVERY_DETAILS: 'FILL_DELIVERY_DETAILS',
};

export const DIALOGS = {
  ADD_POD: 'ADD_POD',
  EDIT_POD: 'EDIT_POD',
  UPDATE_TRACKING_LOG: 'UPDATE_TRACKING_LOG',
  VIEW_TRACKING_LOG: 'VIEW_TRACKING_LOG',
  ZENDESK_TICKET: 'ZENDESK_TICKET',
  EDIT_DOCUMENT_DELIVERY: 'EDIT_DOCUMENT_DELIVERY',
  DOWNLOAD_POD: 'DOWNLOAD_POD',
};

export const VALID_DELIVER_DOCUMENT = [
  PODC_PHYSICAL_STATUS.RECEIVED,
  PODC_PHYSICAL_STATUS.DOORING_RECEIVED,
  PODC_PHYSICAL_STATUS.DELIVERING_TO_AR,
  PODC_PHYSICAL_STATUS.DELIVERING_TO_SHIPPER,
  PODC_PHYSICAL_STATUS.DELIVERED_TO_AR,
  PODC_PHYSICAL_STATUS.DELIVERED_TO_SHIPPER,
];

export const D2D_DOCUMENT_TYPE = {
  DO_1: 'do_1',
  DO_3: 'do_3',
};

export const POD_TO_RECIPIENT_TYPE = {
  [PODC_PHYSICAL_STATUS.DELIVERED_TO_AR]: BROKER_PODC_RECIPIENT_TYPE.AR,
  [PODC_PHYSICAL_STATUS.DELIVERING_TO_AR]: BROKER_PODC_RECIPIENT_TYPE.AR,
  [PODC_PHYSICAL_STATUS.DELIVERING_TO_SHIPPER]: BROKER_PODC_RECIPIENT_TYPE.SHIPPER,
  [PODC_PHYSICAL_STATUS.DELIVERED_TO_SHIPPER]: BROKER_PODC_RECIPIENT_TYPE.SHIPPER,
};

export const DOCUMENT_SEARCH_TYPES = {
  DO_NUMBER: 'DO_NUMBER',
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
  LICENSE_PLATE: 'LICENSE_PLATE',
  TRUCKING_DO_NUMBER: 'TRUCKING_DO_NUMBER',
  DOORING_DO_NUMBER: 'DOORING_DO_NUMBER',
  EXTERNAL_ID: 'EXTERNAL_ID',
};

export const JOB_TYPE = {
  SME: 'SME',
  ENTERPRISE: 'ENTERPRISE',
  PODF: 'PODF',
  DTD: 'DTD',
};
