/* eslint-disable max-len */
import { DOCUMENT_METADATA_FIELD, REQUIREMENT_VALUE_TYPE } from '../constants';

export default {
  add_manage_pricing: 'Add & Manage Pricing',
  approve_transporter: 'Approve Transporter',
  can_not_add_more_requirement: 'Cannot add more requirement because all requirements has already been added',
  delete_requirement_validation: 'This requirement name will be deleted permanently and cannot be undone.',
  destination_city: 'Destination City',
  destination_district: 'Destination District',
  document_metadata_field: {
    [DOCUMENT_METADATA_FIELD.director_name]: 'Name in {{document_type}}',
    [DOCUMENT_METADATA_FIELD.number]: '{{document_type}} Number',
  },
  empty_transporter_document: 'This Transporter Has not Upload any Document',
  expiration_date: 'Expiration Date',
  max_kg: 'Max. Kg',
  max_kg_should_greater: 'Max. Kg should be >= Min.Kg',
  message_confirm_delete_transporter_pricing: 'This Transporter Pricing will be deleted permanently and cannot be undone.',
  message_confirm_save_shipping_requirements_tags: 'The Requirement Name and Value settings will be saved. You can always edit them later when necessary.',
  message_confirm_save_transporter_pricing_tags: 'The Transporter Pricing settings will be saved. You can always edit them later when necessary.',
  message_error_overlapping_tonnage: 'Boxes in red borders cannot have overlapping Min.Kg / Max.Kg',
  min_kg: 'Min. Kg',
  min_kg_should_lower: 'Min. Kg should be <= Max.Kg',
  no_requirement_registered: 'No requirement has been registered',
  origin_city: 'Origin City',
  origin_district: 'Origin District',
  placeholder_requirement_name: 'Search Requirement Name...',
  placeholder_rp: 'Rp...',
  placeholder_search_and_choose: 'Search and choose',
  platform_created_from: 'Platform Created From',
  previous_document: 'Previous Document',
  price_settings: 'Price Settings',
  primary_documents: 'Primary Documents',
  requirement_name: 'Requirement Name',
  reject_transporter: 'Reject Transporter',
  see_previously_files: 'See previously uploaded files.',
  secondary_documents: 'Secondary Documents',
  save_and_next: 'Save and Next',
  shipper_project: 'Shipper Project',
  shipping_requirement_tags_empty: 'Let\'s add Shipping Requirements to complete its profile',
  shipping_requirement_tags_saved: 'Shipping requirement tags saved',
  shipping_requirement_tags_will_not_be_saved: 'Shipping requirement tags will not be saved if you leave this page now.',
  shipping_requirement_tags: 'Shipping Requirement Tags',
  shipping_requirement: 'Shipping Requirement',
  shipping_requirements: 'Shipping Requirements',
  title_confirm_save_shipping_requirements_tags: 'Save Shipping Requirements?',
  title_confirm_save_transporter_pricing: 'Save Transporter Pricing?',
  title_error_overlapping_tonnage: 'Error - Overlapping Min.Kg / Max.Kg',
  transporter_pricing_deleted: 'Transporter Pricing has been successfully deleted',
  transporter_pricing_updated: 'Transporter Pricing has been successfully updated',
  transporter_pricing_will_not_be_saved: 'Transporter Pricing will not be saved if you leave this page now.',
  transporter_pricing: 'Transporter Pricing',
  truck_and_route: 'Truck & Route',
  truck_selection: 'Truck Selection',
  value_type_options: {
    [REQUIREMENT_VALUE_TYPE.BOOLEAN]: 'Yes/No',
    [REQUIREMENT_VALUE_TYPE.INTEGER_MAX]: 'Maximum',
    [REQUIREMENT_VALUE_TYPE.INTEGER_MIN]: 'Minimum',
  },
  verify_document_message: 'Make sure the uploaded file matches the document type',
};
