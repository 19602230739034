/* eslint-disable import/prefer-default-export */
import { SHIPPING_LINE_STATUS } from '~/Configurations/constants';

export const DEFAULT_FILTER_VALUE = [
  SHIPPING_LINE_STATUS.CANCELLED_BY_INTERNAL,
  SHIPPING_LINE_STATUS.SHIPPING_LINE_SHIPMENT_COMPLETE,
];

export const statusOptions = t => DEFAULT_FILTER_VALUE.map(el => ({
  value: el,
  label: t(`first_mile:shipping_line_status_type.${el}`),
}));

export const SHIPMENT_HISTORY_SEARCH_TYPES = {
  DO_NUMBER: 'DO_NUMBER',
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
  CONTAINER_DO_NUMBER: 'CONTAINER_DO_NUMBER',
  PO_NUMBER: 'PO_NUMBER',
  VESSEL_OR_VOYAGE_NAME: 'VESSEL_OR_VOYAGE_NAME',
};
