/* eslint-disable max-len */
import { REQUIREMENT_VALUE_TYPE } from '~/Pages/company-requirement-settings/constants';

export default {
  add_new_requirement: 'Add New Requirement',
  error_field_cannot_empty: 'This field cannot be empty',
  error_field_cannot_empty_choose_one: 'This field cannot be empty. Please choose one.',
  number_type: 'Number Type',
  placeholder_choose_one: 'Please choose one',
  placeholder_requirement_name: 'Type requirement name here...',
  requirement_category: 'Requirement Category',
  requirement_name: 'Requirement Name',
  value_type: 'Value Type',
  value_type_options: {
    [REQUIREMENT_VALUE_TYPE.BOOLEAN]: 'Yes/No',
    [REQUIREMENT_VALUE_TYPE.INTEGER_MAX]: 'Maximum',
    [REQUIREMENT_VALUE_TYPE.INTEGER_MIN]: 'Minimum',
  },
  success_create_requirement: 'Successfully added a new requirement',
  success_update_requirement: 'The requirement detail is successfully changed',
  requirement_categories: 'Requirement Categories',
  requirement_settings_ticker:
    'This page for you to manage requirements related to truck, safety, driver or anything about shipment.',
  history_log: 'History Log',
  placeholder_search_requirement: 'Search Requirement Name...',
  requirement_status: 'Requirement Status',
  edit_status_ticker: 'Status inactive will make all existing requirements on Shippers and Transporters cannot be selected.',
};
