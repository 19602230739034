export const FRAUDULENT_STATUS_ENUM = {
  FRAUD_BY_SYSTEM: 'FRAUD_BY_SYSTEM',
  FRAUD_BY_USER: 'FRAUD_BY_USER',
  NON_FRAUD_BY_SYSTEM: 'NON_FRAUD_BY_SYSTEM',
  NON_FRAUD_BY_USER: 'NON_FRAUD_BY_USER',
};

export const FRAUDULENT_STATUS_COLOR = {
  [FRAUDULENT_STATUS_ENUM.FRAUD_BY_SYSTEM]: 'danger',
  [FRAUDULENT_STATUS_ENUM.FRAUD_BY_USER]: 'danger',
  [FRAUDULENT_STATUS_ENUM.NON_FRAUD_BY_SYSTEM]: 'tealish',
  [FRAUDULENT_STATUS_ENUM.NON_FRAUD_BY_USER]: 'tealish',
};

export const FRAUD_COUNT_ITEMS = {
  TOTAL_TRANSPORTER: 'totalTransporter',
  TOTAL_SHIPMENT: 'totalShipment',
  TOTAL_FRAUD_ITEM: 'totalFraudItem',
  TOTAL_NON_FRAUD_ITEM: 'totalNonFraudItem',
};
