/* eslint-disable import/prefer-default-export */

export const REQUIRED_DOCUMENT_OPTIONS = {
  BILL_OF_LADING: 'Bill of Lading',
  SURAT_JALAN_CARBONIZE: 'Surat Jalan Carbonize',
  SURAT_JALAN_ORIGINAL: 'Surat Jalan Original',
  BERITA_ACARA: 'Berita Acara',
  BUKTI_PENERIMAAN_BARANG: 'Bukti Penerimaan Barang',
  DISPATCH_MEMO: 'Dispatch Memo',
  FORM_ABSENSI_BONGKAR: 'Form Absensi (Bongkar)',
  FORM_ABSENSI_MUAT: 'Form Absensi (Muat)',
  KWITANSI_TKBM: 'Kwitansi TKBM',
  NOTA_TIMBANG_CARBONIZE_BONGKAR: 'Nota Timbang Carbonize (Bongkar)',
  NOTA_TIMBANG_CARBONIZE_MUAT: 'Nota Timbang Carbonize (Muat)',
  NOTA_TIMBANG_ORIGINAL_BONGKAR: 'Nota Timbang Original (Bongkar)',
  NOTA_TIMBANG_ORIGINAL_MUAT: 'Nota Timbang Original (Muat)',
  PACKING_LIST: 'Packing List',
  SURAT_PERINTAH_JALAN: 'Surat Perintah Jalan',
};
