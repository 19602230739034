export const CREDIT_LIMIT_STATUS = {
  ELIGIBLE: 'ELIGIBLE',
  EXCEPTION: 'EXCEPTION',
  OVER_LIMIT: 'OVER_LIMIT',
  HIGH_CONCERN: 'HIGH_CONCERN',
  DISABLED: 'DISABLED',
  TERMINATED: 'TERMINATED',
};

export const CREDIT_LIMIT_BANNER_TITLE = {
  NOT_SET_UP: 'project_credit_limit_not_setup',
  OVER_LIMIT: 'project_credit_limit_over',
  RUNNING_LOW: 'project_credit_limit_running_low',
};

/**
 * Container documents generated from multiple sources,
 * some of the types are defined in backend,
 * but some of them retrieve from different fields without document type
 */
export const CONTAINER_DETAILS_DOCUMENT_TYPE = {
  // defined in FE only
  RELEASE_ORDER: 'RELEASE_ORDER',
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  SHIPPING_INSTRUCTION: 'SHIPPING_INSTRUCTION',
  BILL_OF_LADING: 'BILL_OF_LADING',
  INVOICE_SHIPPING_LINE: 'INVOICE_SHIPPING_LINE',

  // defined in BE
  DTD_RECEIVING_ORDER: 'DTD_RECEIVING_ORDER',
  LOADING_INSTRUCTION: 'LOADING_INSTRUCTION',
  POL: 'POL',
  DTD_RECEIVING_CARD: 'DTD_RECEIVING_CARD',
  OCEAN_FREIGHT: 'OCEAN_FREIGHT',
};
