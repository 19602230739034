export const BID_PRICE_CHANGE_REASON = {
  ASAM_MEL_MELAN_FEE: 'ASAM_MEL_MELAN_FEE',
  LOCATION_CHANGES: 'LOCATION_CHANGES',
  MULTI_PICKUP_DROPOFF: 'MULTI_PICKUP_DROPOFF',
  TKBM_FEE: 'TKBM_FEE',
  OTHER: 'OTHER',
};

export const EVENT_TRACKER_ELEMENT_ID = {
  BTN_ACTION_CHOOSE_AS_WINNER: 'BTN_ACTION_CHOOSE_AS_WINNER',
  BTN_ACTION_EDIT_BID_PRICE: 'BTN_ACTION_EDIT_BID_PRICE',
  BTN_ACTION_WHATSAPP: 'BTN_ACTION_WHATSAPP',
  LINK_SHIPPING_REQUIREMENT: 'LINK_SHIPPING_REQUIREMENT',
  LINK_TRANSPORTER_DETAIL: 'LINK_TRANSPORTER_DETAIL',
};

export const EVENT_TRACKER_MODULE_NAME = {
  CHOOSE_TRANSPORTER_AS_WINNER_DIALOG: 'CHOOSE_TRANSPORTER_AS_WINNER_DIALOG',
  EDIT_BID_PRICE_DIALOG: 'EDIT_BID_PRICE_DIALOG',
  SHIPMENT_CHOOSE_BID_WINNER_MATCHING_ENGINE: 'SHIPMENT_CHOOSE_BID_WINNER_MATCHING_ENGINE',
  UNCHOSEN_TRANSPORTER_FEEDBACK_DIALOG: 'UNCHOSEN_TRANSPORTER_FEEDBACK_DIALOG',
};

export const UNCHOSEN_REASON = {
  EXPENSIVE_PRICE: 'EXPENSIVE_PRICE',
  REQUIREMENTS_ARE_NOT_COMPLETE: 'REQUIREMENTS_ARE_NOT_COMPLETE',
  TRANSPORTER_IS_NOT_INVOLVED_IN_BIDDING: 'TRANSPORTER_IS_NOT_INVOLVED_IN_BIDDING',
  TRANSPORTER_INACTIVE_FOR_TOO_LONG: 'TRANSPORTER_INACTIVE_FOR_TOO_LONG',
  TRUCK_IS_NOT_AVAILABLE: 'TRUCK_IS_NOT_AVAILABLE',
  OTHER: 'OTHER',
};
