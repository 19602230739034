export default {
  main_template: `
*Request Order*

*No. DO* : {{doNumber}}
*Jadwal Muat* : {{loadingDate}}
*Tipe Truk* : {{vehicleType}} {{vehicleSubType}}

{{shipmentPoints}}

*Tonase* : {{loadsCapacity}} {{loadsUnit}}

*Requirement* :
{{requirements}}

{{notes}}

Mohon konfirmasi DO tersebut melalui aplikasi Kargo Vendor dengan nomor: {{doNumber}}
Atau melalui: {{deepLinkJobVendor}}
        `,
  single_shipment_address: `
*Lokasi Muat* : {{pickupAddress}}

*Lokasi Bongkar* : {{dropoffAddress}}

        `,
  multiple_shipment_address: `
*Lokasi Muat* :
{{pickupShipmentPoints}}
*Lokasi Bongkar* :
{{dropoffShipmentPoints}}
        `,
  pickup_address: `Lokasi Muat {{index}}: {{address}}
`,
  dropoff_address: `Lokasi Bongkar {{index}}: {{address}}
`,
  notes: `
*Note* :
{{claimNotes}}

{{additionalTransporterNotes}}
        `,
  required_integer_max: '{{prefix}} maksimal {{requiredValue}}',
  required_integer_min: '{{prefix}} minimal {{requiredValue}}',
  required_boolean: '{{prefix}}',
};
