/* eslint-disable max-len */
export const GENERIC_EVENT_TRACKER = {
  /**
   * Track element click including button or link
   * @params {string} [module_name] - Name of module where the element placed (e.g. RECOMMENDED_TRANSPORTERS)
   * @params {string} element_id - Name of element (e.g. BTN_TAB_NAVIGATION_ITEM)
   * @params {string} [clicked_ksuid] - Ksuid of item being clicked
   * @params {string} [logged_in_company_ksuid] - Ksuid of logged-in company
   * @params {string} [logged_in_employee_ksuid] - Ksuid of logged-in employee
   */
  element_click: 'element_click',

  /**
   * Track when an input field is changed
   * @params {string} [module_name] - Name of module where the element placed (e.g. RECOMMENDED_TRANSPORTERS)
   * @params {string} field_name - Name of the field being changed
   */
  field_changed: 'field_changed',

  /**
   * Track applied filters in a list page
   * @params {string} field_name - Name of the field being changed
   * @params {string} field_value - New value of the field being changed.
   */
  filter_applied: 'filter_applied',

  /**
   * Track page view when URL changed. This is by default provided by Google Analytics.
   * @params {string} [page_location] - Full URL of the page
   */
  page_view: 'page_view',

  /**
   * Track when user visit a certain step in a form
   * @param {string} form_name - Unique name to differentiate the form
   * @param {string} form_session - Unique identifier to differentiate each form visit, created when user visits a form
   * @param {number} step - Currently visited step
   * @param {number} total_step - Total step to be completed
   * @param {number} sequence - Sequence of navigation to make sure back and forth between steps is recorded in the correct order
   */
  stepper_form_visit: 'stepper_form_visit',

  /**
   * Track when user leave a certain step in a form
   * @param {string} form_name - Unique name to differentiate the form
   * @param {string} form_session - Unique identifier to differentiate each form visit, created when user visits a form
   * @param {number} step - Currently visited step
   * @param {number} total_step - Total step to be completed
   * @param {number} sequence - Sequence of navigation to make sure back and forth between steps is recorded in the correct order
   * @param {number} duration_msec - Duration between stepper_form_visit and stepper_form_leave indicating the time to complete a step in milliseconds
   */
  stepper_form_leave: 'stepper_form_leave',
};
/* eslint-enable max-len */

export const GENERIC_ELEMENT_ID = {
  BTN_ACTION_DELETE_ROW: 'BTN_ACTION_DELETE_ROW',
  BTN_ACTION_EDIT_ROW: 'BTN_ACTION_EDIT_ROW',
  BTN_ACTION_EDIT_STATUS: 'BTN_ACTION_EDIT_STATUS',
  BTN_BULK_UPLOAD: 'BTN_BULK_UPLOAD',
  BTN_CANCEL: 'BTN_CANCEL',
  BTN_COLLAPSE: 'BTN_COLLAPSE',
  BTN_CONFIRM: 'BTN_CONFIRM',
  BTN_DELETE_INPUT_LIST_ITEM: 'BTN_DELETE_INPUT_LIST_ITEM',
  BTN_DOWNLOAD_TEMPLATE: 'BTN_DOWNLOAD_TEMPLATE',
  BTN_EXPAND: 'BTN_EXPAND',
  BTN_PREVIOUS: 'BTN_PREVIOUS',
  BTN_SAVE: 'BTN_SAVE',
};
