/* eslint-disable max-len */
import {
  SHIPMENT_STATUSES,
  LOCAL_SHIPMENT_STATUSES,
  DERIVED_SHIPMENT_STATUSES,
  JOB_STATUSES,
  USER_ACCESS_TYPE,
  BROKERAGE_EXTRA_USER_ACCESS_TYPE,
  BROKERAGE_INVOICE_STATUS,
  BROKERAGE_SHIPPER_INVOICE_STATUS,
  TRANSPORTER_VERIFICATION_LEVEL,
  SHIPPER_STATUSES,
  COMPANY_DRIVER_STATUSES,
  CLAIM_FORM_CHECKBOX_OPTIONS,
  AGREEMENT_STATUS_MAP,
  FIRST_MILE_USER_ACCESS_TYPE,
  DANONE_USER_ACCESS_TYPE,
  USER_SALES_ROLE,
  COMPANY_STATUSES,
  CONTRACT_MAP,
  CONTRACT_SCHEME_TYPE,
  CUSTOMER_DIVISION,
  DELIVERY_ORDER_LOAD_TYPE,
  DELIVERY_SERVICES,
  DISBURSEMENT_SCHEME_STATUS,
  DISBURSEMENT_SCHEME_TYPE,
  DO_CANCELLATION_REASONS,
  DO_REJECTION_REASONS,
  DOCUMENT_TYPE_ADD_DOCUMENT,
  DOCUMENT_VERIFICATION_REJECTION_REASONS,
  DOCUMENT_VERIFICATION_STATUSES,
  VEHICLE_COMPANY_REJECTION_REASONS,
  DRIVER_LICENSE_TYPE,
  DRIVER_STATUSES,
  VEHICLE_STATUSES,
  GENERAL_ENTITY_STATUSES,
  GOODS_REJECTION_CATEGORY,
  GPS_STATUS,
  INCLUDE_IN_CALCULATION_MAP,
  INDUSTRY,
  SME_INDUSTRY,
  INVOICE_DELIVERY_SERVICES,
  DO_INVOICE_FEE_TYPES,
  IS_RETURN_FEE_COVERED,
  IS_RETURN_GOODS,
  LOCALE_TYPE,
  LOCATION_FEE_TYPE,
  SHIPPER_LOCATION_STATUS,
  LOCATION_TYPE,
  MAP_SHIPMENT_STATUS,
  MULTIPLE_SHIPMENT_TYPE,
  ON_TIME_STATUS,
  OVERNIGHT_PROOF_TYPE,
  OVERNIGHT_FEE_PRICING_TYPE,
  PARTNER_COMPANY_DRIVER_STATUSES,
  PARTNER_COMPANY_VEHICLE_STATUSES,
  PAYMENT_REQUEST_REJECTION_TYPE,
  PAYMENT_REQUEST_AMOUNT_STATUS,
  PAYMENT_REQUEST_STATUSES,
  PAYMENT_TYPES,
  PKP_OPTIONS,
  PLATE_COLORS,
  POD_STATUS,
  PODC_DELIVERY_SERVICE,
  PODC_DELIVERY_TRACKING_LOG_STATUSES,
  PODC_DOCUMENT_REPLACERS,
  PPH23_DEDUCTOR,
  PRICING_RATE_TYPES,
  PRICING_SCALING_TYPES,
  PRODUCT_CATEGORY,
  REIMBURSEMENT_REQUIREMENT_TYPE,
  REIMBURSEMENT_TYPE,
  REMINDER_VIA,
  SHIPMENT_ACTIVITY_LOG_STATUSES,
  SHIPMENT_DOCUMENT_REJECTION_REASONS,
  SHIPMENT_QUANTITY_UNITS,
  SHIPPER_INVOICE_ACTIVITY_TYPE,
  SHIPPER_LOCATION_OPERATING_DAY,
  SHIPPER_TYPES,
  SHOW_CLAIMS_IN,
  USER_STATUSES,
  TRANSPORTER_ACCOUNT_TYPES,
  TRANSPORTER_COMPANY_REJECTION_REASONS,
  TRANSPORTER_COMPANY_STATUSES,
  DOCUMENT_TYPES,
  MIDMILE_DO_TYPES,
  FILE_UPLOAD_SOURCE,
  SHIPMENT_ACTIVITY_TYPES,
  SHIPMENT_POINT_TYPE,
  INVOICE_TAX_CONFIGURATION,
  INCENTIVE_BUSINESS_LINE,
  INCENTIVE_BUSINESS_TIMESTAMP_TYPE,
  INCENTIVE_LEDGER_STATUS,
  TAX_TYPES_OF_PAYMENT_REQUEST,
  INCENTIVE_LEDGER_AMOUNT_TYPE,
  SME_PRODUCT_CATEGORY,
  MONITORING_POSTS,
  FIRST_MILE_SHIPMENT_TABS,
  FIRST_MILE_SHIPMENT_TYPE,
  SHIPPING_LINE_STATUS,
  INVOICE_DOCUMENT_TYPES,
  INVOICE_ADD_DOCUMENT_TYPES,
  DISBURSEMENT_STATUS_CODE,
  PR_TAX_CONFIGURATION,
  PR_NEW_TAX_CONFIGURATION,
  DA_SHIPMENT_ACTIVITY_LOG_STATUSES,
  CONTRACT_TYPE,
  DTD_TRUCKING_CANCELLATION_REASONS,
  DTD_CONTAINER_CANCELLATION_REASONS,
  DO_MANUAL_ALLOCATION_REASONS,
  SUPPORTING_DOCUMENT_TYPES,
  PURCHASE_ORDER_FIRST_MILE_TRUCKING,
  DO_CANCELLATION_REASONS_V2,
  DO_CANCELLATION_REASONS_V2_CATEGORY,
  TRANSPORTER_PARTNERSHIP_CONTRACT,
  PODC_PHYSICAL_STATUS,
  DISBURSEMENT_METHOD,
  CURRENCY_CODES,
  COUNTRIES,
} from '~/Configurations/constants';

import { ACTORS, DEVICE_LOCATION_UPDATE_SOURCES } from '~/Components/DeliveryOrderActivityLog/constants';
/* eslint-disable camelcase */
import mid_mile from './en/common/mid_mile';
import tip_incentive from './en/tip_incentive';
import { COLUMNS_FIELD_KEY } from '~/Pages/Company/PaymentSettings/CustomInvoiceTemplateSettings/constants';
import { INVOICE_PAID_PAYMENT_TYPE, INVOICE_STATUS } from '~/Pages/Receivable/InvoiceTracker/constants';
import { PRICE_QUOTATION_TYPES } from '~/Pages/Customers/CustomerDetailPage/Components/Prices/PriceForm/QuotationSection/constants';
import { JOB_TYPE } from '~/Pages/Receivable/DocumentListPage/Components/DocumentType/constant';
import { DOORING_AGENT_OTHER_FEES, ON_SITE_PAYMENT_TYPE } from '~/Pages/Receivable/PODForm/ProofOfDeliveryPopup/Utilities/constants';
import { DTD_SAVED_INVOICE_FEE_TYPE } from '~/Pages/Payment/Components/AdditionalFeeFormInvoice/constants';

import {
  PAYABLE_INVOICE_TYPE,
  PAYABLE_INVOICE_STATUSES,
  PAYABLE_INVOICE_FEE_TYPES,
  ON_HOLD_REASONS,
  EXPEDITION_PROVIDER,
  NFT_REJECT_REASON,
  PAYABLE_INVOICE_CANCELLATION_REASON,
} from '~/Pages/Payment/PayableInvoice/constants';
import { FIRST_MILE_TRUCKING_SHIPMENT_TYPE, TRUCKING_SEARCH_TYPES } from '~/Pages/FirstMile/Trucking/constants';
import { DOCUMENT_SEARCH_TYPES } from '~/Pages/Receivable/DocumentListPage/constants';
import { DOORING_SEARCH_TYPES } from '~/Pages/FirstMile/DooringAgent/constant';
import { TO_BE_PROCESSED_SEARCH_TYPES } from '~/Pages/FirstMile/ShippingLine/ToBeProcessed/constants';
import { VESSEL_BOOKED_SEARCH_TYPES } from '~/Pages/FirstMile/ShippingLine/VesselBooked/constants';
import { SHIPMENT_HISTORY_SEARCH_TYPES } from '~/Pages/FirstMile/ShippingLine/ShipmentHistory/constants';
import {
  DO_INVOICE_STATUS_ENUM,
  VA_TRANSACTION_STATUS,
} from '~/Pages/Receivable/VirtualAccountListPage/utilities/constants';
import { FRAUD_SCORE_GROUP_NAME, FRAUD_SCORE_SECTION_NAME } from '~/Pages/fraud-details/helpers/FraudScoreHelper';
import { CONTAINER_SEARCH_TYPES } from '~/Pages/FirstMile/Container/constants';
import { PO_SEARCH_TYPES } from '~/Pages/FirstMile/DoorToDoorPO/constants';
import CHANGE_ROUTE_REASON from '~/Pages/FirstMile/DoorToDoorPO/Components/ChangeRouteForm/constants';
import { DOCUMENT_TYPE_ADDITIONAL_DOCUMENT } from '~/Pages/Shipment/ProofOfDeliverySidebar/constants';
import { DISBURSEMENT_PROCESS_ENUM, TRANSPORTER_KARGO_WALLET_LEDGER_CATEGORY, TRANSPORTER_KARGO_WALLET_LEDGER_SEARCH_TYPE, TRANSPORTER_KARGO_WALLET_LEDGER_STATUS } from '~/Pages/Company/Transporter/constants';
import { WHT_SELECTION_TYPE } from '~/Pages/PaymentV2/PaymentRequest/constants';
import { PROJECT_TYPE } from '~/Pages/Shipper/ShipperForm/constants';

export default {
  mid_mile,
  tip_incentive,
  acceptance_date: 'Date Issued',
  access: 'Access',
  account: 'Account',
  account_details: 'Account Details',
  account_holder: 'Account Holder',
  account_holder_required: 'Account Holder is required',
  account_holder_name: 'Account Holder Name',
  account_name: 'Account Name',
  account_number: 'Account Number',
  account_receivable: 'Account Receivable',
  account_status: 'Account Status',
  account_status_log: 'Account Status Log',
  account_tracker: 'Account Tracker',
  account_type: 'Account Type',
  action: 'Action',
  actions: 'Actions',
  activate: 'Activate',
  activate_driver: 'Activate Driver',
  activate_truck: 'Activate Unit',
  active: 'Active',
  active_delivery_order: 'Active Delivery Order',
  active_doc_labels: {
    gdlDocuments: 'Active GDL',
    kirDocuments: 'Active KIR',
    lembarPajakDocuments: 'Active Lembar Pajak',
    puspakomDocuments: 'Active Puspakom Inspection',
    simDocuments: 'Active SIM',
    stnkDocuments: 'Active STNK',
    taxRoadDocuments: 'Active Tax Road',
    vehicleRegistrationDocuments: 'Active Vehicle Registration',
  },
  active_gdl: 'Active GDL',
  active_insurance: 'Active Insurance',
  active_kir: 'Active KIR',
  active_lembar_pajak: 'Active Lembar Pajak',
  active_puspakom_inspection: 'Active Puspakom Inspection',
  active_shippers: 'Active Shippers',
  active_sim: 'Active SIM',
  active_stnk: 'Active STNK',
  active_tax_road: 'Active Tax Road',
  active_vehicle_registration: 'Active Vehicle Registration',
  activity: 'Activity',
  activity_log: 'Activity Log',
  assigned_date: 'Assigned Date',
  activity_log_type: {
    activate_partner: {
      UPDATE: 'Transporter activated by {{name}}',
    },
    change_vendor_level: {
      UPDATE: 'Transporter\'s verification level changed by {{name}}',
    },
    change_vendor_owner_type: {
      UPDATE: 'Transporter\'s account type changed by {{name}}',
    },
    comment: {
      UPDATE: '{{name}} left a comment',
    },
    create_company_drivers: {
      INSERT: 'Driver created by {{name}}',
      UPDATE: 'Driver details edited by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    create_company_vehicles: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    deactivate_partner: {
      UPDATE: 'Transporter deactivated by {{name}}',
    },
    save_brokerage_vendor: {
      INSERT: 'Transporter created by {{name}}',
      UPDATE: 'Basic Profile edited by {{name}}',
    },
    save_customer: {
      INSERT: 'Shipper created by {{name}}',
      UPDATE: 'Basic Profile edited by {{name}}',
    },
    save_partnership_contract: {
      INSERT: 'Contract {{operationRecepient}} created by {{name}}',
      UPDATE: 'Contract {{operationRecepient}} edited by {{name}}',
    },
    save_partnership_pricing: {
      INSERT: 'Contract {{operationRecepient}} edited by {{name}}',
      UPDATE: 'Contract {{operationRecepient}} edited by {{name}}',
    },
    save_partnership_bulk_pricing: {
      INSERT: 'Contract {{operationRecepient}} new pricing bulk upload by {{name}}',
      UPDATE: 'Contract {{operationRecepient}} new pricing bulk upload by {{name}}',
    },
    save_warehouse: {
      INSERT: 'Location created by {{name}}',
      UPDATE: 'Location edited by {{name}}',
    },
    save_warehouse_fee: {
      INSERT: 'Location Fee created by {{name}}',
      UPDATE: 'Location Fee edited by {{name}}',
    },
    update_partner_driver: {
      INSERT: 'Driver created by {{name}}',
      UPDATE: 'Driver details edited by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    update_partner_vehicle: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    upsert_partner_company_driver: {
      INSERT: 'Driver created by {{name}}',
      UPDATE: 'Driver details edited by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    upsert_partner_company_vehicle: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    verify_company_driver: {
      UPDATE: 'Driver verified by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    verify_company_vehicle: {
      UPDATE: 'Truck verified by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    verify_vendor: {
      UPDATE: 'Transporter verified by {{name}}',
    },
  },
  activity_update: 'Activity Update',
  actual_additional_fee_amount: 'Actual Additional Fee Amount',
  actual_amount: 'Actual Amount',
  actual_arrival: 'Actual Arrival',
  actual_berthing: 'Actual Berthing',
  actual_cancellation_amount: 'Actual Cancellation Amount',
  actual_departure: 'Actual Departure',
  actual_loading_container: 'Actual Loading Container',
  actual_other_fee_amount: 'Actual Other Fee Amount',
  actual_overnight_fee: 'Actual Overnight Fee',
  actual_shipment_fee: 'Actual Shipment Fee',
  actual_shipment_fee_amount: 'Actual Shipment Fee Amount',
  actual_fee_amount: 'Actual Fee Amount',
  add: 'Add',
  adjust: 'Adjust',
  add_account: 'Add Account',
  add_adjustment: 'Add Adjustment',
  add_appointment_number: 'Add Appointment Number',
  add_bank_account: 'Add Bank Account',
  add_bulk_description: 'Add Bulk Description',
  add_claims: 'Add Claims',
  add_company_document: 'Add Company Document',
  add_contact: '+ Add Contact',
  add_contract_document: 'Add Contract Document',
  add_customer: 'Add Customer',
  add_days: 'Add Days',
  add_document: 'Add Document',
  add_document_resturn_sla: 'Add Document Return SLA',
  add_down_payment: 'Add Down Payment',
  add_employee: 'Add Employee',
  add_entity: 'Add Entity',
  add_expected_payment_date: 'Add Expected Payment Date',
  add_fee: 'Add Fee',
  add_fee_for_truck_type: 'Add Fee For {{name}}',
  add_invoice: 'Add Invoice',
  add_invoice_type: 'Add Invoice Type',
  add_location: 'Add Location',
  add_log: 'Add Log',
  add_logo: 'Add Logo',
  add_new_pod: 'Add New POD',
  add_payment: 'Add Payment',
  add_payment_deduction: 'Add Payment Deduction',
  add_payment_item: 'Add Payment Item',
  add_pic_contact: 'Add PIC Contact',
  add_pod: 'Add POD',
  add_remark: 'Add Remark',
  add_remarks: 'Add Remarks',
  add_sales: 'Add Sales',
  add_sales_title: 'Add sales to start managing sales team',
  add_shipper: '+ Add Enterprise Shipper',
  add_shipper_button: 'Add Shipper',
  add_shipper_employee: 'Add Shipper\'s Team',
  add_shipper_group: 'Add Shipper Group',
  create_shipping_instruction: 'Create Shipping Instruction',
  add_sme_shipper: '+ Add SME Shipper',
  add_surat_perintah_muat: 'Add Surat Perintah Muat',
  add_transit_vessel_or_voyage: 'Add Transit Vessel/Voyage',
  add_tax: 'Add Tax {{do_number}}',
  add_tax_title: 'Add Tax',
  add_unit: 'Add Unit',
  add_warehouse: 'Add Warehouse',
  added_at: 'Added At',
  added_on: 'Added On',
  added_the_driver: 'Add Driver',
  additional_brokerage_fee_type: 'Addtional Fee Type',
  additional_charge: 'Additional Charge',
  additional_fee: 'Additional Fee',
  additional_fee_type: {
    cancellation: {
      TRUCK_ARRIVAL: 'Cancellation After Truck Arrival at Origin',
      TRUCK_DETAILS_CONFIRMED: 'Cancellation after Truck Details are Confirmed',
    },
    multishipment: 'Multiple Shipment Fee',
    overnight: {
      LOADING: 'Overnight Loading Fee',
      UNLOADING: 'Overnight Unloading Fee',
    },
  },
  additional_fees: 'Additional Fees',
  additional_note: 'Additional Note',
  additional_notes: 'Additional Notes',
  additional_services: 'Additional Services',
  additional_transport_notes: 'Additional Transporter Notes',
  address: 'Address',
  address_details: 'Address Details',
  addresses: 'Addresses',
  adjust_invoice: 'Adjust Invoice',
  adjustment: 'Adjustment',
  adjustment_amount: 'Adjustment Amount',
  adjustment_type: 'Adjustment Type',
  adjusted_amount: 'Adjusted Amount',
  after: 'After',
  after_dp: 'After DP',
  aging_period: 'Aging Period',
  aging_period_map: {
    '1-15': '1-15 Days',
    '16-30': '16-30 Days',
    '31-60': '31-60 Days',
    '61-90': '61-90 Days',
    '>90': '> 90 Days',
    not_due: 'Not Yet Due',
    bad_debt: 'Bad Debt',
  },
  agreement: 'Agreement',
  agreement_status: 'Agreement Status',
  agreement_status_map: {
    [AGREEMENT_STATUS_MAP.AGREEMENT_SIGNED_BY_BOTH]: 'Agreement Signed By Both',
    [AGREEMENT_STATUS_MAP.AGREEMENT_SIGNED_BY_KARGO]: 'Agreement Signed By Kargo',
    [AGREEMENT_STATUS_MAP.DRAFT_AGREEMENT]: 'Draft Agreement',
    [AGREEMENT_STATUS_MAP.QUOTATION_SIGNED_BY_BOTH]: 'Quotation Signed By Both',
    [AGREEMENT_STATUS_MAP.QUOTATION_SIGNED_BY_KARGO]: 'Quotation Signed By Kargo',
    [AGREEMENT_STATUS_MAP.UNDOCUMENTED]: 'Undocumented',
    [AGREEMENT_STATUS_MAP.WORK_ORDER_SIGNED_BY_BOTH]: 'Work Order Signed By Both',
    [AGREEMENT_STATUS_MAP.WORK_ORDER_SIGNED_BY_KARGO]: 'Work Order Signed By Kargo',
  },
  agreement_type: 'Agreement Type',
  air_waybill_no: 'Air Waybill (Resi) No.',
  air_waybill_photo: 'Air Waybill (Resi) Photo',
  airway_bill_no: 'Air Waybil (Resi) No.',
  all: 'All',
  allocate_manually: 'Allocate Manually',
  allocate_shipment: 'Allocate Shipment',
  allocated_manually: 'Allocated Manually',
  alternative_doc: 'Alternative Documents',
  am_pic: 'AM PIC',
  amount: 'Amount',
  amount_paid: 'Amount Paid',
  and: 'and',
  apply: 'Apply',
  appointment_number: 'Appointment Number',
  approve: 'Approve',
  approved: 'Approved',
  approve_reallocation: 'Approve Reallocation',
  approve_reassignment: 'Approve Reassignment',
  approve_proforma: 'Approve Proforma',
  approved_amount: 'Approved Amount',
  approved_date: 'Approve Date',
  approved_time_elapsed: 'Approved Time Elapsed',
  appt_number: 'Appt. Number',
  ap_internal_notes: 'AP Internal Notes',
  approved_fee_info: 'Approved Fee Info',
  ar: 'AR',
  ar_pic: 'AR PIC',
  arrived_at_origin_datetime: 'Arrived at Origin DateTime',
  assign: 'Assign',
  assign_drivers: 'Assign Drivers',
  assign_trucks: 'Assign Trucks',
  automation: 'Automation',
  automatic_unfulfill: 'Automatic Unfulfill',
  automatic_unfulfill_shipment: 'Automatic Unfulfill Shipment',
  available_facilities: 'Available Facilities',
  all_wallet_status: 'All Wallet Status',
  back: 'Back',
  back_to_thumbnail: 'Back to Thumbnail',
  backdate_do_template: 'Backdate Shipment DO Template',
  bad_debt: 'Bad Debt',
  bank: 'Bank',
  bank_account: 'Bank Account',
  bank_account_required: 'Bank Account is required',
  bank_account_number: 'Account Number',
  bank_account_owner: 'Account Owner',
  bank_details: 'Bank Details',
  bank_information_is: 'Informasi Bank',
  bank_name: 'Bank Name',
  bank_person: 'Bank Person',
  bank_serial_number: 'Bank {{serialNumber}}',
  basic_profile: 'Basic Profile',
  before: 'Before',
  before_dp: 'Before DP',
  below_minimum_content: 'Please check your price again before submitting this form.',
  below_minimum_title: 'Your subtotal is below the negotiation range',
  bid: 'Bid',
  bid_level: {
    L0: 'CREATED',
    L1: 'CREATED',
    L2: 'VERIFIED',
    L3: 'TRUSTED',
  },
  bid_status: {
    ACCEPTED: 'Accepted',
    CANCELLED_AT_CONFIRMATION: 'Cancelled at confirmation',
    CANCELLED_AT_TRUCK_DATA: 'Cancelled at truck_data',
    CANCELLED_BY_INTERNAL: 'Cancelled by internal',
    CANCELLED_BY_SHIPPER: 'Cancelled by shipper',
    CANCELLED_BY_SYSTEM: 'Cancelled by system',
    CANCELLED_BY_TRANSPORTER: 'Cancelled by transporter',
    OPEN: 'Bidding',
    REJECTED: 'Rejected',
    UNFULFILLE: 'Unfulfilled',
  },
  bids: 'Bids',
  billing: 'Billing',
  billing_address: 'Billing Address',
  billing_detail: 'Billing Detail',
  billing_detail_is: 'Rincian Tagihan',
  booking_date: 'Booking Date',
  booking_number: 'Booking Number',
  branch_name: 'Branch Name',
  brand: 'Brand',
  brand_name: 'Brand Name',
  bring_active_labels: {
    gdlDocuments: 'Bring Active GDL',
    kirDocuments: 'Bring active KIR',
    lembarPajakDocuments: 'Bring active Lembar Pajak',
    puspakomDocuments: 'Bring active PUSPAKOM Inspection',
    simDocuments: 'Bring active SIM',
    stnkDocuments: 'Bring active STNK',
    taxRoadDocuments: 'Bring Active Tax Road',
    vehicleRegistrationDocuments: 'Bring Active Vehicle Registration',
  },
  brokerage_invoice_status_map: {
    [BROKERAGE_INVOICE_STATUS.INVOICED]: 'Ongoing Invoice',
    [BROKERAGE_INVOICE_STATUS.NOT_INVOICED]: 'Not Invoiced',
    [BROKERAGE_INVOICE_STATUS.NOT_INVOICEABLE]: 'Not Invoiceable',
  },
  brokerage_shipper_invoice_status_map: {
    [BROKERAGE_SHIPPER_INVOICE_STATUS.INVOICE_GENERATED]: 'Generated',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.FINANCE_APPROVED]: 'Finance Approved',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.PROFORMA_ISSUED]: 'Proforma Issued',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.PROFORMA_APPROVED]: 'Proforma Approved',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.INVOICE_SENT]: 'Invoice Sent',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.INVOICE_RECEIVED]: 'Invoice Received',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.SHIPPER_APPROVED]: 'Shipper Approved',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.PAID]: 'Paid',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.PARTIAL_PAID]: 'Partially Paid',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.INSTALLMENT]: 'Installment',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.RESTRUCTURING]: 'Restructuring',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.LEGAL]: 'Legal',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.DEPRECATED]: 'Deprecated',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.FINANCE_REJECTED]: 'Finance Rejected',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.SHIPPER_REJECTED]: 'Shipper Rejected',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.CANCELLED]: 'Cancelled',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.PROFORMA_REJECTED]: 'Proforma Rejected',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.BAD_DEBT]: 'Bad Debt',
  },
  brokerage_shipper_invoice_status_title_map: {
    [BROKERAGE_SHIPPER_INVOICE_STATUS.PROFORMA_ISSUED]: 'Add Proforma Issued',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.PROFORMA_APPROVED]: 'Add Proforma Approve',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.INVOICE_SENT]: 'Send',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.INVOICE_RECEIVED]: 'Receive',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.SHIPPER_APPROVED]: 'Shipper Approved',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.PAID]: 'Add Payment',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.PARTIAL_PAID]: 'Add Payment',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.INSTALLMENT]: 'Add Installment',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.RESTRUCTURING]: 'Add Restructuring',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.FINANCE_REJECTED]: 'Add Finance Rejected',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.SHIPPER_REJECTED]: 'Add Shipper Rejected',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.CANCELLED]: 'Cancelled',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.PROFORMA_REJECTED]: 'Proforma Rejected',
    [BROKERAGE_SHIPPER_INVOICE_STATUS.BAD_DEBT]: 'Bad Debt',
  },
  bulk_upload_row_length: '{{row}} rows uploaded',
  bulk_approve: 'Bulk Approve',
  bulk_reject: 'Bulk Reject',
  business_type: 'Business Type',
  business_line: 'Business Line',
  cdl: 'CDL',
  calculate: 'Calculate',
  calculate_price: 'Calculate Price',
  calculate_tax: 'Calculate Tax',
  calculation: 'Calculation',
  calendar_days: 'Calendar Days',
  cancel: 'Cancel',
  republish_shipment_to_marketplace: 'Republish Shipment to Marketplace',
  cancel_booking: 'Cancel Booking',
  cancel_do: 'Cancel DO',
  cancel_from_marketplace: 'Cancel from Marketplace',
  cancel_invoice: 'Cancel Invoice',
  cancel_podf_shipment: 'Cancel Shipment',
  cancel_shipment: 'Cancel Shipment',
  cancelation_description: 'Cancelation Description',
  cancelation_reason: 'Cancelation Reason',
  cancellation_after_truck_arrival_at_origin: 'Cancellation after Truck Arrival at Origin',
  cancellation_category: 'Cancellation Category',
  cancellation_fee: 'Cancellation Fee',
  cancellation_fees: 'Cancellation Fees',
  cancellation_reason: 'Cancellation Reason',
  cancellation_description: 'Cancellation Description',
  cancelled: 'Cancelled',
  cannot_assign_shipment_copywriting:
    'The previous shipment of this truck has not completed.'
    + 'Please follow up the driver to make sure the previous shipment has completed',
  cannot_use_invoice_content: 'Please complete this Shipper’s profile first before creating the DO',
  cannot_use_invoice_title: 'This Shipper’s cannot use Invoicing Payment Scheme',
  capacity_amount: 'Capacity Amount',
  capacity_unit: 'Capacity Unit',
  capacity_unit_map: {
    CBM: 'CBM',
    KG: 'Kg',
    TON: 'Ton',
    UNIT: 'Unit',
  },
  canvasser: 'Canvasser',
  cc: 'CC',
  central_address: 'Main Address',
  certain_products: 'Certain Products',
  change: 'Change',
  change_file: 'Change File',
  change_account_type: 'Change Account Type',
  change_details: 'Change Details',
  change_level: 'Change Level',
  change_log: 'Change Log',
  change_password: 'Update Password',
  change_shipment: 'Update Shipment',
  change_transporter_level: 'Change {{transporter_name}} Level',
  change_truck_and_driver: 'Update Truck & Driver',
  change_warehouse: 'Update Warehouse',
  changes: 'Changes',
  check_account: 'Check Account',
  check_all: 'Check all',
  check_entry_before_submitting_message: 'Please check again your entry before submitting this form.',
  choose_custom: 'Choose {{object}}',
  choose_activity: 'Choose Activity',
  choose_alternative_doc: 'Choose Alternative Documents',
  choose_bank: 'Choose Bank',
  choose_company: 'Choose Company',
  choose_document_to: 'Choose Document to look at',
  choose_driver: 'Choose Driver',
  choose_file: 'Choose File',
  choose_language: 'Choose Language',
  choose_onhold_status: 'Choose Onhold Status',
  choose_soa: 'Choose SoA',
  choose_truck: 'Choose Truck',
  choose_winner: 'Choose Winner',
  chosen_transporter: 'Chosen Transporter',
  chosen_winner: 'Chosen Winner',
  choose_location_name: 'Choose Location Name',
  choose_shipment_type: 'Choose Shipment Type',
  city: 'City',
  claim: 'Claim',
  claim_disbursement_deadline: 'Claim Disbursement Deadline',
  claim_form: {
    amounts_claimed_unit: 'Unit',
    checkbox: {
      [CLAIM_FORM_CHECKBOX_OPTIONS.RECEIVED_AT_PITSTOP]: 'Received at Pitstop',
      [CLAIM_FORM_CHECKBOX_OPTIONS.RETURNED_TO_SHIPPER]: 'Returned to Shipper',
    },
    fields: {
      additional_notes: 'Additional Notes',
      amounts_claimed: 'Amounts Claimed',
      claim_type: 'Claim Type',
      product_name: 'Product Name',
      recieved_amount: 'Received Amount',
      returned_amount: 'Returned Amount',
    },
  },
  claim_submission_deadline: 'Claim Submission Deadline',
  claim_value_collection_sla: 'Claim Value Collection SLA',
  claims: 'Claims',
  clear: 'Clear',
  close: 'Close',
  close_date: 'Close Date',
  closing_date: 'Closing Date',
  collected_documents: 'Collected Documents',
  collection_details: 'Collection Details',
  color: 'Color',
  commercial: 'Commercial',
  company: 'Company',
  company_address: 'Company Address',
  company_details: 'Company Details',
  company_document: 'Company Document',
  company_document_type: 'Company Document Type',
  company_documents: 'Company Documents',
  company_driver_status_map: {
    [SHIPPER_STATUSES.ACTIVE]: 'Active',
    [COMPANY_DRIVER_STATUSES.DOCUMENT_EXPIRED]: 'Document Expired',
    [COMPANY_DRIVER_STATUSES.DRAFT]: 'Draft',
    [COMPANY_DRIVER_STATUSES.PENDING]: 'Pending',
    [SHIPPER_STATUSES.REJECTED]: 'Rejected',
    [SHIPPER_STATUSES.REGISTERED]: 'Registered',
  },
  company_name: 'Company Name',
  company_number_already_exists: 'Company Name already exists',
  company_settings: 'Company Settings',
  company_status_map: {
    [COMPANY_STATUSES.ACTIVE]: 'Active',
    [COMPANY_STATUSES.INACTIVE]: 'Inactive',
    [COMPANY_STATUSES.REGISTERED]: 'Registered',
    [COMPANY_STATUSES.CHURN]: 'Churn',
    [COMPANY_STATUSES.DEACTIVATED]: 'Deactivated',
  },
  complete: 'Complete',
  complete_shipper_profile: 'Complete Shipper’s Profile >',
  confirm_proforma_invoice: 'Proforma Invoice Confirmation',
  confirm_reallocation: 'Confirm Reallocation',
  confirm_reassignment: 'Confirm Reassignment',
  confirm_rejection: 'Reject Reassignment?',
  confirm_rejection_trucking: 'Reject Reassignment for {{doNumber}}?',
  consignee: 'Consignee',
  consignee_tracking: 'Consignee Tracking',
  contact: 'Contact',
  contact_details: 'Contact Details',
  contact_number: 'Contact Number',
  contact_person: 'Contact Person',
  contacts: 'Contacts',
  container: 'Container',
  container_do: 'Container DO',
  container_grade: 'Container Grade',
  container_group_load: 'Container Group Load',
  container_information: 'Container Information',
  truck_container_information: 'Truck & Container Information',
  container_quantity: 'Container Quantity',
  container_type: 'Container Type',
  continue: 'Continue',
  contract: 'Contract',
  contract_name: 'Contract Name',
  contract_details: 'Contract Details',
  contract_document: 'Contract Document',
  contract_document_map: {
    [CONTRACT_MAP.CONTRACT_WORK_ORDER]: 'Work Order',
    [CONTRACT_MAP.CONTRACT_AGREEMENT]: 'Agreement',
  },
  contract_document_type: 'Contract Document Type',
  contract_documents: 'Contract Documents',
  contract_map: {
    [CONTRACT_MAP.CONTRACT_AGREEMENT]: 'Agreement',
    [CONTRACT_MAP.CONTRACT_WORK_ORDER]: 'Work Order',
  },
  contract_number: 'Contract Number',
  contract_scheme: 'Contract Scheme',
  contract_scheme_type_map: {
    [CONTRACT_SCHEME_TYPE.ALL_SHIPPER]: 'Dedicated',
    [CONTRACT_SCHEME_TYPE.SINGLE_SHIPPER]: 'Project',
  },
  contract_signee_name: 'Signee Name',
  contract_signee_phone_number: 'Signee Phone Number',
  contract_signee_role: 'Signee Role',
  contract_signing_date: 'Contract Signing Date',
  contract_signing_datetime: 'Contract Signing Date',
  contract_type: 'Contract Type',
  contracts: 'Contracts',
  copy_message: 'Copy Message',
  copy_with_template: 'Copy with Template',
  counted: 'Counted',
  counted_is: 'Terbilang',
  country: 'Country',
  country_code: 'Country Code',
  country_map: {
    [COUNTRIES.ID]: 'Indonesia',
    [COUNTRIES.MY]: 'Malaysia',
  },
  country_code_tooltip: 'Configured in Personal Account page',
  courier_detail: 'Courier Detail',
  covered_by_insurance: 'Covered by Insurance',
  create_bulk_pr: 'Create Bulk PR',
  create_disbursement_request: 'Create Payment Request',
  create_invoice: 'Create Invoice',
  create_new_customer: 'Create New Customer',
  create_new_warehouse: 'Create Warehouse',
  create_order: 'Create Order',
  create_podf: 'Create PODF',
  create_payment_request: 'Create Payment Request',
  create_request: 'Create Request',
  create_shipment: 'Create Shipment',
  create_ticket: 'Create Ticket',
  create_zendesk_ticket: 'Create Zendesk Ticket',
  created_at: 'Created At',
  created_by: 'Created By',
  creator: 'Creator',
  created_on: 'Created On',
  created_date: 'Created Date',
  creation_date: 'Creation Date',
  csv_semicolon_rule: 'Make sure you use semicolons ( ; ) to separate items in the CSV file.',
  currency: 'Currency',
  currency_code_map: {
    [CURRENCY_CODES.IDR]: 'Indonesian Rupiah (IDR)',
    [CURRENCY_CODES.MYR]: 'Malaysian Ringgit (MYR)',
    [CURRENCY_CODES.SGD]: 'Singapore Dollar (SGD)',
    [CURRENCY_CODES.USD]: 'US Dollar (USD)',
  },
  currency_prefix_map: {
    [CURRENCY_CODES.IDR]: 'Rp',
    [CURRENCY_CODES.MYR]: 'RM',
    [CURRENCY_CODES.SGD]: '$',
    [CURRENCY_CODES.USD]: '$',
  },
  current_shipment: 'Current Shipment',
  current_status: 'Current Status',
  current_balance: 'Current Balance',
  current_group: 'Current Group',
  customer: 'Customer',
  customer_details: 'Customer Details',
  customer_division: {
    [CUSTOMER_DIVISION.OPERATIONS]: 'Operations',
    [CUSTOMER_DIVISION.WAREHOUSE]: 'Warehouse',
    [CUSTOMER_DIVISION.SALES]: 'Sales',
    [CUSTOMER_DIVISION.FINANCE]: 'Finance',
    [CUSTOMER_DIVISION.PROCUREMENT]: 'Procurement',
    [CUSTOMER_DIVISION.LOGISTICS]: 'Logistics',
    [CUSTOMER_DIVISION.TREASURY]: 'Treasury',
    [CUSTOMER_DIVISION.CLAIMS]: 'Claims',
  },
  customer_list: 'Customer List',
  customer_name_slash_company: 'Customer / Company Name',
  cv: 'CV',
  damage: 'Damage',
  damage_type: 'Damage Type',
  date: 'Date',
  date_of_establishment: 'Date Of Establishment',
  date_of_issue: 'Date of Issue',
  date_placeholder: 'DD/MM/YYYY',
  day: 'Day',
  days: 'Days',
  deactivate: 'Deactivate',
  deactivate_customer: 'Deactivate Customer',
  deactivate_driver: 'Deactivate Driver',
  deactivate_employee: 'Deactivate Employee',
  deactivate_location: 'Deactivate Location',
  deactivate_truck: 'Deactivate Unit',
  deactivate_warehouse: 'Deactivate Warehouse',
  deadline_date_and_time: 'Deadline Date and Time',
  default_account: 'Default Account',
  delete: 'Delete',
  delete_account: 'Delete Account',
  delete_adjustment_confirmation: 'Delete Adjustment?',
  delete_adjustment_confirmation_pr: 'Delete Adjustment from Payment Request?',
  delete_fee: 'Delete Fee',
  delete_payment_from_invoice: 'Delete payment from invoice?',
  delete_truck_requirement: 'Delete Truck Requirements',
  deliver_documents: 'Deliver Documents',
  delivery: 'Delivery',
  delivery_date: 'Delivery Date',
  delivery_detail: 'Delivery Detail',
  delivery_details: 'Delivery Details',
  delivery_order: 'Delivery Order',
  delivery_order_load_map: {
    [DELIVERY_ORDER_LOAD_TYPE.GROUP_LOAD]: 'Group Load',
    [DELIVERY_ORDER_LOAD_TYPE.NON_GROUP_LOAD]: 'Non Group Load',
  },
  delivery_service: 'Delivery Service',
  delivery_services_map: {
    [DELIVERY_SERVICES.JNE]: 'JNE',
    [DELIVERY_SERVICES.OTHER_DELIVERY_SERVICE]: 'Other Delivery Service',
  },
  delivery_order_types_map: {
    [MIDMILE_DO_TYPES.ENTERPRISE]: 'Enterprise',
    [MIDMILE_DO_TYPES.PODF]: 'PODF',
    [MIDMILE_DO_TYPES.RETAIL]: 'Retail',
    [MIDMILE_DO_TYPES.SME]: 'SME',
  },
  departed_from_origin_datetime: 'Departed from Origin DateTime',
  department: 'Department',
  description: 'Description',
  destination: 'Destination',
  destination_warehouse: 'Destination Warehouse',
  detail_title: 'Details',
  details: 'Details',
  device_model: 'Device Model',
  devices: 'Devices',
  device_location_update_source_map: {
    [DEVICE_LOCATION_UPDATE_SOURCES.MOBILE]: 'D-App',
    [DEVICE_LOCATION_UPDATE_SOURCES.WHATSAPP]: 'Whatsapp',
    [DEVICE_LOCATION_UPDATE_SOURCES.GPS_DEVICE]: 'GPS Device',
  },
  dimension: 'Dimension',
  director_name: 'Director Name',
  disburse_amount: 'Disburse Amount',
  disbursement: 'Disbursement',
  disbursement_amount: 'Disbursement Amount',
  disbursement_details: 'Disbursement Details',
  disbursement_process: 'Disbursement Process',
  disbursement_scheme: 'Disbursement Scheme',
  disbursement_scheme_name: 'Scheme Name',
  disbursement_scheme_stage_map: {
    do_stage: 'DO',
    emoney_stage: 'E-Money',
    invoice_paid_stage: 'Invoice Paid',
    invoice_sent_stage: 'Invoice Sent',
    pod_digital_stage: 'POD Digital',
    pod_physical_stage: 'POD Physical',
    pol_stage: 'POL',
  },
  disbursement_scheme_status_map: {
    [DISBURSEMENT_SCHEME_STATUS.ACTIVE]: 'Active',
    [DISBURSEMENT_SCHEME_STATUS.INACTIVE]: 'Inactive',
  },
  disbursement_scheme_type_map: {
    [DISBURSEMENT_SCHEME_TYPE.BROKERAGE]: 'Brokerage',
    [DISBURSEMENT_SCHEME_TYPE.MARKETPLACE]: 'Marketplace',
    [DISBURSEMENT_SCHEME_TYPE.FINANCING]: 'Financing',
  },
  disbursement_status_code: {
    [DISBURSEMENT_STATUS_CODE.INSUFFICIENT_BALANCE]: 'Insufficient Balance',
    [DISBURSEMENT_STATUS_CODE.UNKNOWN_BANK_NETWORK_ERROR]: 'Unknown Bank Network Error',
    [DISBURSEMENT_STATUS_CODE.TEMPORARY_BANK_NETWORK_ERROR]: 'Bank Network Error',
    [DISBURSEMENT_STATUS_CODE.SWITCHING_NETWORK_ERROR]: 'Switching Network Error',
    [DISBURSEMENT_STATUS_CODE.INVALID_DESTINATION]: 'Invalid Account Destination',
    [DISBURSEMENT_STATUS_CODE.REJECTED_BY_BANK]: 'Rejected by Bank',
    [DISBURSEMENT_STATUS_CODE.TRANSFER_ERROR]: 'Transfer Error',
    [DISBURSEMENT_STATUS_CODE.TEMPORARY_TRANSFER_ERROR]: 'Transfer Error',
    [DISBURSEMENT_STATUS_CODE.UNKNOWN_ERROR]: 'Unknown Error',
  },
  disbursement_stages: 'Disbursement Stages',
  disbursement_summary: 'Disbursement Summary',
  disbursement_time: 'Disbursement Time',
  disbursement_process_enum_map: {
    [DISBURSEMENT_PROCESS_ENUM.INSTANT]: 'Instant',
    [DISBURSEMENT_PROCESS_ENUM.WEEKLY]: 'Weekly',
    [DISBURSEMENT_PROCESS_ENUM.THURSDAY]: 'Thursday',
  },
  disbursement_schedule_enum_map: {
    [DISBURSEMENT_PROCESS_ENUM.INSTANT]: 'Instant',
    [DISBURSEMENT_PROCESS_ENUM.THURSDAY]: 'Weekly Disbursement',
    [DISBURSEMENT_PROCESS_ENUM.WEEKLY]: 'Weekly +7 Disbursement',
  },
  discount: 'Discount',
  discount_amount: 'Discount Amount',
  distance: 'Distance',
  distribution_time: 'Distribution Time',
  district: 'District',
  division: 'Division',
  do_manual_allocation_reason_map: {
    [DO_MANUAL_ALLOCATION_REASONS.CONTRACT_PRICE_NOT_AVAILABLE]: 'Contract Price Not Available',
    [DO_MANUAL_ALLOCATION_REASONS.CONTRACT_PRICE_HAS_CHANGED]: 'Contract Price Has Changed',
    [DO_MANUAL_ALLOCATION_REASONS.NEW_PRICE_ROUTE]: 'New Price Route',
    [DO_MANUAL_ALLOCATION_REASONS.MARKETPLACE_TIME_LIMIT]: 'Marketplace Time Limit',
    [DO_MANUAL_ALLOCATION_REASONS.OTHER_REASON]: 'Other Reason',
  },
  do_cancellation_reason_map: {
    [DO_CANCELLATION_REASONS.TRUCK_NO_SHOW]: 'No Show',
    [DO_CANCELLATION_REASONS.COMPLIANCE_ISSUE]: 'Safety & Quality Compliance',
    [DO_CANCELLATION_REASONS.FULL_STOCK]: 'Full Stock',
    [DO_CANCELLATION_REASONS.NO_EMPTY_JUGS]: 'No Empty Jugs',
    [DO_CANCELLATION_REASONS.NO_PALLET_JUGRACK]: 'No Pallet / JR',
    [DO_CANCELLATION_REASONS.NO_STOCK]: 'No Stock',
    [DO_CANCELLATION_REASONS.LOADING_DELAY]: 'Loading Delay',
    [DO_CANCELLATION_REASONS.TKBM_ISSUE]: 'TKBM',
    [DO_CANCELLATION_REASONS.FORCE_MAJEURE]: 'Force Majeure',
    [DO_CANCELLATION_REASONS.ALLOCATION_REVISION]: 'Revision Allocation',
    [DO_CANCELLATION_REASONS.OTHER_REASON]: 'Other Reason',
  },
  do_cancellation_reason_v2_category_map: {
    [DO_CANCELLATION_REASONS_V2_CATEGORY.CHANGE_POINT]: 'Change Point',
    [DO_CANCELLATION_REASONS_V2_CATEGORY.DO_ALLOCATION]: 'DO allocation',
    [DO_CANCELLATION_REASONS_V2_CATEGORY.INTERNAL_ISSUE]: 'Internal Issue',
    [DO_CANCELLATION_REASONS_V2_CATEGORY.AUDIT_BY_AM]: 'Audit by AM',
    [DO_CANCELLATION_REASONS_V2_CATEGORY.SHIPMENT_RESCHEDULE]: 'Shipment Reschedule',
    [DO_CANCELLATION_REASONS_V2_CATEGORY.SHIPPER_ISSUE]: 'Shipper issue',
    [DO_CANCELLATION_REASONS_V2_CATEGORY.TECHNICAL_ISSUE]: 'Technical Issue',
    [DO_CANCELLATION_REASONS_V2_CATEGORY.TRANSPORTER_ISSUE]: 'Transporter Issue',
    [DO_CANCELLATION_REASONS_V2_CATEGORY.WRONG_INPUT_BY_CS]: 'Wrong Input by CS',
    [DO_CANCELLATION_REASONS_V2_CATEGORY.OTHER_REASON]: 'Other Reason',
  },
  do_cancellation_reason_v2_map: {
    [DO_CANCELLATION_REASONS_V2_CATEGORY.CHANGE_POINT]: {
      [DO_CANCELLATION_REASONS_V2.CHANGE_POINT.DESTINATION_AFTER_TRUCK_ARRIVAL_FOR_LOADING]:
        'Destination (After Truck Arrival for Loading)',
      [DO_CANCELLATION_REASONS_V2.CHANGE_POINT.DESTINATION_BEFORE_TRUCK_ARRIVAL]: 'Destination (Before Truck Arrival)',
      [DO_CANCELLATION_REASONS_V2.CHANGE_POINT.DESTINATION_AFTER_TRUCK_ARRIVAL_FOR_UNLOADING]:
        'Destination (After Truck Arrival for Unloading)',
      [DO_CANCELLATION_REASONS_V2.CHANGE_POINT.ORIGIN_AFTER_TRUCK_ARRIVAL_FOR_LOADING]:
        'Origin (After Truck Arrival for Loading)',
      [DO_CANCELLATION_REASONS_V2.CHANGE_POINT.ORIGIN_BEFORE_TRUCK_ARRIVAL]: 'Origin (Before Truck Arrival)',
      [DO_CANCELLATION_REASONS_V2.CHANGE_POINT.MULTISHIPMENT_TO_SINGLE_SHIPMENT]: 'Multishipment to Single Shipment',
      [DO_CANCELLATION_REASONS_V2.CHANGE_POINT.SINGLE_SHIPMENT_TO_MULTISHIPMENT]: 'Single Shipment to Multishipment',
    },
    [DO_CANCELLATION_REASONS_V2_CATEGORY.DO_ALLOCATION]: {
      [DO_CANCELLATION_REASONS_V2.DO_ALLOCATION.ALLOCATED_TO_COMPETITOR]: 'Allocated to Competitor',
      [DO_CANCELLATION_REASONS_V2.DO_ALLOCATION.BAD_SHIPPER_PRICING]: 'Bad Shipper Pricing',
      [DO_CANCELLATION_REASONS_V2.DO_ALLOCATION.NO_BUCKET]: 'No Bucket (recommended transporters)',
    },
    [DO_CANCELLATION_REASONS_V2_CATEGORY.INTERNAL_ISSUE]: {
      [DO_CANCELLATION_REASONS_V2.INTERNAL_ISSUE.CHANGE_SHIPPER_PROFILE]: 'Change Shipper Profile',
      [DO_CANCELLATION_REASONS_V2.INTERNAL_ISSUE.WRONG_ASSIGNED_BY_FULFILLMENT_TRANSPORTER_PROFILE]:
        'Wrong Assigned by Fulfillment - Transporter profile',
      [DO_CANCELLATION_REASONS_V2.INTERNAL_ISSUE.WRONG_DRIVER_DATA_BY_FULFILLMENT]: 'Wrong Driver Data by Fulfillment',
      [DO_CANCELLATION_REASONS_V2.INTERNAL_ISSUE.WRONG_TRANSPORTER_PRICE_BY_FULFILLMENT]:
        'Wrong Transporter Price by Fulfillment',
      [DO_CANCELLATION_REASONS_V2.INTERNAL_ISSUE.WRONG_COMPLETED_BY_MONITORING]: 'Wrong Completed by Monitoring',
      [DO_CANCELLATION_REASONS_V2.INTERNAL_ISSUE.WRONG_UNFULL_BY_TP_ADMIN]: 'Wrong Unfull by TP Admin',
      [DO_CANCELLATION_REASONS_V2.INTERNAL_ISSUE.WRONG_POL_POD_UPLOAD_BY_MONITORING_POD]:
        'Wrong POL/POD Upload by Monitoring/POD',
      [DO_CANCELLATION_REASONS_V2.INTERNAL_ISSUE.WRONG_PAYMENT_SCHEME_BY_ACCOUNT_PAYMENT]:
        'Wrong Payment Scheme by Account Payment',
      [DO_CANCELLATION_REASONS_V2.INTERNAL_ISSUE.CHANGE_TRANSPORTER_PROFILE]: 'Change Transporter Profile',
      [DO_CANCELLATION_REASONS_V2.INTERNAL_ISSUE.TIP_ERROR]: 'TIP error',
      [DO_CANCELLATION_REASONS_V2.INTERNAL_ISSUE.CHANGE_DRY_RUN]: 'Change Dry Run',
      [DO_CANCELLATION_REASONS_V2.INTERNAL_ISSUE.PAYMENT_LIMIT]: 'Payment Limit',
    },
    [DO_CANCELLATION_REASONS_V2_CATEGORY.AUDIT_BY_AM]: {
      [DO_CANCELLATION_REASONS_V2.AUDIT_BY_AM.LONG_QUEING_AT_UNLOADING_WAREHOUSE]: 'Long Queing at Unloading Warehouse',
      [DO_CANCELLATION_REASONS_V2.AUDIT_BY_AM.LONG_QUEING_AT_LOADING_WAREHOUSE]: 'Long Queing at Loading Warehouse',
      [DO_CANCELLATION_REASONS_V2.AUDIT_BY_AM.SHIPPER_OVERDUE_PAYMENT]: 'Shipper Overdue Payment',
      [DO_CANCELLATION_REASONS_V2.AUDIT_BY_AM.ON_NEGOTIATION_WITH_SHIPPER_REGARDING_SHIPPER_PRICE]:
        'On Negotiation with Shipper Regarding Shipper Price',
      [DO_CANCELLATION_REASONS_V2.AUDIT_BY_AM.WRONG_SHIPPER_PRICE]: 'Wrong Shipper Price',
    },
    [DO_CANCELLATION_REASONS_V2_CATEGORY.SHIPMENT_RESCHEDULE]: {
      [DO_CANCELLATION_REASONS_V2.SHIPMENT_RESCHEDULE.EARLY_PICKUP_DATE]: 'Early Pick up (Date)',
      [DO_CANCELLATION_REASONS_V2.SHIPMENT_RESCHEDULE.DELAYED_PICK_UP_DATE]: 'Delayed Pick up (Date)',
      [DO_CANCELLATION_REASONS_V2.SHIPMENT_RESCHEDULE.EARLY_PICKUP_TIME]: 'Early Pick up (Time)',
      [DO_CANCELLATION_REASONS_V2.SHIPMENT_RESCHEDULE.DELAYED_PICK_UP_TIME]: 'Delayed Pick up (Time)',
    },
    [DO_CANCELLATION_REASONS_V2_CATEGORY.SHIPPER_ISSUE]: {
      [DO_CANCELLATION_REASONS_V2.SHIPPER_ISSUE.OVERCAPACITY]: 'Overcapacity',
      [DO_CANCELLATION_REASONS_V2.SHIPPER_ISSUE.PRODUCTION_ISSUE]: 'Production Issue',
      [DO_CANCELLATION_REASONS_V2.SHIPPER_ISSUE.PURCHASE_ORDER_IS_CANCELLED]: 'Purchase Order is Cancelled',
      [DO_CANCELLATION_REASONS_V2.SHIPPER_ISSUE.CHANGE_SHIPPER_PRICE]: 'Change Shipper Price',
      [DO_CANCELLATION_REASONS_V2.SHIPPER_ISSUE.CANCELLED_PROJECT]: 'Cancelled Project',
      [DO_CANCELLATION_REASONS_V2.SHIPPER_ISSUE.WRONG_TRUCK_SPEC]: 'Wrong Truck Spec',
      [DO_CANCELLATION_REASONS_V2.SHIPPER_ISSUE.CHANGE_TRUCK_TYPE]: 'Change Truck Type',
      [DO_CANCELLATION_REASONS_V2.SHIPPER_ISSUE.CHANGE_TONNAGE]: 'Change Tonnage',
    },
    [DO_CANCELLATION_REASONS_V2_CATEGORY.TECHNICAL_ISSUE]: {
      [DO_CANCELLATION_REASONS_V2.TECHNICAL_ISSUE.RE_UPLOAD_DO_TO_PANTHERA_FOR_MARKETPLACE]:
        'Re Upload DO to Panthera for Marketplace',
      [DO_CANCELLATION_REASONS_V2.TECHNICAL_ISSUE.PANTHERA_ERROR_BUG]: 'Panthera Error (Bug)',
    },
    [DO_CANCELLATION_REASONS_V2_CATEGORY.TRANSPORTER_ISSUE]: {
      [DO_CANCELLATION_REASONS_V2.TRANSPORTER_ISSUE.WRONG_BID_BY_TRANSPORTER]: 'Wrong Bid by Transporter',
      [DO_CANCELLATION_REASONS_V2.TRANSPORTER_ISSUE.CHANGE_TRUCK_TYPE]: 'Change Truck Type',
      [DO_CANCELLATION_REASONS_V2.TRANSPORTER_ISSUE.CHANGE_PAYMENT_SCHEME]: 'Change Payment Scheme',
      [DO_CANCELLATION_REASONS_V2.TRANSPORTER_ISSUE.CHANGE_TRANSPORTER_PRICE]: 'Change Transporter Price',
      [DO_CANCELLATION_REASONS_V2.TRANSPORTER_ISSUE.WRONG_ASSIGNED_UNIT_BY_TRANSPORTER]:
        'Wrong Assigned Unit by Transporter',
    },
    [DO_CANCELLATION_REASONS_V2_CATEGORY.WRONG_INPUT_BY_CS]: {
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.SHIPPER_NAME]: 'Shipper Name',
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.SHIPMENT_LOCATION]: 'Shipment Location',
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.PICKUP_DATE_OR_TIME]: 'Pickup Date or Time',
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.DROP_OFF_DATE_OR_TIME]: 'Drop Off Date or Time',
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.SHIPMENT_TYPE]: 'Shipment Type',
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.TRUCK_TYPE]: 'Truck Type',
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.CAPACITY]: 'Capacity',
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.NOTES]: 'Notes',
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.DUPLICATE_DELIVERY_ORDER]: 'Duplicate Delivery Order',
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.CHANGE_CONFIRMATION_TIME]: 'Change Confirmation Time',
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.TAGGING]: 'Tagging',
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.SHIPPER_PRICING]: 'Shipper Pricing',
      [DO_CANCELLATION_REASONS_V2.WRONG_INPUT_BY_CS.TRANSPORTER_PRICING]: 'Transporter Pricing',
    },
  },
  dtd_container_cancellation_reason_map: {
    [DTD_CONTAINER_CANCELLATION_REASONS.SHIPPER_REQUEST]: 'Shipper Request',
    [DTD_CONTAINER_CANCELLATION_REASONS.CHANGE_STUFFING_LOCATION]: 'Change Stuffing Location',
    [DTD_CONTAINER_CANCELLATION_REASONS.CHANGE_DESTINATION]: 'Change Destination',
    [DTD_CONTAINER_CANCELLATION_REASONS.CHANGE_STUFFING_DATE]: 'Change Stuffing Date',
    [DTD_CONTAINER_CANCELLATION_REASONS.OTHER]: 'Other',
  },
  dtd_trucking_cancellation_reason_map: {
    [DTD_TRUCKING_CANCELLATION_REASONS.FORCE_MAJEURE]: 'Force Majeure',
    [DTD_TRUCKING_CANCELLATION_REASONS.CONTAINER_MISPLACED]: 'Container Misplaced',
    [DTD_TRUCKING_CANCELLATION_REASONS.CONTAINER_REJECTED_BY_SHIPPER]: 'Container or Unit Rejected by Shipper',
    [DTD_TRUCKING_CANCELLATION_REASONS.FULL_STOCK]: 'Full Stock',
    [DTD_TRUCKING_CANCELLATION_REASONS.NO_STOCK]: 'No Stock',
    [DTD_TRUCKING_CANCELLATION_REASONS.LOADING_DELAY]: 'Loading Delay',
    [DTD_TRUCKING_CANCELLATION_REASONS.REVISION_ALLOCATION]: 'Revision Allocation',
    [DTD_TRUCKING_CANCELLATION_REASONS.SAFETY_QUALITY_COMPLIANCE]: 'Safety & Quality Compliance',
    [DTD_TRUCKING_CANCELLATION_REASONS.TKBM]: 'TKBM',
    [DTD_TRUCKING_CANCELLATION_REASONS.OTHER_REASON]: 'Other Reason',
    [DTD_CONTAINER_CANCELLATION_REASONS.SHIPPER_REQUEST]: 'Shipper Request',
    [DTD_CONTAINER_CANCELLATION_REASONS.CHANGE_STUFFING_LOCATION]: 'Change Stuffing Location',
    [DTD_CONTAINER_CANCELLATION_REASONS.CHANGE_DESTINATION]: 'Change Destination',
    [DTD_CONTAINER_CANCELLATION_REASONS.CHANGE_STUFFING_DATE]: 'Change Stuffing Date',
  },
  do_details: 'DO Details',
  do_estimated_price: 'Estimated Posted Price',
  do_margin: 'Margin',
  do_max_bid: 'Max Transporter Bid Price',
  do_max_bid_limit: 'Max Bid Limit',
  do_number: 'DO Number',
  do_posted_price: 'Posted Price {{unit}}',
  do_rejection_reason_map: {
    [DO_REJECTION_REASONS.VEHICLE_NOT_READY]: 'Vehicle Not Ready',
    [DO_REJECTION_REASONS.VEHICLE_MAINTENANCE_NEEDED]: 'Vehicle Maintenance Needed',
    [DO_REJECTION_REASONS.DRIVER_NOT_READY]: 'Driver Not Ready',
    [DO_REJECTION_REASONS.OTHER_REASON]: 'Other Reason',
  },
  do_shipper_pricing: 'Shipper Pricing',
  do_suggested_price: 'Suggested Price',
  do_summary: 'DO Summary',
  do_type: 'Route',
  do_types_map: {
    undefined: '',
    SME: 'SME',
    ENTERPRISE: 'Enterprise',
    PODF: 'PODF',
    TRUCKING_DTD: 'Trucking',
    SHIPPING_LINE_DTD: 'Shipping Line',
    DOORING_AGENT_DTD: 'Dooring',
  },
  do_use_suggested_price: 'Use',
  doc_labels: {
    gdlDocuments: 'GDL',
    kirDocuments: 'KIR',
    lembarPajakDocuments: 'Lembar Pajak',
    puspakomDocuments: 'PUSPAKOM',
    simDocuments: 'SIM',
    stnkDocuments: 'STNK',
    taxRoadDocuments: 'Tax Road',
    vehicleRegistrationDocuments: 'Vehicle Registration',
  },
  doc_required: 'Documents Required',
  doc_scanned: 'Scanned Documents',
  document: 'Document',
  document_collection_notes: 'Document Collection Notes',
  document_delivery_no: 'Document Delivery No.',
  document_details: 'Document Details',
  document_expiry_date: '{{name}} Expiry Date',
  document_info: 'Document Information',
  document_not_uploaded: 'Document Not Uploaded',
  document_number: '{{name}} Number',
  document_replacer: 'Document Replacer',
  required_document_return: 'Required Document Return',
  document_review: 'Document Review',
  document_status: 'Document Status',
  document_type: 'Document Type',
  do_invoice_status: {
    [DO_INVOICE_STATUS_ENUM.PAID]: 'Paid',
    [DO_INVOICE_STATUS_ENUM.PARTIAL_PAID]: 'Partially Paid',
    [DO_INVOICE_STATUS_ENUM.UNPAID]: 'Unpaid',
    [DO_INVOICE_STATUS_ENUM.SHIPPER_APPROVED]: 'Shipper Approved',
  },
  document_type_add_document: {
    [DOCUMENT_TYPE_ADDITIONAL_DOCUMENT.PROOF_OF_DELIVERY]: 'POD',
    [DOCUMENT_TYPE_ADD_DOCUMENT.CLAIM_AND_RETURN]: 'Claims & Returns',
    [DOCUMENT_TYPE_ADD_DOCUMENT.LOADING_FEE]: 'Loading Fee',
    [DOCUMENT_TYPE_ADD_DOCUMENT.UNLOADING_FEE]: 'Unloading Fee',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OVERNIGHT_LOADING]: 'Overnight Loading',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OVERNIGHT_UNLOADING]: 'Overnight Unloading',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OTHER_LOCATION_FEE]: 'Other Location Fee',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OTHER_FEE]: 'Additional Fee',
    [DOCUMENT_TYPES.POL]: 'Proof Of Loading',
    [DOCUMENT_TYPES.POD]: 'Proof Of Delivery',
    [DOORING_AGENT_OTHER_FEES.DEMURRAGE_FEE]: 'Demurrage Fee',
    [DOORING_AGENT_OTHER_FEES.STORAGE_FEE]: 'Storage Fee',
    [DOORING_AGENT_OTHER_FEES.THC_LOLO]: 'THC-LOLO',
    [SUPPORTING_DOCUMENT_TYPES.PROOF_OF_ROUTES_CHANGES]: 'Proof of Routes Changes',
  },
  document_verification_rejection_reason_map: {
    [DOCUMENT_VERIFICATION_REJECTION_REASONS.BLUR]: 'Document is blurry',
    [DOCUMENT_VERIFICATION_REJECTION_REASONS.DOCUMENT_INCOMPLETE]: 'Document is incomplete',
    [DOCUMENT_VERIFICATION_REJECTION_REASONS.EXPIRED]: 'Document is expired',
    [DOCUMENT_VERIFICATION_REJECTION_REASONS.WRONG_DOCUMENT]: 'Wrong Document',
    [DOCUMENT_VERIFICATION_REJECTION_REASONS.OTHER]: 'Others',
  },
  document_verification_status_map: {
    [DOCUMENT_VERIFICATION_STATUSES.PENDING]: 'Pending',
    [DOCUMENT_VERIFICATION_STATUSES.ACCEPTED]: 'Approved',
    [DOCUMENT_VERIFICATION_STATUSES.APPROVED]: 'Approved',
    [DOCUMENT_VERIFICATION_STATUSES.REJECTED]: 'Rejected',
    [DOCUMENT_VERIFICATION_STATUSES.EXPIRED]: 'Expired',
    [DOCUMENT_VERIFICATION_STATUSES.DRAFT]: 'Draft',
    [DOCUMENT_VERIFICATION_STATUSES.REGISTERED]: 'Registered',
    [DOCUMENT_VERIFICATION_STATUSES.NEGLECTED]: 'Neglected',
  },
  document_verification_status_options_map: {
    [DOCUMENT_VERIFICATION_STATUSES.ACCEPTED]: 'Approve',
    [DOCUMENT_VERIFICATION_STATUSES.REJECTED]: 'Reject',
  },
  documents: 'Documents',
  documents_include: 'Documents Include',
  document_id: 'Document ID',
  document_name: 'Document Name',
  dof: 'DOF',
  done: 'Done',
  door_to_door_agent: 'Door-to-door Agent',
  door_to_door_note: 'Door-to-door Note',
  dooring_pic: 'Dooring PIC',
  dooring_agent: 'Dooring Agent',
  down_payment: 'Down Payment',
  download: 'Download',
  download_a_file: 'Download a File',
  download_csv: 'Download CSV',
  download_csv_error_report: 'Download CSV Error Report',
  download_csv_template: 'Download the CSV template',
  download_documents: 'Download Documents',
  download_documents_from_external: 'Download Documents From Web',
  download_excel: 'Download Excel',
  download_pdf: 'Download PDF',
  download_pod: 'Download POD',
  download_pod_file: 'a POD File',
  download_selected_pod: 'Download Selected POD',
  download_template_for: 'Download the template for',
  download_template: 'Download Template',
  drag_and_drop: 'Drag and Drop',
  draft: 'Draft',
  driver: 'Driver',
  driver_app: 'Driver App',
  driver_company_rejection_reason_map: {
    [VEHICLE_COMPANY_REJECTION_REASONS.DOCS_REUPLOAD_NEEDED]: 'Need to reupload docs',
    [VEHICLE_COMPANY_REJECTION_REASONS.OTHER_REASON]: 'Others',
  },
  driver_completeness: 'Kelengkapan Supir',
  driver_details: 'Driver Details',
  driver_helper: 'Driver Helper',
  driver_helper_price: 'Driver Helper Price',
  driver_information: 'Driver Information',
  driver_ksuid: 'Driver KSUID',
  driver_license_type_map: {
    [DRIVER_LICENSE_TYPE.SIM_A]: 'SIM A',
    [DRIVER_LICENSE_TYPE.SIM_A_UMUM]: 'SIM A Umum',
    [DRIVER_LICENSE_TYPE.SIM_BI]: 'SIM B I',
    [DRIVER_LICENSE_TYPE.SIM_BI_UMUM]: 'SIM B I Umum',
    [DRIVER_LICENSE_TYPE.SIM_BII]: 'SIM B II',
    [DRIVER_LICENSE_TYPE.SIM_BII_UMUM]: 'SIM BII Umum',
  },
  driver_name: 'Driver Name',
  driver_not_found: 'Driver not found',
  driver_or_license_place: 'Driver or License Plate',
  driver_note: 'Driver Note',
  driver_notes: 'Driver Notes',
  driver_phone: 'Driver Phone',
  driver_phone_number: 'Driver Phone Number',
  driver_requirements: 'Driver Requirements',
  driver_safety_req: 'Driver Safety Requirements',
  driver_status: 'Driver Status',
  driver_status_map: {
    [DRIVER_STATUSES.SUSPENDED]: 'Suspended',
    [DRIVER_STATUSES.ACTIVE]: 'Active',
    [DRIVER_STATUSES.INACTIVE]: 'Inactive',
    [DRIVER_STATUSES.CREATED]: 'Created',
    [DRIVER_STATUSES.DOCUMENT_EXPIRED]: 'Document Expired',
    [DRIVER_STATUSES.REGISTERED]: 'Active',
    [DRIVER_STATUSES.DEACTIVATED]: 'Deactivated',
  },
  driver_verification_level_action_map: {
    [VEHICLE_STATUSES.ACTIVE]: 'Approve',
    [VEHICLE_STATUSES.REJECTED]: 'Reject',
  },
  drivers: 'Drivers',
  dropoff: 'Dropoff',
  dropoff_date: 'Dropoff Date',
  dropoff_date_and_time: 'Dropoff Date & Time',
  due_date: 'Due Date',
  duplicate: 'Duplicate',
  duration: 'Duration',
  earliest_pickup_date_and_time: 'Earliest Pickup Date & Time',
  earliest_pickup_date: 'Earliest Pickup Date',
  edit: 'Edit',
  edit_basic_profile: 'Edit Basic Profile',
  edit_contracts: 'Edit Contracts',
  edit_customer: 'Update Customer',
  edit_details: 'Edit Details',
  edit_driver: 'Update Driver',
  edit_drivers: 'Edit Drivers',
  edit_employee: 'Edit Employee',
  edit_expected_payment_date: 'Edit Expected Payment Date',
  edit_invoice: 'Edit Invoice',
  edit_location: 'Edit Location',
  edit_mode: 'Edit Mode',
  edit_pic: 'Edit PIC',
  edit_podf: 'Edit PODF',
  edit_position: 'Edit Position',
  edit_requirements: 'Edit Requirements',
  edit_sales: 'Edit Sales',
  edit_sales_pic: 'Edit Sales PIC',
  edit_podf_shipment_details: 'Edit Shipment Details',
  edit_shipment_additional_details: 'Edit Additional Details',
  edit_shipment_details: 'Edit Shipment Details',
  edit_shipper: 'Edit Shipper',
  edit_shipper_group: 'Edit Shipper Group',
  edit_shipper_employee: 'Edit Shipper\'s Team',
  edit_shipper_status: 'Edit Shipper Status',
  edit_si: 'Edit SI',
  edit_status: 'Edit Status',
  edit_tags: 'Edit Tags',
  edit_tax_title: 'Edit Tax',
  edit_tax_options: 'Edit Tax Options',
  edit_options: 'Edit Options',
  edit_transporter_allocation: 'Edit Transporter Allocation',
  edit_timestamp: 'Edit Timestamp',
  edit_truck: 'Edit Truck',
  edit_trucks: 'Edit Trucks',
  edit_truck_requirement: 'Edit Truck Requirements',
  edit_shipment: 'Edit Shipment',
  edit_shipping_line_details: 'Edit Shipping Line Details',
  edit_unit: 'Update Unit',
  edit_vessel: 'Edit Vessel / Voyage',
  edit_warehouse: 'Update Warehouse',
  edit_record: 'Edit Record',
  e_g: 'e.g. {{count}} {{unit}}',
  entity: 'Entity',
  entity_address: 'Entity Address',
  entity_ar_email: 'Entity AR PIC Email',
  entity_code: 'Entity Code',
  entity_name: 'Entity Name',
  entity_npwp: 'Entity NPWP',
  entries: 'Entries',
  enter_document_name_here: 'Enter document name here',
  eligible: 'Eligible',
  email: 'Email',
  email_address: 'Email Address',
  email_proforma_invoice: 'Email Proforma Invoice',
  employee_details: 'Employee Details',
  employee_name: 'Employee Name',
  employees: 'Employees',
  employee: 'Employee',
  empty: 'Empty',
  empty_depo: 'Empty Depo',
  enable_discount: 'Enable discount',
  end: 'End',
  end_date: 'End Date',
  end_time: 'End Time',
  enterprises: 'Enterprises',
  enter_note: 'Enter Note',
  enter_quantity: 'Enter Quantity',
  escalate_to_sales: 'Escalate to Sales',
  estimated: 'Estimated',
  estimated_payment_date: 'Estimated Payment Date',
  eta: 'ETA',
  etb: 'ETB',
  etd: 'ETD',
  eta_origin: 'ETA Origin',
  eta_pod: 'ETA POD',
  etb_pol: 'ETB POL',
  etb_pod: 'ETB POD',
  etd_pod: 'ETD POD',
  etd_pol: 'ETD POL',
  exclude: 'Exclude',
  expected_payment_date: 'Expected Payment Date',
  expiry_date: 'Expiry Date',
  external_contract_no: 'External Contract No.',
  external_contract_number: 'External Contract Number',
  external_id: 'External ID',
  extra_helper: 'Extra Helper',
  extra_helper_price: 'Extra Helper Price',
  external_notes: 'External Notes',
  facilities: 'Facilities',
  facilities_option: {
    FOOD_STALL: 'Food Stall',
    ONSITE_SCALE: 'Onsite Scale',
    PARKING_AREA: 'Parking Area',
    TOILET: 'Toilet',
    WAITING_AREA: 'Waiting Area',
  },
  failed_to_generate_csv: 'Failed to Generate CSV',
  far: 'Far',
  fast_funding: 'Fast Funding',
  fast_payment: 'Fast Payment',
  fee: 'Fee',
  fee_approval: 'Shipment & Fee Approval',
  fee_calculation: 'Fee Calculation',
  fee_description: 'Fee Description',
  fee_details: 'Fee Details',
  fee_naming_indonesian: 'Fee Naming (Indonesian)',
  fee_overall_status_map: {
    DISBURSED: 'Disbursed',
    FEE_REJECTED: 'Rejected',
    REQUEST_APPROVED: 'Request Approved',
    REQUEST_SENT: 'Request Sent',
    REVIEW_ADDITIONAL_FEE: 'Review Fee Now',
    REVIEW_SHIPMENT_FEE: 'Review Shipment Now',
    REVIEWED: 'Reviewed',
    WAITING_FOR_CLAIM: 'Waiting for Claim',
    WAITING_FOR_PENALTY: 'Waiting for Penalty',
    WAITING_FOR_OVERNIGHT: 'Waiting for Overnight',
    WAITING_FOR_TKBM: 'Waiting for TKBM',
    WAITING_FOR_INVOICE: 'Waiting for Invoice',
    WAITING_FOR_INVOICE_REVISION: 'Waiting for Invoice Revision',
    ON_HOLD: 'On Hold',
    PARTIALLY_DISBURSED: 'Partially Disbursed',
  },
  fee_per_point_within_city: 'Fee per Point Within City',
  fee_per_point_different_city: 'Fee per Point Different City',
  fee_request_status_map: {
    APPROVED: 'Request Approved',
    CANCELLED: 'Cancelled',
    DISBURSED: 'Disbursed',
    FEE_REJECTED: 'Fee Rejected',
    ON_HOLD: 'On Hold',
    PAID: 'Paid',
    PENDING: 'Pending',
    REJECTED: 'Rejected',
    REQUEST_APPROVED: 'Request Approved',
    REQUEST_REJECTED: 'Request Rejected',
    REQUEST_SENT: 'Requested',
    REVIEWED: 'Reviewed',
    SHIPPER_PENDING: 'Shipper Pending',
  },
  fee_request_type_map: {
    ADDITIONAL: 'Additional Fee',
    ADJUSTMENT: 'Price Adjustment',
    CLAIMS: 'Claims',
    INSURANCE: 'Insurance',
    KARGO: 'Kargo Fee',
    LATE: 'Late Fee',
    LOADING: 'Loading Fee',
    OTHER: 'Other Location Fee',
    OTHER_ADJUSTMENT: 'Other Adjustment',
    OVERNIGHT_LOADING: 'Overnight Loading Fee',
    OVERNIGHT_UNLOADING: 'Overnight Unloading Fee',
    PENALTY: 'Penalty',
    RETURNS: 'Returns',
    SHIPMENT: 'Shipment Fee',
    TICKET: 'Ticket Tilang Fee',
    UNLOADING: 'Unloading Fee',
    THC_L: 'THC-L',
    THC_D: 'THC-D',
    LSS: 'LSS',
    STORAGE: 'Storage Fee',
    DEMURRAGE: 'Demurrage Fee',
    THC_LOLO: 'THC-LOLO',
  },
  fee_review: 'Fee Review',
  fee_type: 'Fee Type',
  fee_type_shipper: 'Fee Type',
  feedback_submitted: 'Feedback Submitted',
  fees: 'Fees',
  fees_required_on_truck_requirement:
    'Input at least one type of overnight fee, one type of cancellation fee, and all multiple shipment fees',
  fees_subtitle: 'You can view the data relevant to each Overnight & Location Fees',
  file: 'File',
  file_name: 'File Name',
  file_not_selected: 'File not selected',
  file_not_selected_yet: 'File Not Selected',
  file_upload_source_map: {
    BTMS: 'BTMS',
    DA: 'Mitra App',
    TA: 'Transporter App',
    TMS: 'Vendor Central',
    [FILE_UPLOAD_SOURCE.CA]: 'Canvasser App',
  },
  fill_in_csv_instruction: 'Fill in CSV Instruction',
  fill_in_backdated_shipment: 'Fill in the template by following Backdate Shipments CSV Instruction',
  fill_truck_details: 'Fill Truck Details',
  final: 'Final',
  final_price_adjustment: 'Final Price Adjustment',
  final_score: 'Final Score',
  final_shipper_pricing: 'Final Shipper Pricing',
  final_shipment: 'Final Shipment',
  final_shipment_fee: 'Final Shipment Fee',
  final_transporter_price: 'Final Transporter Price',
  finalised_transporter_price: 'Finalised Transporter Price',
  finance_approval: 'Finance Approval',
  finance_contact: 'Finance Contact',
  finance_details: 'Finance Details',
  finish_loading_time: 'Finish Loading Time',
  first_mile: 'First Mile',
  first_shipment_quality: 'First Shipment Quality',
  first_mile_shipment: 'First Mile Shipment',
  first_mile_shipment_tabs: {
    [FIRST_MILE_SHIPMENT_TABS.DOOR_TO_DOOR_PURCHASE_ORDER]: 'Purchase Order',
    [FIRST_MILE_SHIPMENT_TABS.CONTAINER]: 'Container',
    [FIRST_MILE_SHIPMENT_TABS.TRUCKING]: 'Trucking',
    [FIRST_MILE_SHIPMENT_TABS.SHIPPING_LINE]: 'Shipping Line',
    [FIRST_MILE_SHIPMENT_TABS.DOORING_AGENT]: 'Dooring Agent',
  },
  first_mile_shipment_type: {
    [FIRST_MILE_SHIPMENT_TYPE.DOOR_TO_DOOR]: 'Door To Door',
    [FIRST_MILE_SHIPMENT_TYPE.MULTI_DOOR_TO_DOOR]: 'Multi Door To Door',
    [FIRST_MILE_SHIPMENT_TYPE.INBOUND_DTD]: 'Inbound DTD',
    [FIRST_MILE_SHIPMENT_TYPE.TRUCKING_DTD]: 'Trucking DTD',
    [FIRST_MILE_SHIPMENT_TYPE.SHIPPING_LINE_DTD]: 'Shipping Line DTD',
    [FIRST_MILE_SHIPMENT_TYPE.DOORING_AGENT_DTD]: 'Dooring DTD',
    [FIRST_MILE_SHIPMENT_TYPE.OUTBOUND_DTD]: 'Outbound DTD',
    [FIRST_MILE_SHIPMENT_TYPE.FIRST_MILE_TRUCKING]: 'First Mile Trucking',
  },
  shipping_line_status: {
    [SHIPPING_LINE_STATUS.TO_BE_PROCESSED]: 'To Be Processed',
    [SHIPPING_LINE_STATUS.VESSEL_BOOKED]: 'Vessel Booked',
    [SHIPPING_LINE_STATUS.SHIPPING_LINE_SHIPMENT_COMPLETE]: 'Shipment History',
  },
  fixed: 'Fixed',
  fixed_amount: 'Fixed Amount',
  flat_discount: 'Flat Discount',
  flat_pricing_type: 'Flat',
  fleet_completeness: 'Kelengkapan Armada',
  fm_trucking: 'FM Trucking',
  font_size: 'Font Size',
  forgot_password: 'Forgot Password',
  form: 'Form',
  forward_email: 'Forward Email',
  forward_email_to: 'Forward Email to',
  fraud_score: 'Fraud Score',
  fraud_score_report: 'Fraud Score Report',
  fraud_score_item_rating: {
    [FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA].DATE_AND_TIME]: 'Date and Time',
    [FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA].IMAGE_LOCATION]: 'Image Location',
    [FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA].DEVICE]: 'Device',
    [FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.LOCATION_MATCHING].DEVICE_GPS]: 'Device GPS',
    [FRAUD_SCORE_GROUP_NAME[FRAUD_SCORE_SECTION_NAME.LOCATION_MATCHING].TRUCK_GPS]: 'Truck GPS',
  },
  fraud_score_item_list: {
    [FRAUD_SCORE_SECTION_NAME.IMAGE_METADATA]: 'Image Metadata',
    [FRAUD_SCORE_SECTION_NAME.LOCATION_MATCHING]: 'Location Matching',
  },
  free: 'Free',
  freight_central: 'Freight Central',
  from: 'From',
  full_address: 'Full Address',
  full_name: 'Full Name',
  gdl: 'GDL',
  general_entity_status_map: {
    [GENERAL_ENTITY_STATUSES.REGISTERED]: 'Active',
    [GENERAL_ENTITY_STATUSES.DEACTIVATED]: 'Inactive',
  },
  generate_csv: 'Generate CSV',
  generate_csv_instruction: 'Generate CSV Instruction',
  generating: 'Generating ...',
  geofence_radius: 'Geofence Radius',
  geofence_direction: {
    IN: 'in to',
    OUT: 'out from',
  },
  go_to_planned_sub_tab: 'Go to Planned Sub Tab',
  goods_capacity: 'Goods Capacity',
  goods_near_expiry_date: 'Goods Near Expiry Date',
  goods_quantity: 'Goods Quantity',
  goods_rejection_category_map: {
    [GOODS_REJECTION_CATEGORY.CLAIM]: 'Claim',
    [GOODS_REJECTION_CATEGORY.NO_CLAIM]: 'No Claim',
    [GOODS_REJECTION_CATEGORY.REPACKING]: 'Repacking',
  },
  goods_too_few: 'Goods Too Few',
  goods_too_many: 'Goods Too Many',
  goods_type: 'Goods Type',
  gps_brand: 'GPS Brand',
  gps_device: 'GPS Device',
  gps_integration: 'GPS Integration',
  gps_model: 'GPS Model',
  gps_status_map: {
    [GPS_STATUS.OFFLINE]: 'Offline',
    [GPS_STATUS.ONLINE]: 'Online',
  },
  gmv: 'GMV',
  gps: 'GPS',
  gps_integrated: 'GPS Integrated',
  grade_colon: 'Grade',
  grand_total: 'Grand Total',
  group: 'Group',
  group_account: 'Group Account',
  group_details: 'Group Details',
  group_load_id: 'Group Load ID',
  group_load_number: 'Group Load Number',
  group_load_summary: 'Group Load Summary',
  group_name: 'Group Name',
  gross_weight: 'Gross Weight',
  gross_unit: 'Gross Unit',
  goto_planned_tab: 'Go To Planned Sub Tab',
  header: 'Header',
  height: 'Height',
  help: 'Help',
  helper_amount: 'Helper Amount',
  here: 'here',
  hide: 'Hide',
  hold: 'Hold',
  hold_reason: 'Hold Reason',
  hold_payment: 'Hold Payment',
  hours: 'Hours',
  id_card: 'KTP',
  identity_document_map: {
    AKTA: 'Akta Perusahaan',
    KIR: 'KIR',
    KTP: 'KTP',
    NPWP: 'NPWP',
    SIM: 'SIM',
    KTP_NPWP_DIRECTOR: 'KTP NPWP Director',
    'SIUP/SIUJPT': 'SIUP/SIUJPT',
    'SPPPKP/SKB': 'SPPPKP/SKB',
    SPPKP: 'SPPKP',
    INVOICE_EXAMPLE: 'Invoice Example',
    FINANCIAL_REPORT: 'Financial Report',
    STNK: 'STNK',
    BANK_ACCOUNT_BOOK: 'Bank Account Header',
    TDP: 'TDP',
    SKT: 'SKT',
    SURAT_KUASA: 'Surat Kuasa',
    SKB: 'SKB (Surat Keterangan Bebas Pajak)',
    KK: 'KK',
    [PRICE_QUOTATION_TYPES.SHIPPER_PRICE_QUOTATION_SIGNED_BY_BOTH]: 'Shipper Price Quotation Signed by Both',
    [PRICE_QUOTATION_TYPES.SHIPPER_PRICE_QUOTATION_SIGNED_BY_KARGO]: 'Shipper Price Quotation Signed by Kargo',
    NRIC: 'NRIC',
    SSM: 'SSM',
    SST: 'SST',
    VEHICLE_REGISTRATION: 'Truck Road Registration',
    ROAD_TAX: 'Road Tax',
    PUSPAKOM_INSPECTION: 'Puspakom',
    GDL: 'GDL',
    CDL: 'CDL',
  },
  imei: 'IMEI',
  imei_number: 'IMEI Number',
  inactive: 'Inactive',
  incentive: 'Incentive',
  incentive_business_line_map: {
    [INCENTIVE_BUSINESS_LINE.SME_REFERRAL]: 'SME Referral',
    [INCENTIVE_BUSINESS_LINE.SME_MID_MILE]: 'SME Mid-Mile',
    [INCENTIVE_BUSINESS_LINE.ENTERPRISE_REFERRAL]: 'Enterprise Referral',
    [INCENTIVE_BUSINESS_LINE.ENTERPRISE_MID_MILE]: 'Enterprise Mid-Mile',
  },
  incentive_business_timestamp_type_map: {
    [INCENTIVE_BUSINESS_TIMESTAMP_TYPE.POL_UPLOAD]: 'POL Upload',
    [INCENTIVE_BUSINESS_TIMESTAMP_TYPE.POD_UPLOAD]: 'POD Upload',
    [INCENTIVE_BUSINESS_TIMESTAMP_TYPE.INVOICE_UPLOAD]: 'Invoice Upload',
    [INCENTIVE_BUSINESS_TIMESTAMP_TYPE.SHIPMENT_COMPLETE]: 'Shipment Complete',
    [INCENTIVE_BUSINESS_TIMESTAMP_TYPE.OTHER]: 'Other',
  },
  incentive_id: 'Incentive ID',
  incentive_pending_approval: 'Incentive Pending Approval',
  incentive_percentage: 'Incentive Percentage',
  incentive_amount: 'Incentive Amount',
  incentive_amount_type_map: {
    [INCENTIVE_LEDGER_AMOUNT_TYPE.DEBIT]: 'Addition',
    [INCENTIVE_LEDGER_AMOUNT_TYPE.CREDIT]: 'Adjustment',
    [INCENTIVE_LEDGER_AMOUNT_TYPE.DISBURSEMENT]: 'Disbursement',
  },
  incentive_approved: 'Incentive Approved',
  incentive_requested: 'Incentive Requested',
  incentive_disbursed: 'Incentive Disbursed',
  incentive_balance: 'Incentive Balance',
  incentive_ledger_status_map: {
    [INCENTIVE_LEDGER_STATUS.UPLOADED]: 'Uploaded',
    [INCENTIVE_LEDGER_STATUS.APPROVED]: 'Approved',
    [INCENTIVE_LEDGER_STATUS.AP_REJECTED]: 'AP Reject',
    [INCENTIVE_LEDGER_STATUS.REQUESTED]: 'Requested',
    [INCENTIVE_LEDGER_STATUS.FINANCE_REJECTED]: 'Finance Rejected',
    [INCENTIVE_LEDGER_STATUS.DISBURSED]: 'Disbursed',
  },
  include: 'Include',
  include_calculation: 'Include Calculation',
  include_in_calculation: 'Include in calculation',
  include_in_calculation_options: {
    [INCLUDE_IN_CALCULATION_MAP.SUNDAYS]: 'Sundays',
    [INCLUDE_IN_CALCULATION_MAP.NATIONAL_HOLIDAYS]: 'National Holidays',
  },
  industry: 'Industry',
  industry_map: {
    [INDUSTRY.FMCG]: 'FMCG',
    [INDUSTRY.RAW_MATERIAL_OR_ORM]: 'Raw Materials/OEM',
    [INDUSTRY.LOGISTIC_COMPANY]: 'Logistics Company',
    [INDUSTRY.BROKER_TRANSPORTER]: 'Broker/Transporter',
    [INDUSTRY.E_COMMERCE]: 'E-Commerce',
    [INDUSTRY.COMMODITY_AND_TRADING]: 'Commodity and Trading',
    [INDUSTRY.CPG]: 'CPG',
    [INDUSTRY.TOBACCO]: 'Tobacco',
    [INDUSTRY.CONSTRUCTION]: 'Construction',
    [INDUSTRY.AGRICULTURE]: 'Agriculture',
    [INDUSTRY.MINING]: 'Mining',
    [INDUSTRY.AQUACULTURE]: 'Aquaculture',
    [INDUSTRY.OIL_AND_GAS]: 'Oil and Gas',
    [INDUSTRY.TEXTILE]: 'Textile',
    [INDUSTRY.ELECTRONICS]: 'Electronics',
    [INDUSTRY.AUTOMOTIVE]: 'Automotive',
    [INDUSTRY.PHARMACEUTICAL]: 'Pharmaceutical',
    [INDUSTRY.TELECOMMUNICATION]: 'Telecommunication',
    [SME_INDUSTRY.FURNITURE]: 'Furniture',
  },
  information: 'Information',
  initial: 'Initial',
  initial_shipper_pricing: 'Initial Shipper Pricing',
  initial_quantity: 'Initial Quantity',
  initial_quantity_source: 'Initial Quantity Source',
  input_do: 'Input DO',
  input_do_numbers: 'Input DO Numbers',
  input_do_numbers_to_assign: 'Input DO Numbers to Assign',
  input_transporter_price: 'Input Transporter Price',
  input_type: 'Input Type',
  insights: 'Insights',
  installment_amount: 'Installment Amount',
  insurance: 'Insurance',
  insurance_end_date: 'Insurance End Date',
  insurance_price: 'Insurance Price',
  insurance_start_date: 'Insurance Start Date',
  interest_fee: 'Interest Fee',
  internal_note: 'Internal Note',
  internal_notes: 'Internal Notes',
  internal_referral: 'Internal Referral',
  invoice: 'Invoice',
  invoice_template: 'Invoice Template',
  invoice_addressed_to: 'Invoice Addressed To',
  invoice_aging_period: 'Invoice Aging Period',
  invoice_amount: 'Invoice Amount',
  invoice_code: 'Invoice Code',
  invoice_configuration: 'Invoice Configuration',
  invoice_date: 'Invoice Date',
  invoice_generated_date: 'Invoice Generated Date',
  invoice_delivery_service_map: {
    [INVOICE_DELIVERY_SERVICES.JNE]: 'JNE',
    [INVOICE_DELIVERY_SERVICES.GOSEND]: 'GoSend',
    [INVOICE_DELIVERY_SERVICES.GRAB_EXPRESS]: 'Grab Express',
    [INVOICE_DELIVERY_SERVICES.OTHER]: 'Other',
    [INVOICE_DELIVERY_SERVICES.INTERNAL_COURIER]: 'Internal Courier',
    [INVOICE_DELIVERY_SERVICES.EMAIL]: 'Email',
  },
  invoice_recommended_next_action: {
    [INVOICE_STATUS.DRAFT_CREATED]: 'Convert to Invoice',
    [INVOICE_STATUS.DOCUMENTS_REQUIRED]: 'Convert to Invoice',
    [INVOICE_STATUS.RECAP_SENT]: 'Convert to Invoice',
    [INVOICE_STATUS.RECAP_REPLIED]: 'Convert to Invoice',
    [INVOICE_STATUS.PROFORMA_SENT]: 'Convert to Invoice',
    [INVOICE_STATUS.PROFORMA_REPLIED]: 'Convert to Invoice',
    [INVOICE_STATUS.DISPUTE]: 'Convert to Invoice',
    [INVOICE_STATUS.INVOICE_SPLIT]: 'Convert to Invoice',
    [INVOICE_STATUS.GENERATED]: 'Review Invoice',
    [INVOICE_STATUS.INVOICE_GENERATED]: 'Review Invoice', // Temporary Fix for INVOICE_GENERATED Status
    [INVOICE_STATUS.SPV_APPROVED]: 'Mark Invoice Sent',
    [INVOICE_STATUS.SPV_REJECTED]: 'Edit Invoice',
    [INVOICE_STATUS.INVOICE_SENT]: 'Mark as Received',
    [INVOICE_STATUS.INVOICE_RECEIVED]: 'Mark as Approved',
    [INVOICE_STATUS.SHIPPER_APPROVED]: 'Record Payment',
    [INVOICE_STATUS.SHIPPER_REJECTED]: 'Revise',
    [INVOICE_STATUS.PARTIALLY_PAID]: 'Record Payment',
    [INVOICE_STATUS.PAID]: 'Record Payment',
    [INVOICE_STATUS.BAD_DEBT]: 'Bad Debt',
    [INVOICE_STATUS.CANCELLED]: 'Cancelled',
  },
  //! TODO: Remove this when KC_E_INVOICE_REQUEST is no longer needed and move the content above
  invoice_recommended_next_action_with_e_invoice: {
    [INVOICE_STATUS.DRAFT_CREATED]: 'Convert to Invoice',
    [INVOICE_STATUS.DOCUMENTS_REQUIRED]: 'Convert to Invoice',
    [INVOICE_STATUS.RECAP_SENT]: 'Convert to Invoice',
    [INVOICE_STATUS.RECAP_REPLIED]: 'Convert to Invoice',
    [INVOICE_STATUS.PROFORMA_SENT]: 'Convert to Invoice',
    [INVOICE_STATUS.PROFORMA_REPLIED]: 'Convert to Invoice',
    [INVOICE_STATUS.DISPUTE]: 'Convert to Invoice',
    [INVOICE_STATUS.INVOICE_SPLIT]: 'Convert to Invoice',
    [INVOICE_STATUS.GENERATED]: 'Review Invoice',
    [INVOICE_STATUS.INVOICE_GENERATED]: 'Review Invoice', // Temporary Fix for INVOICE_GENERATED Status
    [INVOICE_STATUS.SPV_APPROVED]: 'Request E-Invoice',
    [INVOICE_STATUS.SPV_REJECTED]: 'Edit Invoice',
    [INVOICE_STATUS.DIGITAL_INVOICE_DELETED]: 'Re-request E-Invoice',
    [INVOICE_STATUS.DIGITAL_INVOICE_EXPIRED]: 'Re-request E-Invoice',
    [INVOICE_STATUS.DIGITAL_INVOICE_ISSUED]: 'Mark as Invoice Sent',
    [INVOICE_STATUS.INVOICE_SENT]: 'Mark as Received',
    [INVOICE_STATUS.INVOICE_RECEIVED]: 'Mark as Approved',
    [INVOICE_STATUS.SHIPPER_APPROVED]: 'Record Payment',
    [INVOICE_STATUS.SHIPPER_REJECTED]: 'Revise',
    [INVOICE_STATUS.PARTIALLY_PAID]: 'Record Payment',
    [INVOICE_STATUS.PAID]: 'Record Payment',
    [INVOICE_STATUS.BAD_DEBT]: 'Bad Debt',
    [INVOICE_STATUS.CANCELLED]: 'Cancelled',
  },
  invoice_status_map: {
    [INVOICE_STATUS.DRAFT_CREATED]: 'Draft Created',
    [INVOICE_STATUS.DOCUMENTS_REQUIRED]: 'Documents Required',
    [INVOICE_STATUS.RECAP_SENT]: 'Recap Sent',
    [INVOICE_STATUS.RECAP_REPLIED]: 'Recap Replied',
    [INVOICE_STATUS.PROFORMA_SENT]: 'Proforma Sent',
    [INVOICE_STATUS.PROFORMA_REPLIED]: 'Proforma Replied',
    [INVOICE_STATUS.DISPUTE]: 'In Dispute',
    [INVOICE_STATUS.INVOICE_SPLIT]: 'Invoice Split',
    [INVOICE_STATUS.GENERATED]: 'Generated',
    [INVOICE_STATUS.SPV_APPROVED]: 'Spv Approved',
    [INVOICE_STATUS.SPV_REJECTED]: 'Spv Rejected',
    [INVOICE_STATUS.INVOICE_SENT]: 'Invoice Sent',
    [INVOICE_STATUS.INVOICE_RECEIVED]: 'Invoice Received',
    [INVOICE_STATUS.SHIPPER_APPROVED]: 'Shipper Approved',
    [INVOICE_STATUS.SHIPPER_REJECTED]: 'Shipper Rejected',
    [INVOICE_STATUS.PAID]: 'Paid',
    [INVOICE_STATUS.PARTIALLY_PAID]: 'Partially Paid',
    [INVOICE_STATUS.BAD_DEBT]: 'Bad Debt',
    [INVOICE_STATUS.CANCELLED]: 'Cancelled',
    [INVOICE_STATUS.E_INVOICE]: 'E-Invoice',
    [INVOICE_STATUS.DIGITAL_INVOICE_REQUESTED]: 'E-Invoice Requested',
    [INVOICE_STATUS.DIGITAL_INVOICE_ISSUED]: 'E-Invoice Issued',
    [INVOICE_STATUS.DIGITAL_INVOICE_DELETED]: 'E-Invoice Deleted',
    [INVOICE_STATUS.DIGITAL_INVOICE_EXPIRED]: 'E-Invoice Expired',
    // taken from brokerage_shipper_invoice_status_map
    [INVOICE_STATUS.INVOICE_GENERATED]: 'Generated',
    [INVOICE_STATUS.FINANCE_APPROVED]: 'Finance Approved',
    [INVOICE_STATUS.FINANCE_REJECTED]: 'Finance Rejected',
    [INVOICE_STATUS.PROFORMA_ISSUED]: 'Proforma Issued',
    [INVOICE_STATUS.PROFORMA_APPROVED]: 'Proforma Approved',
    [INVOICE_STATUS.PARTIAL_PAID]: 'Partially Paid',
  },
  invoice_paid_payment_type_map: {
    [INVOICE_PAID_PAYMENT_TYPE.PAYMENT]: 'Payment',
    [INVOICE_PAID_PAYMENT_TYPE.WHT21]: 'WHT 21',
    [INVOICE_PAID_PAYMENT_TYPE.WHT23]: 'WHT 23',
    [INVOICE_PAID_PAYMENT_TYPE.CLAIMS]: 'Claims',
    [INVOICE_PAID_PAYMENT_TYPE.OTHER]: 'Other',
    [INVOICE_PAID_PAYMENT_TYPE.BAD_DEBT]: 'Bad Debt',
  },
  invoice_details: 'Invoice Details',
  invoice_due_date: 'Invoice Due Date',
  invoice_document_type: {
    [INVOICE_DOCUMENT_TYPES.OCEAN_FREIGHT]: 'Ocean Freight',
    [INVOICE_ADD_DOCUMENT_TYPES.THC_D]: 'Terminal Handling Charge - Discharge',
    [INVOICE_ADD_DOCUMENT_TYPES.THC_L]: 'Terminal Handling Charge - Loading',
    [INVOICE_ADD_DOCUMENT_TYPES.LSS]: 'Low Sulfur Surcharge',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OTHER_FEE]: 'Additional Fee',
  },
  invoice_fee_send_documents: {
    PROOF_OF_DELIVERY: 'POD',
    [DOCUMENT_TYPE_ADD_DOCUMENT.CLAIM_AND_RETURN]: 'Claims & Returns',
    [DOCUMENT_TYPE_ADD_DOCUMENT.LOADING_FEE]: 'Loading Fee',
    [DOCUMENT_TYPE_ADD_DOCUMENT.UNLOADING_FEE]: 'Unloading Fee',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OVERNIGHT_LOADING]: 'Overnight Loading',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OVERNIGHT_UNLOADING]: 'Overnight Unloading',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OTHER_LOCATION_FEE]: 'Other Location Fee',
    [DOCUMENT_TYPE_ADD_DOCUMENT.OTHER_FEE]: 'Additional Fee',
  },
  invoice_fee_types: {
    [DO_INVOICE_FEE_TYPES.SHIPMENT_FEE]: 'Shipment Fee',
    [DO_INVOICE_FEE_TYPES.SHIPMENT_FEE_ADJUSTMENT]: 'Shipment Fee (Adjustment)',
    [DO_INVOICE_FEE_TYPES.LOADING_FEE]: 'Loading Fee',
    [DO_INVOICE_FEE_TYPES.UNLOADING_FEE]: 'Unloading Fee',
    [DO_INVOICE_FEE_TYPES.OVERNIGHT_LOADING_FEE]: 'Overnight Loading Fee',
    [DO_INVOICE_FEE_TYPES.OVERNIGHT_UNLOADING_FEE]: 'Overnight Unloading Fee',
    [DO_INVOICE_FEE_TYPES.CANCELLATION_FEE]: 'Cancellation Fee',
    [DO_INVOICE_FEE_TYPES.MULTIPICKUP_FEE]: 'Multipickup Fee',
    [DO_INVOICE_FEE_TYPES.MULTIDROPOFF_FEE]: 'Multidropoff Fee',
    [DO_INVOICE_FEE_TYPES.OTHER_FEE]: 'Additional Fee',
    [DO_INVOICE_FEE_TYPES.OTHER_LOCATION_FEE]: 'Other Location Fee',
    [DTD_SAVED_INVOICE_FEE_TYPE.STORAGE_FEE]: 'Storage Fee',
    [DTD_SAVED_INVOICE_FEE_TYPE.DEMURRAGE_FEE]: 'Demurrage Fee',
    [DTD_SAVED_INVOICE_FEE_TYPE.THC_LOLO]: 'THC-LOLO',
  },
  invoice_fee_types_is: {
    [DO_INVOICE_FEE_TYPES.SHIPMENT_FEE]: 'Biaya Trucking',
    [DO_INVOICE_FEE_TYPES.LOADING_FEE]: 'Biaya Muat',
    [DO_INVOICE_FEE_TYPES.UNLOADING_FEE]: 'Biaya Bongkar',
    [DO_INVOICE_FEE_TYPES.OVERNIGHT_LOADING_FEE]: 'Biaya Inap Di Gudang Muat',
    [DO_INVOICE_FEE_TYPES.OVERNIGHT_UNLOADING_FEE]: 'Biaya Inap Di Gudang Bongkar',
    [DO_INVOICE_FEE_TYPES.CANCELLATION_FEE]: 'Biaya Pembatalan',
    [DO_INVOICE_FEE_TYPES.MULTIPICKUP_FEE]: 'Biaya Pengambilan Banyak',
    [DO_INVOICE_FEE_TYPES.MULTIDROPOFF_FEE]: 'Biaya Multi Drop Off',
    [DO_INVOICE_FEE_TYPES.OTHER_LOCATION_FEE]: 'Biaya Lain',
    [DO_INVOICE_FEE_TYPES.OTHER_FEE]: 'Biaya Tambahan',
  },
  invoice_information: 'Invoice Information',
  invoice_no: 'Invoice No.',
  invoice_not_uploaded: 'This invoice type document not uploaded.',
  invoice_number: 'Invoice Number',
  invoice_receipt_deadline: 'Invoice Receipt Deadline',
  invoice_receipt_schedule: 'Invoice Receipt Schedule',
  invoice_sent: 'Invoice Sent',
  invoice_sent_date: 'Invoice Sent Date',
  invoice_total: 'Invoice Total',
  invoice_type: 'Invoice Type',
  invoice_type_info_map: {
    [DO_INVOICE_FEE_TYPES.LOADING_FEE]: 'Loading Fee Info',
    [DO_INVOICE_FEE_TYPES.UNLOADING_FEE]: 'Unloading Fee Info',
    [DO_INVOICE_FEE_TYPES.OTHER_LOCATION_FEE]: 'Other Fee Info',
  },
  invoice_uploaded: 'This invoice type document already uploaded.',
  invoices_details: 'Invoice Details',
  invoice_tax_configuration_map: {
    [INVOICE_TAX_CONFIGURATION.ALL_FEE]: 'All Fee Types',
    [INVOICE_TAX_CONFIGURATION.ONLY_SHIPMENT_FEE]: 'Shipment Fee Only',
    [INVOICE_TAX_CONFIGURATION.EXCEPT_SHIPMENT_FEE]: 'Except Shipment Fee',
    [INVOICE_TAX_CONFIGURATION.CUSTOM]: 'Customize',
  },
  invoicing_attention: 'Invoicing Attention',
  invoicing: 'Invoicing',
  is_empowering: 'Is Empowering project?',
  is_return_fee_covered_map: {
    [IS_RETURN_FEE_COVERED.NEGOTIABLE]: 'Negotiable',
    [IS_RETURN_FEE_COVERED.NOT_COVERED]: 'Not Covered',
  },
  is_return_goods_map: {
    [IS_RETURN_GOODS.NO_RETURN]: 'No Return',
    [IS_RETURN_GOODS.RETURN_GOODS]: 'Return Goods',
  },
  issue_type: 'Issue Type',
  issued: 'Issued',
  issued_date: 'Issued Date',
  item: 'Item',
  jumlah: 'Jumlah',
  job_type: 'Job Type',
  kargo_fee: 'Kargo Fee',
  kargo_sales: 'Kargo Sales',
  keep_shipment: 'Keep shipment',
  keep_uploading: 'Keep uploading',
  kir: 'KIR',
  ksuid: 'KSUID',
  ktp: 'KTP',
  KTP: 'KTP',
  language: 'Language',
  language_map: {
    [LOCALE_TYPE.id]: 'Bahasa Indonesia',
    [LOCALE_TYPE.en]: 'English',
  },
  last_sender: 'Last Sender',
  last_shipment_time: 'Last Shipment Time',
  last_update: 'Last  Update',
  last_updated_time: 'Last  Updated Time',
  last_updated_by: 'Last Updated By',
  last_uploaded_time: 'Last Uploaded Time',
  lat_and_long: 'Lat & Long',
  late: 'Late',
  late_fee: 'Late Fee',
  latest_activity_log: 'Latest Activity Log',
  latest_aging_period: 'Latest Aging Period',
  latest_payment_stage: 'Latest Payment Stage',
  latest_pickup: 'Latest Pickup',
  latest_pickup_date: 'Latest Pickup Date',
  latest_pickup_date_and_time: 'Latest Pickup Date & Time',
  latitude: 'Latitude',
  latitude_and_longitude: 'Latittude & Longitude',
  leader_name: 'Leader Name',
  leads_channel: 'Leads Channel',
  leave_page: 'Leave Page',
  leave: 'Leave',
  lembar_pajak: 'Lembar Pajak',
  length: 'Length',
  level: 'Level',
  ledger: 'Ledger',
  license_plate: 'License Plate',
  license_plate_color: 'License Plate Color',
  license_plate_number: 'License Plate Number',
  license_type: 'License Type',
  limited_hours: 'Limited Hours',
  line: 'Line',
  load: 'Loading',
  load_and_unload: 'Loading & Unloading',
  load_date_and_time: 'Loading Date and Time',
  load_location: 'Load Location',
  load_more: 'Load More',
  load_passing_letter_title: 'Proof of Loading',
  load_schedule: 'Loading Schedule',
  load_type: 'Load Type',
  load_value: 'Load Value',
  loading_date: 'Loading Date',
  loading_fee: 'Loading Fee',
  loading_fee_not_registered: 'Loading Fee isn’t registered in this Location',
  loading_info: 'Loading Info',
  loading_timestamp: 'Loading Timestamp',
  loading_timestamp_ref: 'Loading Timestamp Ref.',
  local_shipment_statuses: {
    [LOCAL_SHIPMENT_STATUSES.ALL]: 'All Shipments',
    'need-confirmation': 'Need Confirmation',
    [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: 'At Location',
    [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: 'At Warehouse',
    [LOCAL_SHIPMENT_STATUSES.COMPLETED]: 'Completed',
    [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: 'In Transit',
    [LOCAL_SHIPMENT_STATUSES.ONGOING]: 'Ongoing',
    [LOCAL_SHIPMENT_STATUSES.PLANNED]: 'Planned',
  },
  location: 'Location',
  location_details: 'Location Details',
  location_fee: 'Location Fee',
  location_fee_info: 'Location Fee Info',
  location_fee_plural: 'Location Fees',
  location_fee_type_map: {
    [LOCATION_FEE_TYPE.LOADING]: 'Loading',
    [LOCATION_FEE_TYPE.UNLOADING]: 'Unloading',
    [LOCATION_FEE_TYPE.OTHER]: 'Other',
  },
  location_id: 'Location ID',
  location_name: 'Location Name',
  location_notes: 'Location Notes',
  location_phone: 'Location Phone',
  location_pic: 'Location PIC',
  location_status: 'Location Status',
  location_status_map: {
    [SHIPPER_LOCATION_STATUS.ACTIVE]: 'Active',
    [SHIPPER_LOCATION_STATUS.INACTIVE]: 'Inactive',
    [SHIPPER_LOCATION_STATUS.REGISTERED]: 'Active',
  },
  location_type: {
    [LOCATION_TYPE.NORMAL]: 'Normal',
    [LOCATION_TYPE.HOLDING]: 'Holding Location',
    [LOCATION_TYPE.PORT]: 'Port',
    [LOCATION_TYPE.RETURN_DEPO]: 'Return Depo',
    [LOCATION_TYPE.STORE]: 'Store',
  },
  locations: 'Locations',
  log_details: 'Log Details',
  login: 'Login',
  logistics_contact: 'Logistics Contact',
  logistics_pic_name: 'Logistics PIC Name',
  logistics_pic_phone: 'Logistics PIC Phone Number',
  logout: 'Logout',
  longitude: 'Longitude',
  lowest_bid: 'Lowest Bid',
  main_account: 'Main Account',
  main_bank_account: 'Main Bank Account',
  main_recipient: 'Main Recipient',
  make: 'Make',
  map_shipment_status_map: {
    [MAP_SHIPMENT_STATUS.ONGOING_TO_PICKUP]: 'Ongoing to Origin',
    [MAP_SHIPMENT_STATUS.ON_SHIPMENT]: 'Ongoing to Destination',
  },
  manual_account: 'Manual Account',
  many: 'Many',
  margin_from_bid: 'Margin from Bid',
  mark_all_as_read: 'Mark All As Read',
  mark_as_delivered: 'Mark as Delivered',
  mark_as_fully_paid: 'Mark as Fully Paid',
  mark_as_paid: 'Mark as Paid',
  mark_documents_as_delivered: 'Mark Documents as Delivered',
  mark_request_as: 'Mark Request As',
  marketplace_budget: 'Marketplace Budget',
  max_bid_limit: 'Max Bid Limit',
  max_kg: 'Max Kg',
  max_reimbursable_amount: 'Maximum Reimbursable Amount',
  max_reimbursable_amount_short: 'Max. Reimbursable Amount',
  max_transporter_bid_limit: 'Max Transporter Bid Limit',
  max_transporter_bid_price: 'Max Transporter Bid Price',
  maximum_amount: 'Maximum Amount',
  measurement_unit: 'Unit',
  message: 'Message',
  metadata_label: {
    AKTA: {
      director_name: 'Director Name',
    },
    KIR: {
      expiry_date: 'Expiry Date',
      height_in_mm: 'Height',
      length_in_mm: 'Length',
      number: 'KIR Number',
      width_in_mm: 'Width',
    },
    KTP: {
      birth_date: 'Birth Date',
      name: 'KTP Owner Name',
      NIK: 'KTP Number',
    },
    NPWP: {
      number: 'NPWP Number',
    },
    SIM: {
      expiry_date: 'Expiry Date',
      number: 'SIM Number',
    },
    'SIUP/SIUJPT': {
      expiry_date: 'Expiry Date',
      number: 'SIUP Number',
    },
    'SPPPKP/SKB': {
      expiry_date: 'Expiry Date',
      number: 'SKB Number',
    },
    STNK: {
      expiry_date: 'Expiry Date',
      number: 'STNK Number',
      tax_expiry_date: 'Tax Expiry Date',
    },
  },
  min_quantity_to_be_paid: 'Min Quantity to be Paid',
  min_shipper_price: 'Minimum Shipper Price',
  minimum_payment: 'Minimum Payment',
  maximum: 'Maximum',
  minimum: 'Minimum',
  minimum_capacity: 'Minimum Capacity',
  minimum_hours: 'Minimum Hours',
  minimum_hours_rate: 'Minimum Hours Rate',
  minimum_quantity: 'Minimum Quantity',
  minimum_transporter_price: 'Minimum Transporter Price',
  minutes: 'Minutes',
  minimum_invoice: 'Minimum Invoice',
  minimum_invoice_amount: 'Minimum Invoice Amount',
  missing_goods: 'Missing Goods',
  mobile_no: 'Mobile Number',
  model: 'Model',
  monitoring_remarks: 'Monitoring Remarks',
  monitoring_status: 'Monitoring Status',
  monitoring_status_filter: {
    [SHIPMENT_STATUSES.PLANNED]: 'Planned',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [MONITORING_POSTS.beforePickup]: 'Before Pickup',
    [MONITORING_POSTS.pickup]: 'Pickup',
    [MONITORING_POSTS.onShipment]: 'Ongoing to Destination (on Shipment)',
    [MONITORING_POSTS.atDestination]: 'At Destination',
    [MONITORING_POSTS.podUpload]: 'POD Uploaded',
  },
  month: 'Month',
  more_filter: 'More Filter',
  more_filters: 'More Filters',
  more_options: 'More Options',
  more_shipper: 'More Shipper',
  monitoring_post_statuses: {
    [MONITORING_POSTS.beforePickup]: 'Before Pickup',
    [MONITORING_POSTS.pickup]: 'Pickup',
    [MONITORING_POSTS.polUpload]: 'POL Review',
    [MONITORING_POSTS.onShipment]: 'On Shipment',
    [MONITORING_POSTS.atDestination]: 'At Destination',
    [MONITORING_POSTS.podUpload]: 'POD Review',
  },
  multipickup_fee: 'Multipickup Fee',
  multipickups_fees: 'Multi Pickups Fees',
  multipickups_diff_city: 'Multi Pickups - Different City',
  multipickups_same_city: 'Multi Pickups - Same City',
  multidropoffs_same_city: 'Multi Dropoffs - Same City',
  multidropoff_fee: 'Multidropoff Fee',
  multidropoff_diff_city: 'Multi Drop Offs - Different City',
  multidropoff_same_city: 'Multi Drop Offs - Same City',
  multipoint_fee: 'Multi Pickups/Drop Off Fee',
  multiple_shipment: 'Multiple Shipment',
  multiple_shipments: 'multiple shipments',
  multiple_shipment_data: {
    drop_different_city: 'Multidrop Different City Fee',
    drop_same_city: 'Multidrop Same City Fee',
    modal_title: 'Edit Multipickup/drop Fees',
    pickup_different_city: 'Multipickup Different City Fee',
    pickup_same_city: 'Multipickup Same City Fee',
    total: 'Multishipment Total Fee',
  },
  multiple_shipment_fee: 'Multiple Shipment Fee',
  multiple_shipment_map: {
    [MULTIPLE_SHIPMENT_TYPE.MULTIDROP]: 'Multidropoff',
    [MULTIPLE_SHIPMENT_TYPE.MULTIPICKUP]: 'Multipickup',
    [MULTIPLE_SHIPMENT_TYPE.MULTIDROP_MULTIPICKUP]: 'Multipickup and Multidropoff',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_EXPORT]: 'Export FM Trucking',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_OUTBOUND]: 'Outbound FM Trucking',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_IMPORT]: 'Import FM Trucking',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_INBOUND]: 'Inbound FM Trucking',
  },
  multiple_shipment_table: {
    amount: 'Amount',
    count: 'Count',
    edit: 'Edit',
    fee: 'Multipickup/drop Fees',
    fee_type: 'Fee Type',
    total_amount: 'Total Amount',
    total_count: 'Total Count',
  },
  multishipment_fee: 'Multishipment Fee',
  n_a: 'N/A',
  name: 'Name',
  near: 'Near',
  need_update: 'Need Update',
  negotiation_price: 'Negotiation Price',
  new: 'New',
  new_allocation: 'New Allocation',
  new_assignment: 'New Assignment',
  new_booking_information: 'New Booking Information',
  new_customer: 'New Customer',
  new_disbursement_scheme: 'New Scheme',
  new_data: 'New Data',
  new_driver: 'New Driver',
  new_invoice: 'New Invoice',
  new_location: 'New Location',
  new_password: 'New Password',
  new_payment: 'New Payment',
  new_pricing: 'New Pricing',
  new_shipper_name: 'New Shipper Name',
  new_status: 'New Status',
  new_unit: 'New Unit',
  new_warehouse: 'New Warehouse',
  next: 'Next',
  next_shipment: 'Next Shipment',
  nft_notes: 'NFT Notes',
  ninety_days: '> 90 Days (Rp)',
  no: 'No',
  no_account: 'No Account',
  no_back: 'No, Back',
  no_entries: 'No entries inputted',
  no_location_fee: 'No location fee for this shipment',
  no_po: 'PO Number',
  no_pod: 'No POD',
  no_submitted_bids: 'No submitted bids, ask transporters to submit their bid',
  no_upload_required: 'No upload required',
  nomor_polisi: 'Nomor Polisi',
  not_eligible: 'Not Eligible',
  not_required: 'Not Required',
  not_uploaded: 'Not Uploaded',
  not_yet_due: 'Not Yet Due (Rp)',
  not_invoiceable: 'Not Invoiceable',
  notes: 'Notes',
  notes_for_driver: 'Notes for Driver',
  notification: 'Notification',
  npwp: 'NPWP',
  NPWP: 'NPWP',
  npwp_number: 'NPWP Number',
  number_of_shipments: 'Number of Shipments',
  number_of_trucks: 'Number of Truck Units',
  nft_expedition_provider: {
    [EXPEDITION_PROVIDER.GO_SEND]: 'Go Send',
    [EXPEDITION_PROVIDER.GRAB_EXPRESS]: 'Grab Express',
    [EXPEDITION_PROVIDER.NINJA_XPRESS]: 'Ninja Express',
    [EXPEDITION_PROVIDER.ANTERAJA]: 'AnterAja',
    [EXPEDITION_PROVIDER.REX_KIRIMAN_EXPRESS]: 'Rex Kiriman Express',
    [EXPEDITION_PROVIDER.LION_PARCEL]: 'Lion Parcel',
    [EXPEDITION_PROVIDER.PAXEL]: 'Paxel',
    [EXPEDITION_PROVIDER.JNE]: 'Jne',
    [EXPEDITION_PROVIDER.TIKI]: 'Tiki',
    [EXPEDITION_PROVIDER.POS_INDONESIA]: 'Pos Indonesia',
    [EXPEDITION_PROVIDER.WAHANA]: 'Wahan',
    [EXPEDITION_PROVIDER.SICEPAT]: 'SiCepat',
    [EXPEDITION_PROVIDER.SICEPAT_SIUNTUNG]: 'SiCepat SiUntung',
    [EXPEDITION_PROVIDER.JNT_EXPRESS]: 'JNT Express',
    [EXPEDITION_PROVIDER.OTHER]: 'Other',
  },
  nft_reject_reason: {
    [NFT_REJECT_REASON.NEED_REVISION]: 'Need Revision',
    [NFT_REJECT_REASON.OTHER_REASON]: 'Other Reason',
    [NFT_REJECT_REASON.INCOMPLETE]: 'Incomplete',
    [NFT_REJECT_REASON.REVISION]: 'This invoice is Revised, show rejection banner',
  },
  not_yet_categorized: 'Not Yet Categorized',
  nric: 'NRIC',
  NRIC: 'NRIC',
  office_address: 'Office Address',
  ok: 'Ok',
  okay: 'Okay',
  ok_caps: 'OK',
  on_hold: 'On Hold',
  on_hold_type: 'On Hold Type',
  on_time_status_map: {
    [ON_TIME_STATUS.LATE]: 'Late',
    [ON_TIME_STATUS.MAYBE_LATE]: 'Maybe Late',
    [ON_TIME_STATUS.ONTIME]: 'Ongoing',
  },
  on_track: 'On Track',
  onboarding_date: 'Onboarding Date',
  one_to_fifteen_days: '1-15 Days (Rp)',
  open: 'Open',
  open_24_hrs: 'Open 24 hrs',
  open_map: 'Open Map',
  open_to_marketplace: 'Open to Marketplace',
  operating_hours: 'Operating Hours',
  operational_hours: 'Operational Hours',
  optional: 'Optional',
  optional_document: 'Optional Document',
  order: 'Order',
  order_details: 'Order Details',
  order_number: 'Order No.',
  order_type: 'Order Type',
  origin: 'Origin',
  original_amount: 'Original Amount',
  other: 'Other',
  others: 'Others',
  other_document_type: 'Other Document Type',
  other_fee: 'Other Fee',
  other_fee_form: {
    actualAmount: 'Actual Amount',
    additional_notes: 'Additional Notes',
    maximumAmount: 'Max. Reimbursable Amount',
    reimbursement_type: 'Reimbursement Type',
  },
  other_fee_info: 'Other Fee Info',
  other_fee_type: 'Other Fee Type',
  other_notes: 'Other Notes',
  other_reason: 'Other Reason',
  output_option: 'Output option',
  outstanding_payment: 'Outstanding Payment',
  overall_score: 'Overall Score',
  overnight: 'Overnight',
  overnight_actual_duration: 'Overnight Actual Duration',
  overnight_fee_form: {
    check_document_collected: 'Check if you’ve collected these documents',
    fields: {
      approved_elapsed_time: 'Approved Time Elapsed',
    },
    overnight_proof_type: {
      DRIVER_APP_GPS_TIMESTAMPS: 'Driver GPS',
      FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY: 'POD',
      OTHER: 'POD',
      SHIPPER_APPROVAL: 'Shipper Approval',
      WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS: 'POD',
    },
  },
  overnight_fee_pricing_type_map: {
    [OVERNIGHT_FEE_PRICING_TYPE.FLAT]: 'Flat',
    [OVERNIGHT_FEE_PRICING_TYPE.PROGRESSIVE]: 'Progressive',
  },
  overnight_fees_labels: {
    include_holiday: 'Include Holiday',
    include_sunday: 'Include Sunday',
    minimum_hours: 'Minimum Hours',
    overnight_fee: 'Overnight Fee',
    overnight_fees: 'Overnight Fees',
    remarks: 'Remarks',
    required_documents: 'Required Documents',
  },
  overnight_loading_info: 'Overnight Loading Info',
  overnight_proof_description: {
    DRIVER_APP_GPS_TIMESTAMPS: '',
    FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY: 'Not included in POD',
    OTHER: '',
    SHIPPER_APPROVAL: '',
    WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS: 'Written and officially stamped in POD',
  },
  overnight_proof_map: {
    [OVERNIGHT_PROOF_TYPE.SHIPPER_APPROVAL]: 'Approval from Shipper',
    [OVERNIGHT_PROOF_TYPE.FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY]:
      'Form, Berita Acara, or Proof of Overnight Stay',
    [OVERNIGHT_PROOF_TYPE.WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS]: 'Warehouse Entry & Exit Timestamps',
    [OVERNIGHT_PROOF_TYPE.DRIVER_APP_GPS_TIMESTAMPS]: 'Driver App GPS Timestamps',
    [OVERNIGHT_PROOF_TYPE.OTHER]: 'Other',
  },
  overnight_proof_type: {
    DRIVER_APP_GPS_TIMESTAMPS: 'Driver App GPS Timestamps',
    FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY: 'Form, Berita Acara, or Proof of Overnight Stay',
    OTHER: 'Other',
    SHIPPER_APPROVAL: 'Approval from Shipper',
    WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS: 'Warehouse Entry & Exit Timestamps',
  },
  overnight_shipper_duration: 'Overnight Shipper Duration',
  overnight_unloading_info: 'Overnight Unloading Info',
  owner_name: 'Owner Name',
  packaging_damage: 'Packaging Damage',
  paid: 'Paid',
  paid_disbursement: 'Paid Disbursement',
  panthera_entity: 'Panthera Entity',
  panthera_entity_name: 'Panthera Entity Name',
  panthera_poc: 'Panthera POC',
  partner_company_driver_status_map: {
    [PARTNER_COMPANY_DRIVER_STATUSES.REGISTERED]: 'Active',
    [PARTNER_COMPANY_DRIVER_STATUSES.DEACTIVATED]: 'Deactivated',
  },
  partner_company_vehicle_status_map: {
    [PARTNER_COMPANY_VEHICLE_STATUSES.REGISTERED]: 'Active',
    [PARTNER_COMPANY_VEHICLE_STATUSES.DEACTIVATED]: 'Deactivated',
  },
  pass: 'Pass',
  password: 'Password',
  pdf: 'PDF',
  pdf_configuration: 'PDF Configuration',
  past_seven_days: 'Past 7 Days',
  past_three_months: 'Past 3 Months',
  past_month: 'Past Month',
  payload: 'Payload',
  payment: 'Payment',
  payment_id: 'Payment ID',
  payment_and_tax_details: 'Payment and Tax Details',
  payment_deduction: 'Payment Deduction',
  payment_deduction_amount: 'Payment Deduction Amount',
  payment_deduction_info: 'Payment Deduction Info',
  payment_detail: 'Payment Detail',
  payment_details: 'Payment Details',
  payment_information: 'Payment Information',
  payment_item: 'Payment Item',
  payment_method: 'Payment Method',
  payment_or_job_id: 'Payment / Job ID',
  payment_plan: 'Payment Plan',
  payment_request: 'Payment Request',
  payment_request_rejection_type_map: {
    [PAYMENT_REQUEST_REJECTION_TYPE.INVOICE_PROBLEM]: 'Invoice Problem',
    [PAYMENT_REQUEST_REJECTION_TYPE.DELIVERY_ORDER_PROBLEM]: 'Delivery Order Problem',
    [PAYMENT_REQUEST_REJECTION_TYPE.REQUEST_FROM_AP]: 'Request From AP',
  },
  payment_request_status_map: {
    [PAYMENT_REQUEST_AMOUNT_STATUS.DISBURSED_AMOUNT]: 'Disbursed Amount Today',
    [PAYMENT_REQUEST_AMOUNT_STATUS.PENDING_AMOUNT]: 'Non-Automated Pending Amount',
    [PAYMENT_REQUEST_AMOUNT_STATUS.PENDING_AMOUNT_AUTOMATED]: 'Automated Pending Amount',
  },
  payment_request_statuses_map: {
    [PAYMENT_REQUEST_STATUSES.APPROVED]: 'Approved',
    [PAYMENT_REQUEST_STATUSES.CANCELLED]: 'Cancelled',
    [PAYMENT_REQUEST_STATUSES.DISBURSED]: 'Disbursed',
    [PAYMENT_REQUEST_STATUSES.DISBURSEMENT_FAILED]: 'Disbursement Failed',
    [PAYMENT_REQUEST_STATUSES.ON_HOLD]: 'On Hold',
    [PAYMENT_REQUEST_STATUSES.DISBURSEMENT_PLANNED]: 'Disbursement Planned',
    [PAYMENT_REQUEST_STATUSES.PAYMENT_PROCESSING]: 'Payment Processing',
    [PAYMENT_REQUEST_STATUSES.PROCESSING]: 'Xendit Processing',
    [PAYMENT_REQUEST_STATUSES.REJECTED]: 'Rejected',
    [PAYMENT_REQUEST_STATUSES.REVIEW_NOW]: 'Review Now',
    [PAYMENT_REQUEST_STATUSES.WAITING_FOR_WITHDRAWAL]: 'Waiting for Withdrawal',
    [PAYMENT_REQUEST_STATUSES.WITHDRAWAL_REQUESTED]: 'Withdrawal Requested',
  },
  payment_scheme: 'Payment Scheme',
  payment_settings: 'Payment Settings',
  payment_stage: 'Payment Stage',
  payment_status: 'Payment Status',
  payment_terms: 'Payment Terms',
  payment_type: {
    [PAYMENT_TYPES.SHIPMENT_FEE]: 'Shipment Fee',
    [PAYMENT_TYPES.UNLOADING_FEE]: 'Unloading Fee',
    [PAYMENT_TYPES.LOADING_FEE]: 'Loading Fee',
    [PAYMENT_TYPES.OVERNIGHT_FEE]: 'Overnight Fee',
    [PAYMENT_TYPES.TICKET_FEE]: 'Ticket Fee',
    [PAYMENT_TYPES.OTHER_FEE]: 'Other Fee',
  },
  payment_type_stage: 'Payment Type (Stage {{number}})',
  payment_type_title: 'Payment Type',
  payments: 'Payments',
  per_30_days: 'Per 30 Days',
  percentage: 'Percentage',
  percentage_discount: 'Percentage Discount',
  percentage_of_shipper_price: '% of Shipper Price',
  personal: 'Personal',
  personal_account: 'Personal Account',
  pending_approval: 'Pending Approval',
  phone: 'Phone',
  phone_number: 'Phone Number',
  physical_pod: 'Physical POD',
  physical_pod_has_been_set: 'Physical POD has been set to',
  physical_proof_of_delivery: 'Physical Proof of Delivery',
  pic: 'PIC',
  pic_contact: 'PIC Contact',
  pic_contact_number: 'PIC Contact Number',
  pic_email: 'PIC Email',
  pic_name: 'PIC Name',
  pic_phone: 'PIC Phone',
  pic_phone_number: 'PIC Phone Number',
  pic_position: 'PIC Position',
  pic_role: 'PIC Role',
  pickup: 'Pickup',
  pickup_stuffing: 'Pickup Stuffing',
  pickup_date: 'Pickup Date',
  pickup_date_and_time: 'Pickup Date and Time',
  pkp: 'PKP',
  pkp_number: 'PKP Number',
  pkp_options_map: {
    [PKP_OPTIONS.PKP]: 'PKP',
    [PKP_OPTIONS.NON_PKP]: 'Non PKP',
  },
  planned_dates: 'Planned Dates',
  planned_loading: 'Planned Loading',
  plate: 'Plate',
  plate_color: 'Plate Color',
  plate_color_array: {
    [PLATE_COLORS.BLACK_PLATE]: 'Black',
    [PLATE_COLORS.YELLOW_PLATE]: 'Yellow',
  },
  plate_type: 'Plate Type',
  please_choose_file_size: 'Please choose file size',
  plus_interest: '+ Interest',
  po_creation_date: 'PO Creation Date',
  po_number: 'PO Number',
  pod: 'POD',
  pod_additional_notes: 'POD Additional Notes',
  pod_details: 'POD Details',
  pod_information: 'POD Information',
  pod_no: 'POD No.',
  pod_number: 'POD Number',
  pod_numbers: 'POD Numbers',
  pod_received_date: 'POD Received Date',
  pod_return: 'POD Return',
  pod_return_price: 'POD Return Price',
  pod_returns: 'POD Returns',
  pod_status_map: {
    [POD_STATUS.ARRIVING_AT_PITSTOP]: 'Arriving at Pitstop',
    [POD_STATUS.RECEIVED]: 'Received',
    [POD_STATUS.DOORING_RECEIVED]: 'Dooring POD Received',
    [POD_STATUS.DELIVERING_TO_DOORING]: 'Delivering to Dooring Agent',
    [POD_STATUS.DELIVERING_TO_AR]: 'Delivering to AR',
    [POD_STATUS.DELIVERING_TO_SHIPPER]: 'Delivering to Shipper',
    [POD_STATUS.DELIVERING_TO_AR_AND_SHIPPER]: 'Delivering to AR and Shipper',
    [POD_STATUS.DELIVERED_TO_DOORING]: 'Delivered to Dooring Agent',
    [POD_STATUS.DELIVERED_TO_AR]: 'Delivered to AR',
    [POD_STATUS.DELIVERED_TO_SHIPPER]: 'Delivered to Shipper',
    [POD_STATUS.DELIVERED_TO_AR_AND_SHIPPER]: 'Delivered to AR and Shipper',
    [PODC_PHYSICAL_STATUS.NOT_RECEIVED]: 'Arriving at Pitstop',
    [PODC_PHYSICAL_STATUS.DELIVERED_TO_AR_AND_DELIVERED_TO_SHIPPER]: 'Delivered to AR and Shipper',
    [PODC_PHYSICAL_STATUS.DELIVERING_TO_AR_AND_DELIVERING_TO_SHIPPER]: 'Delivering to AR and Shipper',
    [PODC_PHYSICAL_STATUS.DELIVERING_TO_AR_AND_DELIVERED_TO_SHIPPER]: 'Delivering to AR, Delivered to Shipper',
    [PODC_PHYSICAL_STATUS.DELIVERED_TO_AR_AND_DELIVERING_TO_SHIPPER]: 'Delivered to AR, Delivering to Shipper',
  },
  job_type_map: {
    [JOB_TYPE.SME]: 'SME',
    [JOB_TYPE.ENTERPRISE]: 'Enterprise',
    [JOB_TYPE.PODF]: 'PODF',
    [JOB_TYPE.DTD]: 'Door to Door',
  },
  pod_uploaded_on: 'POD uploaded on',
  podc_delivery_service_map: {
    [PODC_DELIVERY_SERVICE.GOSEND]: 'Gosend',
    [PODC_DELIVERY_SERVICE.GRAB_EXPRESS]: 'Grab Express',
    [PODC_DELIVERY_SERVICE.JNE]: 'JNE',
    [PODC_DELIVERY_SERVICE.OTHER]: 'Other Delivery Service',
    [PODC_DELIVERY_SERVICE.INTERNAL_COURIER]: 'Runner',
  },
  podc_delivery_tracking_log_status_map: {
    [PODC_DELIVERY_TRACKING_LOG_STATUSES.LOST]: 'Document Lost, replaced by {{name}}',
    [PODC_DELIVERY_TRACKING_LOG_STATUSES.SENT]: 'Sending Documents to Pitstop',
    [PODC_DELIVERY_TRACKING_LOG_STATUSES.UPDATE_TRACKING]: 'Update Tracking',
    [PODC_DELIVERY_TRACKING_LOG_STATUSES.DOCUMENT_UPLOADED]: 'Document Received at Pitstop',
    [PODC_DELIVERY_TRACKING_LOG_STATUSES.DOCUMENT_DELIVERY_SENDING]: 'Delivering Documents to {{name}}',
    [PODC_DELIVERY_TRACKING_LOG_STATUSES.DOCUMENT_DELIVERY_COMPLETED]: 'Documents Delivered to {{name}}',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
  },
  podc_document_replacer_map: {
    [PODC_DOCUMENT_REPLACERS.BAP]: 'Berita Acara Pelaporan (BAP)',
    [PODC_DOCUMENT_REPLACERS.REPRINTED_POD]: 'Reprinted POD',
  },
  podf: 'PODF',
  podf_shipments: 'podf shipment',
  pol: 'POL',
  pool_address: 'Pool Address',
  pool_city: 'Pool City',
  pool_location: 'Pool Location',
  port_of_discharge: 'Port of Discharge',
  port_of_discharge_date: 'Port of Discharge Date',
  port_of_loading: 'Port of Loading',
  port_of_loading_date: 'Port of Loading Date',
  post_to_marketplace: {
    active: 'Post',
    inactive: 'Not Post',
    switch: 'Post this shipment to marketplace',
    title: 'Post to Marketplace',
  },
  post_to_marketplace_alert: {
    subtitle: 'Please make sure your customized price is correct',
    title: 'Marketplace price doesn\'t match with the system pricing',
  },
  post_to_marketplace_review: {
    do_not_post: 'Do not post',
    post: 'Post',
  },
  postcode: 'Postcode',
  posted_price_summary: 'Posted Price Summary',
  post_to_private_mp: 'Post to Private MP',
  posted_price_summary_labels: {
    max_transporter_bid: 'Max Transporter Bid Price',
    posted_price: 'Posted Price ({{unit}})',
    posted_price_total: 'Posted Price (Total)',
  },
  pph23: 'Pph23',
  pph23_deductor: 'PPh23 Deductor',
  pph23_deductors: {
    [PPH23_DEDUCTOR.PARTNER]: 'Customer',
    [PPH23_DEDUCTOR.SELF]: 'My Company',
  },
  proof_file: 'Proof File',
  ppn: 'PPN',
  pr_detail: 'PR Details',
  pr_number: 'PR Number',
  pr_description: 'PR Description',
  pre_paid: 'Pre-Paid',
  preview_document: 'Preview Document',
  previous_allocation: 'Previous Allocation',
  previous_adjustment: 'Previous Adjustment',
  previous_assignment: 'Previous Assignment',
  previous_booking_information: 'Previous Booking Information',
  previous_data: 'Previous Data',
  previous_month: 'Previous Month',
  previous_payment: 'Previous Payment',
  preview_payment_request: 'Preview Payment Request',
  preview_bulk_payment_request: 'Preview Bulk Payment Request',
  previous_pricing: 'Previous Pricing',
  previous_pr_status: 'Previous PR Status',
  previous_pr_amount: 'Previous PR Amount',
  previous_shipment: 'Previous Shipment',
  price: 'Price',
  price_adjustment: 'Price Adjustment',
  price_calculation: 'Price Calculation',
  price_details: 'Price Details',
  price_province_state_level: 'Price (Province/State Level)',
  price_rate_type: 'Price Rate Type',
  price_summary: 'Price Summary',
  prices: 'Prices',
  pricing: 'Pricing',
  pricing_detail: 'Pricing Detail',
  pricing_rate_type: 'Pricing Rate Type',
  pricing_rate_type_map: {
    [PRICING_RATE_TYPES.PER_KG]: 'Per Kg',
    [PRICING_RATE_TYPES.PER_CBM]: 'Per CBM',
    [PRICING_RATE_TYPES.PER_LANE]: 'Per Lane',
    [PRICING_RATE_TYPES.PER_ROUTE]: 'Per Route',
    [PRICING_RATE_TYPES.PER_UNIT]: 'Per Unit',
  },
  pricing_rate_type_map_seprator: {
    [PRICING_RATE_TYPES.PER_ROUTE]: '',
    [PRICING_RATE_TYPES.PER_KG]: ' / Kg',
    [PRICING_RATE_TYPES.PER_CBM]: ' / CBM',
    [PRICING_RATE_TYPES.PER_UNIT]: ' / Unit',
  },
  pricing_scaling_type_map: {
    [PRICING_SCALING_TYPES.MINIMUM_QUANTITY]: 'Minimum Quantity',
    [PRICING_SCALING_TYPES.GOODS_CAPACITY]: 'Goods Capacity',
  },
  pricing_summary: 'Pricing Summary',
  pricing_type: 'Pricing Type',
  pricings: 'Pricings',
  process_csv: 'Process CSV',
  product_category: 'Product Category',
  product_category_map: {
    [PRODUCT_CATEGORY.ALCOHOLIC_BEVARAGES]: 'Alcoholic Beverages',
    [PRODUCT_CATEGORY.DRINKING_WATER]: 'Drinking Water',
    [PRODUCT_CATEGORY.COFFEE]: 'Coffee',
    [PRODUCT_CATEGORY.FRUIT_FLAVORED_DRINKS]: 'Fruit Flavored Drinks',
    [PRODUCT_CATEGORY.JUICE]: 'Juice',
    [PRODUCT_CATEGORY.MILK]: 'Milk',
    [PRODUCT_CATEGORY.NON_DAIRY]: 'Non-Dairy Milk',
    [PRODUCT_CATEGORY.POWDERED_BEVERAGES_MIXES]: 'Powdered Beverage Mixes',
    [PRODUCT_CATEGORY.SODA]: 'Soda',
    [PRODUCT_CATEGORY.SPORTS_ENERGY_DRINKS]: 'Sports & Energy Drinks',
    [PRODUCT_CATEGORY.TEA_INFUSIONS]: 'Tea & Infusions',
    [PRODUCT_CATEGORY.WATER]: 'Water',
    [PRODUCT_CATEGORY.BAKERY]: 'Bakery',
    [PRODUCT_CATEGORY.SNAKES]: 'Snacks',
    [PRODUCT_CATEGORY.CONDIMENTS_SOUCES]: 'Condiments & Sauces',
    [PRODUCT_CATEGORY.COOKING_BAKING_INGREDIENTS]: 'Cooking & Baking Ingredients',
    [PRODUCT_CATEGORY.DAIRY]: 'Dairy Products (Chilled)',
    [PRODUCT_CATEGORY.DIPS]: 'Dips & Spreads',
    [PRODUCT_CATEGORY.FOOD]: 'Food Gift Baskets',
    [PRODUCT_CATEGORY.FROZEN]: 'Frozen Desserts & Novelties',
    [PRODUCT_CATEGORY.FRUITS]: 'Fruits & Vegetables',
    [PRODUCT_CATEGORY.GRAINS]: 'Grains, Rice & Cereal',
    [PRODUCT_CATEGORY.MEAT]: 'Meat, Seafood & Eggs',
    [PRODUCT_CATEGORY.NUTS]: 'Nuts & Seeds',
    [PRODUCT_CATEGORY.PASTA]: 'Pasta & Noodles',
    [PRODUCT_CATEGORY.PREPARED]: 'Prepared Foods',
    [PRODUCT_CATEGORY.SPICES]: 'Seasonings & Spices',
    [PRODUCT_CATEGORY.SNACKS]: 'Snack Foods',
    [PRODUCT_CATEGORY.SOUPS]: 'Soups & Broths',
    [PRODUCT_CATEGORY.TOUFU]: 'Tofu, Soy & Vegetarian Products',
    [PRODUCT_CATEGORY.FITNESS_AND_NUTRITION]: 'Fitness & Nutrition',
    [PRODUCT_CATEGORY.MEDICINE_AND_DRUGS]: 'Medicine & Drugs',
    [PRODUCT_CATEGORY.FMCG]: 'FMCG',
    [PRODUCT_CATEGORY.PLASTIC]: 'Plastic Pellets',
    [PRODUCT_CATEGORY.PACKAGING]: 'Packaging',
    [SME_PRODUCT_CATEGORY.AUTOMOTIVE]: 'Automotive',
    [SME_PRODUCT_CATEGORY.MOVING_GOODS]: 'Moving Goods',
    [SME_PRODUCT_CATEGORY.CONSTRUCTION_MATERIAL]: 'Construction Material',
    [SME_PRODUCT_CATEGORY.HEAVY_EQUIPMENT]: 'Heavy Equipment',
    [SME_PRODUCT_CATEGORY.FROZEN_GOODS]: 'Frozen Goods',
    [SME_PRODUCT_CATEGORY.AGRICULTURAL_PRODUCT]: 'Agricultural Product',
    [SME_PRODUCT_CATEGORY.GENERAL_CARGO]: 'General Cargo',
    [SME_PRODUCT_CATEGORY.GLASSWARE]: 'Glassware',
    [SME_PRODUCT_CATEGORY.PRODUCT_PACKAGING]: 'Product Packaging',
  },
  product_details: 'Product Details',
  product_type: 'Product Type',
  proforma: 'Proforma',
  proforma_rejected: 'Proforma Rejected',
  proforma_invoice_number: 'Proforma Invoice Number',
  proforma_invoice_date: 'Proforma Invoice Date',
  proforma_invoice_information: 'Proforma Invoice Information',
  profile: 'Profile',
  profile_account: 'Profile Account',
  progressive: 'Progressive',
  progressive_pricing_type: 'Progressive',
  project_canvasser: 'Project Canvasser',
  project_category: 'Project Category',
  project_telesales: 'Project Telesales',
  project_type: 'Project Type',
  project_type_map: {
    [PROJECT_TYPE.LAST_MILE]: 'Last Mile',
  },
  proof: 'Proof',
  proof_of_cancellation: 'Proof of Cancellation to be attached',
  proof_of_cancellation_type: {
    DRIVER_APP_GPS: 'Driver App GPS',
    EMAIL_APPROVAL: 'Email approval',
    WHATSAPP_APPROVAL: 'Whatsapp approval',
  },
  proof_of_delivery: 'Proof of Delivery',
  province: 'Province',
  pt: 'PT',
  purchase_order: 'Purchase Order',
  purchase_order_shipment_type: {
    [FIRST_MILE_SHIPMENT_TYPE.DOOR_TO_DOOR]: 'Single Pickup Door-to-door',
    [FIRST_MILE_SHIPMENT_TYPE.MULTI_DOOR_TO_DOOR]: 'Multi Pickups Door-to-door',
    [PURCHASE_ORDER_FIRST_MILE_TRUCKING.FIRST_MILE_EXPORT]: 'Export FM Trucking',
    [PURCHASE_ORDER_FIRST_MILE_TRUCKING.FIRST_MILE_IMPORT]: 'Import FM Trucking',
    [PURCHASE_ORDER_FIRST_MILE_TRUCKING.FIRST_MILE_OUTBOUND]: 'Outbound FM Trucking',
    [PURCHASE_ORDER_FIRST_MILE_TRUCKING.FIRST_MILE_INBOUND]: 'Inbound FM Trucking',
  },
  puspakom: 'Puspakom',
  puspakom_inspection: 'Puspakom Inspection',
  job_order_no: 'Job / Order No.',
  job_order_number: 'Job / Order Number',
  qty: 'Qty',
  quantity: 'Quantity',
  quantity_type_short: 'Qty type',
  quantityCount: 'Quantity {{count}}',
  queueing_time: 'Queueing Time',
  quick_tip: 'Quick Tip:',
  queued: 'Queued',
  queue_at_destination: 'Queue at Destination',
  queue_at_origin: 'Queue at Origin',
  queue_shipment: 'Queue Shipment',
  service_level_agreement: 'Service Level Agreement',
  shipment_queued_ttms: 'Queued on T-TMS',
  shipment_queue_assigning: 'Assigning to Queue',
  radius: 'Radius',
  reactivate: 'Reactivate',
  reactivate_driver: 'Reactivate Driver',
  reactivate_truck: 'Reactivate Truck',
  reallocate: 'Reallocate',
  real_add_additional_fee: 'Add Additional Fee',
  reason: 'Reason',
  rebate: 'Rebate',
  rebate_percentage: 'Rebate (%)',
  rearrange_position: 'Edit Position',
  receivable: 'Receivable',
  received_date: 'Received Date',
  received_datetime: 'Received Datetime',
  received_detail: 'Received Detail',
  received_location: 'Received Location',
  receiver_name: 'Receiver Name',
  receiver_phone: 'Receiver Phone',
  receiver_phone_number: 'Receiver Phone Number',
  recipient: 'Recipient',
  recipient_detail: 'Recipient Detail',
  recipient_details: 'Recipient Details',
  recommended: 'Recommended',
  refresh_the_page_and_try_again: 'Click the Generate CSV button again or Refresh the page',
  regional: 'Regional',
  register: 'Register',
  registered_company_name: 'Registered Company Name',
  register_nft_partner_account: 'Register NFT / Partner Account',
  registration_date: 'Registration Date',
  registration_id: 'Registration ID',
  reimbursable_amount: 'Reimbursable Amount',
  reimbursement: 'Reimbursement',
  reimbursement_percentage: 'Reimbursement Percentage',
  reimbursement_requirement: 'Reimbursement Requirement',
  reimbursement_requirement_description: 'Reimbursement Requirement Description',
  reimbursement_requirement_type_map: {
    [REIMBURSEMENT_REQUIREMENT_TYPE.REIMBURSABLE_WITH_RECEIPT_AND_STAMP]: 'Reimbursable With Receipt And Stamp',
    [REIMBURSEMENT_REQUIREMENT_TYPE.REIMBURSABLE_WITH_RECEIPT]: 'Reimbursable With Receipt',
    [REIMBURSEMENT_REQUIREMENT_TYPE.REIMBURSABLE]: 'Reimbursable',
    [REIMBURSEMENT_REQUIREMENT_TYPE.NOT_REIMBURSABLE]: 'Not Reimbursable',
    [REIMBURSEMENT_REQUIREMENT_TYPE.OTHER]: 'Other',
  },
  reimbursement_type: 'Reimbursement Type',
  reimbursement_type_description: 'Reimbursement Type Description',
  reimbursement_type_map: {
    [REIMBURSEMENT_TYPE.ROUTE]: '{{shipmentType}}',
    [REIMBURSEMENT_TYPE.UNIT]: '{{shipmentType}}-Unit',
    [REIMBURSEMENT_TYPE.KG]: '{{shipmentType}}-Kg',
    [REIMBURSEMENT_TYPE.OTHER]: 'Other',
  },
  reject: 'Reject',
  reject_reassignment: 'Reject Reassignment',
  reject_reassignment_trucking: 'Reject Reassignment for {{doNumber}}?',
  rejected: 'Rejected',
  rejected_pr: 'PR Rejected',
  rejection_category: 'Rejection Category',
  rejection_date: 'Rejection Date',
  rejection_reason: 'Rejection Reason',
  rejection_type: 'Rejection Type',
  remaining_amount: 'Remaining Amount',
  remark: 'Remark',
  remarks: 'Remarks',
  remember_my_setting: 'Remember my setting',
  reminder_details: 'Reminder Details',
  reminder_for_cutoff_time: 'Reminder for Cut Off Time',
  reminder_for_loading_time: 'Reminder for Loading Time',
  reminder_recipient: 'Reminder Recipient',
  reminder_via: 'Reminder Via',
  reminder_via_map: {
    [REMINDER_VIA.EMAIL]: 'Email',
    [REMINDER_VIA.WHATSAPP]: 'WA',
  },
  remove: 'Remove',
  remove_container: 'Remove Container',
  remove_container_s: 'Remove Container(s)',
  remove_container_action: 'Remove Container from Shipment',
  remove_location: 'Remove Location',
  reopen_job_copywriting: 'Other transporters will be able to bid on this DO again',
  reopen_shipment: 'Reopen Shipment',
  replace_and_deactivate: 'Replace and Deactivate',
  request_type: 'Request Type',
  requested_amount: 'Requested Amount',
  require: 'Required',
  required: 'Required {{object}}',
  required_document: 'Required Document',
  required_documents: 'Required Documents',
  requirement: 'Requirement',
  resend_verification_code: 'Resend Verification Code',
  restructure_amount: 'Restructure Amount',
  restriction_options: 'Restriction Options',
  return: 'Return',
  return_address: 'Return Address',
  return_deadline: 'Return Deadline',
  return_fee: 'Return Fee',
  return_schedule: 'Return Schedule',
  retype_new_password: 'Retype New Password',
  retype_password: 'Retype Password',
  reupload: 'Reupload',
  reupload_document: 'Reupload Document',
  reupload_fixed_csv: 'Reupload Fixed CSV',
  reupload_file: 'Reupload File',
  reupload_ro: 'Reupload RO',
  review: 'Review',
  review_basic_information: 'Review Basic Information',
  review_contract_documents: 'Review Contract Documents',
  review_payments: 'Review Payments',
  review_claim_and_returns: 'Review Claim & Returns',
  review_required_document_returns: 'Review Required Document Returns',
  review_truck_requirements: 'Review Truck Requirements',
  review_fee: 'Review Fee',
  review_invoice: 'Review Invoice',
  review_manually: 'Review Manually',
  review_page: 'Review Page',
  review_pod: 'Review POD',
  review_order: 'Review Order',
  review_shipper: 'Review Shipper',
  review_status: 'Review Status',
  review_summary: 'Review Summary',
  review_pod_full: 'Review Proof of Delivery',
  review_pol_full: 'Review Proof of Loading',
  review_group: 'Review Group',
  reviewed_by: 'Reviewed By',
  revise: 'Revise',
  revise_invoice: 'Revise Invoice',
  role: 'Role',
  role_information: 'Role Information',
  gross_merchandise_value: 'GMV/Gross Merchandise Value',
  target_idr: 'Target (IDR)',
  road_tax: 'Road Tax',
  roles: 'Roles',
  route: 'Route',
  route_information: 'Route Information',
  route_type: 'Route Type',
  routes: 'Routes',
  safety_req: 'Safety Requirements',
  sales: 'Sales',
  sales_details: 'Sales Detail',
  sales_pic: 'Sales PIC',
  main_sales_pic: ' Main Sales PIC',
  select_payment_type: 'Select Payment Type/Stage',
  sales_name: 'Sales Name',
  sales_role_type: {
    [USER_SALES_ROLE.SME_SALES_EXECUTIVE]: 'Sales Executive',
    [USER_SALES_ROLE.SME_TEAM_LEADER]: 'Team Leader',
    [USER_SALES_ROLE.SME_TELESALES]: 'Telesales',
    [USER_SALES_ROLE.SME_SALES_ADMIN]: 'Sales Admin',
    [USER_SALES_ROLE.SME_SALES_LEAD]: 'Sales Lead',
  },
  sales_support: 'Sales Support',
  save: 'Save',
  save_changes: 'Save Changes',
  saved_changes: 'Saved Changes',
  save_customer: 'Save Customer',
  save_document: 'Save Document',
  save_driver: 'Save Driver',
  save_location: 'Save Location',
  save_password: 'Save Password',
  save_position: 'Save Position',
  save_sales_pic: 'Save Sales PIC',
  save_status: 'Save Status',
  save_warehouse: 'Save Warehouse',
  saved_warehouse: 'Saved Warehouse',
  scope_of_work: 'Scope of Work',
  scheme: 'Scheme',
  scheme_detail: 'Scheme Detail',
  scheme_information: 'Scheme Information',
  scheme_name: 'Scheme Name',
  search: 'Search',
  search_account: 'Search Account',
  search_address: 'Search Address',
  search_creator: 'Search Creator',
  search_container_placeholder: 'Search shipment number, container ...',
  search_do_number_and_appointment_number: 'Search DO Number and Appointment Number',
  search_do_number_plate_and_appointment_number: 'DO number, license plate, Appointment Number',
  search_do_number_and_license_number: 'Search DO Number or License Plate Number',
  search_do_number: 'Search DO number',
  search_door_to_door_placeholder: 'Search PO Number, DO Number, External ID, Container Number',
  search_location_name: 'Search Location Name',
  search_pr_number_do_and_reference_id: 'Search PR, DO Number, Reference ID',
  search_shipment_container_or_license_plate: 'Search shipment, container, or license plate number',
  search_shipment_placeholder_btms: 'Shipment No, External ID, License Plate',
  search_shipper: 'Search Shipper',
  search_transporter_name: 'Search Transporter Name',
  search_truck: 'Search Truck',
  search_updater: 'Search Updater',
  search_vessel_booked_placeholder: 'Search DO number, PO number, and container number',
  see_all: 'See All',
  see_full_report: 'See Full Report',
  see_details: 'See Details',
  see_document: 'See Document',
  see_more: 'See More',
  select: 'Select',
  select_with_dash: '- Select -',
  select_adjustment_type: 'Select Adjustment Type',
  select_timestamp_type: 'Select Timestamp Type',
  select_as: 'Select AS',
  select_all: 'Select All',
  select_date: 'Select Date',
  select_items: 'Select Items',
  select_invoice: 'Select Invoice',
  select_invoices_to_download: 'Select Invoices to Download',
  select_proof_file: 'Select Proof File',
  select_category_for_cancellation: 'Select cancellation category',
  select_reason_for_cancellation: 'Select cancellation reason',
  select_rejection_type: 'Select Rejection Type',
  select_soa: 'Select SoA',
  select_truck: 'Select Truck',
  select_type: 'Select Type',
  send: 'Send',
  send_document: 'Send Document',
  send_email: 'Send Email',
  send_email_proforma: 'Send Email Proforma',
  send_invoice: 'Send Invoice',
  send_invoice_reminder: 'Send invoice reminders to this contact',
  send_invoice_reminder_every: 'Send invoice reminders every',
  send_invoice_reminder_via: 'Send invoice reminders via',
  send_manual_reminder: 'Send Manual Reminder',
  send_ticket: 'Send Ticket',
  sender_address: 'Sender Address',
  sender_name: 'Sender Name',
  sent_documents: 'Sent Documents',
  sent_document_url: 'Sent Document URL',
  set_as: 'Set as',
  set_as_main_account: 'Set as Main Account',
  shipment: 'Shipment',
  shipment_activity_log_map: {
    update_via: {
      [SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_CALL]: 'Update by call',
      [SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_WHATSAPP]: 'Update by whatsapp',
      [SHIPMENT_ACTIVITY_LOG_STATUSES.UPDATE_VIA.UPDATE_BY_TRANSPORTER]: 'Update by transporter from ticket',
    },
    shipment_stage: {
      [SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.BEFORE_PICKUP]: 'Before pickup',
      [SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.PICKUP]: 'Pickup',
      [SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.ON_SHIPMENT]: 'On Shipment',
      [SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.DESTINATION]: 'Destination',
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.POST_SHIPMENT]: 'Post Shipment',
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.SHIPMENT_STAGE.PRE_SHIPMENT]: 'Pre Shipment',
    },
    problem: {
      [SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.GPS_NOT_ACTIVE]: 'GPS not active',
      [SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.INCORRECT_POSITION]: 'Incorrect position',
      [SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.LATE_SHIPMENT]: 'Late shipment',
      [SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.ISSUES]: 'Issues',
      [SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.DRIVER_NO_RESPONSE]: 'Driver no response',
      [SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.REMINDER_ON_TIME_PICKUP]: 'Reminder on time pick up',
      [DA_SHIPMENT_ACTIVITY_LOG_STATUSES.PROBLEM.TPT_NO_RESPONSE]: 'TPT no response',
    },
  },
  shipment_activity_log_actor: {
    [ACTORS.SHIPPER]: 'S-TMS',
    [ACTORS.TRANSPORTER]: 'T-TMS / Transporter App',
    [ACTORS.INTERNAL]: 'TMS Admin',
    [ACTORS.BROKERAGE]: 'B-TMS',
    [ACTORS.DRIVER]: 'Driver App',
    [ACTORS.CANVASSER]: 'Canvasser App',
    [ACTORS.UNKNOWN]: 'unknown',
  },
  shipment_completion_date: 'Shipment Completion Date',
  shipment_date: 'Shipment Date',
  shipment_details: 'Shipment Details',
  shipment_document_edit_timestamp: 'Edit Timestamps',
  shipment_document_edit_timestamp_title: 'Edit Timestamps for {{do}}',
  shipment_document_rejection_status: {
    [SHIPMENT_DOCUMENT_REJECTION_REASONS.ADDRESS_UNCLEAR]: 'Destination address is unclear',
    [SHIPMENT_DOCUMENT_REJECTION_REASONS.CROPPED]: 'Photo is cropped',
    [SHIPMENT_DOCUMENT_REJECTION_REASONS.DARK]: 'Picture is too dark',
    [SHIPMENT_DOCUMENT_REJECTION_REASONS.OTHERS]: 'Others',
    [SHIPMENT_DOCUMENT_REJECTION_REASONS.UNCLEAR]: 'Picture is unclear',
  },
  shipment_document_type_map: {
    'claims-returns': 'Claims And Returns',
    'internal-pod': 'POD',
    'internal-pol': 'POL',
  },
  shipment_drop: 'Dropoff',
  shipment_fee: 'Shipment Fee',
  shipment_fee_plural: 'Shipment Fees',
  shipment_info: 'Shipment Information',
  shipment_info_short: 'Shipment Info',
  shipment_job_status_map_brokerage: {
    [JOB_STATUSES.PLANNED]: 'Planned',
    [JOB_STATUSES.WAITING_FOR_JOB_CONFIRMATION]: 'Waiting For Confirmation',
    [JOB_STATUSES.WAITING_FOR_CONFIRMATION]: 'POD Uploaded',
    [JOB_STATUSES.JOB_BIDDING]: 'Bidding',
    [JOB_STATUSES.JOB_UNASSIGNED]: 'Unassigned',
    [JOB_STATUSES.JOB_TRANSPORTER_CONFIRMED]: 'Transporter Confirmed',
    [JOB_STATUSES.ASSIGNED]: 'Assigned',
    [JOB_STATUSES.JOB_WAITING_FOR_SHIPPER_CONFIRMATION]: 'Waiting For Shipper Confirmation',
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Arrived At Destination',
  },
  shipment_no: 'Shipment Number',
  shipment_no_table: 'Shipment No.',
  shipment_number: 'Shipment Number',
  shipment_payment_type: 'Payment Type',
  shipment_pickup: 'Pickup {{type}}',
  shipment_point_type_map: {
    [SHIPMENT_POINT_TYPE.PICKUP]: 'Pickup',
    [SHIPMENT_POINT_TYPE.DROPOFF]: 'Dropoff',
  },
  shipment_price: 'Shipment Price',
  shipment_purchase_order: 'Shipment Purchase Order',
  shipment_quality: 'Shipment Quality',
  shipment_quantity: 'Shipment Quantity',
  shipment_quantity_unit: 'Shipment Quantity Unit',
  shipment_quantity_unit_map: {
    [SHIPMENT_QUANTITY_UNITS.KG]: 'Kg',
    [SHIPMENT_QUANTITY_UNITS.TON]: 'Ton',
    [SHIPMENT_QUANTITY_UNITS.CBM]: 'Cbm',
    [SHIPMENT_QUANTITY_UNITS.UNIT]: 'Unit',
    [SHIPMENT_QUANTITY_UNITS.BOX]: 'Box',
    [SHIPMENT_QUANTITY_UNITS.CARTON]: 'Carton',
    [SHIPMENT_QUANTITY_UNITS.PCS]: 'Pcs',
    [SHIPMENT_QUANTITY_UNITS.CASES]: 'Cases',
    [SHIPMENT_QUANTITY_UNITS.KOLI]: 'Koli',
    [SHIPMENT_QUANTITY_UNITS.DRUM]: 'Drum',
    [SHIPMENT_QUANTITY_UNITS.BAL]: 'Bal',
    [SHIPMENT_QUANTITY_UNITS.ROLL]: 'Roll',
    [SHIPMENT_QUANTITY_UNITS.BAG]: 'Bag',
    [SHIPMENT_QUANTITY_UNITS.BOTTLE]: 'Bottle',
    [SHIPMENT_QUANTITY_UNITS.ZAK]: 'Zak',
    [SHIPMENT_QUANTITY_UNITS.METER]: 'Meter',
  },
  shipment_review: 'Shipment Order Review',
  shipment_settings: 'Shipment Settings',
  shipment_status_map: {
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'At Destination',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'At Origin',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Assigned',
    [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'Behind Schedule',
    [SHIPMENT_STATUSES.BIDDING]: 'Bidding',
    [SHIPMENT_STATUSES.CANCELLED]: 'Cancelled',
    [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'Cancelled by Internal',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Expired',
    [SHIPMENT_STATUSES.DOCUMENT_UPLOADED]: 'Document Uploaded',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Finish Loading',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Finish Unloading',
    [SHIPMENT_STATUSES.JOB_BIDDING]: 'Bidding',
    [SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED]: 'Transporter Confirmed',
    [SHIPMENT_STATUSES.JOB_UNASSIGNED]: 'Unassigned',
    [SHIPMENT_STATUSES.LATE_TO_DESTINATION]: 'Late to Destination',
    [SHIPMENT_STATUSES.LATE_TO_ORIGIN]: 'Late to Origin',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Ongoing to Destination',
    [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'On Time',
    [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'Ongoing to Destination',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Ongoing to Origin',
    [SHIPMENT_STATUSES.PLANNED]: 'Planned',
    [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_DESTINATION_LATE]: 'Queue at Destination Late',
    [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_ORIGIN_LATE]: 'Queue at Origin Late',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_DESTINATION]: 'Queueing At Destination',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_ORIGIN]: 'Queueing At Origin',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'Queueing Overtime',
    [SHIPMENT_STATUSES.REASSIGNED_BY_BROKERAGE]: 'Reassigned',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Reassigned',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Rejected by vendor',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [SHIPMENT_STATUSES.START_LOADING]: 'Start Loading',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start Unloading',
    [SHIPMENT_STATUSES.UNASSIGNED]: 'Unassigned',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Unfulfilled by Internal',
    [SHIPMENT_STATUSES.UPCOMING]: 'Upcoming',
    [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'POD Uploaded',
    [SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION]: 'Waiting For Job Confirmation',
    [SHIPMENT_STATUSES.QUEUED]: 'Queued',
    [SHIPMENT_STATUSES.QUEUED_AND_BTMS_PLANNED]: 'Queued',
    [SHIPMENT_STATUSES.JOB_WAITING_FOR_SHIPPER_CONFIRMATION]: 'Waiting For Shipper Confirmation',
    [JOB_STATUSES.TRANSPORTER_CONFIRMED]: 'Transporter Confirmed',
    [SHIPPING_LINE_STATUS.VESSEL_BOOKED]: 'Vessel Booked',
    [SHIPPING_LINE_STATUS.DEPARTURE]: 'Departure',
    [SHIPPING_LINE_STATUS.ARRIVAL]: 'Arrival',
  },
  shipment_summary: 'Shipment Summary',
  shipment_tracking_code: 'Shipment Tracking Code',
  shipment_type: 'Shipment Type',
  shipment_vendor_assignment_type_description: {
    BROADCAST_CONTRACTED: 'Assign DO to all vendors in your contacts.',
    PRIVATE_BIDDING: 'Assign DO to only your vendor in a private marketplace.',
    SINGLE_CONTRACTED: 'Assign DO to a specific vendor of your choice.',
  },
  shipment_vendor_assignment_type_name: {
    BROADCAST_CONTRACTED: 'All Vendors',
    PRIVATE_BIDDING: 'Private Marketplace',
    SINGLE_CONTRACTED: 'Selected Vendors',
  },
  shipments: 'Shipments',
  shipments_30d: 'Shipments (30d)',
  shipper: 'Shipper',
  shipper_information: 'Shipper Information',
  shipper_group_information: 'Shipper Group Information',
  shipper_agreement_type: {
    EXCLUSIVE: 'Exclusive',
    NON_EXCLUSIVE: 'Non-exclusive',
  },
  shipper_approved: 'Shipper Approved',
  shipper_approved_date: 'Shipper Approve Date',
  shipper_cancellation_fee: 'Shipper Cancellation Fee',
  shipper_category: 'Shipper Category',
  shipper_company_address: 'Shipper Company Address',
  shipper_company_name: 'Shipper Company Name',
  shipper_detail: 'Shipper Detail',
  shipper_details: 'Shipper Details',
  shipper_fault: 'Shipper\'s Fault',
  shipper_invoice_activity_type: {
    [SHIPPER_INVOICE_ACTIVITY_TYPE.REMINDER_SENT]: 'Reminder Sent',
    [SHIPPER_INVOICE_ACTIVITY_TYPE.SHIPPER_PAID]: 'Shipper Paid',
    [SHIPPER_INVOICE_ACTIVITY_TYPE.PAYMENT_PLANNED]: 'Payment Planned',
    [SHIPPER_INVOICE_ACTIVITY_TYPE.LEGAL]: 'Legal',
    [SHIPPER_INVOICE_ACTIVITY_TYPE.RESTRUCTURING]: 'Restructuring',
    [SHIPPER_INVOICE_ACTIVITY_TYPE.INSTALLMENT]: 'Installment',
    [SHIPPER_INVOICE_ACTIVITY_TYPE.ON_HOLD]: 'On Hold',
    [SHIPPER_INVOICE_ACTIVITY_TYPE.DEPRECATED]: 'Deprecated',
  },
  shipper_list: 'Shipper List',
  shipper_location_operating_day_map: {
    [SHIPPER_LOCATION_OPERATING_DAY.MONDAY]: 'Monday',
    [SHIPPER_LOCATION_OPERATING_DAY.TUESDAY]: 'Tuesday',
    [SHIPPER_LOCATION_OPERATING_DAY.WEDNESDAY]: 'Wednesday',
    [SHIPPER_LOCATION_OPERATING_DAY.THURSDAY]: 'Thursday',
    [SHIPPER_LOCATION_OPERATING_DAY.FRIDAY]: 'Friday',
    [SHIPPER_LOCATION_OPERATING_DAY.SATURDAY]: 'Saturday',
    [SHIPPER_LOCATION_OPERATING_DAY.SUNDAY]: 'Sunday',
    [SHIPPER_LOCATION_OPERATING_DAY.WEEKDAY]: 'Working Day',
    [SHIPPER_LOCATION_OPERATING_DAY.WEEKEND]: 'Weekend',
    [SHIPPER_LOCATION_OPERATING_DAY.HOLIDAY]: 'National Holiday',
  },
  shipper_name: 'Shipper Name',
  shipper_name_required: 'Shipper Name is required',
  shipper_name_slash_company: 'Shipper / Company Name',
  shipper_note: 'Shipper Note',
  shipper_notes: 'Shipper Notes',
  shipper_overnight_fee: 'Shipper Overnight Fee',
  shipper_payment_reminders: '{{reminder}}',
  shipper_payments_progress: 'Shipper Payments Progress',
  shipper_phone: 'Shipper Phone',
  shipper_platform_access_type_map: {
    [USER_ACCESS_TYPE.ADMIN]: 'Admin Access',
    [USER_ACCESS_TYPE.FULL_ACCESS]: 'Admin Access',
    [USER_ACCESS_TYPE.FINANCE]: 'Finance',
    [USER_ACCESS_TYPE.VENDOR_MANAGEMENT]: 'Vendor Management',
    [USER_ACCESS_TYPE.TRANSPORT_PLANNER]: 'Transporter Planner',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Restricted Access',
    [USER_ACCESS_TYPE.SALES]: 'Sales',
    [USER_ACCESS_TYPE.SALES_SUPPORT]: 'Sales Support',
    [USER_ACCESS_TYPE.AR]: 'Account Receivable',
    [USER_ACCESS_TYPE.TRAFFIC_CONTROLLER]: 'Traffic Controller',
    [USER_ACCESS_TYPE.ACCOUNT_PAYABLE]: 'Account Payable',
    [USER_ACCESS_TYPE.DOCUMENT_COLLECTION]: 'Document Collection',
    [USER_ACCESS_TYPE.FINANCE_ADMIN]: 'Finance Admin',
    [FIRST_MILE_USER_ACCESS_TYPE.FREIGHT_FORWARDER]: 'Freight Forwarder',
    [FIRST_MILE_USER_ACCESS_TYPE.CONSIGNEE]: 'Consignee',
    [DANONE_USER_ACCESS_TYPE.DANONE_LOGISTIC]: 'Logistics',
    [DANONE_USER_ACCESS_TYPE.DANONE_PROCUREMENT]: 'Procurement',
    [USER_SALES_ROLE.SME_SALES_EXECUTIVE]: 'Sales Executive',
    [USER_SALES_ROLE.SME_TEAM_LEADER]: 'Team Leader',
    [USER_SALES_ROLE.SME_TELESALES]: 'Telesales',
    [USER_ACCESS_TYPE.LOGISTIC_PIC]: 'Logistic PIC',
    [USER_ACCESS_TYPE.MONITORING_PIC]: 'Monitoring PIC',
    [USER_ACCESS_TYPE.KARGO_SHIPPER_SALES]: 'Kargo Team',
    [USER_ACCESS_TYPE.SHIPPER_PLATFORM]: 'Shipper Platform',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Limited Access',
  },
  shipper_platform_link: 'Shipper Platform Link',
  shipper_price: 'Shipper Price',
  shipper_price_per_route: 'Shipper Pricing (Per Route)',
  shipper_price_summary: 'Shipper Pricing Summary',
  shipper_price_summary_label: {
    final_shipper_pricing: 'Final Shipper Pricing',
    min_quatity_to_be_paid: 'Min. Quantity to be paid',
    minimum_shipper_price: 'Minimum Shipper Price',
    multishipment_fees: 'Multishipment Fees',
    shipper_pricing: 'Shipper Pricing ({{unit}})',
  },
  shipper_pricing_details: 'Shipper Pricing Details',
  shipper_pricing_type: 'Shipper Pricing Type',
  shipper_profile: 'Shipper Profile',
  shipper_profile_activity_log: 'Shipper Profile Activity Log',
  shipper_received: 'Shipper Received',
  shipper_registration_id: 'Shipper Registration ID',
  shipper_rejected: 'Shipper Rejected',
  shipper_remarks: 'Remarks',
  shipper_status: 'Shipper Status',
  shipper_status_map: {
    [SHIPPER_STATUSES.CHURN]: 'Churn',
    [SHIPPER_STATUSES.INACTIVE]: 'Inactive',
    [SHIPPER_STATUSES.ACTIVE]: 'Active',
    [SHIPPER_STATUSES.PENDING_ONBOARDING]: 'Pending Onboarding',
    [SHIPPER_STATUSES.SUSPENDED]: 'Suspended',
    [SHIPPER_STATUSES.REJECTED]: 'Rejected',
    [SHIPPER_STATUSES.REGISTERED]: 'Registered',
    [GENERAL_ENTITY_STATUSES.DEACTIVATED]: 'Inactive',
  },
  shipper_timestamp_reference: 'Shipper Timestamp Reference',
  shipper_type: 'Shipper Type',
  shipper_types_map: {
    [SHIPPER_TYPES.A1]: 'A1',
    [SHIPPER_TYPES.A2]: 'A2',
    [SHIPPER_TYPES.A3]: 'A3',
  },
  shipper_group: 'Shipper Group',
  shipper_group_name: 'Shipper Group Name',
  shipper_group_name_required: 'Shipper Group is required',
  shipper_phone_number: 'Shipper Phone Number',
  shipping_instruction: 'Shipping Instruction',
  shipper_invoicing_group: 'Shipper Invoicing Group',
  shipping_line_note: 'Shipping Line Note',
  shipping_instruction_creation_date: 'SI Creation Date',
  terms_of_payment_starts: 'TOP Start At',
  terms_of_payments_period: 'TOP Period',
  product: 'Product',
  shippers: 'Shippers',
  shippers_team: 'Shipper\'s Team',
  show: 'Show',
  show_more: 'Show more',
  show_claims_in: 'Show Claims in',
  show_statistics: 'Show Shipment Statistics',
  show_claims_in_map: {
    [SHOW_CLAIMS_IN.POD]: 'POD',
    [SHOW_CLAIMS_IN.BERITA_ACARA]: 'Berita Acara',
    [SHOW_CLAIMS_IN.LOAD_ITEMS]: 'Load Items',
  },
  signature: 'Signature',
  signature_name: 'Name of person who signs',
  signature_title: 'Title of person who signs',
  signee_name: 'Signee Name',
  signee_phone: 'Signee Phone',
  signee_role: 'Signee Role',
  signed_invoice: 'Signed Invoice',
  sim: 'SIM',
  sim_card_number: 'SIM Card Number',
  sim_type: 'SIM Type',
  single_profile: 'Single Profile',
  single_shipment: 'Single Shipment',
  single_shipments: 'single shipments',
  single_sme_shipments: 'Single SME Shipments',
  mult_download: 'Mult. Download',
  multiple_sme_shipments: 'multiple SME shipments',
  siup: 'SIUP',
  'SIUP/SIUJPT': 'SIUP/SIUJPT',
  sixteen_to_thirty: '16-30 Days (Rp)',
  sixtyOne_to_ninty: '61-90 Days (Rp)',
  spkpp: 'SPKPP',
  skb: 'SKB',
  skb_or_pkp: 'SKB/SPPKP',
  skb_or_pkp_number: 'SKB/SPPKP Number',
  skip: 'Skip',
  skip_this_section: 'Skip this section',
  skip_this_step_and_keep_previous_data: 'Skip this Step and Keep Previous Data',
  skipped: 'Skipped',
  sku: 'SKU',
  sme_industry_map: {
    [SME_INDUSTRY.AUTOMOTIVE]: 'Automotif',
    [SME_INDUSTRY.RECYLING]: 'Bahan Daur Ulang',
    [SME_INDUSTRY.CONSTRUCTION]: 'Bangunan',
    [SME_INDUSTRY.COSMETIC]: 'Cosmetic/kecantikan',
    [SME_INDUSTRY.ELECTRONIC]: 'Elektronik',
    [SME_INDUSTRY.FMCG]: 'FMCG',
    [SME_INDUSTRY.FURNITURE]: 'Furniture',
    [SME_INDUSTRY.HOTEL]: 'Hotel',
    [SME_INDUSTRY.LUMBER]: 'Kayu',
    [SME_INDUSTRY.HEALTHCARE]: 'Kesehatan',
    [SME_INDUSTRY.LOGISTICS]: 'Logistic',
    [SME_INDUSTRY.CLOTHES]: 'Pakaian & Aksesoris',
    [SME_INDUSTRY.AGRICULTURE]: 'Pertanian',
    [SME_INDUSTRY.PERSONAL]: 'Pribadi',
    [SME_INDUSTRY.TEXTILE]: 'Textile & Garment',
  },
  sme_referral_disbursement: 'SME Referral Disbursement',
  sme_payment_status: {
    not_paid: 'Not Paid',
    paid: 'Paid',
  },
  sme_sales_status_map: {
    [USER_STATUSES.ACTIVE]: 'Active',
    [USER_STATUSES.REGISTERED]: 'Created',
    [USER_STATUSES.INACTIVE]: 'Inactive',
  },
  smes: 'SMEs',
  so_sto_number: 'SO/STO Number',
  soa_invoice: 'SoA Invoice',
  source_document: 'Document to be Uploaded',
  source_of_fund: 'Source of Fund',
  source_of_leads: 'Source of Leads',
  special_day_inclusion_type: {
    NATIONAL_HOLIDAYS: 'National Holidays',
    SUNDAYS: 'Sundays',
  },
  spm: 'SPM',
  sppkp: 'SPPKP',
  SPPKP: 'SPPKP',
  stage: 'Stage',
  start: 'Start',
  start_date: 'Start Date',
  start_time: 'Start Time',
  statement_of_account: 'Statement of Account',
  status: 'Status',
  status_change_timestamp: 'Status Change Timestamp',
  status_log: 'Status Log',
  stnk: 'STNK',
  stuffing_date: 'Stuffing Date',
  stuffing_date_1: 'Stuffing Date 1',
  stuffing_date_2: 'Stuffing Date 2',
  stay: 'Stay',
  sub_total: 'Sub Total',
  subject: 'Subject',
  submit: 'Submit',
  submitted_bid: 'Submitted Bid',
  subtotal: 'Subtotal',
  success: 'Success',
  suggested_pricing: 'Suggested Price',
  credit_limit: 'Credit Limit',
  suggestion: 'Suggestion',
  summary: 'Summary',
  sunday: 'Sunday',
  sunday_and_national_holidays: 'Sunday & National Holidays',
  surat_perintah_muat: 'Surat Perintah Muat',
  ssm: 'SSM',
  SSM: 'SSM',
  sst: 'SST',
  SST: 'SST',
  swift_payment: 'Fast Payment',
  tag: 'Tags',
  tags: 'Tags',
  tax: 'Tax',
  tax_added_partially: 'Tax Added Partially',
  tax_base: 'Tax Base',
  tax_calculation: 'Tax Calculation',
  tax_configuration: 'Tax Configuration',
  tax_details: 'Tax Details',
  tax_expiry_date: 'Tax Expiry Date',
  tax_options: 'Tax Options',
  tax_road: 'Tax Road',
  tax_types_of_payment_request_map: {
    vat: 'VAT',
    wht23: 'WHT23',
  },
  tax_types_payment_request_map: {
    [TAX_TYPES_OF_PAYMENT_REQUEST.WHT23]: 'WHT 23',
    [TAX_TYPES_OF_PAYMENT_REQUEST.WHT21]: 'WHT 21',
    [TAX_TYPES_OF_PAYMENT_REQUEST.WHT15]: 'WHT 15',
    [TAX_TYPES_OF_PAYMENT_REQUEST.VAT]: 'VAT',
  },
  tax_settings: 'Tax Settings',
  td: 'TD',
  team_leader: 'Team Leader',
  team_leader_title: 'Upload CSV to manage your sales team structure',
  telephone_no: 'Phone Number',
  telesales: 'Telesales',
  terms_of_payment: 'Terms of Payment',
  terms_of_payment_short: 'ToP',
  terms_of_payments: 'Terms of Payment (TOP)',
  terms_of_payments_description: 'number of days of TOP',
  things: 'Things',
  this_item: 'This item',
  these_items: 'These items',
  this_month: 'This Month',
  this_year: 'This Year',
  thirtyOne_to_sixty: '31-60 Days (Rp)',
  thumbnail_size: 'Thumbnail Size:',
  ticket_link: 'Ticket Link',
  tier_level: 'Tier Level',
  tila: 'TILA',
  time_elapsed: 'Time Elapsed',
  time_frame_map: {
    cutoff_time: 'Cutoff Time',
    latest_loading_time: 'Latest Loading Time',
    loading_time: 'Loading Time',
  },
  time_frame_setting: 'Time Frame Setting',
  time_frame_unfulfill: 'Time frame to unfulfill',
  timestamp: 'Timestamp',
  timestamp_type: 'Timestamp Type',
  timestamp_date: 'Timestamp Date',
  timestamp_reference: 'Timestamp Reference',
  timestamps: 'Timestamps',
  tin: 'NPWP',
  tip_scheme: 'TIP Scheme',
  to: 'To',
  tonnage: 'Tonnage',
  tonnage_kg: 'Tonnage (Kg)',
  tonnage_or_cubication: 'Capacity',
  top: 'TOP',
  top_days: 'ToP Days',
  total: 'Total',
  total_balance: 'Total Balance',
  total_aging_amount: 'Total Aging Amount',
  total_amount: 'Total Amount',
  total_incentive: 'Total Incentive',
  total_payment: 'Total Payment',
  total_price_adjustment: 'Total Price Adjustment',
  total_remaining: 'Total Remaining',
  total_request: 'Total Request',
  total_shipment: 'Total Shipment',
  total_shipment_fee: 'Total Shipment Fee',
  total_shipper_overnight_fee: 'Total Shipper Overnight Fee',
  total_stuffing_location: 'Total of Stuffing Location',
  tracking_link: 'Tracking Link',
  tracking_log: 'Tracking Log',
  tracking_no: 'Tracking No.',
  tracking_no_do1: 'DO 1 Tracking Number',
  tracking_no_do3: 'DO 3 Tracking Number',
  transaction_date: 'Transaction Date',
  transaction_id: 'Transaction ID',
  transporter_incentive_csv: 'Transporter Incentive CSV',
  transport_overnight_fee: 'Transport Overnight Fee',
  transporter_overnight_fee: 'Transporter Overnight Fee',
  transporter: 'Transporter',
  transporter_account_type_map: {
    [TRANSPORTER_ACCOUNT_TYPES.COMPANY]: 'Company',
    [TRANSPORTER_ACCOUNT_TYPES.PERSONAL]: 'Personal',
  },
  transporter_allocation: 'Transporter Allocation',
  transporter_cancellation_fee: 'Transporter Cancellation Fee',
  transporter_company_rejection_reason_map: {
    [TRANSPORTER_COMPANY_REJECTION_REASONS.DOCS_REUPLOAD_NEEDED]: 'Need to reupload docs',
    [TRANSPORTER_COMPANY_REJECTION_REASONS.OTHER_REASON]: 'Others',
  },
  transporter_company_status_options_map: {
    [TRANSPORTER_COMPANY_STATUSES.ACTIVE]: 'Approve',
    [TRANSPORTER_COMPANY_STATUSES.REJECTED]: 'Reject',
    [TRANSPORTER_COMPANY_STATUSES.SUSPENDED]: 'Suspended',
    [TRANSPORTER_COMPANY_STATUSES.BLOCKED]: 'Blocked',
  },
  transporter_address: 'Transporter Address',
  transporter_contract: 'Transporter Contract',
  transporter_details: 'Transporter Details',
  transporter_fulfillment_rate: 'Transporter Fulfillment Rate',
  transporter_invoice_number: 'Transporter Invoice Number',
  transporter_invoice_url: 'Transporter Invoice URL',
  transporter_incentives: 'Transporter Incentives',
  transporter_incentive_ledger: 'Transporter Incentive Ledger',
  transporter_ksuid: 'Transporter KSUID',
  transporter_level: 'Transporter Level',
  transporter_list: 'Transporter List',
  transporter_name: 'Transporter Name',
  transporter_notes: 'Transporter Notes',
  transporter_npwp: 'Transporter NPWP',
  transporter_payments_progress: 'Transporter Payments Progress',
  transporter_phone: 'Transporter Phone',
  transporter_pic: 'Transporter PIC',
  transporter_pr_info: 'Transporter PR Info',
  transporter_price: 'Transporter Price',
  transporter_price_type: 'Transporter Price ({{ type }})',
  transporter_pricing_details: 'Transporter Pricing Details',
  transporter_request: 'Transporter Request',
  transporter_requested_fee: 'Transporter Requested Fee',
  price_rate: 'Price Rate',
  transporter_status: 'Transporter Status',
  transporter_status_map: {
    [TRANSPORTER_COMPANY_STATUSES.PENDING]: 'Pending',
    [TRANSPORTER_COMPANY_STATUSES.REJECTED]: 'Rejected',
    [TRANSPORTER_COMPANY_STATUSES.ACTIVE]: 'Active',
    [TRANSPORTER_COMPANY_STATUSES.SUSPENDED]: 'Suspended',
    [TRANSPORTER_COMPANY_STATUSES.BLOCKED]: 'Blocked',
    [TRANSPORTER_COMPANY_STATUSES.DRAFT]: 'Draft',
  },
  transporter_verification_level: {
    [TRANSPORTER_VERIFICATION_LEVEL.VERIFIED]: 'Verified',
    [TRANSPORTER_VERIFICATION_LEVEL.TRUSTED]: 'Trusted',
    [TRANSPORTER_VERIFICATION_LEVEL.CREATED]: 'Unverified',
  },
  transporters: 'Transporters',
  travelling_expenses: 'Travelling Expenses',
  truck: 'Truck',
  truck_and_driver: 'Truck & Driver',
  truck_brand: 'Truck Brand',
  truck_chosen: 'Truck Chosen',
  truck_details: 'Truck Details',
  truck_doc_req: 'Truck Document Requirements',
  truck_document: 'Truck Document',
  truck_documents: 'Truck Documents',
  truck_information: 'Truck Information',
  truck_list: 'Truck List',
  truck_monitoring: 'Truck Monitoring',
  truck_name: 'Truck Name',
  truck_not_found: 'Truck not found',
  truck_request: 'Truck Request',
  truck_required: 'Truck Required',
  truck_requirement_gdl_option: {
    ACTIVE_GDL: 'Active GDL',
    EXPIRED_GDL: 'Expired GDL',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    PHOTOCOPY_OF_GDL: 'Photocopy of GDL',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_kir_option: {
    ACTIVE_KIR: 'Active KIR',
    EXPIRED_KIR: 'Expired KIR',
    EXPIRED_PROOF_OF_RENEWAL: 'Expired Proof of Renewal',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    VALID_PROOF_OF_RENEWAL: 'Valid Proof of Renewal',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_lembar_pajak_option: {
    ACTIVE_LEMBAR_PAJAK: 'Active Lembar Pajak',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    PROOF_OF_DOCUMENTS_OR_LICENSE_RENEWAL: 'Valid Proof of Renewal',
    PROOF_OF_EXPIRED_TAX: 'Proof of Expired Tax',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_puspakom_option: {
    ACTIVE_PUSPAKOM_INSPECTION: 'Active Puspakom Inspection',
    EXPIRED_PUSPAKOM_INSPECTION: 'Expired Puspakom Inspection',
    EXPIRED_PROOF_OF_RENEWAL: 'Expired Proof of Renewal',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    VALID_PROOF_OF_RENEWAL: 'Valid Proof of Renewal',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_sim_option: {
    ACTIVE_SIM: 'Active SIM',
    EXPIRED_SIM: 'Expired SIM',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    PHOTOCOPY_OF_SIM: 'Photocopy of SIM',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_stnk_option: {
    ACTIVE_STNK: 'Active STNK',
    EXPIRED_STNK: 'Expired STNK',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    TRAFFIC_TICKET_PHOTOCOPY: 'Traffic Ticket Photocopy',
    VALID_PROOF_OF_RENEWAL: 'Valid Proof of Renewal',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_tax_road_option: {
    ACTIVE_TAX_ROAD: 'Active Tax Road',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    PROOF_OF_DOCUMENTS_OR_LICENSE_RENEWAL: 'Valid Proof of Renewal',
    PROOF_OF_EXPIRED_TAX: 'Proof of Expired Tax',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_vehicle_registration_option: {
    ACTIVE_VEHICLE_REGISTRATION: 'Active Vehicle Registration',
    EXPIRED_VEHICLE_REGISTRATION: 'Expired Vehicle Registration',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    TRAFFIC_TICKET_PHOTOCOPY: 'Traffic Ticket Photocopy',
    VALID_PROOF_OF_RENEWAL: 'Valid Proof of Renewal',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirements: 'Truck Requirements',
  truck_safety_req: 'Truck Safety Requirements',
  truck_size: 'Truck Size',
  truck_status: 'Truck Status',
  truck_sub_type: 'Truck Sub Type',
  truck_type: 'Truck Type',
  trucking_do: 'Trucking DO',
  trucks: 'Trucks',
  turn_on: 'Turn On',
  turn_off: 'Turn Off',
  turned_on: 'turned on',
  turned_off: 'turned off',
  type: 'Type',
  type_of_access: 'Access Type',
  type_of_company: 'Type of Company',
  type_of_location: 'Type of Location',
  type_of_fees: 'Type of Fees',
  type_of_product: 'Type of Product',
  type_to_search_driver: 'Type 1 character to search driver',
  type_to_search_license_plate: 'Type 1 character to search license plate',
  uncheck_all: 'Uncheck all',
  unassigned: 'Unassigned',
  unequal_external_id_shipment: {
    info: 'Please put an equal amount of external ID and the number of Shipments, or leave it empty.',
    message: 'Number of external ID doesn\'t match the number of Shipments',
  },
  url_link: 'URL Link',
  unfulfill_do: 'Unfulfill DO',
  unfulfill_shipment: 'Unfulfill Shipment',
  unfulfilled_reason: 'Unfulfilled Reason',
  unit: 'Unit',
  unit_info: 'Unit Information',
  unload: 'Unloading',
  unload_passing_letter_title: 'Proof of Delivery',
  unloading_date: 'Unloading Date',
  unloading_fee: 'Unloading Fee',
  unloading_fee_not_registered: 'Unloading Fee isn’t registered in this Location',
  unloading_info: 'Unloading Info',
  unloading_time: 'Unloading Time',
  unpaid: 'Unpaid',
  update: 'Update',
  update_activity_log_map: 'Update Activity Log',
  update_appt_number_or_spm: 'Update Appt. Number/SPM',
  update_container_information: 'Update Container Information',
  update_location: 'Update Location',
  update_log: 'Update Log',
  update_status: 'Update Status',
  update_status_log: 'Update Status Log',
  updated: 'Updated',
  update_hold_status: 'Update Hold Status',
  updated_by: 'Updated By',
  updated_at: 'Updated At',
  updated_date: 'Updated Date',
  updater: 'Updater',
  updated_via: 'Updated Via',
  upload: 'Upload',
  upload_backdated_csv: 'Upload Backdate Shipment CSV file',
  upload_backdate_shipments: 'Upload Backdate Shipments',
  upload_csv: 'Upload CSV',
  upload_csv_instruction: 'Upload CSV Instruction',
  upload_document: 'Upload Document',
  upload_documents: 'Upload Documents',
  upload_driving_licence: 'Upload Driver License',
  upload_file: 'Upload File',
  upload_kir: 'Upload KIR',
  upload_load_passing_letter: 'Upload Proof of Loading',
  upload_location: 'Upload Location',
  upload_location_fee: 'Upload Location Fee',
  upload_message: 'To upload this file, please change',
  upload_multiple_assignments: 'Upload Multiple Assignments',
  upload_single_shipments_and_assign: 'Upload Single Shipments and Assign',
  upload_multiple_shipments: 'Upload Multiple Shipments',
  upload_npwp: 'Upload NPWP',
  upload_pod: 'Upload POD',
  upload_single_shipments: 'Upload Single Shipments',
  upload_single_sme_shipments: 'Upload Single SME Shipments',
  upload_multiple_sme_shipments: 'Upload Multiple SME Shipments',
  upload_podf_shipments: 'Upload PODF Shipments',
  upload_receiving_card: 'Upload Receiving Card',
  upload_sppkp: 'Upload SPPKP',
  upload_stnk: 'Upload STNK',
  upload_the_id_card: 'Upload KTP',
  upload_tila: 'Upload TILA',
  upload_unload_passing_letter: 'Upload Proof of Delivery',
  upload_your_bulk_assign_csv_file: 'Upload your Bulk Assign CSV file',
  upload_date: 'Upload Date',
  uploaded_from: 'Uploaded From',
  uploaded_time: 'Uploaded Time',
  use_driver_app: 'Use Driver App',
  use_for_timestamp: 'Use For Timestamp',
  use_goods_capacity: 'Use Goods Capacity',
  user: 'User',
  user_access_type_map: {
    [USER_ACCESS_TYPE.ADMIN]: 'Admin',
    [USER_ACCESS_TYPE.FULL_ACCESS]: 'Full Access',
    [USER_ACCESS_TYPE.FINANCE]: 'Finance',
    [USER_ACCESS_TYPE.VENDOR_MANAGEMENT]: 'Vendor Management',
    [USER_ACCESS_TYPE.TRANSPORT_PLANNER]: 'Transporter Planner',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Restricted Access',
    [USER_ACCESS_TYPE.SALES]: 'Sales',
    [USER_ACCESS_TYPE.SALES_SUPPORT]: 'Sales Support',
    [USER_ACCESS_TYPE.AR]: 'Account Receivable',
    [USER_ACCESS_TYPE.TRAFFIC_CONTROLLER]: 'Traffic Controller',
    [USER_ACCESS_TYPE.ACCOUNT_PAYABLE]: 'Account Payable',
    [USER_ACCESS_TYPE.DOCUMENT_COLLECTION]: 'Document Collection',
    [USER_ACCESS_TYPE.FINANCE_ADMIN]: 'Finance Admin',
    [USER_ACCESS_TYPE.RISK]: 'Risk',
    [FIRST_MILE_USER_ACCESS_TYPE.FREIGHT_FORWARDER]: 'Freight Forwarder',
    [FIRST_MILE_USER_ACCESS_TYPE.CONSIGNEE]: 'Consignee',
    [DANONE_USER_ACCESS_TYPE.DANONE_LOGISTIC]: 'Logistics',
    [DANONE_USER_ACCESS_TYPE.DANONE_PROCUREMENT]: 'Procurement',
    [USER_SALES_ROLE.SME_SALES_EXECUTIVE]: 'Sales Executive',
    [USER_SALES_ROLE.SME_TEAM_LEADER]: 'Team Leader',
    [USER_SALES_ROLE.SME_TELESALES]: 'Telesales',
    [USER_SALES_ROLE.SME_SALES_ADMIN]: 'Sales Admin',
    [USER_SALES_ROLE.SME_SALES_LEAD]: 'Sales Lead',
    [USER_ACCESS_TYPE.LOGISTIC_PIC]: 'Logistic PIC',
    [USER_ACCESS_TYPE.MONITORING_PIC]: 'Monitoring PIC',
    [USER_ACCESS_TYPE.KARGO_SHIPPER_SALES]: 'Kargo Shipper Sales',
    [USER_ACCESS_TYPE.SHIPPER_PLATFORM]: 'Shipper Platform',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Limited Access',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.ACCOUNT_PAYABLE]: 'Account Payable',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.AR]: 'Account Receivable',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.DOCUMENT_COLLECTION]: 'Document Collection',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.FINANCE]: 'Finance',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.FINANCE_ADMIN]: 'Finance Admin',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.SALES]: 'Sales',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.SALES_SUPPORT]: 'Sales Support',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.TRAFFIC_CONTROLLER]: 'Traffic Controller',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.TRANSPORT_PLANNER]: 'Transport Planner',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.VENDOR_MANAGEMENT]: 'Vendor Management',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.KARGO_SHIPPER_SALES]: 'Kargo Shipper Sales',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.TRANSPORTER_PRICING_MANAGER]: 'Transporter Pricing Manager',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.TRANSPORTER_PRICING_VIEWER]: 'Transporter Pricing Viewer',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.LEGAL]: 'Legal',
  },
  user_status_map: {
    [USER_STATUSES.ACTIVE]: 'Active',
    [USER_STATUSES.REGISTERED]: 'Registered',
    [USER_STATUSES.INACTIVE]: 'Inactive',
  },
  va_balance_remaining: 'VA Balance Remaining',
  value: 'Value',
  valid_until: 'Valid Until',
  vat: 'VAT',
  vat_percentage: 'VAT Percentage',
  vehicle_body_type: 'Body Type',
  vehicle_company_rejection_reason_map: {
    [VEHICLE_COMPANY_REJECTION_REASONS.DOCS_REUPLOAD_NEEDED]: 'Need to reupload docs',
    [VEHICLE_COMPANY_REJECTION_REASONS.OTHER_REASON]: 'Others',
  },
  vehicle_status_map: {
    ACTIVE: 'Active',
    DEACTIVATED: 'Deactivated',
    DRAFT: 'Draft',
    EXPIRED: 'Expired',
    PENDING: 'Pending',
    REGISTERED: 'Active',
    REJECTED: 'Rejected',
  },
  vehicle_verification_level_action_map: {
    [VEHICLE_STATUSES.ACTIVE]: 'Approve',
    [VEHICLE_STATUSES.REJECTED]: 'Reject',
  },
  vehicle: 'Vehicle',
  vehicle_type: 'Vehicle Type',
  vehicles: 'Vehicles',
  vehicle_details: 'Vehicle Details',
  vehicle_registration: 'Vehicle Registration',
  vendor: 'Vendor',
  vendor_address: 'Vendor Address',
  vendor_company_name: 'Vendor Company Name',
  vendor_detail: 'Vendor Detail',
  vendor_npwp: 'Vendor NPWP',
  vendor_registration_id: 'Vendor Registration ID',
  verification_code: 'Verification Code',
  verification_level: 'Verification Level',
  verification_status: 'Verification Status',
  verification_summary: 'Verification Summary',
  verify: 'Verify',
  verify_documents: 'Verify Documents',
  very_far: 'Very Far',
  very_near: 'Very Near',
  vessel_name: 'Vessel Name',
  view_changes: 'View Changes',
  view_delivery: 'View Delivery',
  view_file: 'View File',
  view_invoice: 'View Invoice',
  view_pod: 'View POD',
  view_shipment: 'View Shipment',
  view_shipment_details: 'View Shipment Details',
  view_si: 'View SI',
  view_shipping_line_invoice: {
    OCEAN_FREIGHT: 'View OF',
    THC_L: 'View THC-L',
    THC_D: 'View THC-D',
    LSS: 'View LSS',
    OTHER_FEE: 'View Additional Fee - {{desc}}',
  },
  view_spm: 'View SPM',
  view_truck_list: 'View Truck List',
  view_activity_log_map: 'View Activity Log',
  virtual_account: 'Virtual Account',
  virtual_account_list: 'Virtual Account List',
  waiting: 'Waiting',
  waiting_for_approval: 'Waiting For Approval',
  waiting_for_pod: 'Waiting for POD',
  waiting_for_received_date: 'Waiting for Received Date',
  warehouse: 'Warehouse',
  warehouse_contact: 'Warehouse Contact',
  warehouse_information: 'Warehouse Information',
  warehouse_name: 'Warehouse Name',
  warehouse_pic: 'Warehouse PIC',
  web_tracking_link: 'Web Tracking Link',
  web_tracking_template: 'Web Tracking Template',
  week: 'Week',
  weight: 'Weight',
  weight_factors_for_choosing_winners: 'Weight Factors for Choosing Winner',
  weight_tolerance: 'Weight Tolerance',
  weight_volume: 'Weight Volume',
  whatsapp: 'WhatsApp',
  wht_selection: 'WHT Selection',
  wht_selection_type: {
    [WHT_SELECTION_TYPE.NONE]: 'No WHT',
    [WHT_SELECTION_TYPE.WHT23]: 'WHT 23',
    [WHT_SELECTION_TYPE.WHT21]: 'WHT 21',
    [WHT_SELECTION_TYPE.WHT15]: 'WHT 15',
  },
  wht15: 'WHT 15',
  wht21: 'WHT 21',
  wht23: 'WHT 23',
  wht23_percentage: 'WHT 23 Percentage',
  wht21_percentage: 'WHT 21 Percentage',
  width: 'Width',
  winner_bid_price: 'Winner Bid Price',
  winner_chosen_successfully: 'Winner chosen successfully',
  work_order: 'Work Order',
  working_days: 'Working Days',
  wrong_goods: 'Wrong Goods',
  year_of_manufacturing: 'Year of Manufacturing',
  yes: 'Yes',
  yes_change: 'Yes, Change',
  yes_approve_invoice: 'Yes, Approve Invoice',
  yes_cancel: 'Yes, cancel',
  yes_cancel_request: 'Yes, Cancel Request',
  yes_deactivate: 'Yes, deactivate',
  yes_hold_request: 'Yes, Hold Request',
  yes_reject_invoice: 'Yes, Reject Invoice',
  yes_reject_request: 'Yes, Reject Request',
  yes_remove: 'Yes, Remove',
  yes_remove_and_cancel: 'Yes, Remove & Cancel',
  zendesk: 'Zendesk',
  zendesk_message: 'Zendesk ticket was created via B-TMS',
  user_access_type_description_map: {
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Access to see, edit everything but cannot manage other employees.',
  },
  document_type_map: {
    [DOCUMENT_TYPES.POD]: 'POD',
    [DOCUMENT_TYPES.POL]: 'POL',
    [DOCUMENT_TYPES.PROOF_OF_APPROVAL_LOWER_PRICE]: 'Proof of Approval',
    [DOCUMENT_TYPES.PROOF_OF_APPROVAL_ROUTE_OUTSIDE_RANGE]: 'Proof of Approval',
  },
  shipment_list: 'Shipment List',
  shipment_requirements: 'Shipment Requirements',
  shipment_notes: 'Shipment Notes',
  review_shipment: 'Review Shipment',
  edit_shipment_information: 'Edit Shipment Information',
  edit_shipment_requirements: 'Edit Shipment Requirements',
  edit_shipment_notes: 'Edit Shipment Notes',
  edit_information: 'Edit Information',
  new_shipment: 'New Shipment',
  new_podf_shipment: 'New PODF Shipment',
  edit_podf_shipment: 'Edit PODF Shipment',
  new_sme_shipment: 'New SME Shipment',
  edit_sme_shipment: 'Edit SME Shipment',
  shipment_review_pol: 'Review POL',
  shipment_review_pod: 'Review POD',
  shipment_activity_status_log_map: {
    [SHIPMENT_ACTIVITY_TYPES.ASSIGNED_ONGOING_TO_PICKUP]: 'In transit to origin',
    [SHIPMENT_ACTIVITY_TYPES.ONGOING_TO_PICKUP_ARRIVE_AT_PICKUP]: 'Queueing',
    [SHIPMENT_ACTIVITY_TYPES.ARRIVE_AT_PICKUP_START_LOADING]: 'Loading',
    [SHIPMENT_ACTIVITY_TYPES.START_LOADING_FINISH_LOADING]: 'Finish loading',
    [SHIPMENT_ACTIVITY_TYPES.FINISH_LOADING_ON_SHIPMENT]: 'In transit to destination',
    [SHIPMENT_ACTIVITY_TYPES.ON_SHIPMENT_ARRIVE_AT_DESTINATION]: 'Queueing',
    [SHIPMENT_ACTIVITY_TYPES.ARRIVE_AT_DESTINATION_START_UNLOADING]: 'Unloading',
  },
  reallocation_adjusment: 'Reallocation Adjusment',
  reallocate_shipment: 'Reallocate Shipment',
  reassign_shipment: 'Reassign Shipment',
  allocation_detail: 'Allocation Detail',
  edit_fee: 'Save Fee',
  reupload_npwp: 'Reupload NPWP',
  actual_overnight_loading_amount: 'Actual Overnight Loading Amount',
  actual_overnight_unloading_amount: 'Actual Overnight Unloading Amount',
  actual_shipment_feeactual_overnight_loading_amount_amount: 'Actual Shipment Fee Amount',
  actual_loading_amount: 'Actual Loading Amount',
  actual_unloading_amount: 'Actual Unloading Amount',
  actual_multi_pick_up_amount: 'Actual Multipickup Amount',
  actual_multi_drop_off_amount: 'Actual Multidropoff Amount',
  actual_quantity: 'Actual Quantity',
  allocation_shipment_manually: 'Allocate Shipment Manually',
  reupload_pkp: 'Reupload PKP',
  upload_pkp: 'Upload PKP',
  exclusive_shipment_info_desc: 'Shipments with exclusive agreement will only be shown to contracted transporters.',
  non_exclusive_shipment_info_desc:
    'Shipments with non-exclusive agreement will be shown to the rest of the marketplace if the last transporter tier doesn’t accept the shipment.',
  unloading_timestamp: 'Unloading Timestamp',
  finished_loading_timestamp: 'Finished Loading Timestamp',
  finished_unloading_timestamp: 'Finished Unloading Timestamp',
  actual_loading_fee: 'Actual Loading Fee',
  actual_unloading_fee: 'Actual Unloading Fee',
  loading_fee_info: 'Loading Fee Info',
  unloading_fee_info: 'Unloading Fee Info',
  shipment_queued: '{{doNumber}} successfully queued',
  check_shipment_queue: 'Check the shipment queue again',
  queue_ttms: 'There\'s a queued shipment from T-TMS. ',
  queue_error_title: 'Can\'t queue the shipment. Please check the shipment queue again!',
  queue_ongoing: '{{shipment}} is {{status}}. ',
  reallocation_error: 'This shipment cannot be reallocated',
  queue_eta_between: '{{nextDO}} has loading date in between the {{thisDO}}. ',
  queue_eta: 'ETA from destination to next origin {{distance}}  •  {{duration}}. ',
  allocation_type: 'Allocation Type',
  on_call: 'On Call',
  contractless_referral: 'Contractless Referral',
  whitelist_status: 'Whitelist Status',
  whitelist_status_placeholder: 'Select a whitelist status here',
  new_booking: 'New Booking',
  shipping_line_booking: 'Shipping Line Booking',
  booking_information: 'Booking Information',
  shipping_line_booking_date: 'Shipping Line Booking Date',
  shipping_line_name: 'Shipping Line Name',
  vessel_voyage: 'Vessel/Voyage',
  vessel: 'Vessel',
  voyage: 'Voyage',
  total_space: 'Total Space',
  use_one_ro: 'Use 1 RO Number & 1 RO Expired Date',
  ro_number: 'RO Number',
  ro_expired_date: 'RO Expired Date',
  ro_release_date: 'RO Release Date',
  upload_ro: 'Upload RO',
  pickup_empty_container: 'Pickup Empty Container',
  estimated_time_of_departure: 'ETD Port of Loading',
  estimated_time_of_arrival: 'ETA Port of Discharge',
  estimated_time_of_berthing: 'ETB Port of Discharge',
  shipping_line_booking_review: 'Shipping Line Booking Review',
  receive_order_document: 'Receive Order Document',
  review_booking: 'Review Booking',
  book_shipping_line: 'Book Shipping Line',
  add_virtual_account_button: 'Add Virtual Account',
  va_transaction: 'VA Transaction',
  va_account: 'VA Account',
  estimated_time_departure: 'Estimated Time of Departure',
  estimated_time_arrival: 'Estimated Time of Arrival',
  container_status: 'Container Status',
  container_number: 'Container Number',
  seal_number: 'Seal Number',
  finance_pic: 'Finance PIC',
  confirm_container: 'Confirm Container',
  confirm: 'Confirm',
  not_generated_yet: 'Not generated yet',
  upload_invoice: 'Upload Invoice',
  template_name: 'Template Name',
  add_template: 'Add Template',
  search_invoice_template: 'Search Invoice Template',
  number_of_columns: 'Number of Columns',
  instruction_creation_date: 'Instruction Creation Date',
  instruction_actual_date: 'Instruction Actual Date',
  invoice_columns_map: {
    [COLUMNS_FIELD_KEY.POD_NUMBER]: 'POD Number',
    [COLUMNS_FIELD_KEY.DO_NUMBER]: 'DO Number',
    [COLUMNS_FIELD_KEY.DESCRIPTION]: 'Description',
    [COLUMNS_FIELD_KEY.DESTINATION]: 'Destination',
    [COLUMNS_FIELD_KEY.LICENSE_PLATE]: 'License Plate',
    [COLUMNS_FIELD_KEY.CONTAINER_NUMBER]: 'Container Number',
    [COLUMNS_FIELD_KEY.LOADING_DATE]: 'Loading Date',
    [COLUMNS_FIELD_KEY.ORIGIN]: 'Origin',
    [COLUMNS_FIELD_KEY.TONNAGE_POD]: 'Tonnage/Volume (POD)',
    [COLUMNS_FIELD_KEY.TONNAGE_DO]: 'Tonnage/Volume (DO)',
    [COLUMNS_FIELD_KEY.TRUCK_TYPE]: 'Truck Type',
    [COLUMNS_FIELD_KEY.DRIVER_NAME]: 'Driver Name',
    [COLUMNS_FIELD_KEY.RATE]: 'Rate',
    [COLUMNS_FIELD_KEY.EXTERNAL_ID]: 'External ID',
    [COLUMNS_FIELD_KEY.FEE]: 'Fee Amount',
    [COLUMNS_FIELD_KEY.CUSTOM]: 'Custom Column',
    [undefined]: '',
  },
  add_column: 'Add Column',
  delete_column: 'Delete Column',
  template: 'Template',
  create: 'Create',
  invoice_sorting_field: 'Invoice Sorting',
  invoice_sorting_order: 'Sort Order',
  ascending: 'Ascending',
  descending: 'Descending',
  enter_template_name: 'Enter template name',
  select_field_to_sort_invoice: 'Select Field to sort Invoice',
  search_column_type: 'Search Column Type',
  delete_this_template: 'Delete this template?',
  yes_delete: 'Yes, Delete',
  template_name_is_required: 'Template name is required',
  sorting_is_required: 'Sorting is required',
  template_successfully_added: 'Template successfully added',
  template_successfully_edited: 'Template successfully edited',
  template_successfully_deleted: 'Template successfully deleted',
  si_invoice: 'Shipping Line Invoice',
  review_shipping_line_invoice: 'Review Shipping Line Invoice',
  ocean_freight: 'Ocean Freight',
  latest_update_by: 'Latest Update by',
  upload_date_time: 'Upload Date & Time',
  upload_date_with_time: 'Upload Date Time',
  invoice_ocean_freight_details: 'Invoice Ocean Freight Details',
  columns: 'Columns',
  fee_info: 'Fee Info',
  invoice_successfully_uploaded: 'Invoice successfully uploaded!',
  upload_bill_of_lading: 'Upload Bill of Lading',
  edit_bill_of_lading: 'Edit Bill of Lading',
  notice_of_arrival: 'Notice of Arrival',
  create_notice_of_arrival: 'Create Notice of Arrival',
  bill_of_lading_number: 'Bill of Lading Number',
  upload_bl: 'Upload BL',
  bill_of_lading_information: 'Bill of Lading Information',
  enter_bill_of_lading_number: 'Enter bill of lading number',
  bill_of_lading_uploaded: 'Bill of Lading successfully uploaded!',
  enter: 'Enter',
  create_draft: 'Create Draft',
  new_draft: 'New Draft',
  edit_draft: 'Edit Draft',
  draft_configuration: 'Draft Configuration',
  draft_details: 'Draft Details',
  copy_noa: 'Copy NOA',
  created: 'Created',
  sent: 'Sent',
  replied: 'Replied',
  dispute: 'Dispute',
  split: 'Split',
  updated_time: 'Updated Time',
  url_link_invalid: 'URL Link Invalid',
  proof_document: 'Proof Document',
  airway_file: 'Airway File',
  shipper_quotation: 'Shipper Quotation',
  quotation: 'Quotation',
  quotation_documents: 'Quotation Documents',
  quotation_type: 'Quotation Type',
  quotation_type_map: {
    [PRICE_QUOTATION_TYPES.SHIPPER_PRICE_QUOTATION_SIGNED_BY_BOTH]: 'Signed by Shipper and Kargo',
    [PRICE_QUOTATION_TYPES.SHIPPER_PRICE_QUOTATION_SIGNED_BY_KARGO]: 'Signed by Kargo',
  },
  invoice_activity_log_type: {
    create_brokerage_do_invoice: {
      INSERT: '{{invoiceType}} Generated',
    },
    update_brokerage_do_invoice: {
      INSERT: '{{invoiceType}} Edit',
      CONVERT: '{{invoiceType}} Generated',
    },
    change_brokerage_do_invoice_status: {
      INSERT: '{{invoiceType}} updated to {{status}}',
      REVERT: '{{invoiceType}} reverted to {{status}}',
      UPDATE: '{{status}} status updated',
      CANCELLED: '{{invoiceType}} Cancelled',
    },
    update_digital_invoice_status: {
      INSERT: '{{invoiceType}} updated to {{activity}}',
    },
  },
  payable_invoice_activity_log_type: {
    create_pbi_transporter_invoice: {
      INSERT: 'Invoice Created',
    },
    update_pbi_transporter_invoice: {
      INSERT: 'Invoice Edited',
    },
    submit_pbi_transporter_invoice: {
      INSERT: 'Invoice Submitted',
      REVISE: 'Invoice Revision Submitted',
    },
    revise_pbi_transporter_invoice: {
      INSERT: 'Invoice Revision Created',
    },
    submit_pbi_invoice_physical_document: {
      INSERT: 'Invoice Docs Sent',
      UPDATE: 'Invoice Docs Sent status is edited',
    },
    change_nft_pbi_do_invoice_status: {
      INSERT: 'Invoice {{status}}',
      UPDATE: 'Invoice updated to {{status}}',
      EDIT: 'Invoice {{status}} is edited',
      REJECT: 'Invoice {{status}}',
    },
    cancel_do_invoice_status: {
      INSERT: 'Invoice Docs Received Cancelled',
    },
    update_invoice_fee_amount: {
      INSERT: '{{feeType}} Adjusted',
    },
    change_nft_pbi_do_invoice_payment_status: {
      INSERT: 'Invoice {{status}}',
    },
  },
  updated_by_x_at_y: 'Updated by {{x}} at {{y}}',
  send_e_invoice_request: 'Send E-Invoice Request',
  warehouse_arrival_date: 'Warehouse Arrival Date',
  finished_unloading_date: 'Finished Unloading Date',
  container_number_voyage_do_number_or_license_plate: 'Container number, voyage, DO number, or license plate',
  excel: 'Excel',
  invoice_already_paid: 'Invoice Already Paid',
  invoice_has_no_payment: 'Invoice has no Payment',
  new_invoice_from_draft: 'New Invoice from Draft',
  pickup_location: 'Pickup Location',
  additional_description: 'Additional Description',
  enter_additional_description: 'Enter additional description',
  cancellation: 'Cancellation',
  send_proforma_through_email: 'Send Proforma through Email',
  request: 'Request',
  signer_email: 'Signer Email',
  download_document: 'Download Document',
  download_e_invoice: 'Download E-Invoice',
  e_invoice_request: 'E-Invoice Request',
  e_invoice_options: 'E-Invoice Options',
  e_invoice_configuration: 'E-Invoice Configuration',
  transporter_incentive_program: 'Transporter Incentive Program',
  minimum_margin: 'Minimum Margin',
  maximum_margin: 'Maximum Margin',
  rejection: 'Rejection',
  status_date_warning: 'Status Date must be chronological',
  manage_e_invoice: 'Manage E-Invoice',
  send_to_shipper: 'Send to Shipper',
  tip: 'TIP',
  re_request_e_invoice: 'Re-request E-Invoice',
  send_e_invoice_request_to_teken_aja: 'Send E-Invoice Request to TekenAja',
  taxable_item: 'Taxable Item',
  shipment_only: 'Shipment Only',
  shipment_and_overnight: 'Shipment & Overnight',
  shipment_overnight_storage_demurrage: 'Shipment, Overnight, Storage & Demurrage',
  total_overnight_loading_fee: 'Total Overnight Loading Fee',
  total_overnight_unloading_fee: 'Total Overnight Unloading Fee',
  total_storage_fee: 'Total Storage Fee',
  total_demurrage_fee: 'Total Demurrage Fee',
  fee_tax_configuration_map: {
    [PR_TAX_CONFIGURATION.SHIPMENT_FEES]: 'Shipment Only',
    [PR_TAX_CONFIGURATION.SHIPMENT_FEES_AND_OVERNIGHT_FEES]: 'Shipment & Overnight',
  },
  fee_new_tax_configuration_map: {
    [PR_NEW_TAX_CONFIGURATION.SHIPMENT_FEES]: 'Shipment Only',
    [PR_NEW_TAX_CONFIGURATION.SHIPMENT_FEES_OVERNIGHT_FEES_STORAGE_DEMURRAGE_FEES]: 'Shipment, Overnight, Storage & Demurrage',
  },
  total_price_with_tax_item: 'Total Price With Tax Item',
  target_tip: 'Target TIP',
  e_invoice: 'E-Invoice',
  expired: 'Expired',
  deleted: 'Deleted',
  pol_details: 'POL Details',
  pol_number: 'POL Number',
  shipper_email: 'Shipper Email',
  cc_to: 'CC To',
  placeholder_email: 'name@email.com',
  enter_email_subject: 'Enter Email Subject',
  enter_email_message: 'Enter Email Message',
  send_e_invoice_to_shipper: 'Send E-Invoice to Shipper',
  download_invoice: 'Download Invoice',
  e_invoice_issued: 'E-Invoice Issued',
  sent_date: 'Sent Date',
  signer: 'Signer',
  email_cc_to: 'Email CC to',
  deleted_by: 'Deleted By',
  digital_invoice_type: {
    STAMP: 'Stamp',
    SIGNATURE: 'Signature',
    MATERAI: 'Materai',
  },
  shipping_line: 'Shipping Line',
  containers_number: 'Container(s) Number',
  containers_details: 'Container(s) Details',
  container_details: 'Container Details',
  select_source_of_pod: 'Select source of POD that you want to use',
  custom_column_label: 'Custom Column Label',
  warehouse_coordinate: 'Warehouse Coordinate',
  uploading_coordinate: 'Uploading Coordinate',
  within_radius: 'Within Radius',
  requested_fee_info: 'Requested Fee Info',
  date_received: 'Date Received',
  date_created: 'Date Created',
  transporter_due_date: 'Transporter Due Date',
  faktur_pajak: 'Faktur Pajak',
  pbi_due_date: 'PBI Due Date',
  nft: 'NFT',
  pod_collection: 'POD Collection',
  system_requested_fee_info_shipment: 'System Requested Fee Info - Shipment Fee',
  docs_received: 'Docs Received',
  payable: 'Payable',
  contract_type_text: {
    [CONTRACT_TYPE.MID_MILE]: 'Mid Mile',
    [CONTRACT_TYPE.TRUCKING_DTD]: 'FM Trucking',
    [CONTRACT_TYPE.DOORING_DTD]: 'FM Dooring',
    [TRANSPORTER_PARTNERSHIP_CONTRACT.FIRST_MILE_TRUCKING]: 'FM Trucking',
  },
  file_format_pdf_jpeg: 'File format: PDF/JPEG',
  billing_details: 'Billing Details',
  hold_invoice: 'Hold Invoice',
  payment_summary: 'Payment Summary',
  stuffing: 'Stuffing',
  fm_trucking_price: 'FM Trucking Price',
  fm_dooring_price: 'FM Dooring Price',
  tdp: 'TDP',
  TDP: 'TDP',
  rechoose_file: 'Rechoose File',
  payable_invoice_type_map: {
    [PAYABLE_INVOICE_TYPE.SHIPMENT_FEE_CATEGORY]: 'Shipment Fee',
    [PAYABLE_INVOICE_TYPE.ADDITIONAL_FEE_CATEGORY]: 'Additional Fees',
  },
  payable_invoice_status_map: {
    [PAYABLE_INVOICE_STATUSES.APPROVED]: 'Digital Approved',
    [PAYABLE_INVOICE_STATUSES.CANCELLED]: 'Cancelled',
    [PAYABLE_INVOICE_STATUSES.CREATED]: 'Created',
    [PAYABLE_INVOICE_STATUSES.ON_HOLD]: 'On Hold',
    [PAYABLE_INVOICE_STATUSES.PAID]: 'Disbursed',
    [PAYABLE_INVOICE_STATUSES.PAYMENT_REQUESTED]: 'Request Sent',
    [PAYABLE_INVOICE_STATUSES.PHYSICALLY_APPROVED]: 'Docs Approved',
    [PAYABLE_INVOICE_STATUSES.PHYSICALLY_RECEIVED]: 'Docs Received',
    [PAYABLE_INVOICE_STATUSES.PHYSICALLY_SENT]: 'Docs Sent',
    [PAYABLE_INVOICE_STATUSES.REJECTED]: 'Rejected',
    [PAYABLE_INVOICE_STATUSES.REVISION_CREATED]: 'Revision Created',
    [PAYABLE_INVOICE_STATUSES.REVISION_SUBMITTED]: 'Revision Submitted',
    [PAYABLE_INVOICE_STATUSES.SUBMITTED]: 'Submitted',
  },
  payable_invoice_fee_types_map: {
    [undefined]: 'undefined',
    [PAYABLE_INVOICE_FEE_TYPES.SHIPMENT_FEE]: 'Shipment Fee',
    [PAYABLE_INVOICE_FEE_TYPES.ADDITIONAL_FEE]: 'Additional Fee',
    [PAYABLE_INVOICE_FEE_TYPES.OTHER_FEE]: 'Other Fee',
    [PAYABLE_INVOICE_FEE_TYPES.OTHER_LOCATION_FEE]: 'Other Location Fees',
    [PAYABLE_INVOICE_FEE_TYPES.LOADING_FEE]: 'Loading Fee',
    [PAYABLE_INVOICE_FEE_TYPES.UNLOADING_FEE]: 'Unloading Fee',
    [PAYABLE_INVOICE_FEE_TYPES.OVERNIGHT_LOADING_FEE]: 'Overnight Loading Fee',
    [PAYABLE_INVOICE_FEE_TYPES.OVERNIGHT_UNLOADING_FEE]: 'Overnight Unloading Fee',
  },
  go_to_review_page: 'Go to Review Page',
  payable_invoice_search_placeholder: 'Search shipment or invoice number',
  invoice_internal_notes: 'Invoice Internal Notes',
  enter_internal_notes: 'Enter Internal Notes',
  submitted_date: 'Submitted Date',
  received_details: 'Received Details',
  courier_type: 'Courier Type',
  airway_bill: 'Airway Bill',
  tracker_link: 'Tracker Link',
  create_pr: 'Create PR',
  first_mile_price: 'First Mile Price',
  destination_wh: 'Destination WH',
  rejection_notes: 'Rejection Notes',
  payable_invoice_on_hold_reason_map: {
    [ON_HOLD_REASONS.WAITING_FOR_CLAIM]: 'Waiting for Claim',
    [ON_HOLD_REASONS.WAITING_FOR_OVERNIGHT]: 'Waiting for Overnight',
    [ON_HOLD_REASONS.CHECKING_PROCESS]: 'Checking Process',
    [ON_HOLD_REASONS.WAITING_FOR_DOCUMENT]: 'Waiting for Document',
    [ON_HOLD_REASONS.OTHER]: 'Other',
  },
  on_hold_reason: 'On Hold Reason',
  on_hold_notes: 'On Hold Notes',
  see_sent_details: 'See Sent Details',
  cancel_documents_received: 'Cancel Documents Received',
  upload_received_documents_here: 'Upload Received Documents Here',
  yes_confirm: 'Yes, Confirm',
  invalid_date: 'Invalid Date',
  date_received_required: 'Date Received is required',
  due_date_required: 'Due Date is required',
  invalid_notes: 'Invalid Notes',
  file_required: 'File is required',
  received_by_kargo: 'Received by Kargo',
  sent_by_transporter: 'Sent by Transporter',
  date_submitted: 'Date Submitted',
  projects: 'Projects',
  project: 'Project',
  assigned: 'Assigned',
  are_your_sure_to: 'Are you sure to',
  manual_allocation: 'Manual Allocation',
  no_invoice_uploaded: 'No Inovice Uploaded',
  faktur_pajak_number: 'Faktur Pajak Number',
  enter_faktur_pajak_number: 'Enter Faktur Pajak Number',
  see_invoice_sent_details: 'See Invoice Sent Details',
  undo_status: 'Undo Status',
  undo_remove: 'Undo Remove',
  enter_notes: 'Enter Notes',
  invoice_fee_type: 'Invoice Fee Type',
  received_doc_notes: 'Received Doc Notes',
  received_physical_invoice_details: 'Received Phyisical Invoice Details',
  nft_invoice_details: 'NFT Invoice Details',
  transporter_invoice_attachment: 'Transporter Invoice Attachment',
  update_estimated_date: 'Update Estimated Date',
  update_shipment_date: 'Update Shipment Date',
  do_not_created_yet: 'DO not created yet',
  shipping_line_details: 'Shipping Line Details',
  btms_received_invoice: 'B-TMS Received Invoice',
  cancel_invoice_received: 'Cancel Invoice Received',
  please_enter_valid_number: 'Please enter valid number',
  supervisor: 'Supervisor',
  payment_date: 'Payment Date',
  select_payment_date: 'Select Payment Date',
  fee_is_not_part_of_the_invoice: 'Fee is not a part of the invoice',
  remove_from_marketplace: 'Remove from Marketplace',
  remove_all_containers: 'Remove All Containers',
  shipper_pricing: 'Shipper Pricing',
  no_container_number: 'No Container Number',
  total_fee_amount: 'Total Fee Amount',
  total_fee_selected: 'Total Fee Selected',
  adjustment_bad_debt: 'Adjustment Bad Debt',
  document_search_receiveable: {
    [DOCUMENT_SEARCH_TYPES.DO_NUMBER]: 'Shipment Number',
    [DOCUMENT_SEARCH_TYPES.CONTAINER_NUMBER]: 'Container Number',
    [DOCUMENT_SEARCH_TYPES.LICENSE_PLATE]: 'License Plate',
    [DOCUMENT_SEARCH_TYPES.TRUCKING_DO_NUMBER]: 'FM Trucking DO',
    [DOCUMENT_SEARCH_TYPES.DOORING_DO_NUMBER]: 'FM Dooring DO',
    [DOCUMENT_SEARCH_TYPES.EXTERNAL_ID]: 'External ID',
  },
  trucking_search: {
    [TRUCKING_SEARCH_TYPES.DO_NUMBER]: 'Shipment Number',
    [TRUCKING_SEARCH_TYPES.CONTAINER_NUMBER]: 'Container Number',
    [TRUCKING_SEARCH_TYPES.CONTAINER_DO_NUMBER]: 'DO-DTD',
    [TRUCKING_SEARCH_TYPES.LICENSE_PLATE]: 'License Plate',
    [TRUCKING_SEARCH_TYPES.APPOINTMENT_NUMBER]: 'Appt. Number / SPM',
    [TRUCKING_SEARCH_TYPES.ORDER_NUMBER]: 'Job / Order Number',
  },
  dooring_search: {
    [DOORING_SEARCH_TYPES.DO_NUMBER]: 'DO Number',
    [DOORING_SEARCH_TYPES.LICENSE_PLATE]: 'License Plate',
    [DOORING_SEARCH_TYPES.CONTAINER_NUMBER]: 'Container Number',
    [DOORING_SEARCH_TYPES.VESSEL_OR_VOYAGE_NAME]: 'Vessel / Voyage',
    [DOORING_SEARCH_TYPES.CONTAINER_DO_NUMBER]: 'Main DO',
  },
  to_be_processed_search: {
    [TO_BE_PROCESSED_SEARCH_TYPES.CONTAINER_DO_NUMBER]: 'DO-DTD',
    [TO_BE_PROCESSED_SEARCH_TYPES.CONTAINER_NUMBER]: 'Container Number',
  },
  vessel_booked_search: {
    [VESSEL_BOOKED_SEARCH_TYPES.DO_NUMBER]: 'Shipment Number',
    [VESSEL_BOOKED_SEARCH_TYPES.CONTAINER_NUMBER]: 'Container Number',
    [VESSEL_BOOKED_SEARCH_TYPES.CONTAINER_DO_NUMBER]: 'DO-DTD',
    [VESSEL_BOOKED_SEARCH_TYPES.VESSEL_OR_VOYAGE_NAME]: 'Vessel / Voyage',
    [VESSEL_BOOKED_SEARCH_TYPES.PO_NUMBER]: 'PO Number',
  },
  shipment_history_search: {
    [SHIPMENT_HISTORY_SEARCH_TYPES.DO_NUMBER]: 'Shipment Number',
    [SHIPMENT_HISTORY_SEARCH_TYPES.CONTAINER_NUMBER]: 'Container Number',
    [SHIPMENT_HISTORY_SEARCH_TYPES.CONTAINER_DO_NUMBER]: 'DO-DTD',
    [SHIPMENT_HISTORY_SEARCH_TYPES.VESSEL_OR_VOYAGE_NAME]: 'Vessel / Voyage',
    [SHIPMENT_HISTORY_SEARCH_TYPES.PO_NUMBER]: 'PO Number',
  },
  container_search: {
    [CONTAINER_SEARCH_TYPES.CONTAINER_DO_NUMBER]: 'DO-DTD',
    [CONTAINER_SEARCH_TYPES.CONTAINER_NUMBER]: 'Container Number',
    [CONTAINER_SEARCH_TYPES.PO_NUMBER]: 'PO Number',
  },
  po_search: {
    [PO_SEARCH_TYPES.CONTAINER_DO_NUMBER]: 'DO-DTD',
    [PO_SEARCH_TYPES.CONTAINER_NUMBER]: 'Container Number',
    [PO_SEARCH_TYPES.PO_NUMBER]: 'PO Number',
    [PO_SEARCH_TYPES.ORDER_NUMBER]: 'Job / Order Number',
  },
  other_documents: 'Other Documents',
  va_transaction_status_map: {
    [null]: '',
    [undefined]: '',
    [VA_TRANSACTION_STATUS.PARTIALLY_RECONCILED]: 'Partially Reconciled',
    [VA_TRANSACTION_STATUS.RECONCILED]: 'Reconciled',
    [VA_TRANSACTION_STATUS.SHIPPER_PAID]: 'Shipper Paid',
  },
  reconcile: 'Reconcile',
  reconciliation: 'Reconciliation',
  reconcile_va: 'Reconcile VA',
  reconciliation_status: 'Reconciliation Status',
  shipper_invoice: 'Shipper Invoice',
  non_invoice: 'Non-Invoice',
  num_invoice: 'No. Invoice',
  va_information: 'VA Information',
  va_amount: 'VA Amount',
  change_route_reason: 'Change Route Reason',
  supporting_document: 'Supporting Document',
  supporting_document_type: {
    [SUPPORTING_DOCUMENT_TYPES.PROOF_OF_ROUTES_CHANGES]: 'Proof of Route Changes',
  },
  total_balance_remaining: 'Total Balance Remaining',
  search_invoice_number: 'Search Invoice Number',
  record_payment: 'Record Payment',
  no_records: 'No Records',
  balance_distribution_summary: 'Balance Distribution Summary',
  distributed_amount: 'Distributed Amount',
  okay_got_it: 'Okay, got it!',
  remove_this_from_va: 'Remove this from the VA?',
  va_details: 'VA Details',
  yes_leave_page: 'Yes, Leave Page',
  yes_go_to_previous_page: 'Yes, Go To Previous Page',
  are_you_sure_leave: 'Are you sure you want to leave this page?',
  are_you_sure_go_to_previous: 'Are you sure you want to go to previous page?',
  surat_jalan: 'Surat Jalan',
  invalid_container: 'Invalid Container',
  bulk_action: 'Bulk Action',
  want_save_change: 'Do you want to save the changes?',
  please_choose_file: 'Please choose file to upload',
  shipper_delivery_order: 'Shipper Delivery Order',
  va_transaction_id: 'VA Transaction ID',
  invoice_do_number: 'Invoice / DO Number',
  requirement_settings: 'Requirement Settings',
  contract_price: 'Contract Price',
  shipper_name_on_si: 'Shipper Name on SI',
  ft_20: '20 FT',
  ft_40: '40 FT',
  per_20ft: 'per 20 FT',
  per_40ft: 'per 40 FT',
  mandatory: 'Mandatory',
  shipment_route: 'Shipment Route',
  shipper_confirmation_document: 'Shipper Confirmation Document',
  change_route_reason_map: {
    [CHANGE_ROUTE_REASON.SHIPPER_REQUEST]: 'Shipper Request',
    [CHANGE_ROUTE_REASON.DATA_CORRECTION]: 'Data Correction',
    [CHANGE_ROUTE_REASON.FULL_DESTINATION_WAREHOUSE]: 'Full Destination Warehouse',
  },
  warehouse_destination: 'Warehouse Destination',
  view_document: 'View Document',
  view_detail: 'View Detail',
  match: 'Match',
  not_match: 'Not Match',
  no_cancel: 'No, Cancel',
  no_stay_here: 'No, Stay Here',
  shipper_project: 'Shipper Project',
  job_or_order_number: 'Job or Order Number',
  bulk_upload: 'Bulk Upload',
  bulk_upload_transporter_pricing: 'Bulk Upload Transporter Pricing',
  previous: 'Previous',
  view_update: 'View Update',
  trusted: 'Trusted',
  shipping_requirement: 'Shipping Requirement',
  new_price: 'New Price',
  first: 'First',
  prev: 'Prev',
  page: 'Page',
  last_1_month: 'Last 1 Month',
  active_balance: 'Active Balance',
  transaction_history: 'Transaction History',
  withdrawal_request: 'Withdrawal Request',
  saldo_kargo: 'Kantong Kargo',
  enabled: 'Enabled',
  disabled: 'Disabled',
  enable: 'Enable',
  disable: 'Disable',
  transaction_number: 'Transaction Number',
  bank_destination: 'Bank Destination',
  entry_category_map: {
    [TRANSPORTER_KARGO_WALLET_LEDGER_CATEGORY.MONEY_IN]: 'Money In',
    [TRANSPORTER_KARGO_WALLET_LEDGER_CATEGORY.MONEY_OUT]: 'Money Out',
  },
  kargo_wallet_ledger_status_map: {
    [TRANSPORTER_KARGO_WALLET_LEDGER_STATUS.DISBURSED]: 'Disbursed',
    [TRANSPORTER_KARGO_WALLET_LEDGER_STATUS.ADDED_TO_WALLET]: 'Added To Wallet',
    [TRANSPORTER_KARGO_WALLET_LEDGER_STATUS.MONEY_IN_REVERTED]: 'Cancelled',
    [TRANSPORTER_KARGO_WALLET_LEDGER_STATUS.MONEY_OUT_CANCELLED]: 'Withdrawal Cancelled',
  },
  no_record: 'No Record',
  transaction_type: 'Transaction Type',
  transaction_status: 'Transaction Status',
  wallet_ledger_search_type: {
    [TRANSPORTER_KARGO_WALLET_LEDGER_SEARCH_TYPE.BANK_DESTINATION_NUMBER]: 'Bank Destination Number',
    [TRANSPORTER_KARGO_WALLET_LEDGER_SEARCH_TYPE.CONTAINER_NUMBER]: 'Container Number',
    [TRANSPORTER_KARGO_WALLET_LEDGER_SEARCH_TYPE.DO_NUMBER]: 'DO Number',
    [TRANSPORTER_KARGO_WALLET_LEDGER_SEARCH_TYPE.INVOICE_NUMBER]: 'Invoice Number',
    [TRANSPORTER_KARGO_WALLET_LEDGER_SEARCH_TYPE.PR_NUMBER]: 'PR Number',
    [TRANSPORTER_KARGO_WALLET_LEDGER_SEARCH_TYPE.TRANSACTION_NUMBER]: 'Transaction Number',
  },
  wet_signed_invoice: 'Wet Signed Invoice',
  force_withdrawal: 'Force Withdrawal',
  withdrawal_notes: 'Withdrawal Notes',
  yes_proceed: 'Yes, Proceed',
  reason_for_cancellation: 'Reason for Cancellation',
  on_site_payment_type: {
    [ON_SITE_PAYMENT_TYPE.PAID_BY_KARGO]: 'Paid by Kargo',
    [ON_SITE_PAYMENT_TYPE.PAID_BY_TRANSPORTER]: 'Paid by Transporter',
  },
  kargo_vendor_deeplink: 'Kargo Vendor Deeplink',
  open_route_tracing: 'Open Route Tracing',
  copy_route_tracing_link: 'Copy Route Tracing Link',
  disbursement_method_map: {
    [DISBURSEMENT_METHOD.KARGO]: 'Kargo',
    [DISBURSEMENT_METHOD.KARGO_MODALKU]: 'Modalku',
  },
  schedule_disburse: 'Schedule Disburse',
  not_active: 'Not Active',
  disburse: 'Disburse',
  wallet: 'Wallet',
  funding_source: 'Funding Source',
  invoice_financing_docs: 'Invoice Financing Docs',
  not_yet_available: 'Not Yet Available',
  change_to_kargo_payment_method: 'Change to Kargo Payment Method',
  total_paid_by_kargo: 'Total Paid by Kargo',
  total_invoice_financing: 'Total Invoice Financing',
  amount_without_tax: 'Amount Without Tax',
  paid_by_invoice_financing: 'Paid by Invoice Financing',
  paid_by_kargo: 'Paid by Kargo',
  show_detail: 'Show Detail',
  hide_detail: 'Hide Detail',
  fast_transfer_deduction: 'Fast Transfer Deduction',
  final_payment: 'Final Payment',
  tax_invoice_number: 'Tax Invoice Number',
  fee_detail: 'Fee Detail',
  manual_selection: 'Manual Selection',
  bulk_list: 'Bulk List',
  job_id_list: 'Job ID List',
  no_document_uploaded_yet: 'No Document Uploaded Yet',
  check_list: 'Check List',
  download_reviewed_shipment_list: 'Download Reviewed Shipment List',
  checking_job_list_id_on: 'Checking Job List ID on',
  continue_anyway: 'Continue Anyway',
  tax_base_dpp: 'Tax Base (DPP)',
  overnight_loading: 'Overnight Loading',
  overnight_unloading: 'Overnight Unloading',
  paid_vat: 'Paid VAT',
  paid_wht23: 'Paid WHT 23',
  vat_amount: 'VAT Amount',
  wht23_amount: 'WHT 23 Amount',
  cancel_checking: 'Cancel Checking',
  bulk_fee_adjustment: 'Bulk Fee Adjustment',
  download_bulk_fee_adjustment_template: 'Download Bulk Fee Adjustment Template',
  ignore: 'Ignore',
  requested: 'Requested',
  cancellation_invoice_reason: {
    [PAYABLE_INVOICE_CANCELLATION_REASON.NEED_REVISION]: 'Invoice details need revision',
    [PAYABLE_INVOICE_CANCELLATION_REASON.INCOMPLETE]: 'Attachments are incomplete',
    [PAYABLE_INVOICE_CANCELLATION_REASON.OTHER_REASON]: 'Other Reasons',
  },
  cancel_invoice_alert: 'This invoice will be canceled and cannot be reactivated',
  cancel_reason: 'Cancellation Reason',
  cancel_invoice_failed: 'Failed to cancel invoice',
  cancel_invoice_success: 'Invoice cancelled successfully',
  do_entity: 'DO Entity',
  start_pickup_date: 'Start Pickup Date',
  end_pickup_date: 'End Pickup Date',
  fee_categories: 'Fee Categories',
  add_documents: 'Add Documents',
  additional_documents: 'Additional Documents',
  additional_documents_list: 'Additional Documents List',
  checking_data_on: 'Checking data on',
};
