import { FILE_SIZES } from '~/Configurations/constants';
import { PHYSICAL_DOCUMENT_STATUS } from '../Shipment/Utilities/constants';

export const REQUIRED_DOCUMENT_ACTION = {
  DELETE_FILES: 'DELETE_FILES',
  REARRANGE_POSITION: 'REARRANGE_POSITION',
  REJECT_FILES: 'REJECT_FILES',
  MANAGE_SINGLE_FILE: 'MANAGE_SINGLE_FILE',
};

export const STATE_MACHINE_ACTION = {
  ...REQUIRED_DOCUMENT_ACTION,
  FILE_LIST_SET_PREVIEWED_FILE_INDEX: 'FILE_LIST_SET_PREVIEWED_FILE_INDEX',
  FILE_LIST_CHANGE_VIEW_MODE: 'FILE_LIST_CHANGE_VIEW_MODE',
  RIGHT_SIDEBAR_BACK: 'RIGHT_SIDEBAR_BACK',
  SHOW_PODC_SIDEBAR: 'SHOW_PODC_SIDEBAR',
};

export const DOCUMENT_RETURN_STATUS_DEFAULT_STATE = {
  digitalDocumentStatus: null,
  physicalDocumentStatus: PHYSICAL_DOCUMENT_STATUS.NOT_RECEIVED,
};

export const DEFAULT_FILE_SIZES = {
  ORIGINAL: '0',
  LOW: '0',
  MEDIUM: '0',
};

export const DOCUMENT_TYPE_INDEX_DEFAULT_STATE = {
  typeIndex: 0,
  subTypeIndex: 0,
};

export const DOCUMENT_REJECTION_REASON = {
  DOCUMENT_IS_INCOMPLETE: 'DOCUMENT_IS_INCOMPLETE',
  FILE_PROBLEM: 'FILE_PROBLEM',
};

export const FILE_REJECTION_REASON = {
  DOCUMENT_IS_BLURRY: 'DOCUMENT_IS_BLURRY',
  DOCUMENT_IS_CROPPED: 'DOCUMENT_IS_CROPPED',
  WRONG_DOCUMENT: 'WRONG_DOCUMENT',
};

export const VIEW_MODE = {
  THUMBNAIL: 'THUMBNAIL',
  EMPTY: 'EMPTY',
  FULL_IMAGE: 'FULL_IMAGE',
  LOADING: 'LOADING',
};

export const HEADER_MODE = {
  DOCUMENT_ACTIONS: 'DOCUMENT_ACTIONS',
  DOCUMENT_NAME: 'DOCUMENT_NAME',
};

export const PROOF_OF_DELIVERY_DOCUMENT_TYPE = 'PROOF_OF_DELIVERY';

export const JSPDF_IMAGE_COMPRESSION_MAPPING = {
  [FILE_SIZES.ORIGINAL]: 'NONE',
  [FILE_SIZES.MEDIUM]: 'FAST',
  [FILE_SIZES.LOW]: 'MEDIUM',
};

export const DOWNLOADED_DOCUMENT_PAD_TOP = 10;
export const DOWNLOADED_DOCUMENT_PAD_LEFT = 10;
export const DOWNLOADED_DOCUMENT_PAPER_SIZE = 'a4';
export const DOWNLOADED_DOCUMENT_PAPER_ORIENTATION = 'portrait';
