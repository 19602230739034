// import { DO_INVOICE_FEE_TYPES } from '~/Configurations/constants';

export const DOORING_FEE_TYPE = {
  DOORING_LOADING_FEE: 'DOORING_LOADING_FEE',
  DOORING_UNLOADING_FEE: 'DOORING_UNLOADING_FEE',
  DOORING_OVERNIGHT_LOADING_FEE: 'DOORING_OVERNIGHT_LOADING_FEE',
  DOORING_OVERNIGHT_UNLOADING_FEE: 'DOORING_OVERNIGHT_UNLOADING_FEE',
  DOORING_MULTIPICKUP_FEE: 'DOORING_MULTIPICKUP_FEE',
  DOORING_MULTIDROPOFF_FEE: 'DOORING_MULTIDROPOFF_FEE',
  DOORING_CANCELLATION_FEE: 'DOORING_CANCELLATION_FEE',
  DOORING_OTHER_FEE: 'DOORING_OTHER_FEE',
  DOORING_OTHER_LOCATION_FEE: 'DOORING_OTHER_LOCATION_FEE',
  STORAGE_FEE: 'STORAGE_FEE',
  DEMURRAGE_FEE: 'DEMURRAGE_FEE',
  THC_LOLO: 'THC_LOLO',
};

export const TRUCKING_FEE_TYPE = {
  TRUCKING_LOADING_FEE: 'TRUCKING_LOADING_FEE',
  TRUCKING_UNLOADING_FEE: 'TRUCKING_UNLOADING_FEE',
  TRUCKING_OVERNIGHT_LOADING_FEE: 'TRUCKING_OVERNIGHT_LOADING_FEE',
  TRUCKING_OVERNIGHT_UNLOADING_FEE: 'TRUCKING_OVERNIGHT_UNLOADING_FEE',
  TRUCKING_MULTIPICKUP_FEE: 'TRUCKING_MULTIPICKUP_FEE',
  TRUCKING_MULTIDROPOFF_FEE: 'TRUCKING_MULTIDROPOFF_FEE',
  TRUCKING_CANCELLATION_FEE: 'TRUCKING_CANCELLATION_FEE',
  TRUCKING_OTHER_FEE: 'TRUCKING_OTHER_FEE',
  TRUCKING_OTHER_LOCATION_FEE: 'TRUCKING_OTHER_LOCATION_FEE',
};

export const DTD_SAVED_INVOICE_FEE_TYPE = {
  SHIPMENT_FEE: 'SHIPMENT_FEE',
  ...DOORING_FEE_TYPE,
  ...TRUCKING_FEE_TYPE,
};

export const DTD_TRANSLATED_FEE_TYPE = {
  [DOORING_FEE_TYPE.DOORING_LOADING_FEE]: 'LOADING_FEE',
  [DOORING_FEE_TYPE.DOORING_UNLOADING_FEE]: 'UNLOADING_FEE',
  [DOORING_FEE_TYPE.DOORING_OVERNIGHT_LOADING_FEE]: 'OVERNIGHT_LOADING_FEE',
  [DOORING_FEE_TYPE.DOORING_OVERNIGHT_UNLOADING_FEE]: 'OVERNIGHT_UNLOADING_FEE',
  [DOORING_FEE_TYPE.DOORING_MULTIPICKUP_FEE]: 'MULTIPICKUP_FEE',
  [DOORING_FEE_TYPE.DOORING_MULTIDROPOFF_FEE]: 'MULTIDROPOFF_FEE',
  [DOORING_FEE_TYPE.DOORING_CANCELLATION_FEE]: 'CANCELLATION_FEE',
  [DOORING_FEE_TYPE.DOORING_OTHER_FEE]: 'OTHER_FEE',
  [DOORING_FEE_TYPE.DOORING_OTHER_LOCATION_FEE]: 'OTHER_LOCATION_FEE',
  [DOORING_FEE_TYPE.STORAGE_FEE]: 'STORAGE_FEE',
  [DOORING_FEE_TYPE.DEMURRAGE_FEE]: 'DEMURRAGE_FEE',
  [DOORING_FEE_TYPE.THC_LOLO]: 'THC_LOLO',
  [TRUCKING_FEE_TYPE.TRUCKING_LOADING_FEE]: 'LOADING_FEE',
  [TRUCKING_FEE_TYPE.TRUCKING_UNLOADING_FEE]: 'UNLOADING_FEE',
  [TRUCKING_FEE_TYPE.TRUCKING_OVERNIGHT_LOADING_FEE]: 'OVERNIGHT_LOADING_FEE',
  [TRUCKING_FEE_TYPE.TRUCKING_OVERNIGHT_UNLOADING_FEE]: 'OVERNIGHT_UNLOADING_FEE',
  [TRUCKING_FEE_TYPE.TRUCKING_MULTIPICKUP_FEE]: 'MULTIPICKUP_FEE',
  [TRUCKING_FEE_TYPE.TRUCKING_MULTIDROPOFF_FEE]: 'MULTIDROPOFF_FEE',
  [TRUCKING_FEE_TYPE.TRUCKING_CANCELLATION_FEE]: 'CANCELLATION_FEE',
  [TRUCKING_FEE_TYPE.TRUCKING_OTHER_FEE]: 'OTHER_FEE',
  [TRUCKING_FEE_TYPE.TRUCKING_OTHER_LOCATION_FEE]: 'OTHER_LOCATION_FEE',
};

export const DTD_TYPE = {
  DOORING_AGENT_DTD: 'DOORING_AGENT_DTD',
  TRUCKING_DTD: 'TRUCKING_DTD',
};

export const DTD_INVOICE_FEE_TYPES = [
  {
    label: 'Container DO',
    groupName: 'CONTAINER_DO',
    options: {
      SHIPMENT_FEE: 'SHIPMENT_FEE',
      SHIPMENT_FEE_ADJUSTMENT: 'SHIPMENT_FEE_ADJUSTMENT',
    },
  },
  {
    label: 'Trucking DO',
    groupName: 'TRUCKING_DO',
    options: {
      ...TRUCKING_FEE_TYPE,
    },
    type: DTD_TYPE.TRUCKING_DTD,
  },
  {
    label: 'Dooring DO',
    groupName: 'DOORING_DO',
    options: {
      ...DOORING_FEE_TYPE,
    },
    type: DTD_TYPE.DOORING_AGENT_DTD,
  },
];

export const dtdMultiPickupDropoff = [
  DTD_SAVED_INVOICE_FEE_TYPE.DOORING_MULTIPICKUP_FEE,
  DTD_SAVED_INVOICE_FEE_TYPE.DOORING_MULTIDROPOFF_FEE,
  DTD_SAVED_INVOICE_FEE_TYPE.TRUCKING_MULTIPICKUP_FEE,
  DTD_SAVED_INVOICE_FEE_TYPE.TRUCKING_MULTIDROPOFF_FEE,
];

export const fileType = {
  filepreview: 'filepreview',
  modal: 'modal',
};

export const multiPickupDropoffDTDConverter = {
  TRUCKING_DTD_MULTIPICKUP_FEE: 'TRUCKING_MULTIPICKUP_FEE',
  TRUCKING_DTD_MULTIDROPOFF_FEE: 'TRUCKING_MULTIDROPOFF_FEE',
  DOORING_AGENT_DTD_MULTIPICKUP_FEE: 'DOORING_MULTIPICKUP_FEE',
  DOORING_AGENT_DTD_MULTIDROPOFF_FEE: 'DOORING_MULTIDROPOFF_FEE',
};

export const multiNaming = {
  MULTIDROPOFF_FEE: 'Biaya Multi Drop Off',
  MULTIPICKUP_FEE: 'Biaya Pengambilan Banyak',
};

export const multiDtdNaming = {
  DOORING_MULTIDROPOFF_FEE: 'Biaya Multi Drop Off (Dooring)',
  DOORING_MULTIPICKUP_FEE: 'Biaya Pengambilan Banyak (Dooring)',
  TRUCKING_MULTIDROPOFF_FEE: 'Biaya Multi Drop Off (Trucking)',
  TRUCKING_MULTIPICKUP_FEE: 'Biaya Pengambilan Banyak (Trucking)',
};
