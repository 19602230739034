/* eslint-disable max-len */

// https://github.com/kargotech/backend/blob/6c4d90eb817049fc727aa6bdfab41ce9f63f539d/apps/payment_service/lib/payment_service/utils/constants/digital_document_constants.ex#L7
export const MATERAI_DOCUMENT_STATUS = {
  DOCUMENT_EMETERAI_REQUESTED: 'DOCUMENT_EMETERAI_REQUESTED',
  DOCUMENT_METERAI_STAMPED: 'DOCUMENT_METERAI_STAMPED',
};

export const E_MATERAI_FEE = 10000;

export const PAYMENT_REQUEST_FEE_TYPE = {
  MATERAI_FEE: 'MATERAI_FEE',
  QUICK_PAYMENT_DEDUCTION_FEE: 'QUICK_PAYMENT_DEDUCTION_FEE',
  SERVICE_FEE: 'SERVICE_FEE',
};
