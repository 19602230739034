export const DATE_FORMAT = 'DD MMM YYYY';
export const DATE_TIME_FORMAT = 'DD MMM YYYY, HH:mm';
export const TIME_FORMAT = `HH:mm`;

export const QUOTATION_STATUS = {
  PENDING: 'PENDING',
  ON_HOLD: 'ON_HOLD',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  SIGNED: 'SIGNED',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
};

// New status added in https://kargox.atlassian.net/browse/FEX-1353
export const QUOTATION_STATUS_V2 = {
  PENDING: 'PENDING',
  LEGAL_APPROVED: 'LEGAL_APPROVED',
  LEGAL_ON_HOLD: 'LEGAL_ON_HOLD',
  LEGAL_REJECTED: 'LEGAL_REJECTED',
  SALES_LEAD_APPROVED: 'SALES_LEAD_APPROVED',
  SALES_LEAD_REJECTED: 'SALES_LEAD_REJECTED',
  APPROVED: 'APPROVED',
  SIGNED: 'SIGNED',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
};

export const QUOTATION_STATUS_COLOR_SCHEME = {
  [QUOTATION_STATUS.PENDING]: 'warning',
  [QUOTATION_STATUS.ON_HOLD]: 'darkGrey',
  [QUOTATION_STATUS.APPROVED]: 'success',
  [QUOTATION_STATUS.REJECTED]: 'danger',
  [QUOTATION_STATUS.SIGNED]: 'success',
  [QUOTATION_STATUS.CANCELLED_BY_INTERNAL]: 'danger',
  [QUOTATION_STATUS.CANCELLED_BY_SHIPPER]: 'danger',

  // New status added in https://kargox.atlassian.net/browse/FEX-1353
  [QUOTATION_STATUS_V2.PENDING]: 'warning',
  [QUOTATION_STATUS_V2.LEGAL_APPROVED]: 'warning',
  [QUOTATION_STATUS_V2.LEGAL_ON_HOLD]: 'darkGrey',
  [QUOTATION_STATUS_V2.LEGAL_REJECTED]: 'danger',
  [QUOTATION_STATUS_V2.SALES_LEAD_APPROVED]: 'warning',
  [QUOTATION_STATUS_V2.SALES_LEAD_REJECTED]: 'danger',
  [QUOTATION_STATUS_V2.APPROVED]: 'success',
  [QUOTATION_STATUS_V2.SIGNED]: 'success',
  [QUOTATION_STATUS_V2.CANCELLED_BY_INTERNAL]: 'danger',
  [QUOTATION_STATUS_V2.CANCELLED_BY_SHIPPER]: 'danger',
};

export const REVIEWABLE_QUOTATION_STATUS = [
  QUOTATION_STATUS.PENDING,
  QUOTATION_STATUS.REJECTED,
  QUOTATION_STATUS.ON_HOLD,
];

// New status approval sequence in https://kargox.atlassian.net/browse/FEX-1353
// Quotation review sequence 1: legal review
export const REVIEWABLE_SEQUENCE_1_QUOTATION_STATUS = [
  QUOTATION_STATUS_V2.PENDING,
  QUOTATION_STATUS_V2.LEGAL_ON_HOLD,
  QUOTATION_STATUS_V2.LEGAL_REJECTED,

  // Add the previous version of status for backward compatibility
  ...REVIEWABLE_QUOTATION_STATUS,
];

// New status approval sequence in https://kargox.atlassian.net/browse/FEX-1353
// Quotation review sequence 2: sales lead review (Term of payment review)
export const REVIEWABLE_SEQUENCE_2_QUOTATION_STATUS = [
  QUOTATION_STATUS_V2.LEGAL_APPROVED,
  QUOTATION_STATUS_V2.SALES_LEAD_REJECTED,
];

export const QUOTATION_STATUS_WITH_REMARKS = [
  QUOTATION_STATUS.ON_HOLD,
  QUOTATION_STATUS.CANCELLED_BY_INTERNAL,
  QUOTATION_STATUS.CANCELLED_BY_SHIPPER,
  QUOTATION_STATUS.REJECTED,
  QUOTATION_STATUS_V2.CANCELLED_BY_INTERNAL,
  QUOTATION_STATUS_V2.CANCELLED_BY_SHIPPER,
  QUOTATION_STATUS_V2.LEGAL_ON_HOLD,
  QUOTATION_STATUS_V2.LEGAL_REJECTED,
  QUOTATION_STATUS_V2.SALES_LEAD_REJECTED,
];

export const READY_TO_SIGN_QUOTATION_STATUS = [
  QUOTATION_STATUS_V2.APPROVED,
];

export const FINAL_STATE_QUOTATION_STATUS = [
  QUOTATION_STATUS.SIGNED,
  QUOTATION_STATUS.CANCELLED_BY_SHIPPER,
  QUOTATION_STATUS.CANCELLED_BY_INTERNAL,
];

export const CANCELLATION_QUOTATION_STATUS = [
  QUOTATION_STATUS.CANCELLED_BY_SHIPPER,
  QUOTATION_STATUS.CANCELLED_BY_INTERNAL,
];

export const T_O_P_APPROVAL_THRESHOLD_DAYS = 30;

export const QUOTATION_APPROVAL_TYPE_ENUM = {
  LEGAL_APPROVAL: 'LEGAL_APPROVAL',
  SALES_LEAD_APPROVAL: 'SALES_LEAD_APPROVAL',
};

export const QUOTATION_APPROVAL_STATUS_ENUM = {
  ON_HOLD: 'ON_HOLD',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
};

export default {};
