import { SHIPMENT_STATUSES } from '~/Configurations/constants';

export const RECEIVED_LOCATIONS_MAP = {
  BANDUNG_MARGAHAYU_OFFICE: 'Bandung - Margahayu Office',
  CIKATOMAS_OFFICE: 'Cikatomas Office',
  PITSTOP_BAWEAN_SEMARANG: 'Pitstop Bawean Semarang',
  PITSTOP_CIBITUNG: 'Pitstop Cibitung',
  PITSTOP_PANDAAN: 'Pitstop Pandaan',
  PITSTOP_TANJUNG_PERAK: 'Pitstop Tanjung Perak',
  PITSTOP_TANJUNG_PRIOK: 'Pitstop Tanjung Priok',
  PLAZA_ASIA_OFFICE: 'Plaza Asia Office',
};
export const RECEIVED_LOCATIONS_SELECT_OPTIONS = Object.keys(RECEIVED_LOCATIONS_MAP).map(key => ({
  label: RECEIVED_LOCATIONS_MAP[key],
  value: key,
}));
export const TIMESTAMP_INITIAL_VALUE_SOURCES = {
  LOADING: {
    OriginArrivedDateTime: SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    OriginDepartedDateTime: SHIPMENT_STATUSES.FINISH_LOADING,
  },
  UNLOADING: {
    DestinationArrivedDateTime: SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    DestinationDepartedDateTime: SHIPMENT_STATUSES.FINISH_UNLOADING,
  },
};
export const dtdEnums = {
  TRUCKING_DTD: 'TRUCKING_DTD',
  SHIPPING_LINE_DTD: 'SHIPPING_LINE_DTD',
  DOORING_AGENT_DTD: 'DOORING_AGENT_DTD',
};
export const DOORING_AGENT_OTHER_FEES = {
  THC_LOLO: 'THC_LOLO',
  STORAGE_FEE: 'STORAGE_FEE',
  DEMURRAGE_FEE: 'DEMURRAGE_FEE',
};

export const FM_TRUCKING_OTHER_FEES = {
  STORAGE_FEE: 'STORAGE_FEE',
  DEMURRAGE_FEE: 'DEMURRAGE_FEE',
};

export const ON_SITE_PAYMENT_TYPE = {
  PAID_BY_KARGO: 'PAID_BY_KARGO',
  PAID_BY_TRANSPORTER: 'PAID_BY_TRANSPORTER',
};
