export const CREDIT_LIMIT_STATUS = {
  ELIGIBLE: 'ELIGIBLE',
  EXCEPTION: 'EXCEPTION',
  OVER_LIMIT: 'OVER_LIMIT',
  HIGH_CONCERN: 'HIGH_CONCERN',
  DISABLED: 'DISABLED',
  TERMINATED: 'TERMINATED',
};

export default {};
