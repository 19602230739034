/* eslint-disable import/prefer-default-export */

export const PROJECT_DETAIL_TAB_PATHS = {
  BASIC_INFORMATION: 'basic-information',
  CONTRACT_DOCUMENTS: 'contract-documents',
  PAYMENTS: 'payments',
  CLAIM_RETURNS: 'claim-returns',
  DOCUMENT_RETURNS: 'document-returns',
  TRUCK_REQUIREMENTS: 'truck-requirements',
  LOCATION_FEE: 'location-fee',
  PRICES: 'prices',
  SHIPPING_REQUIREMENT_TAGS: 'shipping-requirement-tags',
};

// TODO: need to clean this up after KC_SHIPPER_CREDIT_LIMIT flag is removed
export const PROJECT_DETAIL_TAB_PATHS_WITH_CREDIT = {
  BASIC_INFORMATION: 'basic-information',
  CONTRACT_DOCUMENTS: 'contract-documents',
  CREDIT_LIMIT: 'credit-limit',
  PAYMENTS: 'payments',
  CLAIM_RETURNS: 'claim-returns',
  DOCUMENT_RETURNS: 'document-returns',
  TRUCK_REQUIREMENTS: 'truck-requirements',
  LOCATION_FEE: 'location-fee',
  PRICES: 'prices',
  SHIPPING_REQUIREMENT_TAGS: 'shipping-requirement-tags',
};
