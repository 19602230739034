import gql from 'graphql-tag';

export default gql`
query PROFILE($companyKsuid: String!) {
  myEmployeeProfile(companyKsuid: $companyKsuid) {
    accessType
    accessTypes
    company {
      signatureName
      signatureTitle
      address
      email
      handle
      hasConsentedCustomer
      isDofEligible
      isPodfEligible
      ksuid
      isPartneredWithEnterpriseShipper
      logo {
        fileName
        uploadedAt {
          date
        }
        url {
          full
        }
      }
      metadata
      companyAliases {
        name
        address
        npwpNumber
        arEmail
        logoUrl
        entityCode
      }
      name
      phoneNumber
      status
      type
    }
    email
    ksuid
    name
    user {
      phoneNumber
      email
      defaultLocale
    }
  }
  companySettings(companyKsuid: $companyKsuid) {
    # name inside company details here is only used in tms admin
    # for opening a transporter detail page
    companyDetails {
      name
    }
    companyDocuments {
      ktpFile {
        fileName
        uploadedAt {
          datetime
        }
        url {
          full
        }
      }
      npwpFile {
        fileName
        uploadedAt {
          datetime
        }
        url {
          full
        }
      }
      sppkpFile {
        fileName
        uploadedAt {
          datetime
        }
        url {
          full
        }
      }
    }
  }
  companyBankAccounts(companyKsuid: $companyKsuid) {
    ksuid
    accountHolder
    accountNumber
    isDefault
    bank {
      ksuid
      name
    }
  }
}
`;
