import { FEE_REQUEST_HOLD_STATUSES, FEE_REQUEST_OVERALL_STATUS, FEE_REQUEST_TYPE } from '~/Configurations/constants';

export const TRANSPORTER_PRICE_TYPE = {
  FINAL: 'FINAL',
  INITIAL: 'INITIAL',
};

export const DTD_SHIPMENT_TYPES = {
  TRUCKING_DTD: 'TRUCKING_DTD',
  SHIPPING_LINE_DTD: 'SHIPPING_LINE_DTD',
  DOORING_AGENT_DTD: 'DOORING_AGENT_DTD',
};

export const FIRST_MILE_TRUCKING_SHIPMENT_TYPE = {
  FM_EXPORT: 'FM_EXPORT',
  FM_IMPORT: 'FM_IMPORT',
  FM_INBOUND: 'FM_INBOUND',
  FM_OUTBOUND: 'FM_OUTBOUND',
};

export const disableCreatePRStatuses = [
  FEE_REQUEST_OVERALL_STATUS.DISBURSED,
  FEE_REQUEST_OVERALL_STATUS.REQUEST_SENT,
  FEE_REQUEST_OVERALL_STATUS.FEE_REJECTED,
  FEE_REQUEST_OVERALL_STATUS.REQUEST_APPROVED,
];

export const ON_HOLD_BUTTON_STATUS_LIST = [
  ...FEE_REQUEST_HOLD_STATUSES,
  FEE_REQUEST_OVERALL_STATUS.REVIEW_SHIPMENT_FEE,
  FEE_REQUEST_OVERALL_STATUS.REVIEW_ADDITIONAL_FEE,
];

export const PKP_OPTIONS_MAP = {
  PKP: true,
  NON_PKP: false,
};

export const ADDITIONAL_FEE_SPL_TYPE = [
  FEE_REQUEST_TYPE.THC_L,
  FEE_REQUEST_TYPE.THC_D,
  FEE_REQUEST_TYPE.LSS,
];

export const ADDITIONAL_FEE_DA_TYPE = [
  FEE_REQUEST_TYPE.STORAGE,
  FEE_REQUEST_TYPE.DEMURRAGE,
  FEE_REQUEST_TYPE.THC_LOLO,
];

export const ADDITIONAL_DOD_ALL = [
  ...ADDITIONAL_FEE_SPL_TYPE,
  ...ADDITIONAL_FEE_DA_TYPE,
];

export const REQUESTED_FEE_TYPE = {
  THC_L: 'THC-L',
  THC_D: 'THC-D',
  LSS: 'LSS',
  STORAGE_FEE: 'STORAGE_FEE',
  STORAGE: 'STORAGE_FEE',
  DEMURRAGE_FEE: 'DEMURRAGE_FEE',
  DEMURRAGE: 'DEMURRAGE_FEE',
  THC_LOLO: 'THC_LOLO',
};

export const EXPANDABLE_KEY_IDX = 1;

export const SHIPMENT_DATE_FIELD = 'shipmentDate';
export const PANTHERA_ENTITY_FIELD = 'pantheraEntities';

export const BULK_PAYMENT_REQUEST_SELECTION_ERROR = {
  WRONG_TEMPLATE: 'WRONG_TEMPLATE',
  SERVER_VALIDATION_ERROR: 'SERVER_VALIDATION_ERROR',
};

export const BULK_CREATE_ADJUSTMENT_FEE_ERROR = {
  WRONG_TEMPLATE: 'WRONG_TEMPLATE',
  SERVER_VALIDATION_ERROR: 'SERVER_VALIDATION_ERROR',
};

export const BULK_UPDATE_SHIPMENT_FEE_ERROR = {
  WRONG_TEMPLATE: 'WRONG_TEMPLATE',
  SERVER_VALIDATION_ERROR: 'SERVER_VALIDATION_ERROR',
};
