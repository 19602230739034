import {
  USER_ACCESS_TYPE,
  FIRST_MILE_USER_ACCESS_TYPE,
  DANONE_USER_ACCESS_TYPE,
  BROKERAGE_EXTRA_USER_ACCESS_TYPE,
} from '~/Configurations/constants';

const { CONSIGNEE, FREIGHT_FORWARDER } = FIRST_MILE_USER_ACCESS_TYPE;

const { DANONE_LOGISTIC, DANONE_PROCUREMENT } = DANONE_USER_ACCESS_TYPE;

const {
  ADMIN,
  AR,
  DOCUMENT_COLLECTION,
  FINANCE,
  FULL_ACCESS,
  RESTRICTED_ACCESS,
  TRAFFIC_CONTROLLER,
  ACCOUNT_PAYABLE,
  TRANSPORT_PLANNER,
  FINANCE_ADMIN,
  SALES_SUPPORT,
  SALES,
  SME_SALES_ADMIN,
  SME_SALES_LEAD,
  RISK,
  LEGAL,
} = USER_ACCESS_TYPE;

// First Mile Project Specific
const FIRST_MILE_ACCESS_PRIVILEGE = {
  CONTAINER_READ: [CONSIGNEE, FREIGHT_FORWARDER],
  CONTAINER_WRITE: [FREIGHT_FORWARDER],
  FIRST_MILE_COMPANY_CONSTANT_READ: [CONSIGNEE, FREIGHT_FORWARDER],
  FIRST_MILE_COMPANY_CONSTANT_WRITE: [FREIGHT_FORWARDER],
  BILL_OF_LADING_READ: [CONSIGNEE, FREIGHT_FORWARDER],
  BILL_OF_LADING_WRITE: [FREIGHT_FORWARDER],
  UPDATE_SHIPPING_LINE_ESTIMATED_DATE: [FULL_ACCESS, TRAFFIC_CONTROLLER],
  DELIVERY_READ: [CONSIGNEE, FREIGHT_FORWARDER],
  DELIVERY_WRITE: [FREIGHT_FORWARDER],
  FIRST_MILE_WAREHOUSE_READ: [FREIGHT_FORWARDER],
  FIRST_MILE_WAREHOUSE_WRITE: [FREIGHT_FORWARDER],
  REPORT_READ: [FREIGHT_FORWARDER, CONSIGNEE],
  FIRST_MILE_INVOICE_WRITE: [FREIGHT_FORWARDER],
  CANCEL_TRUCKING_DTD: [FULL_ACCESS, TRAFFIC_CONTROLLER],
  FIRST_MILE_INVOICE_READ: [FREIGHT_FORWARDER, CONSIGNEE],
  REMOVE_CONTAINER_FROM_PO: [FULL_ACCESS, SALES_SUPPORT],
  UPDATE_RO: [FULL_ACCESS, TRANSPORT_PLANNER],
  UPDATE_CONTAINER_COMPLETE: [FULL_ACCESS, TRANSPORT_PLANNER, TRAFFIC_CONTROLLER],
  EDIT_DOORING_AGENT_TAGS: [FULL_ACCESS, TRANSPORT_PLANNER, TRAFFIC_CONTROLLER],
  REASSIGN_COMPLETED_TRUCKING: [FULL_ACCESS, TRANSPORT_PLANNER],
  REALLOCATE_TRUCKING: [FULL_ACCESS, TRANSPORT_PLANNER],
  SPL_ACTIVITY_LOG: [FULL_ACCESS, TRAFFIC_CONTROLLER],
  EDIT_TIMESTAMP: [FULL_ACCESS, TRAFFIC_CONTROLLER],
  TRUCKING_ACTIVITY_LOG: [FULL_ACCESS, TRAFFIC_CONTROLLER],
  EDIT_TIMESTAMP_DOORING_AGENT: [FULL_ACCESS, TRAFFIC_CONTROLLER, TRANSPORT_PLANNER],
  EDIT_VESSEL_VOYAGE_DIALOG: [FULL_ACCESS, TRANSPORT_PLANNER],
  EDIT_ROUTES_PO: [FULL_ACCESS, SALES_SUPPORT],
};

/**
 * List of different user's permission mapped from the user's role
 *
 * Here are the list of roles with each explanation
 * ADMIN: Admin for the whole TMS
 * FULL_ACCESS & RESTRICTED_ACCESS: Roles from TTMS
 * FINANCE, SALES, SALES_SUPPORT, VENDOR_MANAGEMENT, AR: Different basic roles from a company for BTMS
 *
 * **Custom Roles**
 * DANONE_LOGISTIC & DANONE_PROCUREMENT
 */
export const ACCESS_PRIVILEGE = {
  SHIPMENT_READ: [
    ADMIN,
    DANONE_LOGISTIC,
    DANONE_PROCUREMENT,
    DOCUMENT_COLLECTION,
    FULL_ACCESS,
    RESTRICTED_ACCESS,
    TRAFFIC_CONTROLLER,
    FINANCE,
    FINANCE_ADMIN,
    ACCOUNT_PAYABLE,
    SME_SALES_ADMIN,
    SME_SALES_LEAD,
    RISK,
    ...Object.values(BROKERAGE_EXTRA_USER_ACCESS_TYPE),
  ],
  SHIPMENT_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, SALES_SUPPORT, DANONE_LOGISTIC],
  SHIPMENT_FIRST_MILE_READ: [
    ADMIN,
    DANONE_LOGISTIC,
    DANONE_PROCUREMENT,
    DOCUMENT_COLLECTION,
    FULL_ACCESS,
    RESTRICTED_ACCESS,
    TRAFFIC_CONTROLLER,
    FINANCE,
    FINANCE_ADMIN,
    ACCOUNT_PAYABLE,
    RISK,
    ...Object.values(BROKERAGE_EXTRA_USER_ACCESS_TYPE).filter(
      access => access !== SME_SALES_ADMIN && access !== SME_SALES_LEAD,
    ),
  ],
  SHIPMENT_FIRST_MILE_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, SALES_SUPPORT, DANONE_LOGISTIC],
  PRICE_READ: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS, RISK],
  PRICE_WRITE: [ADMIN, FULL_ACCESS, SALES_SUPPORT],
  SHIPMENT_COMPANY_CONSTANT_READ: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS, DANONE_LOGISTIC, DANONE_PROCUREMENT, RISK],
  SHIPMENT_COMPANY_CONSTANT_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS],
  VEHICLE_TRACKING_READ: [RISK, ADMIN, FULL_ACCESS, RESTRICTED_ACCESS],
  COMPANY_DETAILS_READ: [
    RISK,
    FULL_ACCESS,
    FINANCE,
    FINANCE_ADMIN,
    ACCOUNT_PAYABLE,
    RESTRICTED_ACCESS,
    DANONE_LOGISTIC,
    DANONE_PROCUREMENT,
  ],
  COMPANY_DETAILS_WRITE: [FULL_ACCESS],
  EMPLOYEES_READ: [ADMIN, FULL_ACCESS, FINANCE, RESTRICTED_ACCESS, DANONE_LOGISTIC, DANONE_PROCUREMENT, RISK],
  EMPLOYEES_WRITE: [FULL_ACCESS],
  BIDDING_READ: [RESTRICTED_ACCESS, RISK],
  BIDDING_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS],
  VEHICLES_READ: [
    ADMIN,
    RISK,
    FULL_ACCESS,
    FINANCE,
    RESTRICTED_ACCESS,
    TRAFFIC_CONTROLLER,
    DOCUMENT_COLLECTION,
    SME_SALES_LEAD,
    SME_SALES_ADMIN,
  ],
  VEHICLES_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, FINANCE],
  VEHICLES_TRACKING_UPDATE: [FULL_ACCESS, TRAFFIC_CONTROLLER],
  DRIVERS_READ: [ADMIN, FULL_ACCESS, FINANCE, RESTRICTED_ACCESS, RISK],
  DRIVERS_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, FINANCE],
  CUSTOMERS_READ: [
    ADMIN,
    RISK,
    FULL_ACCESS,
    RESTRICTED_ACCESS,
    FINANCE,
    FINANCE_ADMIN,
    ACCOUNT_PAYABLE,
    AR,
    SALES_SUPPORT,
    SME_SALES_ADMIN,
    SME_SALES_LEAD,
    ...Object.values(BROKERAGE_EXTRA_USER_ACCESS_TYPE),
  ],
  CUSTOMERS_INVOICE_REMINDER_UPDATE: [FULL_ACCESS, FINANCE, AR],
  CUSTOMERS_CREATE: [FULL_ACCESS, SALES_SUPPORT],
  CUSTOMERS_UPDATE: [ADMIN, FULL_ACCESS, SALES_SUPPORT],
  VENDORS_READ: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS, DANONE_LOGISTIC, DANONE_PROCUREMENT, RISK],
  VENDORS_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, DANONE_PROCUREMENT],
  VENDOR_CONTRACTS_READ: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS, DANONE_PROCUREMENT, RISK],
  VENDOR_CONTRACTS_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, DANONE_PROCUREMENT],
  WAREHOUSES_READ: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS, FINANCE, DANONE_LOGISTIC, DANONE_PROCUREMENT, RISK],
  WAREHOUSES_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, FINANCE, DANONE_LOGISTIC, DANONE_PROCUREMENT],
  PAYMENT_READ: [ADMIN, FULL_ACCESS, FINANCE, FINANCE_ADMIN, ACCOUNT_PAYABLE, RISK],
  PAYMENT_WRITE: [FULL_ACCESS, FINANCE, FINANCE_ADMIN, ACCOUNT_PAYABLE],
  PAYMENT_REQUEST_REVISE: [FULL_ACCESS, ADMIN, ACCOUNT_PAYABLE, FINANCE, FINANCE_ADMIN],
  PAYMENT_REQUEST_APPROVE: [FULL_ACCESS, ADMIN, FINANCE, FINANCE_ADMIN],
  PAYMENT_REQUEST_EDIT: [FULL_ACCESS],
  CREATE_PAYMENT_REQUEST: [ADMIN, FULL_ACCESS, FINANCE, FINANCE_ADMIN, ACCOUNT_PAYABLE],
  DISBURSEMENT: [FULL_ACCESS, ADMIN, FINANCE, FINANCE_ADMIN],
  AR_AGING_READ: [FULL_ACCESS, ADMIN, FINANCE, FINANCE_ADMIN, RISK],
  SHIPMENT_CSV_DOWNLOAD: [ADMIN],
  DO_CREATE: [ADMIN, FULL_ACCESS, SALES_SUPPORT],
  BULK_ASSIGN: [ADMIN, FULL_ACCESS, TRAFFIC_CONTROLLER, TRANSPORT_PLANNER],
  BULK_COMPLETED: [ADMIN, FULL_ACCESS, SALES_SUPPORT],
  DO_UPDATE: [ADMIN, FULL_ACCESS, SALES_SUPPORT],
  DO_BID_PRICE_UPDATE: [FULL_ACCESS, DANONE_PROCUREMENT],
  DO_STATUS_UPDATE: [ADMIN, FULL_ACCESS, TRAFFIC_CONTROLLER],
  POD_CREATE: [ADMIN, FULL_ACCESS, DOCUMENT_COLLECTION],
  POD_UPDATE: [ADMIN, FULL_ACCESS, DOCUMENT_COLLECTION, TRAFFIC_CONTROLLER],
  TRANSPORTER_DATA_VIEW: [ADMIN],
  SHIPPER_COPYWRITE: [ADMIN],
  PERSONAL_PHONE_NUMBER_READ: [FULL_ACCESS, RESTRICTED_ACCESS, FINANCE, RISK],
  TRANSPORTER_LIST_READ: [ADMIN],
  APPROVE_REASSIGNMENT: [FULL_ACCESS, TRANSPORT_PLANNER, TRAFFIC_CONTROLLER],
  SHIPPER_SWIFT_PAYMENT_WRITE: [ADMIN],
  SWIFT_PAYMENT_BYPASS: [ADMIN],
  SWIFT_PAYMENT_APPLY: [FULL_ACCESS, FINANCE],
  REVIEW_DOF_REQUEST: [ADMIN],
  DISBURSE_DOF_REQUEST: [ADMIN],
  REVIEW_PODF_REQUEST: [ADMIN],
  DISBURSE_PODF_REQUEST: [ADMIN],
  DISBURSEMENT_SCHEME: [ADMIN],
  LOCATION_FEE_WRITE: [FULL_ACCESS, SALES_SUPPORT],
  INSIGHTS_READ: [FULL_ACCESS, DANONE_LOGISTIC, DANONE_PROCUREMENT, AR, ACCOUNT_PAYABLE, FINANCE, FINANCE_ADMIN, RISK],
  RECEIVABLE_READ: [AR, ACCOUNT_PAYABLE, DOCUMENT_COLLECTION, FINANCE, FINANCE_ADMIN, FULL_ACCESS, RISK],
  RECEIVABLE_WRITE: [AR, ACCOUNT_PAYABLE, DOCUMENT_COLLECTION, FINANCE, FINANCE_ADMIN, FULL_ACCESS],
  SHIPMENT_ACTIONS_BROKERAGE: [ADMIN, FULL_ACCESS, TRANSPORT_PLANNER],
  BROKERAGE_SALES_WRITE: [ADMIN, FULL_ACCESS, SME_SALES_ADMIN],
  ACCOUNT_TRACKER_READ: [ADMIN, FULL_ACCESS, AR, FINANCE, FINANCE_ADMIN, RISK],
  SHIPMENT_ACTIONS_ALLOCATION: [
    ADMIN,
    DANONE_LOGISTIC,
    DANONE_PROCUREMENT,
    DOCUMENT_COLLECTION,
    FULL_ACCESS,
    RESTRICTED_ACCESS,
    TRAFFIC_CONTROLLER,
    FINANCE,
    FINANCE_ADMIN,
    ACCOUNT_PAYABLE,
    ...Object.values(BROKERAGE_EXTRA_USER_ACCESS_TYPE).filter(access => access !== SALES_SUPPORT),
  ],
  PAYMENT_SETTINGS_WRITE: [FULL_ACCESS],
  PAYMENT_CUSTOM_TAX_SETTINGS: [FULL_ACCESS, FINANCE_ADMIN],
  CUSTOM_INVOICE_TEMPLATE_WRITE: [AR, FINANCE, FINANCE_ADMIN, FULL_ACCESS],
  SHIPPER_GROUP_WRITE: [AR, FINANCE, FINANCE_ADMIN, FULL_ACCESS],
  ...FIRST_MILE_ACCESS_PRIVILEGE,
  TRANSPORTER_PRICING_VIEW: [
    BROKERAGE_EXTRA_USER_ACCESS_TYPE.TRANSPORTER_PRICING_VIEWER,
    BROKERAGE_EXTRA_USER_ACCESS_TYPE.TRANSPORTER_PRICING_MANAGER,
    FULL_ACCESS,
    RISK,
  ],
  TRANSPORTER_PRICING_MANAGE: [BROKERAGE_EXTRA_USER_ACCESS_TYPE.TRANSPORTER_PRICING_MANAGER, FULL_ACCESS],
  RISK_READ: [
    FULL_ACCESS,
    ADMIN,
    RISK,
  ],
  READ_ONLY: [RISK],
  EPOD_UPDATE: [ADMIN, FULL_ACCESS, DOCUMENT_COLLECTION, TRAFFIC_CONTROLLER],
  EPOD_UPDATE_PHYSICAL_STATUS: [ADMIN, FULL_ACCESS, DOCUMENT_COLLECTION],
  QUOTATION_REVIEW: [ADMIN, FULL_ACCESS, LEGAL],
  QUOTATION_LEGAL_REVIEW: [ADMIN, FULL_ACCESS, LEGAL],
  QUOTATION_SALES_REVIEW: [ADMIN, FULL_ACCESS, SME_SALES_LEAD],
  QUOTATION_EDIT: [ADMIN, FULL_ACCESS, SALES, SME_SALES_LEAD, LEGAL],
  QUOTATION_EDIT_T_O_P: [ADMIN, FULL_ACCESS, SALES, SME_SALES_LEAD],
  QUOTATION_CREATE: [ADMIN, FULL_ACCESS, SALES, SME_SALES_LEAD],
};

export default {};
