/* eslint-disable max-len */
import {
  DERIVED_SHIPMENT_STATUSES,
  DISBURSEMENT_STATUS_CODE,
  FILE_SIZES,
  SHIPMENT_POINT_STATUS,
  SHIPMENT_STATUSES,
  TRANSPORTER_VERIFICATION_LEVEL,
} from '~/Configurations/constants';
/* eslint-disable camelcase */
import mid_mile from './en/message/mid_mile';
import errors from './en/message/errors';
import transporter from './en/message/transporter';
import { DISBURSEMENT_PROCESS_ENUM } from '~/Pages/Company/Transporter/constants';
import { BULK_CREATE_ADJUSTMENT_FEE_ERROR, BULK_PAYMENT_REQUEST_SELECTION_ERROR, BULK_UPDATE_SHIPMENT_FEE_ERROR } from '~/Pages/Payment/FeeRequests/constants';

export default {
  mid_mile,
  example: {
    role: 'e.g. Head of Operation',
  },
  bulk_assign_csv_generate_success: 'Bulk Assignment CSV file has been successfully generated!',
  bulk_assign_csv_upload_error: 'Revise {{number}} DO Assignments',
  bulk_assign_csv_upload_error_desc: 'Download the CSV Error Report below to review and fix the errors shown',
  bulk_assign_csv_upload_success: '{{number}} DOs successfully assigned!',
  bulk_assign_csv_upload_success_desc: 'Please recheck the DOs in Planned Sub Tab to make sure the entries are correct',
  bulk_assign_csv_max_do_error: 'Please enter no more than 100 DO numbers',
  bulk_assign_input_textarea_placeholder: 'Enter DO Number here or input manually (e.g. DO-1234567,DO-1118888)',
  bulk_shipment_csv_upload_success: 'Bulk Shipment successfully uploaded',
  country_changed: 'Country changed to {{country}}',
  duplicate_sales: 'Cannot assign same sales twice to a shipper',
  entity_explanation: 'List of Entity that Panthera is regsistered for',
  input_do_numbers_to_assign_tips: 'Copy the DO Number from your tracker (max. 100 DOs) and paste it in the box',
  welcome_to_shipper_sales: 'Welcome to Kargo Shipper!',
  welcome_to_shipper_sales_description: 'Please see shipping to start managing your business with Cargo Shipper',
  login_not_allowed:
    'Your account is not registered with any Shipper,'
    + 'contact your Team Leader to register you for a Shipper',
  created_by_table: 'Created by {{name}} on {{date}}',
  late_by: 'Late by {{duration}}',
  redirecting_to_planned_sub_tab_in: 'Redirecting to planned sub tab in {{time}}...',
  shipment_status_map_multi: {
    [SHIPMENT_STATUSES.UPCOMING]: 'Upcoming',
    [SHIPMENT_STATUSES.JOB_WAITING_FOR_SHIPPER_CONFIRMATION]: 'Waiting For Shipper Confirmation',
    [SHIPMENT_STATUSES.PLANNED]: 'Planned',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Assigned',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Ongoing to Origin {{sequence}}',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'At Origin {{sequence}}',
    [SHIPMENT_STATUSES.START_LOADING]: 'Start Loading {{sequence}}',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Finish Loading {{sequence}}',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Ongoing to Destination {{sequence}}',
    [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'Ongoing to Destination {{sequence}}',
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'At Destination {{sequence}}',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start Unloading {{sequence}}',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Finish Unloading {{sequence}}',
    [SHIPMENT_STATUSES.DOCUMENT_UPLOADED]: 'Document Uploaded {{sequence}}',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [SHIPMENT_POINT_STATUS.COMPLETE]: 'Shipment Completed',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'Cancelled by Internal',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper',
    [SHIPMENT_STATUSES.CANCELLED]: 'Cancelled',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Unfulfilled',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Reassigned',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Unfulfilled',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Expired',
    [SHIPMENT_STATUSES.JOB_BIDDING]: 'Bidding',
    [SHIPMENT_STATUSES.JOB_UNASSIGNED]: 'Unassigned',
    [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'POD Uploaded {{sequence}}',
    [SHIPMENT_STATUSES.LATE_TO_ORIGIN]: 'Late to origin {{sequence}}',
    [SHIPMENT_STATUSES.LATE_TO_DESTINATION]: 'Late to destination {{seqeuence}}',
    [SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED]: 'Transporter Confirmed',
    [SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION]: 'Waiting For Job Confirmation',
    [SHIPMENT_STATUSES.REASSIGNED_BY_BROKERAGE]: 'Reassigned By Brokerage',

    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_ORIGIN]: 'Queueing At Origin {{squence}}',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_DESTINATION]: 'Queueing At Destination {{sequence}}',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'Queueing Overtime',
    [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'Behind Schedule',
    [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'On Time',
    [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_ORIGIN_LATE]: 'Queue at Origin {{sequence}} Late',
    [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_DESTINATION_LATE]: 'Queue at Destination {{seqeunce}} Late',
  },
  on_time_status_map: {
    LATE: '{{time}} Late to {{suffix}}',
    MAYBE_LATE: 'Maybe Late to {{suffix}}',
    ONTIME: 'Ongoing to {{suffix}}',
  },
  file_size_options_map: {
    [FILE_SIZES.ORIGINAL]: 'Original file size',
    [FILE_SIZES.MEDIUM]: 'Medium file size, medium image quality',
    [FILE_SIZES.LOW]: 'Minimum file size, poor image quality',
  },
  fill_in_csv_instruction: {
    1: 'Click Download CSV button above',
    2: 'Fill in the CSV template by following Bulk Assign CSV Instruction{{message}}',
    3: 'Once you are done, save your changes and upload new Bulk Assign CSV file',
  },
  general_successfully_saved: '{{name}} successfully saved',
  general_successfully_registered: '{{name}} successfully registered',
  generate_csv_instruction: {
    1:
      'Insert DO numbers in the box above. Make sure you use comma (,) between each DO if you'
      + '  insert manually (e.g. DO-1234,DO-5678,DO-9012). Multiple Assignments only accept Unassigned and Planned',
    2: 'Click Generate CSV button. System will generate CSV with automatically filled Shipper Name, Allocation Status, and Pickup Date',
  },
  generic_add: 'Add {{name}}',
  generic_add_more: 'Add More {{name}}',
  generic_add_new: 'Add New {{name}}',
  generic_bulk_add: 'Bulk Add {{name}}',
  generic_upload: 'Upload {{name}}',
  generic_edit: 'Edit {{name}}',
  generic_update: 'Update {{name}}',
  generic_save: 'Save {{name}}',
  generic_select: 'Select {{name}}',
  generic_view: 'View {{name}}',
  generic_info: '{{name}} Info',
  generic_active: 'Active {{name}}',
  generic_inactive: 'Inactive {{name}}',
  generic_deactivate: 'Deactivate {{name}}',
  generic_fee_for: '{{fee}} for {{doNumber}}',
  assign_all_to_this: 'Assign all {{name}} registered to this {{recepient}}',
  all_registered_will_be_available_for_assignment:
    'All {{name}} registered to this {{recepient}} will be available for assignment.',
  please_wait_generating_pdf: 'Please Wait, Generating PDF ...',
  fetching_file_size: 'Fetching file size...',
  enter_valid_number_with_upto_2_decimal: 'Enter valid value with up to 2 decimals',
  enter_to_add_another_pod_number: 'Press ‘Enter’ to add another POD Number below',
  enter_to_add_another_pol_number: 'Press ‘Enter’ to add another POL Number below',
  enter_appointment_number: 'Enter Appointment Number',
  shipment_quantity_warning:
    'We\'ll calculate this for invoicing. To get the correct payment, make sure you input the right quantity.',
  previous_shipment_status_warning: {
    title: 'You\'re choosing previous shipment status',
    message:
      'Doing this will rollback the shipment status. '
      + 'The status change will be reflected immediately in Driver App and driver may need to re-upload some documents.',
  },
  choose_container_to_remove: 'Choose Containers to Removed from Purchase Order',
  timestamp_same_or_after_msg:
    'Date and Time for this Timestamp must be the same with or after the Date and Time of the previous Timestamp',
  pod_document_rejected_msg: 'Make sure you\'ve approved the POD document, Claim/Return Document or Goods Photo',
  please_wait_document_being_prepared: 'Please wait, your documents are being prepared...',
  file_has_not_been_selected: 'File not selected',
  search_shipment_placeholder: 'Search DO Number or License Plate Number',
  search_shipment_placeholder_invoice: 'Search POD No, Shipment No.',
  search_shipment_n_container_placeholder_invoice: 'Search POD, container, or shipment number',
  price_difference: 'Price can affected by claims and pinalties',
  shipment_not_included_in_invoice_confirmation: 'This shipment will not be included in this invoice if you continue.',
  shipment_full_payment: 'Full Shipment Payment',
  empty_notification: 'Empty Notifications',
  invalid_bank_account: 'Fill in valid account number',
  invalid_bank_account_holder_name: 'Fill in valid account holder name',
  invalid_phone_number: 'Need to start with the + character',
  download_locations_csv_msg: 'Or download the template {{name}}',
  download_states_csv_msg: 'Download list of available states',
  bid_choose_winner_setting_msg:
    'We\'ll choose a winner for you if you haven\'t picked one after a certain period of time.',
  max_bid_greater_than_posted_price: 'Max Bid Limit must be equal to or greater than Posted Price',
  no_driver_app: 'No Driver App',
  updated_location: 'Updated Location',
  location_not_updated: 'Location not updated from driver',
  order_number_should_not_contain_comma: 'Order No. should not contain comma (,)',
  pickup_date_and_time_error: {
    latest_later_than_earliest: 'Latest Pickup Date & Time must be later than Earliest Pickup Date & Time.',
    before_last_pickup: 'Pickup Date & Time must be later than Pickup Date & Time before this pickup',
    before_deadline_date: 'Pickup Date & Time must be later than deadline date & time',
    before_ro_expiry_date: 'Pickup Date & Time must be later than RO expiry date & time',
  },
  dropoff_date_and_time_error: {
    later_than_latest_pickup: 'Dropoff Date & Time must be later than Latest Pickup Date & Time.',
    later_than_previous_dropoff: 'Dropoff Date & Time must be later than previous Dropoff Date & Time.',
  },
  deadline_date_and_time_error: {
    before_or_same_current: '"Deadline Date and Time” must be before or at the same time as the current date and time',
    after_loading_date_time:
      '”Deadline Date and Time” must be before or at the same time as the Earliest Pickup Date and Time',
    existing_deadline_passed: 'Cannot edit a “Deadline Date and Time” that has already passed ',
  },
  errors: {
    ...errors,
    unexpected: 'Something is wrong, Team will investigate the issue',
    network: {
      500: 'Internal Server Error',
      common: 'Check your connection',
    },
    status_code: {
      401: 'You don\'t have access',
      500: 'Internal Server Error',
      '018-5500': 'Internal Server Error',
    },
    error_code: {
      401: 'You don\'t have access',
      445: 'Phone number not registered.',
      500: 'Internal Server Error',
    },
    phone_number_already_registered: 'Phone Number Already Registered',
    phone_number_already_existed: 'Phone number already belongs to "{{shipperName}}", enter a different phone number',
    price_not_registered_origin_destination: 'Price is not registered in either origin/destination.',
    price_rate_not_exist: 'Goods Capacity quantity or measure has no registered Price Rate Type',
    goods_capacity_not_registered: 'Goods Capacity quantity has not registered yet on Price Rate Type',
    verification_code_mismatch: 'Kode verifikasi yang anda masukkan tidak sesuai',
    otp_required_please_retry: 'Verification code required. Please retry',
    failed_submit_location_fee: 'Please fill out the mandatory fields before adding Location Fee(s)',
    different_origin_and_destination: 'Origin and Destination must be different',
    truck_requirement_isnt_registered_yet: 'Truck requirement isn’t registered yet',
    backend: {
      btmsRemoveContainerFromSpl: {
        252: 'Can only remove container from SPL with status vessel booked, departure, and arrival.',
      },
      common: {
        401: 'You don\'t have access',
        500: 'Internal Server Error',
        '018-5500': 'Internal Server Error',
      },
      generateCompanyHandles: {
        34: 'Please use only alpha numeric and lower case',
      },
      saveCompanyDetails: {
        34: 'Please use only alpha numeric and lower case',
      },
      saveCompanyVehicle: {
        1: 'Company Name tidak terdaftar',
        2: 'Armada belum tidak terdaftar',
        3: 'Gagal mengUpload STNK',
        4: 'Gagal mengUpload KIR',
        14: 'Armada sudah terdaftar',
        15: 'Tipe kendaraan tidak valid',
      },
      saveCompanyVehicles: {
        422: 'Ada beberapa data yang tidak valid',
      },
      approveDofRequest: {
        83: 'Pengajuan pembayaran cepat tidak terList di sistem',
        84: 'Satu atau beberapa pengiriman yang Rejected bukan bagian dari pengajuan pembayaran ini',
      },
      saveCustomer: {
        1: 'Company not registered',
        5: 'Customer\'s company not registered',
        484: 'Vendor code already taken',
        485: 'Vendor ID already taken',
      },
      saveEmployee: {
        463: 'Email domain already whitelisted',
        464: 'Email already taken as admin',
        465: 'Email aready taken within company',
        466: 'Phone number already taken',
        467: 'Phone number already taken as admin',
        468: 'Email not invited or whitelisted',
        469: 'Failed to send verification mail',
        470: 'Company name should be unique',
        471: 'User already Invited',
        484: 'Email already taken',
      },
      createSmeSales: {
        463: 'Email domain already whitelisted',
        464: 'Email already taken as admin',
        465: 'Email aready taken within company',
        466: 'Phone number already taken',
        467: 'Phone number already taken as admin',
        468: 'Email not invited or whitelisted',
        469: 'Failed to send verification mail',
        470: 'Company name should be unique',
        471: 'User already Invited',
        484: 'Email already taken',
      },
      createShipperEmployee: {
        35: 'Phone number already in use',
        465: 'Email already in use',
      },
      updateSmeSales: {
        11: 'Employee does not exists',
        12: 'Invalid employee access type',
        27: 'Team leader ksuid missing',
        28: 'Team leader has child',
        500: 'Unknown error',
      },
      updateCompanyConstant: {
        53: 'Company constant does not exist',
        54: 'Company constant name should be unique',
        55: 'Company constant ID should be unique',
        440: 'User with given ksuid is not an employee of the given company',
        500: 'Internal server error',
      },
      createCompanyConstant: {
        1: 'Company does not exist',
        54: 'Company constant name should be unique',
        55: 'Company constant ID should be unique',
        440: 'User with given ksuid is not an employee of the given company',
        500: 'Internal server error',
      },
      saveCompanyProduct: {
        3001: 'SKU ID has been registered',
        3002: 'SKU Name has been registered',
        3003: 'Invalid Group ID',
        3004: 'SKU not found',
      },
      createBillOfLading: {
        94: 'BL Number already used',
      },
      editBillOfLading: {
        94: 'BL Number already used',
        422: 'Store cannot be changed',
      },
      saveBackdatedShipments: {
        422: 'Invalid CSV parameter/attributes',
      },
      saveDeliveryOrder: {
        137: 'Duplicate Product ID',
        187: 'Maaf, DO tidak dapat dibuat karena terdapat duplikat transporter incentive. Mohon hubungi admin untuk menghapus salah satu incentive sehingga tidak terdapat duplikasi',
      },
      saveDeliveryOrders: {
        53: 'Invalid unloading location',
        54: 'Invalid loading location',
        56: 'Invalid SKU',
        58: 'Invalid loads capacity unit',
        63: 'Invalid unloading date',
        66: 'Invalid customer',
        67: 'Driver not found',
        74: 'Company does not exist',
        105: 'Contract to given transporter does not exist',
        113: 'Invalid order number',
        114: 'Invalid shipment type',
        115: 'Invalid latest_pickup_datetime',
        116: 'Invalid location ID',
        117: 'Invalid truck type',
        118: 'Invalid truck subtype',
        119: 'Truck type and/or truck subtype combination not found',
        120: 'Invalid loads (delivery order) capacity',
        121: 'Company shipment type not found',
        122: 'Multiple company shipment type found',
        123: 'Company order type not found',
        124: 'Multiple company order type found',
        125: 'Invalid transporter ID and/or name',
        126: 'Pickup Date and Time must be earlier than Latest Pickup Date and Time',
        127: 'Dropoff Date and Time must be later than Pickup Date and Time',
        128: 'Latest Pickup Date and Time must be earlier than Dropoff Date and Time',
        137: 'Duplicate Company Product (SKU) found on the same DO',
        140: 'Invalid window time',
        141: 'Invalid timezone',
        142: 'Invalid company product (SKU)',
        143: 'Invalid company product (SKU) quantity',
        144: 'Duplicate Company Product (SKU) found on the same DO',
        156:
          'Invalid fulfillment type (It should be either blank or belongs to'
          + ' SINGLE_CONTRACTED, BROADCAST_CONTRACTED or PRIVATE_BIDDING)',
        157: 'Transporter_id should be blank',
        158: 'Contracted transporters for the given route and shipment type does not exist',
        159: 'Non-contracted transporters for the given route & shipment type does not exist',
        229: 'Multipickup/drop fees or count must using number',
        422: 'Invalid CSV parameter/attributes',
        482: 'Fill with YYYY-MM-DD, HH:mm:ss format',
        483: 'Fill with YYYY-MM-DD, HH:mm:ss format',
        484: 'Fill with active location registered in B-TMS',
        485: 'Fill with active location registered in B-TMS',
        486: 'Insert registered shipper name.',
        487: 'Vehicle Requirement not found. Please register your Truck Type.',
        488: 'Choose Type Truck with Overnight Fee on registered Shipper Name and Shipment Type',
        489: 'Cancellation Fee not found',
        490: 'Choose Location Name with Loading Fee on registered Shipper Name, Truck Type, Body Type, and Shipment Type',
        491: 'Choose Location Name with Unloading Fee on registered Shipper Name, Truck Type, Body Type, and Shipment Type',
        493: 'Fill with YYYY-MM-DD, HH:mm:ss format',
        494: 'Fill with YYYY-MM-DD, HH:mm:ss format',
        497: 'Fill with YES or TRUE',
        498: 'Fill with NO or FALSE',
        499:
          '- For Group Load, make sure number of External ID matches number of shipment (separate with "";"")\n'
          + '- For Non Group Load, leave blank or insert 1 External ID',
        500: 'Only Posted DO have Posted Price',
        501: 'Posted DO must have Origin or Posted Price',
        502: 'Fill Posted Price as you like or use Suggested Price',
        503: 'Only Posted DO have Margin',
        505: 'Make sure Price Rate Type matches Goods Capacity Unit. Choose Per Route or Per Capacity Unit (Kg, CBM, Unit)',
        506: 'Fill with Kg, CBM, or Unit',
        507: 'Fill with TRUE or FALSE',
        508: 'Only Posted DO has Max Transporter Bid Price',
        509: 'Make sure it matches Max Bid Limit',
        510: 'Only Posted DO has Max Bid Limit Percentage',
        512: '- Leave blank or insert 1 for Group Load\n'
        + '- Insert number between 2 to 20 for Non Group Load',
        513: 'Choose valid Shipment Type',
        516: 'Choose valid Truck Type',
        517: 'Choose valid Truck Body Type',
        518: '- Avoid negative number\n'
        + '- Max. 8 numbers after comma',
        519: 'Choose valid Capacity Unit',
        523: '- Avoid negative number\n'
        + '- Max. 8 numbers after comma',
        524: 'Fill with numbers between 0 to 100',
        525: '- Avoid negative number\n'
        + '- Max. 8 numbers after comma',
        526: 'Please register your Price Rate Type first',
        527: 'Multishipment Fee not found',
        528: 'Invalid suggested price',
        529: 'Claim not found',
        530: 'Choose valid Shipment Type',
        531:
          'Insert at minimum:\n1 Pickup 2 Dropoffs for MULTIDROP type\n2 Pickups 1 Dropoff for MULTIPICKUP type\n'
          + '\n2 Pickups 2 Dropoffs for MULTIDROP_MULTIPICKUP type',
        532: 'Input a date and time format using YYYY-MM-DD HH:mm:ss',
        533: 'Make sure number of point_locations, point_shipment_type, point_type, & point_datetime is equal',
        534: 'Fill with active location registered in B-TMS',
        535: 'Warehouse not found. Try another Warehouse',
        536: 'Warehouse Fee not found',
        537: 'Make sure Reimbursement Type same with Shipment Type',
        538: 'Fill with numbers between 0 to 100',
        539: 'Fill both Dropoff Location and Dropoff Datetime',
        540: 'Error occured while getting shipper. Please try again later',
        541: 'Error occured while getting warehouse. Please try again later',
        542: 'Error occured while getting warehouse fee. Please try again later',
        543: 'Post to Marketplace must have Dropoff',
        544: 'Invalid SME Payment Scheme',
        545: 'Invalid Driver Helper',
        546: 'Invalid Driver Helper Price',
        547: 'Invalid Extra Helper',
        548: 'Invalid Extra Helper Amount',
        549: 'Invalid Extra Helper Price',
        550: 'Invalid POD Return',
        551: 'Invalid POD Return Price',
        552: 'Invalid POD Return Address',
        553: 'Invalid Flat Discount',
        554: 'Company bank account not found',
        555: 'Input custom shipment price value',
        556: 'Missing required data',
        557: 'Invalid datetime',
        558: 'Invalid decimal',
        559: 'Invalid Transporter name',
        560: `This vehicle doesn't belong to selected transporter`,
        561: 'Invalid use_suggested_price',
        562: 'Invalid post_to_marketplace',
        563: 'Deadline datetime is later than Earliest Pickup datetime',
        564: 'Earliest Pickup datetime is later than Latest Pickup datetime',
        565: 'Latest Pickup datetime id later than Dropoff datetime',
        566: 'Deadline datetime should be earlier than early pickup 1 datetime',
        567: 'Early Pickup 1 datetime should be earlier than Latest Pickup 1 datetime',
        568: `Can't create DO with OVER MAX capacity and suggested price is not available`,
        569: 'use_goods_capacity cannot be true for per_route price_rate_type',
        570: 'Invalid point_latest_pickup_datetime',
        571:
          'point_latest_pickup_datetime must be on or later than the corresponding point_datetime'
          + 'and before the next point_datetime',
        572: 'Invalid window times',
        573: 'Invalid Location ID',
        574: 'Point date should be sequential from first point to the next',
        575: 'Multistop Fee not found',
        576: 'pod_return cannot be TRUE when physical_pod is FALSE',
        577: 'Invalid latest_point_a_datetime',
        578: 'point_latest_pickup_datetime length should be equal to pickup points length',
        579: 'Payment Scheme cannot be Invoice for Non-invoice Shipper',
        580: 'Payment Scheme must be Invoice for Invoice Shipper',
        581: 'MULTIPICKUP Shipments should have more than 1 Pickup and exactly 1 Dropoff point',
        582: 'MULTIDROP Shipments should have more than 1 Dropoff and exactly 1 Pickup point',
        583: 'MULTIPICKUP_MULTIDROP Shipments should have more than 1 Pickup and Dropoff points',
        584: 'Reset shipment status failed',
        585: 'Truck Type does not have a Price for the given route',
        586: 'invalid product_type. Refer https://kargox.atlassian.net/l/c/7p5Z0NeR for list of allowed values',
        587: 'Invalid CSV Separator',
        588: 'Transporter not found / invalid',
        589: 'Invalid driver phone number format. Please use +62xxxxxx',
        590: 'Vehicle not found',
        591: `This driver doesn't belong to selected transporter`,
        592: `Transporter already allocated, cannot do reallocation`,
        593: 'Driver already allocated, cannot do reallocation',
        594: 'Vehicle already allocated, cannot do reallocation',
        616: 'POD Link invalid. Please only use Google Drive URL',
        617: `Google Drive files not found or hasn't been granted access to files`,
        618: 'Fill with YYYY-MM-DD, HH:mm:ss format',
        645: 'Fee cannot be filled when count is empty',
        646: 'Count cannot be filled when fee is empty',
        647: 'Total count must be less than or equals to (total location - 2)',
      },
      uploadContainers: {
        422: 'Invalid container file',
      },
      createContainer: {
        110: 'Duplicate container number on the same BL',
        111: 'Duplicate seal number on the same BL',
        112: 'Duplicate container and seal number on the same BL',
      },
      updateContainer: {
        110: 'Duplicate container number on the same BL',
        111: 'Duplicate seal number on the same BL',
        112: 'Duplicate container and seal number on the same BL',
      },
      createContainers: {
        422: 'Duplicate container or seal number on the same BL',
      },
      savePartnershipPricing: {
        1001: 'Invalid Transporter',
        1002: 'Duplicate Pricings or Overlapped Active Dates',
        1003: 'Partnership pricing not found',
        1004: 'Failed to delete partnership pricing',
        1005: 'Failed to generate lane ID',
        1006: 'Partnership lane empty',
      },
      savePartnershipPricings: {
        422: 'Invalid CSV parameter/attributes',
        1001: 'Invalid Transporter',
        1002: 'Duplicate Pricing',
        1003: 'Partnership pricing not found',
        1004: 'Failed to deletepartnership pricing',
        1005: 'Failed to generate lane ID',
        1006: 'Partnership lane empty',
      },
      searchCompanyHandle: {
        1: 'Vendor is not found, please re-insert the correct Vendor Registration ID',
      },
      createFirstMileInvoice: {
        422: 'Invoice number has been used before, it must be unique',
      },
      saveWarehouse: {
        422: 'Some fields are already exist',
        490: 'Location ID already taken in company',
        491: 'Location code already taken in company',
      },
      saveWarehouses: {
        422: 'Invalid CSV parameter/attributes',
        490: 'Location ID already taken in company',
        491: 'Location code already taken in company',
      },
      saveWarehouseFee: {
        45: 'Location Fee already exists with same Fee Type, Shipment Type, Truck Type, and Body Type',
        46: 'Location Fee already exists with same Fee Type, Shipment Type, Truck Type, and Body Type',
      },
      saveBrokerageVendor: {
        34: 'Please use only alpha numeric and lower case for Registration ID',
      },
      verifyVendor: {
        1: 'Vendor not registered',
      },
      updateDeliveryOrderTransporter: {
        62: 'Pickup time/latest pickup time must be smaller than dropoff time.',
        126: 'Pickup Date and Time must be earlier than Latest Pickup Date and Time',
        127: 'Dropoff Date and Time must be later than Pickup Date and Time',
        128: 'Latest Pickup Date and Time must be earlier than Dropoff Date and Time',
      },
      assignPlannedShipment: {
        108: 'Shipment is not assignable(current status is not PLANNED)',
        131: 'Company Vehicle has ongoing shipment',
        132: 'Company Driver has ongoing shipment',
      },
      deactivateCompanyVehicle: {
        2: 'Company vehicle does not exists',
        10: 'Vehicle belongs to a different company',
        57: 'Company Vehicle has ongoing shipment',
      },
      deactivateCompanyDriver: {
        6: 'Company driver does not exists.',
        10: 'Driver belongs to a different company',
        58: 'Company Driver has ongoing shipment',
      },
      assignShipperSalesAccess: {
        465: 'Email already in use',
        500: 'The employee already assigned',
      },
      submitBrokerageDoInvoice: {
        177: 'Invoice number already exists',
      },
      approveReassignmentRequest: {
        30: 'This shipment has no active reassignment',
        31: 'Cannot reassign this shipment',
      },
      updateContainerRo: {
        208: 'Container not found',
        209: 'Document file not found',
        251: 'Container doesn\'t have trucking DO',
        210: 'Invalid date sequence',
        500: 'Internal server error',
      },
      reassignCompletedDtdShipment: {
        250: 'The invoice for this shipment has been created or the payment has been approved. You can only edit when the invoice not been processed.',
      },
      cancelDeliveryOrder: {
        250: 'Invoice for this shipment have been created or Payment Request have been approved. This DO cannot be cancelled',
        241: 'The container for this shipment has been confirmed. This DO cannot be cancelled.',
      },
      deleteShippingRequirement: {
        523: 'Requirement not found',
      },
      registerPartnerAccount: {
        4301: 'User with specified phone number already exists',
        4302: 'User with specified email already exists',
        4303: 'Registration failed',
      },
    },
  },
  search_by_driver_name: 'Search by driver name',
  search_by_name: 'Search by name',
  sales_name: 'Sales Name',
  sales_gmv_target: 'e.g. 125.000.000',
  phone_number_placeholder: 'Type phone number',
  phone_number_example_placeholder: 'e.g. +6281234567891',
  search_invoice_no_or_pic: 'Search Invoice No, AR PIC',
  password_placeholder: 'Type password',
  otp_instruction: 'Type the new password, then insert code\nverification that we send to',
  send_verification_code_success: 'Verification code sent.',
  send_verification_code_failed: 'send_verification_code_failed',
  reset_password_success: 'Reset password successful.',
  some_file_download_failed: 'Failed to download some file',
  choose_shipment_to_create_invoice: 'Choose shipment from a customer',
  select_shipment_to_create_invoice: 'Select Shipment from one Shipper',
  select_shipment_to_be_marked_as_not_invoiceable: 'Select Shipments to be Marked as Not Invoiceable',
  select_dos_for_payemnt_request: 'Select DOs for Payment Request',
  select_payement_req_for_disbursment: 'Select Payment Request for Disbursment',
  select_payement_req_for_mark_as_disbursed: 'Select Payment Request to be Mark as Disbursed',
  customer_central_address_help_field: 'This address will be shown as customer address when creating invoice',
  search_customer: 'Search Customer',
  search_ar_pic: 'Search AR PIC',
  search_panthera_poc: 'Search Panthera Poc',
  search_sales_name: 'Search Sales Name',
  search_employee_name_phone: 'Search Employee Name, Phone',
  search_level: 'Search Level',
  search_tags: 'Search Tags',
  search_aging_period: 'Search Aging Period',
  search_invoice_aging_period: 'Search Invoice Aging Period',
  search_transporter: 'Search Transporter',
  search_monitoring_status: 'Search Monitoring Status',
  search_account_status: 'Search Account Status',
  search_pic: 'Search PIC',
  search_request: 'Search Request Type',
  search_warehouse: 'Search Warehouse',
  search_warehouse_name_or_address: 'Search Warehouse Name / Address',
  choose_customer: 'Choose Customer',
  choose_type_of_goods: 'Choose goods type',
  choose_type_of_goods_name: 'Choose Goods Type Name',
  shipment_allocation_choose_shipper_helper: 'Driver will get notification for this shipment.',
  search_by_license_plate: 'Search by license plate',
  search_by_shipment_number_license_plate: 'Search Shipment No, License Plate',
  search_by_container_no: 'Search by container number',
  search_by_company_name: 'Search Company Name',
  search_by_transporter_name: 'Search Transporter Name',
  search_by_shipper_name_phone: 'Search Shipper Name, Phone',
  search_do_number: 'Search DO Number',
  dont_have_an_account_yet: 'Don\'t have account yet?',
  already_have_an_account: 'Already have account?',
  enter_verification_code_that_we_sent_to: 'Insert verification code that we send to',
  fill_account_details: 'Fill account details',
  fill_company_details: 'Fill company details',
  password_doesnt_match: 'Your password verification doesn\'t match your password',
  welcome_to_vendor_central: 'Welcome to {{name}}!',
  welcome_to_vendor_central_description: 'Fill your company detail to start managing your business with {{name}}',
  welcome_to_platform: 'Enter {{name}}',
  shipment_successfully_saved: 'Shipment successfully saved',
  pod_uploaded_successfully: 'POD successfully saved',
  files_downloaded_successfully: 'Files downloaded successfully',
  shipment_successfully_edited: 'Shipment successfully edited',
  shipper_successfully_saved: 'Enterprise Shipper added',
  shipper_successfully_edit: 'Enterprise Shipper edited',
  sme_shipper_successfully_saved: 'SME Shipper added',
  sme_shipper_successfully_edit: 'SME Shipper edited',
  sme_shipper_mid_mile_must_not_use_referral: 'SME Shipper Mid-Mile shouldn\'t have referral name',
  shipper_failed_to_add: 'Failed to add Enterprise Shipper',
  shipper_failed_to_edit: 'Failed to edit Enterprise Shipper',
  bill_of_lading_successfully_saved: 'Bill of Lading successfully saved',
  leave_this_page_question: 'Are you sure to leave this page?',
  skip_truck_requirement_question: 'Are you sure want to skip Truck Requirement?',
  skip_warehouse_question: 'Are you sure you want to skip Locations?',
  delete_payment_request_warning: 'The DO will be deleted from your payment request',
  leave_form_warning: 'Data will not get saved if you leave this page now.',
  leave_form_warning_2: 'The data you enter in this page will not be saved',
  leave_pod_form_warning: 'You\'ll lost all the data you\'ve inputted so far.',
  leave_shipment_form_warning: 'Shipment will not get saved if you leave this page now.',
  leave_shipper_form_warning: 'Shipper will not get saved if you leave this page now.',
  leave_location_form_warning: 'Location will not get saved if you leave this page now.',
  leave_bill_of_lading_form_warning: 'Bill of Lading will not get saved if you leave this page now.',
  upload_assignment_csv_instruction: {
    1: 'Upload your Bulk Assign CSV, you only able to upload 1 CSV file at a time',
    2: 'Click Process CSV button below, the system will start to check your input',
    3: 'The shipment assignment that passed validation will immediately be processed and assigned or planned depending on your input',
    4: 'If there are any errors, the system will output a CSV Error Report. Download this file and fix all the errors shown there',
  },
  user_access_type_description_map: {
    FULL_ACCESS: 'Access to see, edit, and manage other employees.',
    FINANCE:
      'Employee can manage post-shipment processes, including payment request approval,'
      + ' and payment disbursements.',
    FINANCE_ADMIN:
      'Employee can manage post-shipment processes, including payment request approval,'
      + ' and payment disbursements.',
    VENDOR_MANAGEMENT: 'Employee can register new transporter, truck, and driver and verify the related documents.',
    TRANSPORT_PLANNER:
      'Employee can manage the pre-shipment processes, including choosing the winner,'
      + ' allocating manually, and assigning truck and driver.',
    RESTRICTED_ACCESS: 'Access to see shipment and payment information.',
    SALES: 'Employee can register new shippers, locations, fees, and create shipments on behalf of the shippers.',
    SALES_SUPPORT:
      'Employee can register new shippers, locations, fees, but cannot create shipments on behalf of the shippers.',
    SME_SALES_ADMIN: 'Employee can add and remove Sales Team member data.',
    SME_SALES_LEAD: 'Employee can access Sales App and has read-only access to BTMS (Vehicle, Shipment, Shippers Tab).',
    AR:
      'Employee can manage post-shipment process, including creating invoices, monitor'
      + ' receivables and payment agings, and sending invoice reminders to Shippers.',
    TRAFFIC_CONTROLLER:
      'Employee can manage on-shipment processes, including live shipment monitoring,'
      + ' updating shipment timestamps, and reviewing driver’s uploaded documents.',
    DOCUMENT_COLLECTION:
      'Employee can manage post-shipment processes, including uploading document,'
      + ' monitoring document deliveries, and request payments.',
    ACCOUNT_PAYABLE:
      'Employee can manage post-shipment processes, including approving shipment fees,'
      + ' generating payment requests, and reviewing uploaded documents.',
    FREIGHT_FORWARDER: '-',
    CONSIGNEE: '-',
    DANONE_LOGISTIC: 'Access to logistics specific modules',
    DANONE_PROCUREMENT: 'Access to procurement specific modules',
    KARGO_SHIPPER_SALES: 'Kargo Shipper Sales',
    TRANSPORTER_PRICING_VIEWER: 'Employee can view Transporter Pricing page but can not modify any pricing data.',
    TRANSPORTER_PRICING_MANAGER: 'Employee can view Transporter Pricing page and modify any pricing data.',
    RISK: 'Employee can monitor all pre-shipment, on-shipment and post-shipment activities and access the risk dashboard for credit control',
    LEGAL: 'Employee can review, approve, hold, or reject quotations created by Sales',
  },
  status_successfully_changed: 'Status successfully changed',
  add_new_shipper: 'Add Shippers to start managing shipper profiles',
  add_shipper_profile: 'Let\'s Add a location fee to complete your shipper profile',
  add_shipper_warehouse: 'There are no Warehouse for this Shipper. Let\'s Add a warehouse',
  add_prices_profile: 'Let’s add price to complete your shipper profile.',
  add_contract_transporter: 'Add a contract to start managing your contracts here.',
  add_prices_contract: 'Add prices for this contract now',
  add_prices_contract_details: 'Add prices to start assigning this contract to a shipment.',
  add_platform_access_title: 'Add shipper team or sales team to enable this shipper to use Shipper Platform',
  platform_access_kargo_sales_warning: 'This sales will be able to access this Shipper\'s Platform',
  platform_access_activate_message: 'Activating user can ennable their access in using the Shipper Platform.',
  platform_access_deactivate_message: 'Deactivating user can remove their access in using the Shipper Platform.',
  shipment_successfully_cancelled: 'Shipment successfully cancelled',
  cancel_do_warning: 'This shipment will still be saved, but will not be able to be re-actived.',
  cancellation_do_warning: 'Transporter will get notified about your cancellation.',
  bill_of_lading_successfully_deleted: 'Bill of Lading successfully deleted',
  delete_bill_of_lading_warning:
    'Bill of Lading and all data related (including document and container)'
    + ' will be deleted. This action is permanent.',
  password_successfully_changed: 'Password successfully changed',
  bank_account_successfully_saved: 'Bank account successfully saved',
  choose_account_number: 'Choose bank account number',
  company_entity_successfully_saved: 'New Entity successfully saved',
  shipment_fee_successfully_added: 'Shipment fee successfully added.',
  select_container_to_remove: 'Select The Container You Want to Remove',
  remove_all_container_cancel_spl:
    'By removing all containers, you also cancel the Shipping Line DO and it cannot be undone.',
  remove_all_container_title: 'You\'re About to Remove All Containers ',
  remove_all_container_description:
    'This will cancel the Shipping Line Booking DO and all data will be deleted from the Vessel Booked tab.',
  remove_partial_container_description:
    'The selected container will be removed from the Shipping Line DO and moved to the To Be Processed table.',
  search_by_invoice_number: 'Search invoice by invoice number',
  change_shipment_status_message_title: 'Change status will...',
  transporter_address_for_invoice_helper: 'Add customer address that will be added in invoice.',
  only_kargo_admin_can_edit: 'Only Kargo Admin can edit this information.',
  logo_upload_helper: 'Logo must be in .jpg or .png format, minimal 300 px.',
  add_account_helper_text: 'Add bank account number to be added in invoice',
  ineligible_dof_shipment_status: 'You can only file shipment if at least the POL is already received',
  shipment_already_requested_for_financing: 'Shipment already requested for financing',
  swift_payment_price_tnc: 'Disbursement payment will be calculated based on evaluation and your agreement',
  shipper_already_included_on_selected_dof_request: 'You can only file one customer per request',
  does_document_meet_requirements: 'Does the document meet requirements?',
  please_resubmit_rejected_requests: 'Please re sumbit the rejected requests',
  please_resubmit_rejected_documents: 'Please resubmit the rejected documents',
  invoice_will_be_shown_here: 'Invoice will be shown here',
  enter_invoice_number: 'Input your invoice number',
  delete_shipment_from_invoice_warning: 'Shipment and document that you submitted for this invoice will also deleted.',
  select_truct_first_to_change_status: 'Select truck first before update status',
  unable_to_change_delivery_details: 'Unable to change delivery order details because it\'s registed on financing',
  not_registered_with_kargo_dof_program: 'You are not registered on Kargo\'s DOF program',
  not_registered_with_kargo_podf_program: 'You are not registered on Kargo\'s PODF program',
  no_invoice_approved: 'No approved invoice',
  no_invoice_rejected: 'No rejected invoice',
  file_should_not_contain_mixed_file_types: 'File should not contain mixed file types',
  document_has_invalid_file_type: 'Document has invalid file type!',
  invoice_pdf_should_only_one_file: 'Invoice pdf should only contain one pdf file',
  invoice_receipt_successfully_added: 'Invoice receipt successfully added',
  payment_request_invoice: 'Payment Request Invoice added successfully',
  payment_request_submit: 'Payment Request submit successfully',
  disburse_request_submit: ' Disburse Request submit successfully',
  disbursement_request_hold_modal_info:
    'All of the approved fee will still be eligible for disbursement. Select the on hold status that you want to show to the Transporter.',
  payment_request_invoice_for_preview: 'Invoice added for preview',
  search_scheme_name: 'Search Scheme',
  search_disbursement_scheme_name_placeholder: 'Search scheme name',
  we_will_send_temporary_password:
    'We will send temporary password to the {{name}}\'s phone number.'
    + ' {{name}} can later sign in and change the password.',
  add_shipper_team_confirmation:
    'By confirming this action, you\'re enabling your shipper to use Shipper Platform.'
    + ' Ask your shipper to open ',
  using_this_phone_number_to_activate: 'using this phone number to activate their account.',
  contracted_transporter_price_info:
    'Prices are generated via a contract agreement with your company and selected transporters',
  kargo_will_process_your_do: 'Kargo will be processing your DO. Please wait for the confirmation.',
  choose_container_to_edit: 'Choose container to edit',
  please_check_the_following: 'Please check the following',
  document_not_uploaded: 'Document are not uploaded',
  exceed_shipper_limit: 'Price exceed shipper limit',
  shipment_has_claim: 'This shipment has claims',
  shipment_has_insurance: 'This shipment has insurance',
  registration_id_taken: 'Registration ID already exists',
  save_transporter_info: 'This transporter account will be able to bid and accept in Transporter Marketplace.',
  reject_transporter_info: 'This transporter will get notified about the rejection and asked to reupload the docs.',
  here: 'here',
  change_account_type_confirmation: 'Change {{subject}} type from {{from}} to {{to}}?',
  change_account_type_warning: 'Changing account type will remove the uploaded {{docs}}',
  change_account_type_note: 'Different account type have different required document.',
  company_account_type_changed: '{{company}} account type changed.',
  mark_as_deactive_confirmation: 'Mark {{name}} deactive?',
  mark_as_active_confirmation: 'Mark {{name}} active?',
  transporter_company_status_options_note_map: {
    SUSPENDED: 'Transporter won\'t be able to bid/accept up to several days.',
    BLOCKED: 'Transporter won\'t be able to bid/accept up until the Internal Team re-activates it back.',
  },
  license_plate_type: 'License Plate Type',
  transporter_acctivation_note: 'This transporter will be able to bid/accept again.',
  download_pr_on_submission: 'Download Payment Request file on submission',
  adjustment_will_be_deleted_from_review: 'This adjustment will be deleted from your review',
  adjustment_will_be_deleted_from_pr_review: 'The Adjustment will be deleted from your payment request',
  ontime_perfomace_of_pickup_dropoff: 'On-Time Perfomance of pickup/dropoff',
  please_input_location_first: 'Please Input Location First',
  please_input_location_trucktype_first: 'Please Input Location & Truck type First',
  time_spent_by_truck_at_loading_or_unloading_location: 'Time Spent by truck at loading or unloading location',
  please_input_vendor_first: 'Please Input Vendor First',
  average_time_for_vendor_to_accept_confirm_fulfillment:
    'Average time for a vendor to accept/confirm fulfillment on shipments',
  completed_fulfilled_shipments: 'Completed/Fulfilled shipments',
  please_choose_sorter_period: 'Please choose a shorter period',
  send_reminder_to_whatsapp: 'Send reminder to the Transporter Whatsapp group about this shipment details.',
  search_based_on_various_fields:
    'You can search based on DO Number / Origin City / Vendor Name / License Plate / Driver Phone Number',
  time_frame_setting: 'Set your cutoff time to get remind about which shipment need to be followed up first',
  automatic_unfulfill_shipment: 'The shipment will be automatically unfulfill after passing this time frame',
  cancel_shipment_warning: 'This shipment will be marked as cancelled',
  unfulfill_shipment_warning: 'This shipment will be marked as unfulfilled',
  shipment_unfulfilled: 'Shipment {{doNumber}} Unfulfilled',
  reject_reassignment: 'Transporter will get notified that the reassignment is rejected.',
  trucking_reject_reassignment: 'We will notify Transporter that reassignment request rejected. Please enter the rejection reason.',
  successfully_reassigned: '{{doNumber}} successfully reassigned!',
  successfully_rejected: '{{doNumber}} successfully rejected!',
  sku_removal_msg: 'Removing this SKU will affect some modules.',
  please_fill_the_form: 'Please fill the form before saving',
  csv_upload_failed: 'CSV upload failed, please contact Engineer Team',
  check_if_you_have_collected_these_documents: 'Check if you have collected these documents',
  cancel_job_from_marketplace: 'This shipment will be cancelled in Marketplace and the status will be Unassigned.',
  no_contract_available: 'There is no contract available for this route',
  no_contract_available_help_info:
    'Don\'t worry, we\'ll notify the related team after'
    + ' you created the DO. We\'ll send the DO to Private Marketplace then.',
  no_vendor_in_marketplace: 'No vendor in Private Marketplace',
  select_proforma_issued_date: 'Select Proforma Issued Date',
  select_proforma_approved_date: 'Select Proforma Approved Date',
  select_delivery_service: 'Select Delivery Service',
  select_sent_date: 'Select Sent Date',
  select_received_date: 'Select Received Date',
  select_send_date: 'Select Send Date',
  select_rejection_date: 'Select Rejection Date',
  select_approved_date: 'Select Approved Date',
  input_receiver_name: 'Input Receiver Name',
  invoice_delivery_service_req_document:
    'Documents Include: POD, Claims & Returns,'
    + ' Overnight Loading, and Overnight Unloading',
  choose_invoice_receive_proof_file: 'Choose Invoice Received Proof File',
  choose_airway_file: 'Choose Air Waybill (Resi) File',
  choose_proof_file: 'Choose Proof File',
  input_cancelation_timestamp: 'Input Cancelation Timestamp',
  choose_shipper_approval_file: 'Choose Shipper Approval File',
  select_status: 'Select Status',
  invoice_sent_status_is_skipped: 'Invoice Sent Status is Skipped',
  invoice_received_status_is_skipped: 'Invoice Received Status is Skipped',
  some_invoice_status_got_skipped: 'Some Invoice Status got Skipped',
  filled_out_important_invoice_skipped_status:
    'Filled out the important invoice\'s skipped status by clicking \'add log\' text-button',
  winner_successfully_selected: 'Winner Successfully Selected',
  price_successfully_added: 'Price Successfully Added',
  price_has_been_input: 'Price and bid limit has been input',
  order_has_expired: 'Order has expired',
  winner_cant_be_set: 'Winner can not be set',
  leave_invoice_page_warning_msg:
    'This invoice will not be generated.'
    + ' You will lose all the data you have inputted so far',
  cancel_this_invoice: 'Cancel this invoice?',
  deactivate_sales_message:
    'Deactivating user can remove their access in using the'
    + ' Field Sales App, all their data from app should be transferred to another Sales.',
  activate_sales_message:
    'This sales will be able to login again in Sales app using the password'
    + ' that he has previously or use the forgot password button.',
  reassign_message_title: 'The shipper that previously handled by this sales need to be reassigned',
  change_team_leader: 'Change Team Leader',
  assign_all_truck_title: 'Assign all trucks registered to this Transporter',
  assign_all_truck_desc: 'All trucks registered to this Transporter will be available for assignment.',
  assign_some_truck_title: 'Use Truck Slots',
  assign_some_truck_desc: 'Only trucks allocated to these truck slots will be available for assignment.',
  assign_all_driver_title: 'Assign all drivers registered to this Transporter',
  assign_all_driver_desc: 'All drivers registered to this Transporter will be available for assignment.',
  assign_some_driver_title: 'Select Drivers',
  assign_some_driver_desc: 'Only selected drivers will be available for assignment.',
  mass_do_cancellation_warning: 'Once you delete the Orders, The Order will be inactive.',
  processing_mass_do_cancellation: 'Processing your Mass DO Cancellation.....',
  delete_gps_device_desc:
    'This action is permanent and cannot be reversed. You can still re-add the'
    + ' GPS device by filling in the exact same details by clicking on the "Add GPS Device" button.',
  yes_number_not_inputted: 'Yes (Number is not inputted yet)',
  exclusive_shipment_info_desc: 'Shipments with exclusive agreement will only be shown to contracted transporters.',
  non_exclusive_shipment_info_desc:
    'Shipments with non-exclusive agreement will be shown to the rest of'
    + ' the marketplace if the last transporter tier doesn’t accept the shipment.',
  calculate_tax_for_all_shipment: 'Calculate Tax for All Shipments',
  calculate_tax_for_all_items: 'Calculate Tax for All Items',
  calculating_tax_to_all_shipment_info: 'Calculating Tax to all shipments will override the current tax setting',
  make_all_paid_vat_zero: 'Make all paid VAT as Rp 0',
  save_transporter_level_info: {
    [TRANSPORTER_VERIFICATION_LEVEL.TRUSTED]:
      'This transporter account will be able to bid and accept in Transporter Marketplace.',
    [TRANSPORTER_VERIFICATION_LEVEL.VERIFIED]: 'This transporter account will be able bid in Transporter Marketplace',
  },
  download_soa_using_kargo_template: 'Or download SoA using Kargo\'s template',
  no_recipient_is_registered_for_shipper: 'No recipient is registered for this shipper',
  set_up_recipient_first: 'Set up the recipient in contact section under company details to send reminder',
  neither_reminder_is_checked: 'Neither Reminder via email nor whatsapp is checked for this shipper',
  set_up_reminder_via_first: 'Set up the reminder via under payment section in shipper profile to send reminder',
  no_activities_recorded: 'No Activities Recorded',
  ensure_tax_all_do: 'Please ensure tax collection has been added to all DO',
  ensure_do_adjusted_accordingly: 'Please ensure the DO Price is adjusted accordingly:',
  pr_has_price_adjustment: 'PR has Price Adjustment',
  do_payment_is_automated: 'DO Payment is automated',
  you_cant_edit_do_automated: 'You can\'t edit this DO, contact admin for more information',
  payment_is_automated: 'Payment is automated',
  you_cant_choose_this_do: 'You can\'t choose this DO',
  review_do_manually_confirmation:
    'You won’t be able to review it automatically anymore once you submit this confirmation.',
  configuration_for_transporter_disbursement: 'Configuration for Transporter Disbursement',
  setting_full_access_only: 'Settings only accessible for Full Access',
  settings_full_and_finance_access_only: 'Settings only accessible for Full Access or Finance Admin',
  reallocate_shipment_question: 'Reallocate Shipment {{doNumber}}',
  reallocate_shipment_message:
    '{{doNumber}} will be rolled back to Planned Queued status to Truck {{licensePlate}}'
    + ' and Driver\'s {{driverName}} since driver has an ongoing shipment',
  trucking_reassignment_message:
    '{{doNumber}} will reassigned to Truck {{licensePlate}}'
    + ' with Driver {{driverName}}. DO status will move to Planned Queued.',
  shipment_successfully_added: 'You\'ve added new Shipment(s)',
  automated_disbursement_will_be_turned: 'All of the automated disbursements after this action will be {{action}}',
  shipment_does_not_require_pod_physical: 'This shipment does not required Physical POD to be disbursed',
  transporter_incentive_csv_upload_error_msg_map: {
    start: 'Correct entries has been submitted, please fix {{lines}} lines from ',
    link: 'this sheets',
    end: ' and reupload to complete the submission',
  },
  search_ledger_table: 'Search Item Code, Incentive ID, PR Number',
  will_be_moved_to_incentive_approved: 'will be moved to incentive approved calculation.',
  will_be_removed_to_pending_calculation: 'will be remove from pending calculation.',
  search_account: 'Search Account',
  search_business_line: 'Search Business Line',
  do_has_been_reallocated: 'DO has been reallocated',
  do_has_been_cancelled: 'DO has been cancelled',
  please_recheck_payment_detail: 'Please re-check the payment detail.',
  minimum_incentive_pr: 'Minimum amount should be greater or equal than Rp 10.000',
  total_price_including_vat: 'Total Price (Including VAT if any)',
  total_price_with_tax_details: 'Total Price with Tax Details',
  business_line_balance_is_lower_than_limit: '{{businessLine}}\'s Incentive Amount is less than {{limit}}',
  bulk_pr_info_message: 'This request will be created into separated PRs following the row input',
  add_bulk_description_msg: 'Adding Descrption to all Transporter will override the current description input',
  choose_invoice_to_be_sent_optional: 'Choose Invoice to be sent (Optional)',
  select_transporter_accounts_to_continue: 'Select Transporter Accounts to continue',
  please_click_and_hold_the_pod_file:
    'Please click and hold [Drag] the POD file that you want to moved and then drop it on the new position you wanted.',
  new_position_saved: 'New position has been saved',
  failed_to_save_new_position: 'Failed to save new position',
  reassignment_already_approved: 'Reassignment already approved',
  has_no_active_reassignment: 'This shipment has no active reassignment',
  cannot_reassign_shipment: 'Cannot Reassign this Shipment',
  invalid_reassign_request: 'Invalid Reassignment Request',
  wht_is_disable_for_sme_referral: 'WHT is not available for SME Referral Shipments',
  active_do_tooltip: 'Hover or click on data to view shipment details.',
  configuration_for_tax_percentage_options: 'Configuration for Tax Percantage Options',
  configuration_for_minimum_disbursement_options: 'Configuration Setting for Minimum Disbursement Amount',
  do_in_invoice_has_claims: 'DO in invoice has claims',
  please_check_the_claim_issues_before_creating_invoice: 'Please check the claim issues before creating invoice',
  backdated_upload_success: 'Create DO backdate mark as completed success',
  sent_document_url_invalid_msg: 'Sent Document URL is invalid',
  you_can_add_trucking_do_note: 'You can add Trucking DO Note when related Trucking DO has been created',
  container_multiselect_message: 'You can only select DO Number with the same Port of Loading and Port of Discharge',
  select_do_number_shipping_line: 'Select DO Number(s) to book Shipping Line',
  select_do_post_private_mp: 'Select DO Number(s) to Post to Private Marketplace',
  dtd_job_created: 'Door-to-door Purchase Order has been created!',
  max_file_upload: 'Maximum upload each PDF/PNG/JPEG file size: 5 MB',
  max_file_upload_png_jpeg: 'Maximum upload each PNG/JPEG file size: 5 MB',
  shipper_group_profile: 'Shipper Group, Profile',
  vessel_booked: 'Vessel Booking Success!',
  enter_container_number: 'Enter container number',
  enter_seal_number: 'Enter seal number',
  enter_container_yard_location: 'Enter container yard location',
  ro_expired: 'RO Release Date will be used for Pickup Date in Trucking Subtab',
  booking_shipping_line: 'Book Shipping Line will create Door-to-door Trucking DO automatically',
  one_ro_for_all: 'RO and Expiration Date of Container 1 will be applied to all containers',
  do_status_changed_to: '{{ doNumber }} status will be changed to',
  container_tags_edited: '{{jobNumber}} tags edited',
  confirm_container_header: 'Just confirm the container(s) in Actual Loading from Shipping Line',
  confirm_container_message: 'You can remove the container from Shipping Line and the container will be deleted from the table list.',
  first_step_header: 'Confirm Container',
  first_step_message: 'Select container(s) to be included in the Shipping Instruction',
  second_step_header: 'Enter Details',
  second_step_message: 'Enter details to complete the Shipping Instruction information',
  third_step_header: 'Review Shipping Instruction',
  third_step_message: 'Make sure you have entered the correct details',
  moving_shipper_group_in_list:
    'Moving shipper across group will move its invoicing data to the new one. Old data still can be found in the previous shipper group ',
  search_transaction_id_acc_number: 'Search Transaction ID, Account Number',
  default_va_set_success: 'Default Virtual Account set success',
  shipper_group_successful_save: 'Shipper Group Successfully saved',
  shipper_group_successful_edit: 'Shipper Group Successfully edited',
  generating_pdf: 'Generating PDF...',
  enter_additional_note_here: 'Enter additional note here',
  container_confirm_success: '{{ jobNumber }} container(s) successfully confirmed!',
  remove_container_title: 'Removed container(s) will be separated from Shipping Line DO',
  remove_container_message: 'You can re-book or unfulfill the removed container(s) later',
  remove_container_confirm_message: 'Make sure you confirm or remove the correct container(s)',
  email_example_placeholder: 'e.g. mail@address.com',
  complete_consignee: 'Please complete Consignee details for each shipper',
  instruction_created_multiple_shipper: 'Shipping Instruction will be created for each shipper',
  please_enter: 'Please enter {{object}}',
  shipper_do_need_attention:
    'Some DOs from {{shipperName}} are already added to the shipper group Invoice, please mark the invoice(s) as paid or cancel it to resolve this.',
  shipping_instruction_not_created: 'Shipping Instruction Not Created Yet',
  create_shipping_instruction_before: 'Create Shipping Instruction before you upload Invoice or Bill of Lading',
  delete_invoice_template_warning:
    'Deleting this template will not affect any existing invoice, but the action cannot be undone so you will need to re-add it if needed',
  you_can_add_terminal_handling:
    'You can add Terminal Handling Charge and Low Sulfur Surcharge after uploading Ocean Freight',
  not_available_yet_for_shipper_invoicing_group: 'Not available yet for shipper group',
  add_transit_vessel_voyage_placeholder: 'Enter transit vessel/voyage',
  add_remarks_placeholder: 'Enter remark',
  invoice_add_document_warning:
    'Make sure the Total Amount is correct because it is not editable once it has been approved by Account Payable',
  invoice_template_change_modal_title: 'Change the template ?',
  invoice_template_change_modal_msg: 'Changing this template will remove the input that you have made in the columns',
  shipping_instruction_cannot_created: 'Shipping Instruction Cannot be Created',
  cant_created_si_dialog_message:
    'Shipping Instruction requires Container Number and Seal Number.\nFor more information, please contact related Trucking Traffic Controller',
  settings_full_finance_access_ar_only:
    'Add template only accessible for Full Access, Finance Admin, Finance or Account Receivable',
  disbursement_status_code_suggestion: {
    [DISBURSEMENT_STATUS_CODE.INSUFFICIENT_BALANCE]:
      'Please make sure you have sufficient balance to make the disbursement, then retry',
    [DISBURSEMENT_STATUS_CODE.UNKNOWN_BANK_NETWORK_ERROR]:
      'Sorry, an error has occured and we\'re unable to procees your request. Please retry in an hour',
    [DISBURSEMENT_STATUS_CODE.TEMPORARY_BANK_NETWORK_ERROR]:
      'Sorry, an error has occured and we\'re unable to procees your request. Please retry in 1-3 hours',
    [DISBURSEMENT_STATUS_CODE.SWITCHING_NETWORK_ERROR]:
      'Please make sure the destination bank account is active and able to receive funds, then retry in 1-3 hours',
    [DISBURSEMENT_STATUS_CODE.INVALID_DESTINATION]:
      'Please make sure that you enter the correct destination bank account, then retry the disbursement',
    [DISBURSEMENT_STATUS_CODE.REJECTED_BY_BANK]:
      'Please make sure the destination bank account is active and able to receive the disbursement amount, then retry',
    [DISBURSEMENT_STATUS_CODE.TRANSFER_ERROR]:
      'Sorry, we\'re unable to process your request. Please contact Admin for further information',
    [DISBURSEMENT_STATUS_CODE.TEMPORARY_TRANSFER_ERROR]:
      'Sorry, an error has occured and we\'re unable to procees your request. Please retry in 1-2 hours',
    [DISBURSEMENT_STATUS_CODE.UNKNOWN_ERROR]:
      'Unknown Error has occured. Please contact Admin for further information.',
  },
  search_invoice_number: 'Search Invoice Number',
  search_invoice_no_or_ar_pic: 'Search Invoice No, DO Number, VCC',
  revert_warning:
    'Reverting back the status will overwrite all of the data that are displayed on the tracker. The historical log will still exist in the update log.',
  max_custom_columns_met: 'Already met the maximum 10 columns',
  transporter_price_does_not_exist: `This route's Transporter price does not exist yet.`,
  check_transporter_contract: `Please check again the transporter's contract.`,
  title_no_contract_found: 'No Contract Found',
  desc_no_contract_found: 'To allocate shipment, you can add a contract first',
  title_banner_edit_status_for_shipment_completed: 'Set Free Time Storage and Free Time Demurrage',
  desc_banner_edit_status_for_shipment_completed:
    'Free Time Storage and Free Time Demurrage will start from Actual Berthing Time',
  transporter,
  you_can_change_doagent_by_editing_the_si: 'You can change Dooring Agent by editing the Shipping Instruction',
  notice_of_arrival_successfully_copied: 'Notice of Arrival successfully copied!',
  noa_message:
    'NOA\n{{containers}}{{containerType}}\n\nShipper: {{ship}}\nRoute: {{stuffing}} - {{destination}}\nShipping Line: {{shippingLine}}\nVessel/Voyage: {{vessel}}\nActual Departure: {{actualDeparture}}\nEstimated Time of Arrival: {{estimatedArrival}}\nDooring Agent: {{consignee}}\nDestination WH: {{destinationWh}}\nBill of Lading: {{bol}}',
  upload_shipper_price_quotation: 'Make sure the uploaded file matches the quotation type',
  send_proforma_success: 'Send Proforma Success',
  planned_dooring_date_added_successfully: 'Planned Dooring Date successfully added!',
  create_shipping_instruction_before_noa: 'To create Notice of Arrival, Shipping Instruction must be created',
  e_invoice_request: 'This E-Invoice will be sent to TekenAja Platform to be signed by the signer',
  invoice_value_below_materai_requirement: 'Invoice value below Rp5.000.000 doesn\'t require meterai',
  invoice_materai_not_required: 'Meterai is not needed for this Invoice',
  estimated_berthing_info_si_form: 'The date used to create DO 3 for each container in this vessel/voyage',
  tooltip_message_da_search_table: 'Search by container number, voyage, DO number, or license plate',
  change_dooring_agent: 'To change Dooring Agent (Transporter), contact Shipping Instruction PIC',
  tax_has_been_calculated:
    'Tax that has been calculated before will be deleted, and you will need to calculate it again',
  change_taxable_item_confirmation: 'Are you sure to change the taxable item?',
  unable_to_save_project_status: 'You need to change the status first to save',
  update_activity_log_success: 'Activity log successfully updated',
  etd_should_not_be_earlier_than_close_date: 'ETD should not be earlier than Close Date',
  unable_to_reallocate_completed_dooring_shipment: 'Unable to edit Shipping Instruction due to completed Dooring DO',
  additional_fee_restriction: 'Additional fee cannot be edited because the disbursement has been processed',
  send_e_invoice_to_shipper_success: 'E-Invoice successfully sent to Shipper',
  send_e_invoice_request_success: 'E-Invoice successfully requested',
  selected_deliver_document_tooltip_message:
    'You can Only select shipments with the same type, shipper name, and recipient',
  selected_multiple_download_tooltip_message: 'You can only select shipments with the same type and shipper',
  unable_to_cancel_container_tooltip_message: 'This container can\'t be removed.',
  cancel_do_reason: 'Enter cancellation description',
  all_containers_removed_spl: 'All containers will be removed from Shipping Line DO.',
  can_rebook_removed_container: 'You can re-book the removed container(s) from To be Processed table',
  cannot_upload_pod:
    'To upload Proof of Delivery, please deliver Proof of Loading first. Click the Review button to continue the upload document process',
  vessel_voyage_not_exist: 'No Vessel or Voyage Found',
  create_new_booking: 'Create a new booking to start the Shipping Line booking process',
  vessel_voyage_not_found: 'No Result Found',
  try_another_vessel_voyage:
    'Try another vessel/voyage or create a new booking to start the Shipping Line booking process',
  vessel_name_already_entered: 'Vessel name has been entered',
  uploading_coordinate_outside_radius: 'Uploading Coordinate is Outside the radius',
  make_sure_driver_actually_upload_doc: 'Please make sure the driver is actually uploading the real {{doc}}',
  disbursement_automated_is_turned_off: 'Disbursement Automation is automatically turned off',
  automated_disbursement_not_automated_reason: {
    POL_NON_APPROVED: 'POL is Not Approved by Traffic Controller',
    POL_NON_DRIVER_APP: 'POL is Not Uploaded via Driver App',
    POL_OUTSIDE_GEOFENCE: 'POL Uploading Coordinate is Outside the radius, but has been approved by Traffic Controller',
    POL_INVALID: 'POL is Invalid',
    POL_MISSING: 'POL(s) not submitted yet',
    POD_NON_APPROVED: 'POD is Not Approved by Traffic Controller',
    POD_NON_DRIVER_APP: 'POD is Not Uploaded via Driver App',
    POD_OUTSIDE_GEOFENCE: 'POD Uploading Coordinate is Outside the radius, but has been approved by Traffic Controller',
    POD_INVALID: 'POD is Invalid',
    POD_MISSING: 'POD(s) not submitted yet',
    PICKUP_POINT_NON_WAREHOUSE:
      'This shipment origin/destination is not a warehouse, can’t determine the radius of uploading POL/POD area.',
    DROPOFF_POINT_NON_WAREHOUSE:
      'This shipment origin/destination is not a warehouse, can’t determine the radius of uploading POL/POD area.',
  },
  multi_shipment_scopes_title: 'Invoice has First Mile & Mid Mile shipments',
  multi_shipment_scopes_desc:
    'This invoice consists of different shipment numbers from First Mile & Mid Mile. Container Number will not be available for Mid Mile shipment',
  shipment_price_exceed_credit_limit: 'Shipment Price exceeded shipper’s credit limit',
  shipment_creation_is_blocked_credit_limit_exceed:
    'Shipment creation is blocked because shipper’s credit is {{totalOutstanding}} exceeding the current limit {{creditLimit}}',
  shipment_price_will_exceed_credit_limit: 'Shipment Price will Exceed shipper’s credit limit',
  shipment_creation_is_blocked_credit_limit_will_exceed:
    'Shipment creation is blocked because shipper’s credit will be {{newTotalOutstanding}} by this submission exceeding the current limit {{creditLimit}}',
  voyage_already_used: 'Voyage name has been used for another shipping line',
  origin_destination_not_warehouse: 'This shipment origin/destination is not a warehouse',
  disbursement_automation_will_be_turned_off: 'Disbursement automation will be turned off',
  physical_doc_has_been_received: 'Physical document has been received',
  this_invoice_from_nft_faktur_pajak: 'This Invoice from NFT contains Faktur Pajak',
  d2d_pricing_duplicate: 'This route has an existing price. To continue, please change route location',
  input_entity_before_selecting_the_shipper_name: 'Please input the entity before selecting the Shipper Name',
  confirm_to_mark_do_as_not_invoiceable: 'Confirm to mark the DO(s) as Not Invoiceable?',
  change_status_back_to_not_invoiced: 'Change status back to Not Invoiced?',
  this_do_will_not_be_able_to_be_invoiced:
    'This DO(s) will not be able to be invoiced and counted as an outstanding payment',
  this_do_will_be_able_to_be_invoiced: 'This DO will be able to be invoiced again',
  rejection_notes_to_shown_nft: 'Enter rejection notes to be shown in NFT',
  on_hold_notes_to_shown_nft: 'Enter on hold notes to be shown in NFT',
  confirm_to_mark_invoice_on_hold: 'Confirm to mark this invoice as On Hold?',
  received_modal_notes_placeholder: 'Enter notes regarding invoice received',
  mark_as_docs_approved: 'Mark Invoice as Docs Approved immediately',
  invoice_includes_faktur_pajak: 'The Invoice that I received includes Faktur Pajak',
  pickup_location_not_found_system: 'No pickup location in system.\nPlease enter the pickup location',
  shipment_type_not_found_system: 'Shipment type cannot be used.\nTry another shipment type',
  all_data_will_be_erased_and_not_restored: 'All related data will be erased and cannot be restored',
  dropoff_location_not_found_system: 'No dropoff location in system.\nPlease enter the pickup location',
  cancel_payable_invoice_docs_received: 'All of the data that has been inputted will be removed',
  post_to_marketplace_title: 'DO(s) to Post to Private Marketplace',
  post_to_marketplace_subtitle: 'The DO(s) with details below will be posted to private marketplace',
  post_to_marketplace_error: 'To post the following DO(s), ',
  post_to_marketplace_add_price: 'please add contract price for the route',
  only_not_invoice_shipment_numbers_as_not_invoiceable:
    'Only Not Invoiced Shipment Numbers can be marked as Not Invoiceable',
  invoice_number_already_exist_please_contact_pr:
    'Invoice number already exists. Please create PR from the ‘Invoice’ page',
  payment_date_is_not_due_yet: 'Payment Date is not due yet',
  remove_from_private_mp: 'Remove DO from Private Marketplace?',
  closing_date_must_not_below_ro_expiry: 'Closing Date should not be lesser than RO Expiry date',
  do_removed_from_private_mp: 'DO has been removed from private marketplace!',
  some_fees_in_the_do_is_not_reviewed_yet: 'Some fees in the DO is not reviewed yet',
  make_sure_all_of_the_fees: 'Make sure all of the fees in the invoice are reviewed before creating a payment request',
  ro_update_success: 'RO {{doNumber}} successfully updated!',
  cant_update_driver: 'You can’t edit this Driver or License Plate',
  error_250_edit_container_title: 'Cannot Update Container & Seal Number',
  error_250_edit_container_desc:
    'The invoice for this shipment has been created or the payment has been approved. You can only edit when the invoice not been processed.',
  select_fees_to_be_marked_as_bad_debt: 'Select fee(s) to be marked as Bad Debt',
  error_update_ro_between_close_date: 'RO Expired Date must be in between RO Release and Close Date',
  adjustment_is_more: 'The amount is more than the max adjustment',
  number_sample_placeholder: 'e.g. 12',
  containers_need_to_have_container_number: 'All containers must have container number to create Shipping Instruction!',
  leave_si_form_message_confirm_container: 'Data will not get saved if you leave this page now',
  leave_si_form_message_input_detail: 'Container Confirmation Data will not get saved if you leave this page now',
  remove_container_banner_description: 'By clicking "Next" button, you will remove container(s) from Shipping Line DO or click "Undo Remove" to keep container(s) in this Shipping Line DO.',
  reconciliation_status_message: 'The invoice payment status will only be changed in the B-TMS once you submit this',
  this_action_cannot_be_undone_va: 'This action cannot be undone and you will have to re-add it if needed',
  amount_exceeds_va_balance: 'Amount exceeds VA balance',
  select_do_surat_jalan: 'Select DO Number(s) to Generate and Print Surat Jalan',
  update_successfull: 'Information successfully updated!',
  shipment_route_change: 'Select shipment route container you want to edit',
  edit_shipment_banner_info: 'Edit shipment route will create new Purchase Order with different route.',
  contract_pricing_si: 'Turn ON toggle to inform the Shipping Line about the Contract Price via Generated SI',
  shipper_name_si: 'Enter shipper name on SI',
  select_container_same_status: 'Select container with same status',
  cannot_edit_assigned_dooring_agent: 'Cannot edit assigned Dooring Agent',
  spl_status_empty_containers_title: `You can't complete a shipment without a container`,
  spl_status_empty_containers_desc: `Please add container and then create Shipping Instruction to complete the shipment`,
  spl_status_missing_si_title: `You can't complete a shipment without Shipping Instruction`,
  spl_status_missing_si_desc: 'Please create or update the Shipping Instruction to complete the shipment',
  bulk_upload: {
    check_upload_file_description: 'Make sure you use comma (,) in the CSV document that you uploaded below.',
    instruction: {
      1: '1. Download this CSV document template',
      2: '2. Fill in the transporter pricing data',
      3: '3. Save the file format as .csv and make sure you use comma (,) to separate items',
      4: '4. Upload the CSV document from the filled template here',
    },
    invalid_format: 'Error Template CSV',
    invalid_headers_description: 'Please use the appropriate CSV Template as provided.',
  },
  shipment_point_incomplete: 'Shipment point incomplete',
  payment_successfully_recorded: 'Payment successfully recorded',
  action_on_this_shipper_is_currently_disabled: 'Please use UPI Dashboard for this Shipper',
  credit_limit_has_not_been_setup: 'Credit limit has not been set up by authorized Team',
  please_contact_credit_limit: 'Please contact the authorized team to follow up shipper’s credit limit',
  project_credit_limit_not_setup: 'This Projects Credit Limit is Not Set Up.',
  project_credit_limit_over: 'This Projects Credit Limit is Over Limit/Disabled/Terminated.',
  project_credit_limit_running_low: 'This Projects Available Credit is Running Low.',
  please_contact_risk_team: 'Please contact the risk team for further details.',
  kargo_wallet_will_be_enabled: 'Kantong Kargo for this transporter will be {{action}}',
  only_accessible_when_wallet_zero: 'Disabling Kantong Kargo Access is only able to be done when the Active Balance & Requested Balance is {{currencyPrefix}} 0',
  maximum_upload_each_5mb: 'Maximum upload each PDF/PNG/JPEG file size: 5 MB',
  quotation_successfully_sent: 'Quotation Successfully Sent',
  quotation_successfully_updated: 'Quotation Successfully Updated',
  please_input_valid_template: 'Please input valid template',
  select_payment_request_to_continue: 'Select Payment Request to Continue',
  write_the_reason_to_change_payment_method: 'Write the Reason to Change Payment Method',
  im_aware_that_kargo_will_fully_disburse: 'Im aware that Kargo will fully disburse this Payment Request with Xendit',
  are_you_sure_cancel_checking: 'Are you sure you want to Cancel Checking?',
  ongoing_creation_will_be_lost: 'Your ongoing order creation will be  will be interrupted and any progress made will be lost if you proceed.',
  disbursement_schedule_enum_desc: {
    [DISBURSEMENT_PROCESS_ENUM.INSTANT]: 'Processing time under 5 minutes',
    [DISBURSEMENT_PROCESS_ENUM.THURSDAY]: 'Disbursed on the upcoming Thursday',
    [DISBURSEMENT_PROCESS_ENUM.WEEKLY]: 'Disbursed next Thursday +7 day processing',
  },
  bulk_payment_request_selection: {
    title: 'Please Use Predefined Template',
    description: 'Please Upload the DO List Using our Template File Format, Consider these rules:',
    rules: {
      0: '\u2022 The DO Number <strong>Should appears on the Payable Database</strong>',
      1: '\u2022 There should be <strong>no duplicate DO Number</strong> on the same list',
      2: '\u2022 The Job IDs should be from <strong>ONE Transporter</strong>',
    },
  },
  bulk_payment_request_selection_errors: {
    [BULK_PAYMENT_REQUEST_SELECTION_ERROR.WRONG_TEMPLATE]: {
      title: 'Unrecognized Template',
      description: 'System couldn’t process the Order List on the {{template}}. Due unrecognized template uploaded. Please Consider this couple of reasons:',
      reason: {
        0: '\u2022 Header column of the <strong>tables should match</strong> with your latest template.',
        1: '\u2022 Miss-match naming on the sheets of the table.',
      },
    },
    [BULK_PAYMENT_REQUEST_SELECTION_ERROR.SERVER_VALIDATION_ERROR]: {
      title: 'There are {{total}} DO Number(s) are not recognized.',
      description: 'System couldn’t process the Shipment list on the {{template}}, This might happened due to these reason:',
      reason: {
        MULTIPLE_TRANSPORTERS: '\u2022 <strong>DO Number listed NOT coming from single Transporter</strong>',
        NOT_AVAILABLE: '\u2022 DO Number are <strong>not available in the Payable Database</strong>',
        ALREADY_REQUESTED: '\u2022 All fee contained in the <strong>DO Number are already requested in another PR</strong>',
        ALREADY_INVOICED: '\u2022 All fee contained in the <strong>DO Number are already invoiced</strong>',
      },
    },
  },
  bulk_payment_request_selection_success: {
    title: '{{x}} DO Number(s) recognized.',
    description: '{{total}} DO Number(s) that has been defined from the {{template}} are successfully recognized. If there is a duplicated DO on the list, it will be merged into single selection. The Selected DO Numbers contains:',
  },
  bulk_create_adjustment_fee: {
    title: 'Please Use Predefined Template',
    description: 'Please Upload the DO List Using our Template File Format, Consider these rules:',
    rules: {
      0: '\u2022 The DO Number <strong>Should appears on the Payable Database</strong>',
      1: '\u2022 All required <strong>should be filled with suggested format</strong>',
    },
  },
  bulk_create_adjustment_fee_success: {
    title: '{{x}} Adjustment Fee(s) for {{y}} DO Number(s) recognize',
    description: 'File {{template}} contains:',
  },
  bulk_create_adjustment_fee_errors: {
    [BULK_CREATE_ADJUSTMENT_FEE_ERROR.WRONG_TEMPLATE]: {
      title: 'Unrecognized Template',
      description: 'System couldn’t process the Order List on the {{template}}. Due unrecognized template uploaded. Please Consider this couple of reasons:',
      reason: {
        0: '\u2022 Header column of the <strong>tables should match</strong> with your latest template.',
        1: '\u2022 Miss-match naming on the sheets of the table.',
      },
    },
    [BULK_CREATE_ADJUSTMENT_FEE_ERROR.SERVER_VALIDATION_ERROR]: {
      title: 'There are {{total}} Adjustment Fee(s) are not recognized.',
      description: 'System couldn’t process the Adjustment Fee list on the {{template}}, This might happened due to these reason:',
      reason: {
        MULTIPLE_TRANSPORTERS: '\u2022 <strong>DO Number listed NOT coming from single Transporter</strong>',
        NOT_AVAILABLE: '\u2022 DO Number are <strong>not available in the Payable Database</strong>',
        DUPLICATED_DOCUMENT: '\u2022 Certain type of Adjustment <strong>does not allowed duplicates</strong>',
        POSITIVE_VALUE: '\u2022 Certain type of Adjustment are <strong>not meeting the desired value</strong>',
      },
    },
  },
  bulk_update_shipment_fee: {
    title: 'Please Use Predefined Template',
    description: 'Please Upload the DO Number of the shipment fee using our Template File Format, please consider these rules:',
    rules: {
      0: '\u2022 The DO Number should be <strong>appears on the Payable Database</strong>',
      1: '\u2022 The Shipment Fee should be registered as correct <strong>Payment Method</strong>',
      2: '\u2022 All required <strong>column should be filled with suggested format</strong>',
    },
  },
  bulk_update_shipment_fee_success: {
    title: '{{x}} Shipment Fee(s) for {{y}} DO Number(s) recognize',
    description: 'File {{template}} contains:',
  },
  bulk_update_shipment_fee_errors: {
    [BULK_UPDATE_SHIPMENT_FEE_ERROR.WRONG_TEMPLATE]: {
      title: 'Unrecognized Template',
      description: 'System couldn’t process the Order List on the {{template}}. Due unrecognized template uploaded. Please Consider this couple of reasons:',
      reason: {
        0: '\u2022 Header column of the <strong>tables should match</strong> with your latest template.',
        1: '\u2022 Miss-match naming on the sheets of the table.',
      },
    },
    [BULK_UPDATE_SHIPMENT_FEE_ERROR.SERVER_VALIDATION_ERROR]: {
      title: 'There are {{total}} Shipment Fee(s) are not recognized.',
      description: 'System couldn’t process the Shipment Fee list on the {{template}}, This might happened due to these reason:',
      reason: {
        MULTIPLE_TRANSPORTERS: '\u2022 <strong>DO Number listed NOT coming from single Transporter</strong>',
        NOT_AVAILABLE: '\u2022 DO Number are <strong>not available in the Payable Database</strong>',
        FEE_NOT_AVAILABLE: '\u2022 DO Number <strong>does not have selected payment stage</strong>',
        INVALID_FORMAT: '\u2022 Invalid data format',
      },
    },
  },
  are_you_sure_you_want_to_ignore_unrecognize_do: 'Are you sure you want to Ignore Unrecognized DO Number?',
  payment_request_recognize_do_only: 'Your ongoing Payment Request creation will be populated with the recognizable DO Nuber only',
  calculate_from_whole_shipment_fee: 'Calculate from Transporter Price',
  cannot_apply_multiple_rules: 'Couldn’t apply multiple rules',
  bank_account_not_found: 'Bank Account doesnt exists in the transporter',
  must_later: '{{end}} must be later than {{start}}',
  bulk_create_pbi_transporter_invoices_failed: 'Failed to create invoice(s)',
  add_documents_request_selection: {
    title: 'Please Use Predefined Template',
    description: 'Please Upload the DO List Using our Template File Format',
    errors: {
      title: 'Failed to submit',
      description: 'There are error in the data on {{template}}. See the reason in column \'Error Reason\' from sheet below.',
    },
    success: 'Bulk upload additional documents success',
  },
  error_title: 'Something went wrong',
  error_hint: 'Please report this error to our team and we will take a look at it',
};
