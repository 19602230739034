/* eslint-disable max-len */
export default {
  accept_now: 'Accept Now',
  accept_now_consent: 'I have read and agree with the details above',
  accept_x: 'Accept {{x}}',
  bid_x: 'Bid {{x}}',
  bidding_price: 'Bidding Price',
  errors: {
    status_code: {
      26152: 'Shipment Has Been Accepted',
      26151: 'Expired Shipment',
    },
  },
  error_descriptions: {
    status_code: {
      26152: 'This shipment has been accepted by another Sales Support. Please proceed to work on other shipments',
      26151: 'Another vendor has processed this shipment. Please proceed to work on other shipments',
    },
  },
  waiting_for_confirmation: 'Waiting for Confirmation',
  shipment_statuses: {
    accepted: 'Confirmed',
    bid_won: 'Bid Won',
    bidding: 'Bidding',
    draft: 'Waiting for Confirmation',
    expired: 'Expired',
    open_for_bidding: 'Open for Bidding',
    rejected_by_pbi: 'Rejected',
    rejected_by_shipper: 'Rejected by Shipper',
  },
  confirm_shipment: 'Confirm Shipment',
  reject_shipment: 'Reject Shipment',
  keep_shipment: 'Keep Shipment',
  bid_shipment: 'Bid Shipment',
  rejection_reason: 'Rejection Reason',
  rejection_description: 'Rejection Description',
  placeholder_bidding_price: 'Enter the bidding price',
  placeholder_rejection_reason: 'Select rejection reason',
  placeholder_rejection_description: 'Enter rejection description',
  plan_shipment: 'Plan Shipment',
  rejection_reasons_options: {
    DRIVER_NOT_READY: 'Driver is not available',
    VEHICLE_NOT_READY: 'Truck is not available',
    VEHICLE_MAINTENANCE_NEEDED: 'Truck needs to be repaired',
    OTHER_REASON: 'Other',
  },
  error_rejection: 'Please enter rejection description',
  error_bidding_price_min: 'The minimum bidding price must be {{amount}}',
  error_bidding_price_max: 'The maximum bidding price must be {{amount}}',
  message_accept_success: 'Shipment successfully accepted!',
  message_bid_success: 'Shipment successfully bid!',
  ok_understand: 'OK, I Understand',
};
