import { get } from 'lodash-es';
import { Message } from '@kargotech/tms-ui/components';
import { generateClient as commonGenerateClient } from '@kargotech/tms-common/Services/apollo';
import { SERVICES_URL, APP_CONTEXT_STORAGE_KEY } from '~/Configurations/constants';

import i18next from './i18n';
import Auth from '../Configurations/auth';
// import fragmentTypes from '~/Services/apollo/fragmentTypes';

export const generateClient = (uri = '', skipAuth = false) => {
  async function setBearerToken(headers) {
    // get the authentication token from local storage if it exists
    const cognitoCurrentSession = await Auth.currentSession();
    const token = cognitoCurrentSession.getIdToken().getJwtToken();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  }

  const appRoleSetter = () => (localStorage.getItem(APP_CONTEXT_STORAGE_KEY) || 'TRANSPORTER').toLowerCase();
  const applicationIdSetter = () => 'btms';
  const companyKsuidSetter = () => window.localStorage.getItem('currentCompany');
  return commonGenerateClient({
    uri,
    skipAuth,
    bearerTokenSetter: setBearerToken,
    appRoleSetter,
    applicationIdSetter,
    companyKsuidSetter,
    onBackendError: (error, operation) => {
      if (get(operation, 'variables.SKIP_TOAST')) {
        return;
      }
      const GENERAL_ERROR_CODE = [500, 401, '018-5500'];
      if (
        (error.status_code && GENERAL_ERROR_CODE.includes(error.status_code))
        || (error.error_code && GENERAL_ERROR_CODE.includes(error.error_code))
      ) {
        Message.error(i18next.t(`message:errors.backend.common.${error.status_code || error.error_code}`));
      } else if ((error.status_code || error.error_code) && (error.path || []).length > 0) {
        Message.error(i18next.t(`message:errors.backend.${error.path[0]}.${error.status_code || error.error_code}`));
      } else {
        const errorMessage = error?.message ?? i18next.t('message:errors.unexpected');
        Message.error(errorMessage);
      }
    },
    onNetworkError: networkError => (
      networkError.statusCode === 500
        ? Message.error(i18next.t('message:errors.network:500'))
        : Message.error(i18next.t('message:errors.network.common'))
    ),
    onUnexpectedError: () => Message.error(i18next.t('message:errors.unexpected')),
  });
};

/**
 * Object containing constants for various Apollo clients.
 *
 * @readonly
 * @enum {string}
 * @property {string} AUTH
 * @property {string} EVENT
 * @property {string} LOAD
 * @property {string} MASTER_SERVICE
 * @property {string} PANTHERA
 * @property {string} PAYMENT
 * @property {string} PROFILE
 * @property {string} PROFILE_PUBLIC
 * @property {string} PROFILE_TRANSPORTER_URL
 * @property {string} FRAUD
 */
export const APOLLO_CLIENTS = Object.keys(SERVICES_URL).reduce((accumulator, serviceKey) => ({
  ...accumulator,
  [serviceKey]: generateClient(
    SERVICES_URL[serviceKey],
    false,
  ),
}), {});

export default {};
