import { SHIPPER_FORM_TYPES } from '~/Configurations/constants';

export const PO_SEARCH_TYPES = {
  PO_NUMBER: 'PO_NUMBER',
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
  CONTAINER_DO_NUMBER: 'CONTAINER_DO_NUMBER',
  ORDER_NUMBER: 'ORDER_NUMBER',
};

export const MODAL_TYPE = {
  CANCEL_PO: 'CANCEL_PO',
  EDIT_ROUTES_PO: 'EDIT_ROUTES_PO',
  EDIT_ROUTE_FORM: 'EDIT_ROUTE_FORM',
  UPDATE_CONTAINER_INFO: 'UPDATE_CONTAINER_INFO',
  REJECT_NFS_PO: 'REJECT_NFS_PO',
};

export const FIRST_MILE_SHIPMENT_TYPE = {
  DTD: 'DTD',
  FM_EXPORT: 'FM_EXPORT',
  FM_IMPORT: 'FM_IMPORT',
  FM_INBOUND: 'FM_INBOUND',
  FM_OUTBOUND: 'FM_OUTBOUND',
};

export const DEFAULT_STATUS_FILTER_VALUE = [
  FIRST_MILE_SHIPMENT_TYPE.DTD,
  FIRST_MILE_SHIPMENT_TYPE.FM_OUTBOUND,
  FIRST_MILE_SHIPMENT_TYPE.FM_EXPORT,
  FIRST_MILE_SHIPMENT_TYPE.FM_INBOUND,
  FIRST_MILE_SHIPMENT_TYPE.FM_IMPORT,
];

export const statusOptions = t => DEFAULT_STATUS_FILTER_VALUE.map(el => ({
  value: el,
  label: t(`first_mile:first_mile_shipment_type.${el}`),
}));

export const PO_TYPE = {
  DOOR_TO_DOOR: 'DOOR_TO_DOOR',
  FIRST_MILE_TRUCKING: 'FIRST_MILE_TRUCKING',
};

export const NFS_PO_STATUS = {
  CANCELLED: 'CANCELLED',
  ORDER_PENDING: 'ORDER_PENDING',
  ORDER_CONFIRMED: 'ORDER_CONFIRMED',
  REJECTED_BY_TRANSPORTER: 'REJECTED_BY_TRANSPORTER',
};

export const NFS_PO_STATUS_COLOR_SCHEME = {
  [NFS_PO_STATUS.CANCELLED]: 'danger',
  [NFS_PO_STATUS.ORDER_PENDING]: 'primary',
  [NFS_PO_STATUS.ORDER_CONFIRMED]: 'success',
  [NFS_PO_STATUS.REJECTED_BY_TRANSPORTER]: 'danger',
};

export const PO_SOURCE = {
  BTMS: 'BTMS',
  NFS: 'NFS',
};

// Do not sort because of design requirement
export const NFS_PO_REJECTION_REASON = {
  SCHEDULE_NOT_AVAILABLE: 'SCHEDULE_NOT_AVAILABLE',
  TRANSPORTER_NOT_AVAILABLE: 'TRANSPORTER_NOT_AVAILABLE',
  DRIVER_NOT_AVAILABLE: 'DRIVER_NOT_AVAILABLE',
  OTHER_REASON: 'OTHER_REASON',
};

export const DEFAULT_PO_FORM_VALUES = {
  duplicatedFromContainerKsuid: null,
  poType: null,
  type: SHIPPER_FORM_TYPES.CREATE,
  visible: false,
};
