import { PODC_DIGITAL_STATUS, PODC_PHYSICAL_STATUS, POD_STATUS } from '~/Configurations/constants';

export default {
  digital_document: 'Digital Document',
  physical_document: 'Physical Document',
  podc_digital_document_status: {
    [PODC_DIGITAL_STATUS.NOT_UPLOADED]: 'Not Uploaded',
    [PODC_DIGITAL_STATUS.PARTIALLY_UPLOADED]: 'Partially Uploaded',
    [PODC_DIGITAL_STATUS.FULLY_UPLOADED]: 'Fully Uploaded',
    [PODC_DIGITAL_STATUS.PARTIALLY_REVIEWED]: 'Partially Reviewed',
    [PODC_DIGITAL_STATUS.REJECTED]: 'Rejected',
    [PODC_DIGITAL_STATUS.PASS_REVIEW]: 'Pass Review',
  },
  podc_physical_document_status: {
    [PODC_PHYSICAL_STATUS.NOT_RECEIVED]: 'Not Received',
    [PODC_PHYSICAL_STATUS.RECEIVED]: 'Received',
    [PODC_PHYSICAL_STATUS.DOORING_RECEIVED]: 'Dooring Received',
    [PODC_PHYSICAL_STATUS.DELIVERING_TO_DOORING]: 'Delivering to Dooring',
    [PODC_PHYSICAL_STATUS.DELIVERED_TO_DOORING]: 'Delivered to Dooring',
    [PODC_PHYSICAL_STATUS.DELIVERING_TO_AR]: 'Delivering to AR',
    [PODC_PHYSICAL_STATUS.DELIVERED_TO_AR]: 'Delivered to AR',
    [PODC_PHYSICAL_STATUS.DELIVERING_TO_SHIPPER]: 'Delivering to Shipper',
    [PODC_PHYSICAL_STATUS.DELIVERED_TO_SHIPPER]: 'Delivered to Shipper',
    [PODC_PHYSICAL_STATUS.DELIVERING_TO_AR_AND_DELIVERING_TO_SHIPPER]: 'Delivering to AR, Delivering to Shipper',
    [PODC_PHYSICAL_STATUS.DELIVERING_TO_AR_AND_DELIVERED_TO_SHIPPER]: 'Delivering to AR, Delivered to Shipper',
    [PODC_PHYSICAL_STATUS.DELIVERED_TO_AR_AND_DELIVERING_TO_SHIPPER]: 'Delivered to AR, Delivering to Shipper',
    [PODC_PHYSICAL_STATUS.DELIVERED_TO_AR_AND_DELIVERED_TO_SHIPPER]: 'Delivered to AR, Delivered to Shipper',
    [POD_STATUS.DELIVERING_TO_AR_AND_SHIPPER]: 'Delivering to AR, Delivering to Shipper',
    [POD_STATUS.DELIVERED_TO_AR_AND_SHIPPER]: 'Delivered to AR, Delivered to Shipper',
  },
  review_update_documents: 'Review & Update Documents',
};
