import { MATERAI_DOCUMENT_STATUS, PAYMENT_REQUEST_FEE_TYPE } from '../constants';

/* eslint-disable max-len */
export default {
  additional_fee_reviewed: 'Additional Fee Reviewed',
  attach_e_materai: 'Attach e-Materai',
  e_attach_e_materai_confirmation_message: 'Transporter agree to use e-materai for this invoice. Please confirm if you wish to proceed with e-stamp attachment and mark this document as the final invoice.',
  bulk_fee_adjustment: 'Bulk Fee Adjustment',
  bulk_shipment_fee_list: 'Bulk Shipment Fee List',
  e_materai: 'e-Materai',
  eligible_covered_insurance: 'This shipment is eligible to be covered by insurance.',
  hold_invoice_warning: 'Invoices can be on hold only for internal issues. No external (transporter) interference involved.',
  materai_document_status: {
    [MATERAI_DOCUMENT_STATUS.DOCUMENT_METERAI_STAMPED]: 'Attached',
    [MATERAI_DOCUMENT_STATUS.DOCUMENT_EMETERAI_REQUESTED]: 'On Progress',
  },
  not_eligible_covered_insurance: 'This shipment is not eligible to claim because it is not covered by insurance',
  not_setup_covered_insurance: 'The insurance coverage for this shipment has not been set up yet. Please update it on',
  payment_request_fee_title: {
    [PAYMENT_REQUEST_FEE_TYPE.MATERAI_FEE]: 'e-Materai',
    [PAYMENT_REQUEST_FEE_TYPE.QUICK_PAYMENT_DEDUCTION_FEE]: 'Fast Transfer Deduction',
    [PAYMENT_REQUEST_FEE_TYPE.SERVICE_FEE]: 'Service Fee',
  },
  payment_request_fee_description: {
    [PAYMENT_REQUEST_FEE_TYPE.QUICK_PAYMENT_DEDUCTION_FEE]: 'Counted {{percentage}}% from total Payment Request',
  },
  reject_invoice_warning: 'Invoices can be rejected only for external issues involving external parties (transporter).',
  set_up_fee_adjustment: 'Set Up Fee Adjustment',
  shipment_fee_reviewed: 'Shipment Fee Reviewed',
  shipment_fee_status: 'Shipment Fee Status',
};
