/* eslint-disable import/prefer-default-export */

export const PRICE_QUOTATION_TYPES = {
  SHIPPER_PRICE_QUOTATION_SIGNED_BY_BOTH: 'SHIPPER_PRICE_QUOTATION_SIGNED_BY_BOTH',
  SHIPPER_PRICE_QUOTATION_SIGNED_BY_KARGO: 'SHIPPER_PRICE_QUOTATION_SIGNED_BY_KARGO',
};

export const DOCUMENT_TESTID_SUFFIX = {
  [PRICE_QUOTATION_TYPES.SHIPPER_PRICE_QUOTATION_SIGNED_BY_BOTH]: 'SignedByShipperAndKargo',
  [PRICE_QUOTATION_TYPES.SHIPPER_PRICE_QUOTATION_SIGNED_BY_BOTH]: 'SignedByKargo',
};
