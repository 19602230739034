import moment from 'moment-timezone';

/* eslint-disable import/prefer-default-export */
/**
 * get timezone based on datetime
 * @param {String} date - date or datetime that wants to be defined the timezone
 * @returns {String}
 */
export const getTimezone = (date = new Date()) => {
  const time = moment(date).tz(moment.tz.guess());
  return time.tz().split('/').at(1).replace('_', ' ');
};

/**
 * format time with timezone
 * @param {String} date - date or datetime that wants to be defined the timezone
 * @param {String} format - format of the output
 * @returns {String}
 */
export const formatWithTimezone = (date, format, isBracketed = false) => {
  if (!date) return '';
  try {
    const time = moment(date).tz(moment.tz.guess());
    const timezone = time.tz().split('/').at(1).replace('_', ' ');

    if (isBracketed) return `${time.format(format)} [${timezone}]`;
    return `${time.format(format)} ${timezone}`;
  } catch (_) {
    return '';
  }
};

const time = moment().tz(moment.tz.guess());
export const timezone = time.tz().split('/').at(1).replace('_', ' ');
