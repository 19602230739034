import { BROKERAGE_SHIPPER_INVOICE_STATUS } from '~/Configurations/constants';

// eslint-disable-next-line max-len
export const FILE_FORMAT = 'image/jpeg,image/png,application/pdf,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const PDF_MODAL_TYPE = {
  PDF_PREVIEW: 'PDF_PREVIEW',
  PROFORMA: 'PROFORMA',
  E_INVOICE_REQUEST: 'E_INVOICE_REQUEST',
  MANAGE_E_INVOICE: 'MANAGE_E_INVOICE',
};

export const BANK_CODE_TO_NAME = {
  BCA: 'Bank Central Asia',
  BNI: 'Bank Negara Indonesia',
  MANDIRI: 'Bank Mandiri',
  PERMATA: 'Bank Permata',
  SAHABAT_SAMPOERNA: 'Bank Sahabat Sampoerna',
  BRI: 'Bank Rakyat Indonesia',
  CIMB: 'Bank CIMB Niaga',
  BSI: 'Bank Syariah Indonesia',
  BJB: 'Bank Jabar Banten',
};

export const DRAFT_INVOICE_STATUS = {
  DRAFT_CREATED: 'DRAFT_CREATED',
  DOCUMENTS_REQUIRED: 'DOCUMENTS_REQUIRED',
  RECAP_SENT: 'RECAP_SENT',
  RECAP_REPLIED: 'RECAP_REPLIED',
  PROFORMA_SENT: 'PROFORMA_SENT',
  PROFORMA_REPLIED: 'PROFORMA_REPLIED',
  DISPUTE: 'DISPUTE',
  INVOICE_SPLIT: 'INVOICE_SPLIT',
};

export const DIGITAL_INVOICE_STATUS = {
  DIGITAL_INVOICE_REQUESTED: 'DIGITAL_INVOICE_REQUESTED',
  DIGITAL_INVOICE_ISSUED: 'DIGITAL_INVOICE_ISSUED',
  // TODO: Uncomment deleted e-invoice if deleted status is ready
  // DIGITAL_INVOICE_DELETED: 'DIGITAL_INVOICE_DELETED',
  DIGITAL_INVOICE_EXPIRED: 'DIGITAL_INVOICE_EXPIRED',
};

export const ON_GOING_INVOICE_STATUS = {
  GENERATED: 'GENERATED',
  SPV_APPROVED: 'SPV_APPROVED',
  SPV_REJECTED: 'SPV_REJECTED',
  INVOICE_SENT: 'INVOICE_SENT',
  INVOICE_RECEIVED: 'INVOICE_RECEIVED',
  SHIPPER_APPROVED: 'SHIPPER_APPROVED',
  SHIPPER_REJECTED: 'SHIPPER_REJECTED',
  ...DIGITAL_INVOICE_STATUS,
};

export const INVOICE_PAYMENT_STATUS = {
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  PAID: 'PAID',
  BAD_DEBT: 'BAD_DEBT',
};

export const STATUS_AFTER_DIGITAL_INVOICE = {
  INVOICE_SENT: 'INVOICE_SENT',
  INVOICE_RECEIVED: 'INVOICE_RECEIVED',
  SHIPPER_APPROVED: 'SHIPPER_APPROVED',
  SHIPPER_REJECTED: 'SHIPPER_REJECTED',
  ...INVOICE_PAYMENT_STATUS,
};

export const NON_DRAFT_INVOICE_STATUS = {
  ...ON_GOING_INVOICE_STATUS,
  ...INVOICE_PAYMENT_STATUS,
  ...DIGITAL_INVOICE_STATUS,
  CANCELLED: 'CANCELLED',
};

export const INVOICE_STATUS = {
  ...BROKERAGE_SHIPPER_INVOICE_STATUS, // Temporary fix until fully migrated to invoiceStatus
  ...DRAFT_INVOICE_STATUS,
  ...ON_GOING_INVOICE_STATUS,
  ...DIGITAL_INVOICE_STATUS,
  ...INVOICE_PAYMENT_STATUS,
  CANCELLED: 'CANCELLED',
  E_INVOICE: 'E_INVOICE',
};

export const INVOICE_STATUS_SORT_ARRAY = [
  ...Object.keys(DRAFT_INVOICE_STATUS),
  ...Object.keys(ON_GOING_INVOICE_STATUS),
  ...Object.keys(INVOICE_PAYMENT_STATUS),
  INVOICE_STATUS.CANCELLED,
];

export const DISPLAY_MODE = {
  WRITE: 'WRITE',
  READ: 'READ',
};

export const INVOICE_STATUS_NEGATIVES = [
  INVOICE_STATUS.SPV_REJECTED,
  INVOICE_STATUS.SHIPPER_REJECTED,
  INVOICE_STATUS.CANCELLED,
  INVOICE_STATUS.BAD_DEBT,
  INVOICE_STATUS.DIGITAL_INVOICE_DELETED,
  INVOICE_STATUS.DIGITAL_INVOICE_EXPIRED,
];

export const INVOICE_STATUS_COLOR_SCHEME = {
  [INVOICE_STATUS.DRAFT_CREATED]: 'warning',
  [INVOICE_STATUS.DOCUMENTS_REQUIRED]: 'warning',
  [INVOICE_STATUS.RECAP_SENT]: 'primary',
  [INVOICE_STATUS.RECAP_REPLIED]: 'primary',
  [INVOICE_STATUS.PROFORMA_SENT]: 'primary',
  [INVOICE_STATUS.PROFORMA_REPLIED]: 'primary',
  [INVOICE_STATUS.DISPUTE]: 'warning',
  [INVOICE_STATUS.INVOICE_SPLIT]: 'primary',
  [INVOICE_STATUS.GENERATED]: 'warning',
  [INVOICE_STATUS.SPV_APPROVED]: 'primary',
  [INVOICE_STATUS.SPV_REJECTED]: 'danger',
  [INVOICE_STATUS.INVOICE_SENT]: 'primary',
  [INVOICE_STATUS.INVOICE_RECEIVED]: 'primary',
  [INVOICE_STATUS.SHIPPER_APPROVED]: 'primary',
  [INVOICE_STATUS.SHIPPER_REJECTED]: 'danger',
  [INVOICE_STATUS.PARTIALLY_PAID]: 'primary',
  [INVOICE_STATUS.PAID]: 'success',
  [INVOICE_STATUS.BAD_DEBT]: 'danger',
  [INVOICE_STATUS.CANCELLED]: 'danger',
  [INVOICE_STATUS.DIGITAL_INVOICE_REQUESTED]: 'warning',
  [INVOICE_STATUS.DIGITAL_INVOICE_ISSUED]: 'primary',
  [INVOICE_STATUS.DIGITAL_INVOICE_DELETED]: 'danger',
  [INVOICE_STATUS.DIGITAL_INVOICE_EXPIRED]: 'danger',
  // BTMS temporary fix
  [INVOICE_STATUS.INVOICE_GENERATED]: 'warning',
  [INVOICE_STATUS.FINANCE_APPROVED]: 'primary',
  [INVOICE_STATUS.FINANCE_REJECTED]: 'danger',
  [INVOICE_STATUS.PARTIAL_PAID]: 'primary',
};

export const INVOICE_RECOMMENDED_ACTION_STATUS_TARGET = {
  [INVOICE_STATUS.DRAFT_CREATED]: INVOICE_STATUS.GENERATED,
  [INVOICE_STATUS.DOCUMENTS_REQUIRED]: INVOICE_STATUS.GENERATED,
  [INVOICE_STATUS.RECAP_SENT]: INVOICE_STATUS.GENERATED,
  [INVOICE_STATUS.RECAP_REPLIED]: INVOICE_STATUS.GENERATED,
  [INVOICE_STATUS.PROFORMA_SENT]: INVOICE_STATUS.GENERATED,
  [INVOICE_STATUS.PROFORMA_REPLIED]: INVOICE_STATUS.GENERATED,
  [INVOICE_STATUS.DISPUTE]: INVOICE_STATUS.GENERATED,
  [INVOICE_STATUS.INVOICE_SPLIT]: INVOICE_STATUS.GENERATED,
  [INVOICE_STATUS.GENERATED]: INVOICE_STATUS.SPV_APPROVED,
  [INVOICE_STATUS.SPV_APPROVED]: INVOICE_STATUS.DIGITAL_INVOICE_REQUESTED,
  [INVOICE_STATUS.SPV_REJECTED]: INVOICE_STATUS.GENERATED,
  [INVOICE_STATUS.DIGITAL_INVOICE_DELETED]: INVOICE_STATUS.DIGITAL_INVOICE_REQUESTED,
  [INVOICE_STATUS.DIGITAL_INVOICE_EXPIRED]: INVOICE_STATUS.DIGITAL_INVOICE_REQUESTED,
  [INVOICE_STATUS.DIGITAL_INVOICE_ISSUED]: INVOICE_STATUS.INVOICE_SENT,
  [INVOICE_STATUS.INVOICE_SENT]: INVOICE_STATUS.INVOICE_RECEIVED,
  [INVOICE_STATUS.INVOICE_RECEIVED]: INVOICE_STATUS.SHIPPER_APPROVED,
  [INVOICE_STATUS.SHIPPER_APPROVED]: INVOICE_STATUS.PAID,
  [INVOICE_STATUS.SHIPPER_REJECTED]: INVOICE_STATUS.GENERATED,
  [INVOICE_STATUS.PARTIALLY_PAID]: INVOICE_STATUS.PAID,
  // Temporary fix until fully migrated to invoiceStatus
  [INVOICE_STATUS.INVOICE_GENERATED]: INVOICE_STATUS.FINANCE_APPROVED,
};

export const INVOICE_HIDE_RECOMMENDED_ACTION = [
  INVOICE_STATUS.BAD_DEBT,
  INVOICE_STATUS.PAID,
  INVOICE_STATUS.CANCELLED,
  INVOICE_STATUS.DIGITAL_INVOICE_REQUESTED,
];

export const INVOICE_CHANGE_STATUS_APPROVAL = [INVOICE_STATUS.SPV_APPROVED, INVOICE_STATUS.SHIPPER_APPROVED];

export const INVOICE_CHANGE_STATUS_REJECTION = [INVOICE_STATUS.SPV_REJECTED, INVOICE_STATUS.SHIPPER_REJECTED];

export const INVOICE_CHANGE_STATUS_PAYMENT = [INVOICE_STATUS.PAID, INVOICE_STATUS.PARTIALLY_PAID];

export const INVOICE_DELIVERY_SERVICES = {
  JNE: 'JNE',
  GOSEND: 'GOSEND',
  GRAB_EXPRESS: 'GRAB_EXPRESS',
  INTERNAL_COURIER: 'INTERNAL_COURIER',
  EMAIL: 'EMAIL',
  OTHER: 'OTHER',
};

export const INVOICE_FORM_TYPE = {
  ADJUST: 'ADJUST',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  REVISE: 'REVISE',
};

export const INVOICE_PAID_ADJUSTMENT_TYPE = {
  CLAIMS: 'CLAIMS',
  WHT23: 'WHT23',
  WHT21: 'WHT21',
  OTHER: 'OTHER',
  BAD_DEBT: 'BAD_DEBT',
};

export const INVOICE_PAID_PAYMENT_TYPE = {
  ...INVOICE_PAID_ADJUSTMENT_TYPE,
  PAYMENT: 'PAYMENT',
};

export const DEFAULT_NEW_PAYMENT_VALUE = {
  datetime: undefined,
  paidAmount: undefined,
  paymentType: INVOICE_PAID_PAYMENT_TYPE.PAYMENT,
};

export const DEFAULT_NEW_ADJUSTMENT = {
  datetime: undefined,
  paidAmount: undefined,
  paymentType: undefined,
};

export const EXPANDABLE_KEY_IDX = 1;

export const INVOICE_CSV_HEADERS = {
  invoiceNumber: 'invoice_number',
  totalAmount: 'amount',
  status: 'current_status',
  invoiceDate: 'invoice_date',
  arPic: 'ar_pic',
};

export const DRAFT_INVOICE_STATUS_SORT_VALUE = {
  [INVOICE_STATUS.DRAFT_CREATED]: 0,
  [INVOICE_STATUS.DOCUMENTS_REQUIRED]: 1,
  [INVOICE_STATUS.RECAP_SENT]: 1,
  [INVOICE_STATUS.RECAP_REPLIED]: 1,
  [INVOICE_STATUS.PROFORMA_SENT]: 1,
  [INVOICE_STATUS.PROFORMA_REPLIED]: 1,
  [INVOICE_STATUS.DISPUTE]: 1,
  [INVOICE_STATUS.INVOICE_SPLIT]: 1,
  [INVOICE_STATUS.CANCELLED]: 1,
};

export const INVOICE_STATUS_SORT_VALUE = {
  [INVOICE_STATUS.GENERATED]: 0,
  [INVOICE_STATUS.SPV_APPROVED]: 1,
  [INVOICE_STATUS.SPV_REJECTED]: 1,
  [INVOICE_STATUS.INVOICE_SENT]: 2,
  [INVOICE_STATUS.INVOICE_RECEIVED]: 3,
  [INVOICE_STATUS.SHIPPER_APPROVED]: 4,
  [INVOICE_STATUS.SHIPPER_REJECTED]: 4,
  [INVOICE_STATUS.PAID]: 5,
  [INVOICE_STATUS.PARTIALLY_PAID]: 5,
  [INVOICE_STATUS.BAD_DEBT]: 5,
  [INVOICE_STATUS.CANCELLED]: 6,
};

export const INVOICE_STATUS_ORDER = {
  0: [INVOICE_STATUS.GENERATED],
  1: [INVOICE_STATUS.SPV_APPROVED, INVOICE_STATUS.SPV_REJECTED],
  2: [INVOICE_STATUS.INVOICE_SENT],
  3: [INVOICE_STATUS.INVOICE_RECEIVED],
  4: [INVOICE_STATUS.SHIPPER_APPROVED, INVOICE_STATUS.SHIPPER_REJECTED],
  5: [INVOICE_STATUS.PARTIALLY_PAID, INVOICE_STATUS.PAID, INVOICE_STATUS.BAD_DEBT],
  6: [INVOICE_STATUS.CANCELLED],
};

export const DRAFT_INVOICE_STATUS_ORDER = {
  0: [INVOICE_STATUS.GENERATED],
  1: [
    INVOICE_STATUS.DOCUMENTS_REQUIRED,
    INVOICE_STATUS.RECAP_SENT,
    INVOICE_STATUS.RECAP_REPLIED,
    INVOICE_STATUS.PROFORMA_SENT,
    INVOICE_STATUS.PROFORMA_REPLIED,
    INVOICE_STATUS.DISPUTE,
    INVOICE_STATUS.INVOICE_SPLIT,
    INVOICE_STATUS.CANCELLED,
  ],
};

export const INVOICE_STATUS_GROUPS = {
  DRAFT: Object.values(DRAFT_INVOICE_STATUS),
  ON_GOING_INVOICE: Object.values(ON_GOING_INVOICE_STATUS),
  PAYMENT_STATUS: Object.values(INVOICE_PAYMENT_STATUS),
  CANCELLED: [INVOICE_STATUS.CANCELLED],
};

export const EXPANDABLE_FILTER_KEYS = {
  INVOICE_GENEREATED_DATE: 'invoiceGeneratedDate',
  INVOICE_SENT_DATE: 'invoiceSentDate',
};

export const DATE_TIME_FORMAT = `DD MMM YYYY, HH:mm`;
export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = `HH:mm`;

export default {};
