import { DOORING_AGENT_STATUS, SHIPPING_LINE_STATUS } from '~/Configurations/constants';

export const EDITED_DEFAULT_VALUE = {
  doNumber: null,
  jobKsuid: null,
  initialValue: null,
  field: null,
  restriction: null,
};

export const EDITED_FIELD_TYPE = {
  TransitVesselVoyage: 'transitVesselVoyage',
  Remarks: 'remarks',
  Status: 'status',
  Vessel: 'vessel',
};

export const SHIPPING_DEFAULT_VALUE = {
  hasPODC: false,
  activeDialog: null,
  customerKsuid: null,
  doNumber: null,
  shipmentKsuid: null,
  record: {},
};

export const DEFAULT_FILTER_VALUE = [
  SHIPPING_LINE_STATUS.VESSEL_BOOKED,
  SHIPPING_LINE_STATUS.DEPARTURE,
  SHIPPING_LINE_STATUS.ARRIVAL,
];

export const statusOptions = t => DEFAULT_FILTER_VALUE.map(el => ({
  value: el,
  label: t(`first_mile:shipping_line_status_type.${el}`),
}));

export const LABEL_WIDTH = '20rem';

export const VESSEL_BOOKED_SEARCH_TYPES = {
  DO_NUMBER: 'DO_NUMBER',
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
  CONTAINER_DO_NUMBER: 'CONTAINER_DO_NUMBER',
  PO_NUMBER: 'PO_NUMBER',
  VESSEL_OR_VOYAGE_NAME: 'VESSEL_OR_VOYAGE_NAME',
};

export const SHIPPING_INSTRUCTION_CONTRACT_TYPES = {
  CONTRACT_20_FT_CONTAINER: 'CONTRACT_20_FT_CONTAINER',
  CONTRACT_40_FT_CONTAINER: 'CONTRACT_40_FT_CONTAINER',
};

export const CONTAINER_SIZE_SI = {
  FT_20: '20 FT',
  FT_40: '40 FT',
};

export const DOORING_AGENT_STATUS_TO_REMOVE = [
  DOORING_AGENT_STATUS.UNASSIGNED,
  DOORING_AGENT_STATUS.UPCOMING,
];
