export const PIC_COMPANY_DEFAULT_VALUES = {
  role: undefined,
  sendInvoiceReminders: false,
  name: '',
  phoneNumber: '',
  email: '',
};

export const CUSTOMER_PIC = {
  LOGISTICS: 'LOGISTICS',
  PROCUREMENT: 'PROCUREMENT',
  FINANCE: 'FINANCE',
  TREASURY: 'TREASURY',
  CLAIMS: 'CLAIMS',
};

export const PIC_COMPANY_FINANCE = {
  role: CUSTOMER_PIC.FINANCE,
  sendInvoiceReminders: false,
  name: '',
  phoneNumber: '',
  email: '',
};

export const CUSTOMER_PIC_DESCRIPTION = {
  LOGISTICS: 'In charge of operations, monitoring, and DO ordering',
  PROCUREMENT: 'In charge of price negotiations and creating Purchase Orders (PO).',
  FINANCE: 'In charge of account payables. ',
  TREASURY: 'In charge of disbursing payments.',
  CLAIMS: 'In charge of handling claims.',
};

export const REQUIRED_DOCUMENT_RETURN_TYPE_ENUM = {
  BERITA_ACARA: 'BERITA_ACARA',
  BUKTI_PENERIMAAN_BARANG: 'BUKTI_PENERIMAAN_BARANG',
  DISPATCH_MEMO: 'DISPATCH_MEMO',
  FORM_ABSENSI: 'FORM_ABSENSI',
  FORM_ABSENSI_BONGKAR: 'FORM_ABSENSI_BONGKAR',
  FORM_ABSENSI_MUAT: 'FORM_ABSENSI_MUAT',
  KWITANSI_TKBM: 'KWITANSI_TKBM',
  NOTA_TIMBANG: 'NOTA_TIMBANG',
  NOTA_TIMBANG_CARBONIZE_BONGKAR: 'NOTA_TIMBANG_CARBONIZE_BONGKAR',
  NOTA_TIMBANG_CARBONIZE_MUAT: 'NOTA_TIMBANG_CARBONIZE_MUAT',
  NOTA_TIMBANG_ORIGINAL_BONGKAR: 'NOTA_TIMBANG_ORIGINAL_BONGKAR',
  NOTA_TIMBANG_ORIGINAL_MUAT: 'NOTA_TIMBANG_ORIGINAL_MUAT',
  PACKING_LIST: 'PACKING_LIST',
  SURAT_PERINTAH_JALAN: 'SURAT_PERINTAH_JALAN',
  BILL_OF_LADING: 'BILL_OF_LADING',
  SURAT_JALAN_CARBONIZE: 'SURAT_JALAN_CARBONIZE',
  SURAT_JALAN_ORIGINAL: 'SURAT_JALAN_ORIGINAL',
  BERITA_ACARA_MUAT: 'BERITA_ACARA_MUAT',
  BERITA_ACARA_SERAH_TERIMA_BARANG: 'BERITA_ACARA_SERAH_TERIMA_BARANG',
  BERITA_SERAH_TERIMA_BARANG: 'BERITA_SERAH_TERIMA_BARANG',
  BON_TIMBANG: 'BON_TIMBANG',
  COMMERCIAL_INVOICE: 'COMMERCIAL_INVOICE',
  DELIVERY_LOCAL_ORDER_COPY: 'DELIVERY_LOCAL_ORDER_COPY',
  DELIVERY_LOCAL_ORDER_ORIGINAL: 'DELIVERY_LOCAL_ORDER_ORIGINAL',
  DELIVERY_NOTE: 'DELIVERY_NOTE',
  EIR_DEPOT: 'EIR_DEPOT',
  EIR_PORT: 'EIR_PORT',
  FAKTUR_PAJAK: 'FAKTUR_PAJAK',
  FAKTUR_PAJAK_DEPOT: 'FAKTUR_PAJAK_DEPOT',
  GOOD_RECEIPT: 'GOOD_RECEIPT',
  LIVE_LOADING_DOCUMENT: 'LIVE_LOADING_DOCUMENT',
  LOLO: 'LOLO',
  PACKINGLIST_CARBONIZE: 'PACKINGLIST_CARBONIZE',
  PACKINGLIST_ORIGINAL: 'PACKINGLIST_ORIGINAL',
  PPFTZ_03: 'PPFTZ_03',
  RBPB_CARBONIZE: 'RBPB_CARBONIZE',
  RBPB_ORIGINAL: 'RBPB_ORIGINAL',
  RECEIPT_DEURAGE: 'RECEIPT_DEURAGE',
  RECEIPT_EXTEND: 'RECEIPT_EXTEND',
  RECEIPT_REPAIR: 'RECEIPT_REPAIR',
  RECEIPT_STORAGE_PORT: 'RECEIPT_STORAGE_PORT',
  SURAT_JALAN_PABRIK_CARBONIZE: 'SURAT_JALAN_PABRIK_CARBONIZE',
  SURAT_JALAN_PABRIK_ORIGINAL: 'SURAT_JALAN_PABRIK_ORIGINAL',
  SURAT_JALAN_PANTHERA_CARBONIZE: 'SURAT_JALAN_PANTHERA_CARBONIZE',
  SURAT_JALAN_PANTHERA_ORIGINAL: 'SURAT_JALAN_PANTHERA_ORIGINAL',
  SURAT_JALAN_SHIPPER_CARBONIZE: 'SURAT_JALAN_SHIPPER_CARBONIZE',
  SURAT_JALAN_SHIPPER_ORIGINAL: 'SURAT_JALAN_SHIPPER_ORIGINAL',
  SURAT_JALAN_TRANSFER_CARBONIZE: 'SURAT_JALAN_TRANSFER_CARBONIZE',
  SURAT_JALAN_TRANSFER_ORIGINAL: 'SURAT_JALAN_TRANSFER_ORIGINAL',
  SURAT_KETERANGAN_MUAT_BARANG: 'SURAT_KETERANGAN_MUAT_BARANG',
  SURAT_PENERIMAAN_BARANG: 'SURAT_PENERIMAAN_BARANG',
  SURAT_PERINTAH_EXPEDISI: 'SURAT_PERINTAH_EXPEDISI',
  SURAT_PERINTAH_PENGELUARAN_BARANG: 'SURAT_PERINTAH_PENGELUARAN_BARANG',
  TALLY_SHEET: 'TALLY_SHEET',
  TKBM_BONGKAR: 'TKBM_BONGKAR',
  TKBM_MUAT: 'TKBM_MUAT',
  OTHER: 'OTHER',
};

export const BE_DOCUMENT_REQUIREMENTS_TYPE = {
  FOR_RECEIVER_AT_DESTINATION: 'FOR_RECEIVER_AT_DESTINATION',
  TO_BE_ATTACHED_TO_INVOICE: 'TO_BE_ATTACHED_TO_INVOICE',
  TO_BE_RETURNED_TO_SHIPPER: 'TO_BE_RETURNED_TO_SHIPPER',
};

export const REQUIREMENT_TAGS_PRIORITY_LEVELS = {
  MANDATORY: 0,
  MANDATORY_WITH_FOLLOW_UP: 1,
  OPTIONAL: 2,
};

export const QUOTATION_TYPE = {
  MID_MILE: 'MID_MILE',
  RENTAL: 'RENTAL',
  PROJECT: 'PROJECT',
  FM_DTD: 'FM_DTD',
};

export const QUOTATION_PRICE_SCALING = {
  PER_KG: 'PER_KG',
  PER_CBM: 'PER_CBM',
  PER_ROUTE: 'PER_ROUTE',
  PER_PO: 'PER_PO',
  PER_UNIT: 'PER_UNIT',
};
