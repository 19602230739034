export const DATE_FORMAT = 'DD MMM YYYY';
export const DATE_TIME_FORMAT = 'DD MMM YYYY, HH:mm';
export const TIME_FORMAT = `HH:mm`;

export const PAYABLE_INVOICE_TYPE = {
  SHIPMENT_FEE_CATEGORY: 'SHIPMENT_FEE_CATEGORY',
  ADDITIONAL_FEE_CATEGORY: 'ADDITIONAL_FEE_CATEGORY',
};

export const PAYABLE_INVOICE_STATUSES = {
  CREATED: 'CREATED',
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  REVISION_CREATED: 'REVISION_CREATED',
  REVISION_SUBMITTED: 'REVISION_SUBMITTED',
  PHYSICALLY_SENT: 'PHYSICALLY_SENT',
  PHYSICALLY_RECEIVED: 'PHYSICALLY_RECEIVED',
  PHYSICALLY_APPROVED: 'PHYSICALLY_APPROVED',
  ON_HOLD: 'ON_HOLD',
  CANCELLED: 'CANCELLED',
  PAYMENT_REQUESTED: 'PAYMENT_REQUESTED',
  PAID: 'PAID',
};

export const PAYABLE_INVOICE_FEE_TYPES = {
  SHIPMENT_FEE: 'SHIPMENT_FEE',
  ADDITIONAL_FEE: 'ADDITIONAL_FEE',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OTHER_FEE: 'OTHER_FEE',
  OTHER_LOCATION_FEE: 'OTHER_LOCATION_FEE',
  OVERNIGHT_LOADING_FEE: 'OVERNIGHT_LOADING_FEE',
  OVERNIGHT_UNLOADING_FEE: 'OVERNIGHT_UNLOADING_FEE',
};

export const PAYABLE_INVOICE_STATUS_NO_ACTION = [
  PAYABLE_INVOICE_STATUSES.CREATED,
  PAYABLE_INVOICE_STATUSES.CANCELLED,
  PAYABLE_INVOICE_STATUSES.PAID,
  PAYABLE_INVOICE_STATUSES.PAYMENT_REQUESTED,
  PAYABLE_INVOICE_STATUSES.REVISION_CREATED,
];

export const PAYABLE_INVOICE_STATUS_WITH_BANNER = [
  PAYABLE_INVOICE_STATUSES.CANCELLED,
  PAYABLE_INVOICE_STATUSES.REJECTED,
  PAYABLE_INVOICE_STATUSES.ON_HOLD,
];

export const PAYABLE_INVOICE_REVIEWABLE = [
  PAYABLE_INVOICE_STATUSES.REVISION_SUBMITTED,
  PAYABLE_INVOICE_STATUSES.APPROVED,
  PAYABLE_INVOICE_STATUSES.SUBMITTED,
  PAYABLE_INVOICE_STATUSES.ON_HOLD,
  PAYABLE_INVOICE_STATUSES.REJECTED,
  PAYABLE_INVOICE_STATUSES.PHYSICALLY_SENT,
  PAYABLE_INVOICE_STATUSES.PHYSICALLY_RECEIVED,
];

export const PAYABLE_INVOICE_CANCELABLE = [
  PAYABLE_INVOICE_STATUSES.CREATED,
  PAYABLE_INVOICE_STATUSES.REVISION_CREATED,
  PAYABLE_INVOICE_STATUSES.SUBMITTED,
  PAYABLE_INVOICE_STATUSES.REVISION_SUBMITTED,
  PAYABLE_INVOICE_STATUSES.APPROVED,
  PAYABLE_INVOICE_STATUSES.REJECTED,
];

export const PAYABLE_INVOICE_CANCELLATION_REASON = {
  NEED_REVISION: 'NEED_REVISION',
  INCOMPLETE: 'INCOMPLETE',
  OTHER_REASON: 'OTHER_REASON',
  REVISION: 'REVISION',
};

export const PAYABLE_INVOICE_READY_FOR_PR = [
  PAYABLE_INVOICE_STATUSES.APPROVED,
  PAYABLE_INVOICE_STATUSES.PHYSICALLY_APPROVED,
];

export const PAYABLE_INVOICE_STATUS_COLOR_SCHEME = {
  [PAYABLE_INVOICE_STATUSES.CREATED]: 'warning',
  [PAYABLE_INVOICE_STATUSES.SUBMITTED]: 'warning',
  [PAYABLE_INVOICE_STATUSES.APPROVED]: 'warning',
  [PAYABLE_INVOICE_STATUSES.REJECTED]: 'danger',
  [PAYABLE_INVOICE_STATUSES.REVISION_CREATED]: 'warning',
  [PAYABLE_INVOICE_STATUSES.REVISION_SUBMITTED]: 'warning',
  [PAYABLE_INVOICE_STATUSES.PHYSICALLY_SENT]: 'warning',
  [PAYABLE_INVOICE_STATUSES.PHYSICALLY_RECEIVED]: 'awrning',
  [PAYABLE_INVOICE_STATUSES.PHYSICALLY_APPROVED]: 'warning',
  [PAYABLE_INVOICE_STATUSES.ON_HOLD]: 'warning',
  [PAYABLE_INVOICE_STATUSES.CANCELLED]: 'danger',
  [PAYABLE_INVOICE_STATUSES.PAYMENT_REQUESTED]: 'warning',
  [PAYABLE_INVOICE_STATUSES.PAID]: 'success',
};

export const ON_HOLD_REASONS = {
  WAITING_FOR_CLAIM: 'WAITING_FOR_CLAIM',
  WAITING_FOR_OVERNIGHT: 'WAITING_FOR_OVERNIGHT',
  CHECKING_PROCESS: 'CHECKING_PROCESS',
  WAITING_FOR_DOCUMENT: 'WAITING_FOR_DOCUMENT',
  OTHER: 'OTHER',
};

export const PAYABLE_INVOICE_FEE_CONVERT_TO_FEE_SUM_FEE = {
  [PAYABLE_INVOICE_FEE_TYPES.SHIPMENT_FEE]: 'shipment',
  [PAYABLE_INVOICE_FEE_TYPES.OTHER_FEE]: 'additional',
  [PAYABLE_INVOICE_FEE_TYPES.OTHER_LOCATION_FEE]: 'other',
  [PAYABLE_INVOICE_FEE_TYPES.LOADING_FEE]: 'loading',
  [PAYABLE_INVOICE_FEE_TYPES.UNLOADING_FEE]: 'unloading',
  [PAYABLE_INVOICE_FEE_TYPES.OVERNIGHT_LOADING_FEE]: 'overnight_loading',
  [PAYABLE_INVOICE_FEE_TYPES.OVERNIGHT_UNLOADING_FEE]: 'overnight_unloading',
};

export const EXPEDITION_PROVIDER = {
  GO_SEND: 'GO_SEND',
  GRAB_EXPRESS: 'GRAB_EXPRESS',
  NINJA_XPRESS: 'NINJA_XPRESS',
  ANTERAJA: 'ANTERAJA',
  REX_KIRIMAN_EXPRESS: 'REX_KIRIMAN_EXPRESS',
  LION_PARCEL: 'LION_PARCEL',
  PAXEL: 'PAXEL',
  JNE: 'JNE',
  TIKI: 'TIKI',
  POS_INDONESIA: 'POS_INDONESIA',
  WAHANA: 'WAHANA',
  SICEPAT: 'SICEPAT',
  SICEPAT_SIUNTUNG: 'SICEPAT_SIUNTUNG',
  JNT_EXPRESS: 'JNT_EXPRESS',
  OTHER: 'OTHER',
};

export const RECEIVED_MODAL_MODE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export const UI_MODAL_TYPE = {
  ON_HOLD: 'ON_HOLD',
  REJECT: 'REJECT',
  APPROVED: 'APPROVED',
};

export const NFT_REJECT_REASON = {
  OTHER_REASON: 'OTHER_REASON',
  NEED_REVISION: 'NEED_REVISION',
  INCOMPLETE: 'INCOMPLETE',
  REVISION: 'REVISION',
};

export default {};
