import React, { useState, createContext, useCallback, useMemo, useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { intersection } from 'lodash-es';

import { AuthContext } from '@kargotech/tms-core/auth';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { Message } from '@kargotech/tms-ui/components';
import { useTranslation } from 'react-i18next';
import { COUNTRIES, USER_ACCESS_TYPE } from '~/Configurations/constants';
import { ACCESS_PRIVILEGE } from '~/Configurations/accessPrevillegeMap';
import { getUserProfile } from '~/Models/userProfile';
import { APOLLO_CLIENTS } from '../Services/apollo';
import PROFILE from '~/GraphQL/ProfileService/Queries/profile';
import useLocalStorage from '~/Hooks/useLocalStorage';
import sentry from '~/Services/sentry';

export const ProfileContext = createContext();

function ProfileProvider({ children }) {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useLocalStorage('selectedCountry', COUNTRIES.ID);
  const { selectedCompanyKsuid, logout } = useContext(AuthContext);
  const [profile, setProfile] = useState(getUserProfile(null));
  const [country, setCountry] = useState(selectedCountry);
  const updateContext = useUnleashContext();

  const resetProfile = useCallback(() => setProfile(getUserProfile(null)), []);

  const getSelectedCompany = useCallback(() => {
    const { ksuid, ...restProfileAttrrs } = profile?.company || {};
    return {
      ksuid: selectedCompanyKsuid || ksuid,
      ...restProfileAttrrs,
    };
  }, [profile, selectedCompanyKsuid]);

  const { refetch: getProfile, loading } = useQuery(PROFILE, {
    client: APOLLO_CLIENTS.PROFILE,
    fetchPolicy: 'network-only',
    skip: !selectedCompanyKsuid,
    notifyOnNetworkStatusChange: true,
    variables: {
      companyKsuid: selectedCompanyKsuid,
    },
    onCompleted: profileData => {
      const formattedProfileData = getUserProfile(profileData);
      setProfile(formattedProfileData);

      // set user context on unleash
      updateContext({
        userId: formattedProfileData.ksuid,
        companyId: formattedProfileData.company.ksuid,
        email: formattedProfileData.email,
        phone: formattedProfileData.phoneNumber,
      });

      // set sentry user context
      sentry.setUser({
        id: formattedProfileData.ksuid,
        companyId: formattedProfileData.company.ksuid,
        email: formattedProfileData.email,
        phone: formattedProfileData.phoneNumber,
      });
    },
    onError: resetProfile,
  });

  const getCompanyMetadata = useCallback(() => {
    const { metadata } = getSelectedCompany();
    if (!metadata || typeof metadata !== 'string') {
      return undefined;
    }

    try {
      return JSON.parse(metadata);
    } catch {
      return undefined;
    }
  }, [getSelectedCompany]);

  const getCompanyAliases = useCallback(() => {
    const { companyAliases } = getSelectedCompany() || {};

    return companyAliases || [];
  }, [getSelectedCompany]);

  const getPantheraEntitiesByCode = useCallback(() => {
    const companyAliases = getCompanyAliases();

    return companyAliases.reduce((acc, alias) => {
      const entity = alias.entityCode;
      return {
        ...acc,
        [entity]: alias,
      };
    }, {});
  }, [getCompanyAliases]);

  const getPantheraEntitiesByName = useCallback(() => {
    const companyAliases = getCompanyAliases();

    return companyAliases.reduce((acc, alias) => {
      const { name } = alias;
      return {
        ...acc,
        [name]: alias,
      };
    }, {});
  }, [getCompanyAliases]);

  useEffect(() => {
    const companyKsuid = profile?.company?.ksuid;
    if (['SME_TELESALES', 'SME_SALES_EXECUTIVE'].includes(profile?.company?.accessType)) {
      logout();
    }

    const allowedCompanyKsuids = window.REACT_APP_ALLOWED_COMPANY_KSUIDS
      ?.split(',')
      ?.map(ksuid => ksuid?.trim());

    const isAllowedCompany = (
      allowedCompanyKsuids?.includes(companyKsuid)
      || companyKsuid === window.REACT_APP_ALLOWED_COMPANY_KSUID
    );

    if (companyKsuid && !isAllowedCompany) {
      logout();
    }
  }, [profile, logout]);

  const getFirstMileCompany = useCallback(
    () => {
      try {
        /* eslint-disable camelcase */
        const {
          is_first_mile,
          is_first_mile_transporter,
          ...otherProps
        } = getCompanyMetadata() || {};

        if (is_first_mile) {
          return {
            isFirstMileShipper: true,
            ...otherProps,
          };
        }

        if (is_first_mile_transporter) {
          return {
            isFirstMileTransporter: true,
            ...otherProps,
          };
        }
        /* eslint-enable camelcase */

        return null;
      } catch (_) {
        return null;
      }
    },
    [getCompanyMetadata],
  );

  const isFirstMileShipper = useMemo(() => {
    const firstMileCompany = getFirstMileCompany();
    return Boolean(firstMileCompany && firstMileCompany.isFirstMileShipper);
  }, [getFirstMileCompany]);

  const isFirstMileTransporter = useMemo(() => {
    const firstMileCompany = getFirstMileCompany();
    return firstMileCompany && firstMileCompany.isFirstMileTransporter;
  }, [getFirstMileCompany]);

  /**
   * Check logged-in user roles are matching with allowed roles.
   * Logged-in user roles is stored in localStorage under selectedCompany.accessTypes property.
   * Allowed roles should be any value from ACCESS_PRIVILEGE.
   * The logged-in user roles will be intersecting with the provided Allowed roles excluding RESTRICTED_ACCESS
   * User is authorized if there's one or more matching roles
   * @require src/Configurations/accessPrevillegeMap.js#ACCESS_PRIVILEGE
   * @example
   * isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPMENT_READ);
   * @param {String[]} roles must-have roles
   * @returns {Boolean} is user authorized or not
   */
  const isAuthorizedToAccess = useCallback(
    (roles = []) => {
      const allowedRoles = roles.filter(role => role !== USER_ACCESS_TYPE.RESTRICTED_ACCESS);
      const userAccessTypes = getSelectedCompany()
        .accessTypes?.filter(accessType => accessType !== USER_ACCESS_TYPE.RESTRICTED_ACCESS) || [];

      return !!intersection(allowedRoles, userAccessTypes).length;
    },
    [getSelectedCompany],
  );

  // read only access
  const isReadOnlyAccess = useMemo(() => {
    const userAccessTypes = getSelectedCompany()
      .accessTypes?.filter(accessType => accessType !== USER_ACCESS_TYPE.RESTRICTED_ACCESS) || [];

    return userAccessTypes?.every(
      accessType => ACCESS_PRIVILEGE.READ_ONLY.includes(accessType),
    );
  }, [getSelectedCompany]);

  const switchCountry = countryCode => {
    setCountry(countryCode);
    setSelectedCountry(countryCode);
    Message.success(t('message:country_changed', {
      country: t(`common:country_map.${countryCode}`),
    }));
  };

  return (
    <ProfileContext.Provider
      value={{
        profile,
        getProfile,
        resetProfile,
        getSelectedCompany,
        isAuthorizedToAccess,
        getFirstMileCompany,
        isFirstMileShipper,
        isFirstMileTransporter,
        isReadOnlyAccess,
        getCompanyMetadata,
        getCompanyAliases,
        getPantheraEntitiesByCode,
        getPantheraEntitiesByName,
        loading,
        country,
        switchCountry,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export default ProfileProvider;
