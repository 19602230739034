import { generateColumnOptionObject } from './utils';

export const INVOICE_TEMPLATE_MODES = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
};

export const CUSTOM_COLUMNS_MODES = {
  READ: 'READ',
  EDIT: 'EDIT',
};

export const COLUMNS_FIELD_KEY = {
  POD_NUMBER: 'POD_NUMBER',
  DO_NUMBER: 'DO_NUMBER',
  LOADING_DATE: 'LOADING_DATE',
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
  ORIGIN: 'ORIGIN',
  DESTINATION: 'DESTINATION',
  LICENSE_PLATE: 'LICENSE_PLATE',
  TONNAGE_POD: 'TONNAGE_POD',
  TONNAGE_DO: 'TONNAGE_DO',
  RATE: 'RATE',
  DESCRIPTION: 'DESCRIPTION',
  EXTERNAL_ID: 'EXTERNAL_ID',
  DRIVER_NAME: 'DRIVER_NAME',
  TRUCK_TYPE: 'TRUCK_TYPE',
  FEE: 'FEE',
  CUSTOM: 'CUSTOM',
};

export const DEFAULT_FIELD_KEYS = [
  COLUMNS_FIELD_KEY.POD_NUMBER,
  COLUMNS_FIELD_KEY.DO_NUMBER,
  COLUMNS_FIELD_KEY.LICENSE_PLATE,
  COLUMNS_FIELD_KEY.LOADING_DATE,
  COLUMNS_FIELD_KEY.DESCRIPTION,
];

export const initialColumnsOptions = Object.keys(COLUMNS_FIELD_KEY).map(generateColumnOptionObject);

export default {};
