// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// import 'firebase/analytics;

// Add the Firebase products that you want to use
import 'firebase/messaging';
import 'firebase/analytics';

const config = {
  apiKey: window.FIREBASE_API_KEY,
  projectId: window.FIREBASE_PROJECT_ID,
  appId: window.FIREBASE_APP_ID,
  messagingSenderId: window.FIREBASE_MESSAGING_SENDER_ID,
};

const firebaseApp = firebase.initializeApp(config);

const messaging = firebase.messaging.isSupported() ? firebaseApp.messaging() : null;

// eslint-disable-next-line import/prefer-default-export
export { messaging, config, firebaseApp };
