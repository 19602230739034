export default {
  mid_mile: 'Mid Mile',
  for_receiver_at_destination: 'For Receiver at Destination',
  to_be_returned_to_shipper: 'To be Returned to Shipper',
  to_be_attached_to_invoice: 'To be Attached to Invoice',
  required_document_type: 'Required Document Type',
  sla_return: 'SLA Return',
  use_tip: 'Use TIP Scheme for this shipment',
  tip_eligible: 'TIP Eligible',
};
