import React, { useCallback } from 'react';
import { get } from 'lodash-es';
import { AuthProvider } from '@kargotech/tms-core/auth';
import FlagProvider from '@unleash/proxy-client-react';

import UIWrapper from './UIWrapper';
import { APP_CONTEXT_STORAGE_KEY } from '~/Configurations/constants';
import UNLEASH_CONFIGS from '~/Configurations/unleash';
import AppProvider from '../Contexts/AppProvider';
import ApolloClientProvider from '../Contexts/ApolloClientProvider';
import ProfileProvider from '../Contexts/ProfileProvider';
import NavigationProvider from '../Contexts/NavigationProvider';
import { APOLLO_CLIENTS } from '../Services/apollo';

export default function AppWrapper({ children }) {
  const handleLogout = useCallback(() => {
    Object.keys(APOLLO_CLIENTS).forEach(apolloClient => {
      const cache = get(APOLLO_CLIENTS, `${apolloClient}.cache`);
      if (cache) {
        cache.reset();
      }
    });
  }, []);

  return (
    <UIWrapper>
      <AppProvider>
        <AuthProvider
          apiClient={APOLLO_CLIENTS.PROFILE}
          onLogoutSuccess={handleLogout}
          platformAccess="PBI"
          preservedLocalStorageKeys={[APP_CONTEXT_STORAGE_KEY]}
        >
          <ApolloClientProvider>
            <FlagProvider config={UNLEASH_CONFIGS}>
              <ProfileProvider>
                <NavigationProvider>
                  {children}
                </NavigationProvider>
              </ProfileProvider>
            </FlagProvider>
          </ApolloClientProvider>
        </AuthProvider>
      </AppProvider>
    </UIWrapper>
  );
}
