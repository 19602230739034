/* eslint-disable camelcase */
import company_requirements_settings from '~/Pages/company-requirement-settings/translations/en';
import company from '~/Pages/Company/translations/en';
import first_mile from '~/Pages/FirstMile/translations/en';
import fraud from '~/Pages/fraud/translations/en';
import shipment from '~/Pages/Shipment/translations/en';
import shipping_requirement from '~/Pages/shipping-requirement/translations/en';
import transporters from '~/Pages/transporters/translations/en';
import receiveables from '~/Pages/Receivable/translations/en';
import payment from '~/Pages/Payment/translations/en';

import company_form from './en/company_form';
import shipment_need_confirmation from './en/shipment_need_confirmation';
import shippers from './en/shippers';
import common from './en_common';
import compose from './en_compose';
import message from './en_message';
import shame from './shame';

export default {
  common,
  company,
  company_form,
  company_requirements_settings,
  compose,
  first_mile,
  fraud,
  message,
  receiveables,
  shame,
  shipment,
  shipment_need_confirmation,
  shippers,
  shipping_requirement,
  transporters,
  payment,
};
