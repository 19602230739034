import React, { Suspense, useEffect, lazy, useContext } from 'react';
import {
  Switch,
  Redirect,
  Route,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageSuspenseFallback } from '@kargotech/tms-ui/components';
import { useFlag } from '@unleash/proxy-client-react';
import {
  USER_ACCESS_TYPE,
} from '~/Configurations/constants';

import { AppContext } from '~/Contexts/AppProvider';
import { ProfileContext } from '~/Contexts/ProfileProvider';
import { PrivateRoute, AuthRoute } from '~/Components/Route';

import { ACCESS_PRIVILEGE } from './accessPrevillegeMap';
import { trackEvent } from '~/Services/tracker';
import { GENERIC_EVENT_TRACKER } from './constants/eventTracker';
/* eslint-disable max-len */
const MainLayout = lazy(() => import(/* webpackChunkName: "MainLayout" */ '../Layouts/MainLayout'));
const MinimalLayout = lazy(() => import(/* webpackChunkName: "MinimalLayout" */ '../Layouts/MinimalLayout'));

const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPage" */ '../Pages/Auth/Login'));
const RegisterPage = lazy(() => import(/* webpackChunkName: "RegisterPage" */ '../Pages/Auth/Register'));
const RegisterDetailsPage = lazy(() => import(/* webpackChunkName: "RegisterDetailsPage" */ '../Pages/Auth/Register/RegisterDetails'));
const OtpVerification = lazy(() => import(/* webpackChunkName: "OtpVerification" */ '../Pages/Auth/OtpVerification'));
const ChooseCompanyPage = lazy(() => import(/* webpackChunkName: "ChooseCompanyPage" */ '../Pages/Auth/ChooseCompanyPage'));
const PersonalAccount = lazy(() => import(/* webpackChunkName: "PersonalAccount" */ '../Pages/PersonalAccount'));

// eslint-disable-next-line
const DisbursementSchemeListPage = lazy(() => import(/* webpackChunkName: "DisbursementSchemeListPage" */ '../Pages/Payment/DisbursementScheme/DisbursementSchemeListPage'));
// eslint-disable-next-line
const DisbursementSchemeDetailPage = lazy(() => import(/* webpackChunkName: "DisbursementSchemeDetailPage" */ '../Pages/Payment/DisbursementScheme/DisbursementSchemeDetailPage'));

const FeeRequestListPage = lazy(() => import(/* webpackChunkName: "FeeRequestListPage" */ '../Pages/Payment/FeeRequests/FeeRequestListPage'));
const PaymentRequestListPage = lazy(() => import(/* webpackChunkName: "PaymentRequestListPage" */ '../Pages/Payment/PaymentRequests/PaymentRequestListPage'));
const PaymentRequestDetailPage = lazy(() => import(/* webpackChunkName: "PaymentRequestDetailPage" */ '../Pages/Payment/PaymentRequestDetailPage'));
const PaymentRequestDetailPageV2 = lazy(() => import(/* webpackChunkName: "PaymentRequestDetailPage" */ '../Pages/PaymentV2/PaymentRequest/PaymentRequestDetailPage'));
const IncentivePaymentRequestDetailPage = lazy(() => import(/* webpackChunkName: "IncentivePaymentRequestDetailPage" */ '../Pages/Payment/PaymentRequests/PaymentRequestForm/PaymentRequestDetailPage'));
const InsightsInvoiceARAgingTrackerListPage = lazy(() => import(/* webpackChunkName: "InsightsInvoiceARAgingTrackerListPage" */ '../Pages/Payment/InsightsInvoiceARAgingTracker/PaymentARAgingTrackerListPage'));
const ReceivableListPage = lazy(() => import(/* webpackChunkName: "ReceivableListPage" */ '../Pages/Receivable/ReceivableListPage'));
const VirtualAccountListPage = lazy(() => import(/* webpackChunkName: "ReceivableListPage" */ '../Pages/Receivable/VirtualAccountListPage/VirtualAccountListPage'));
const BrokeragePaymentShipment = lazy(() => import(/* webpackChunkName: "PaymentShipment" */ '../Pages/Payment/ShipmentListPage/ShipmentListPage'));

const InvoiceTrackerListPage = lazy(() => import(/* webpackChunkName: "InvoiceTrackerListPage" */ '../Pages/Receivable/InvoiceTracker/InvoiceTrackerListPage'));
const InvoiceTrackerSummaryPage = lazy(() => import(/* webpackChunkName: "InvoiceTrackerSummaryPage" */ '../Pages/Receivable/InvoiceTracker/InvoiceTrackerSummaryPage'));
const InvoiceTrackerBillingPage = lazy(() => import(/* webpackChunkName: "InvoiceTrackerSummaryPage" */ '../Pages/Receivable/InvoiceTracker/InvoiceTrackerBillingPage'));

const InvoiceTrackerNextListPage = lazy(() => import(/* webpackChunkName: "InvoiceTrackerListPage" */ '~/Pages/Receivable/InvoiceTrackerNext/pages/InvoiceTrackerListPage'));
const InvoiceTrackerNextSummaryPage = lazy(() => import(/* webpackChunkName: "InvoiceTrackerSummaryPage" */ '~/Pages/Receivable/InvoiceTrackerNext/pages/InvoiceTrackerSummaryPage'));
const InvoiceTrackerNextBillingPage = lazy(() => import(/* webpackChunkName: "InvoiceTrackerSummaryPage" */ '~/Pages/Receivable/InvoiceTrackerNext/pages/InvoiceTrackerBillingPage'));

const PayableInvoiceListPage = lazy(() => import(/* webpackChunkName: "PayableInvoiceListPage" */ '~/Pages/Payment/PayableInvoice/pages/PayableInvoiceListPage'));
const PayableInvoiceDetailPage = lazy(() => import(/* webpackChunkName: "PayableInvoiceDetailPage" */ '../Pages/Payment/PayableInvoice/pages/PayableInvoiceDetailPage'));
const ShipperReminderDetailPage = lazy(() => import(/* webpackChuckName: "ShipperReminderDetailPage" */ '../Pages/Receivable/ShipperReminderDetailPage/ShipperReminderDetailPage'));
const TransporterIncentiveLedgerPage = lazy(() => import(/* webpackChuckName: "TransporterIncentiveLedgerPage" */ '../Pages/Payment/TransporterIncentives/TransporterIncentiveLedger/TransporterIncentiveLedgerPage'));
const CustomerListPage = lazy(() => import(/* webpackChunkName: "CustomerListPage" */ '../Pages/Customers/CustomerListPage'));
const CustomerDetailPage = lazy(() => import(/* webpackChunkName: "CustomerDetailPage" */ '../Pages/Customers/CustomerDetailPage'));
const CustomerSMEDetailPage = lazy(() => import(/* webpackChunkName: "CustomerSMEDetailPage" */ '../Pages/Customers/CustomerDetailPage/CustomerSMEDetailPage'));
const WarehouseListPage = lazy(() => import(/* webpackChunkName: "WarehouseListPage" */ '../Pages/Customers/WarehouseListPage'));
const WarehouseDetailPage = lazy(() => import(/* webpackChunkName: "WarehouseDetailPage" */ '../Pages/Customers/WarehouseDetailPage'));

const TransporterIncentiveList = lazy(() => import(/* webpackChunkName: "TransporterIncentiveList" */ '../Pages/Payment/TransporterIncentives/TransporterIncentivesList/TransporterIncentivesListPage'));
const DocumentListPage = lazy(() => import(/* webpackChunkName: "DocumentListPage" */ '~/Pages/Receivable/DocumentListPage'));

const GPSDevicesPage = lazy(() => import(/* webpackChunkName: "GPSDevicesPage" */ '~/Pages/GPS/Devices'));

const ContainerDetailPage = lazy(() => import(/* webpackChunkName: "ContainerDetailPage" */ '../Pages/Container/ContainerDetailPage'));
const ShipmentTruckTrackingPage = lazy(() => import(/* webpackChunkName: "ShipmentTruckTrackingPage" */ '../Pages/Vehicle/ShipmentTruckTrackingPage'));

const WelcomePage = lazy(() => import(/* webpackChunkName: "WelcomePage" */ '../Pages/WelcomePage'));

const PreviewPODPage = lazy(() => import(/* webpackChunkName: "PreviewPODPage" */ '../Pages/Preview'));

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ '../Pages/App/NotFound'));

/**
 * Incremental Routes Grouping.
 * Group routes based on base path following the final decision of FE code structure.
 */
const CompanyRoutes = lazy(() => import(/* webpackChunkName: "CompanyRoutes" */ '~/Routes/Company'));
const FirstMileShipmentRoutes = lazy(() => import(/* webpackChunkName: "FirstMileShipmentRoutes" */ '~/Routes/FirstMileShipment'));
const MidMileShipmentRoutes = lazy(() => import(/* webpackChunkName: "MidMileShipmentRoutes" */ '~/Routes/MidMileShipment'));
const ShippersRoutes = lazy(() => import(/* webpackChunkName: "ShippersRoutes" */ '~/Routes/Shippers'));
const TransporterRoutes = lazy(() => import(/* webpackChunkName: "TransporterRoutes" */ '~/Routes/Transporter'));
const FraudRoutes = lazy(() => import(/* webpackChhunkName: "FraudRoutes" */ '~/Routes/Fraud'));

/* eslint-enable max-len */

export default function Routes() {
  const { t } = useTranslation();
  const location = useLocation();
  const KC_PAYABLE_INVOICES = useFlag('kc_payable_invoices');
  const KC_INVOICE_PAYMENT_MIGRATION = useFlag('kc_invoice_payment_migration');
  const KC_BTMS_RECEIVABLE_UPI_MIGRATION = useFlag('KC_BTMS_RECEIVABLE_UPI_MIGRATION');
  const KC_REFACTOR_PAYMENT_REQUEST = useFlag('KC_REFACTOR_PAYMENT_REQUEST');

  const {
    profile,
    getSelectedCompany,
    isAuthorizedToAccess,
  } = useContext(ProfileContext);

  const { displayLanguageSetting, changeLanguage } = useContext(AppContext);

  useEffect(() => {
    if (displayLanguageSetting && profile.defaultLocale != null) {
      changeLanguage(profile.defaultLocale);
    }
  }, [changeLanguage, displayLanguageSetting, profile.defaultLocale]);

  const customerPageTabNavigation = [
    {
      path: '/customer/customer-list',
      title: isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPPER_COPYWRITE)
        ? t('common:shipper')
        : t('common:customer'),
    },
    {
      path: '/customer/warehouse-list',
      title: t('common:warehouse'),
    },
  ];

  const customerDetailsTabNavigation = [
    {
      path: '?tab=cdetails',
      title: t('common:company_details'),
    },
    {
      path: '?tab=cpayments',
      title: t('common:payments'),
    },
    {
      path: '?tab=cdocreqs',
      title: t('common:required_document_return'),
    },
    {
      path: '?tab=ctruckreqs',
      title: t('common:truck_requirements'),
    },
    {
      path: '?tab=cclaims',
      title: t('common:claims'),
    },
    {
      path: '?tab=clocationfees',
      title: t('common:location_fee'),
    },
    {
      path: '?tab=cprices',
      title: t('common:prices'),
    },
  ];

  const customerSMEDetailsTabNavigation = [
    {
      path: '?tab=cdetails',
      title: 'Company Details',
    },
    {
      path: '?tab=cpodReturnsClaims',
      title: 'POD Returns & Claims',
    },
    {
      path: '?tab=clocationpic',
      title: 'Location PICs',
    },
    {
      path: '?tab=clocationfee',
      title: 'Location Fee',
    },
    {
      path: '?tab=cprices',
      title: 'Prices',
    },
    ...(window.SME_PLATFORM_ACCESS
      ? [{
        path: '?tab=cplatformaccess',
        title: 'Platform Access',
      }] : []),
  ];

  const customerSMEDetailsInvoicedTabNavigation = [
    {
      path: '?tab=cdetails',
      title: 'Company Details',
    },
    {
      path: '?tab=cpayments',
      title: 'Payments',
    },
    {
      path: '?tab=cpodReturnsClaims',
      title: 'POD Returns & Claims',
    },
    {
      path: '?tab=clocationpic',
      title: 'Location PICs',
    },
    {
      path: '?tab=clocationfee',
      title: 'Location Fee',
    },
    {
      path: '?tab=cprices',
      title: 'Prices',
    },
    ...(window.SME_PLATFORM_ACCESS
      ? [{
        path: '?tab=cplatformaccess',
        title: 'Platform Access',
      }] : []),
  ];

  const payableTabNavigation = [
    {
      path: '/payment/fee-approval',
      title: t('common:fee_approval'),
    },
    ...(
      KC_PAYABLE_INVOICES ? [
        {
          path: '/payable/invoice',
          title: t('common:invoice'),
        },
      ] : []),
    {
      path: '/payment/payment-request',
      title: t('common:payment_request'),
    },
    {
      path: '/payment/transporter-incentives',
      title: t('common:transporter_incentive_program'),
      'data-testid': 'tabTIP',
    },
  ].filter(Boolean);

  const insightsTabNavigation = [
    {
      path: '/insights/receivable',
      title: t('common:receivable'),
    },
  ].filter(Boolean);

  const receivableTabNavigation = [
    {
      path: '/receivable/documents',
      title: t('common:documents'),
    },
    ...(KC_BTMS_RECEIVABLE_UPI_MIGRATION
      ? []
      : [
        ...(isAuthorizedToAccess(ACCESS_PRIVILEGE.ACCOUNT_TRACKER_READ)
          ? [{
            path: '/receivable/account-tracker',
            title: t('common:account_tracker'),
          }] : []),
        {
          path: '/receivable/shipment-list',
          title: t('common:shipment'),
        },
        {
          path: '/receivable/invoice-list',
          title: `${t('common:invoice')}`,
        },
        ...(KC_INVOICE_PAYMENT_MIGRATION ? [{
          path: '/receivable/next-invoice-list',
          title: `${t('common:invoice')} (Beta)`,
        }] : []),
        {
          path: '/receivable/virtual-account-transactions',
          title: t('common:va_transaction'),
        },
      ]),
  ].filter(Boolean);

  const gpsIntegrationTabNavigation = [
    {
      path: '/gps/devices',
      title: t('common:devices'),
    },
  ];

  const renderDefaultRootPath = () => {
    if (getSelectedCompany().accessType === USER_ACCESS_TYPE.FINANCE) {
      return <Redirect exact={true} from="/" to="/company/details" />;
    }

    return <Redirect exact={true} from="/" to="/shipment" />;
  };

  // Track page_view when navigation changed
  // Firebase Analytics doesn't automatically track page_view when client side navigation changed
  useEffect(() => {
    trackEvent(GENERIC_EVENT_TRACKER.page_view, {
      page_location: `${window.location.origin}${location.pathname}${location.search}`,
    });
  }, [location]);

  return (
    <Suspense fallback={<PageSuspenseFallback />}>
      <Switch>
        <Route path="/auth">
          <MinimalLayout>
            <Suspense fallback={<PageSuspenseFallback />}>
              <Switch>
                <AuthRoute
                  exact={true}
                  path="/auth/login/:accessType?"
                  showLogo={true}
                  title={t('common:login')}
                >
                  <LoginPage />
                </AuthRoute>
                <AuthRoute
                  exact={true}
                  path="/auth/register"
                  showLogo={true}
                  title={t('common:register')}
                >
                  <RegisterPage />
                </AuthRoute>
                <AuthRoute
                  exact={true}
                  path="/auth/register/details/:phoneNumber"
                  showLogo={false}
                  title={t('message:fill_account_details')}
                >
                  <RegisterDetailsPage />
                </AuthRoute>
                <AuthRoute
                  exact={true}
                  path="/auth/verification/:phoneNumber"
                >
                  <OtpVerification options="REGISTRATION" />
                </AuthRoute>
                <AuthRoute
                  exact={true}
                  path="/auth/forgot-password/:phoneNumber"
                >
                  <OtpVerification options="RESET_PASSWORD" />
                </AuthRoute>
                <AuthRoute
                  exact={true}
                  path="/auth/choose-company"
                  showLogo={false}
                  title={t('common:choose_company')}
                >
                  <ChooseCompanyPage />
                </AuthRoute>
                <Redirect to="/auth/login" />
              </Switch>
            </Suspense>
          </MinimalLayout>
        </Route>

        <PrivateRoute path="/welcome" title="">
          <MinimalLayout>
            <WelcomePage />
          </MinimalLayout>
        </PrivateRoute>
        <PrivateRoute path="/receivable/preview/:ksuid/:shipmentKsuid" title="">
          <PreviewPODPage />
        </PrivateRoute>

        {/* First Mile Routes */}
        <PrivateRoute path="/shipment/first-mile">
          <FirstMileShipmentRoutes t={t} />
        </PrivateRoute>

        {/* Refactored Routes */}
        <PrivateRoute path="/company">
          <CompanyRoutes t={t} />
        </PrivateRoute>
        <PrivateRoute path="/shipment">
          <MidMileShipmentRoutes t={t} />
        </PrivateRoute>
        <PrivateRoute path="/shippers">
          <ShippersRoutes t={t} />
        </PrivateRoute>
        <PrivateRoute path="/transporter">
          <TransporterRoutes t={t} />
        </PrivateRoute>
        <PrivateRoute path="/fraud">
          <FraudRoutes t={t} />
        </PrivateRoute>
        <PrivateRoute path="/">
          <MainLayout>
            <Suspense fallback={<PageSuspenseFallback />}>
              <Switch>
                <PrivateRoute
                  exact={true}
                  path="/personal-account"
                  title={t('common:personal_account')}
                >
                  <PersonalAccount />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/customer/customer-list"
                  tabNavigation={customerPageTabNavigation}
                  title={
                    isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPPER_COPYWRITE)
                      ? t('common:shipper_list')
                      : t('common:customer_list')
                  }
                >
                  <CustomerListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/customer/customer-details/:customerKsuid/:companyKsuid?"
                  tabNavigation={customerDetailsTabNavigation}
                >
                  <CustomerDetailPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/customer/sme-details/:customerKsuid/:companyKsuid?"
                  tabNavigation={customerSMEDetailsTabNavigation}
                >
                  <CustomerSMEDetailPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/customer/sme-details/invoice/:customerKsuid/:companyKsuid?"
                  tabNavigation={customerSMEDetailsInvoicedTabNavigation}
                >
                  <CustomerSMEDetailPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/customer/warehouse-list"
                  tabNavigation={customerPageTabNavigation}
                  title={
                    isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPPER_COPYWRITE)
                      ? t('common:shipper_list')
                      : t('common:customer_list')
                  }
                >
                  <WarehouseListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/customer/warehouse-details/:warehouseKsuid"
                >
                  <WarehouseDetailPage />
                </PrivateRoute>

                <Redirect
                  exact={true}
                  from="/customer"
                  to="/customer/customer-list"
                />

                <PrivateRoute
                  exact={true}
                  path="/container/details/:containerID"
                >
                  <ContainerDetailPage />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/vehicle/tracking"
                  title={t('common:truck_monitoring')}
                >
                  <ShipmentTruckTrackingPage />
                </PrivateRoute>
                <Redirect
                  exact={true}
                  from="/vehicle"
                  to="/vehicle/tracking"
                />

                {/* NOTE: Will be migrated fully from /payment to /payable */}
                <Redirect
                  exact={true}
                  from="/payment"
                  to="/payment/fee-approval"
                />
                <PrivateRoute
                  exact={true}
                  path="/payment/disbursement-scheme"
                  skipTabNavigationSetup={true}
                  title={t('common:disbursement_scheme')}
                >
                  <DisbursementSchemeListPage />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/payment/disbursement-scheme/:disbursementSchemeKsuid"
                >
                  <DisbursementSchemeDetailPage />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/payment/payment-request/:ksuid"
                >
                  {KC_REFACTOR_PAYMENT_REQUEST ? (
                    <PaymentRequestDetailPageV2 />
                  ) : (
                    <PaymentRequestDetailPage />
                  )}
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/payment/incentive-payment-request/:ksuid"
                >
                  <IncentivePaymentRequestDetailPage />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/payment/fee-approval/"
                  tabNavigation={payableTabNavigation}
                  title={t('common:payable')}
                >
                  <FeeRequestListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payable/invoice/"
                  tabNavigation={payableTabNavigation}
                  title={t('common:payable')}
                >
                  <PayableInvoiceListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payable/invoice/:invoiceKsuid"
                  skipTabNavigationSetup={true}
                >
                  <PayableInvoiceDetailPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payment/payment-request/"
                  tabNavigation={payableTabNavigation}
                  title={t('common:payable')}
                >
                  <PaymentRequestListPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payment/transporter-incentives/:transporterCompanyKsuid"
                >
                  <TransporterIncentiveLedgerPage />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  path="/payment/transporter-incentives"
                  tabNavigation={payableTabNavigation}
                  title={t('common:payable')}
                >
                  <TransporterIncentiveList />
                </PrivateRoute>

                {/* End of /payment */}

                <Redirect
                  exact={true}
                  from="/receivable"
                  to="/receivable/documents"
                />
                <PrivateRoute
                  exact={true}
                  path="/receivable/documents"
                  tabNavigation={receivableTabNavigation}
                  title={t('common:documents')}
                >
                  <DocumentListPage />
                </PrivateRoute>

                {/* Start Receivable Routes */}

                {
                  KC_BTMS_RECEIVABLE_UPI_MIGRATION
                    ? (
                      <PrivateRoute
                        path="/receivable/*"
                        tabNavigation={receivableTabNavigation}
                      >
                        <NotFound />
                      </PrivateRoute>
                    )
                    : (
                      <PrivateRoute path="/receivable/*">
                        <Switch>
                          {
                            isAuthorizedToAccess(ACCESS_PRIVILEGE.ACCOUNT_TRACKER_READ) && (
                              <PrivateRoute
                                exact={true}
                                path="/receivable/account-tracker"
                                tabNavigation={receivableTabNavigation}
                                title={t('common:receivable')}
                              >
                                <ReceivableListPage />
                              </PrivateRoute>
                            )
                          }
                          <PrivateRoute
                            exact={true}
                            path="/receivable/shipment-list"
                            tabNavigation={receivableTabNavigation}
                            title={t('common:receivable')}
                          >
                            <BrokeragePaymentShipment />
                          </PrivateRoute>
                          {/* Invoice Tracker v1 */}
                          <PrivateRoute
                            exact={true}
                            path="/receivable/invoice-list"
                            tabNavigation={receivableTabNavigation}
                            title={t('common:receivable')}
                          >
                            <InvoiceTrackerListPage />
                          </PrivateRoute>
                          <PrivateRoute
                            exact={true}
                            path="/receivable/invoice-summary/:invoiceKsuid"
                            skipTabNavigationSetup={true}
                          >
                            <InvoiceTrackerSummaryPage />
                          </PrivateRoute>
                          <PrivateRoute
                            exact={true}
                            path="/receivable/invoice-billing/:invoiceKsuid"
                            skipTabNavigationSetup={true}
                          >
                            <InvoiceTrackerBillingPage />
                          </PrivateRoute>
                          {/* Invoice Tracker v2 */}
                          <PrivateRoute
                            exact={true}
                            path="/receivable/next-invoice-list"
                            tabNavigation={receivableTabNavigation}
                            title={t('common:receivable')}
                          >
                            <InvoiceTrackerNextListPage />
                          </PrivateRoute>
                          <PrivateRoute
                            exact={true}
                            path="/receivable/next-invoice-summary/:invoiceKsuid"
                            skipTabNavigationSetup={true}
                          >
                            <InvoiceTrackerNextSummaryPage />
                          </PrivateRoute>
                          <PrivateRoute
                            exact={true}
                            path="/receivable/next-invoice-billing/:invoiceKsuid"
                            skipTabNavigationSetup={true}
                          >
                            <InvoiceTrackerNextBillingPage />
                          </PrivateRoute>
                          {/* End of Invoice Tracker v2 */}
                          <PrivateRoute
                            exact={true}
                            path="/receivable/virtual-account-transactions"
                            tabNavigation={receivableTabNavigation}
                            title={t('common:receivable')}
                          >
                            <VirtualAccountListPage />
                          </PrivateRoute>
                          <PrivateRoute
                            exact={true}
                            path="/receivable/shipper/:shipperCompanyKsuid"
                          >
                            <ShipperReminderDetailPage />
                          </PrivateRoute>
                        </Switch>
                      </PrivateRoute>
                    )
                }

                {/* End of Receivable */}

                <Redirect
                  exact={true}
                  from="/insights"
                  to="/insights/receivable"
                />

                {
                  isAuthorizedToAccess(ACCESS_PRIVILEGE.AR_AGING_READ) && (
                    <PrivateRoute
                      exact={true}
                      path="/insights/receivable"
                      tabNavigation={insightsTabNavigation}
                      title={t('common:insights')}
                    >
                      <InsightsInvoiceARAgingTrackerListPage />
                    </PrivateRoute>
                  )
                }

                <Redirect
                  exact={true}
                  from="/gps"
                  to="/gps/devices"
                />

                <PrivateRoute
                  exact={true}
                  path="/gps/devices"
                  tabNavigation={gpsIntegrationTabNavigation}
                  title={t('common:gps_integration')}
                >
                  <GPSDevicesPage />
                </PrivateRoute>

                {renderDefaultRootPath()}
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </MainLayout>
        </PrivateRoute>

        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}
