import { SHIPMENT_STATUSES, FM_SHIPMENT_STATUSES } from '~/Configurations/constants';

export const TRUCKING_SEARCH_TYPES = {
  DO_NUMBER: 'DO_NUMBER',
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
  CONTAINER_DO_NUMBER: 'CONTAINER_DO_NUMBER',
  LICENSE_PLATE: 'LICENSE_PLATE',
  APPOINTMENT_NUMBER: 'APPOINTMENT_NUMBER',
  ORDER_NUMBER: 'ORDER_NUMBER',
};

export const DIALOG_TYPE = {
  EDIT_SHIPMENT: 'EDIT_SHIPMENT',
  UPDATE_RO: 'UPDATE_RO',
  ALLOCATE_MANUALLY: 'ALLOCATE_MANUALLY',
  REMOVE_FROM_MP: 'REMOVE_FROM_MP',
  UPDATE_APPT_SPM: 'UPDATE_APPT_SPM',
  CANCEL_DO: 'CANCEL_DO',
  UNFULFILL_DO: 'UNFULFILL_DO',
  REALLOCATE: 'REALLOCATE',
  EDIT_TIMESTAMP: 'EDIT_TIMESTAMP',
  UPDATE_CONTAINER_INFO: 'UPDATE_CONTAINER_INFO',
  UPDATE_ACTIVIY_LOG: 'UPDATE_ACTIVIY_LOG',
  UPDATE_STATUS: 'UPDATE_STATUS',
  VIEW_ACTIVITY_LOG: 'VIEW_ACTIVITY_LOG',
  ASSIGN_TRUCKING: 'ASSIGN_TRUCKING',
  GENERATE_SURAT_JALAN: 'GENERATE_SURAT_JALAN',
};

export const DEFAULT_DIALOG_VAL = {
  type: null,
  additionalInfo: null,
  key: null,
  number: null,
  initValue: null,
};

export const SELECT_MODES = {
  POST_TO_PRIVATE_MP: 'POST_TO_PRIVATE_MP',
  PRINT_SURAT_JALAN: 'PRINT_SURAT_JALAN',
};

export const STATUS_CODE = {
  PENDING: 1,
  APPROVED_BY_SHIPPER: 2,
  REJECTED: 3,
  CANCELLED: 4,
  APPROVED_BY_BROKERAGE: 5,
};

export const VALID_STATUS_TO_REASSIGN = [
  SHIPMENT_STATUSES.PLANNED,
  SHIPMENT_STATUSES.QUEUED,
  SHIPMENT_STATUSES.QUEUED_AND_BTMS_PLANNED,
  SHIPMENT_STATUSES.ASSIGNED,
  SHIPMENT_STATUSES.ASSIGNING,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ONGOING_TO_DESTINATION,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
];

export const FIRST_MILE_TRUCKING_SHIPMENT_TYPE = {
  TRUCKING_DTD: 'TRUCKING_DTD',
  FM_EXPORT: 'FM_EXPORT',
  FM_IMPORT: 'FM_IMPORT',
  FM_INBOUND: 'FM_INBOUND',
  FM_OUTBOUND: 'FM_OUTBOUND',
};

export const TRUCKING_TYPE_FILTER_VALUE = [
  FIRST_MILE_TRUCKING_SHIPMENT_TYPE.TRUCKING_DTD,
  FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_EXPORT,
  FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_IMPORT,
  FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_INBOUND,
  FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_OUTBOUND,
];

export const MONITORING_POSTS = {
  PRE_SHIPMENT: 'PRE_SHIPMENT',
  ON_SHIPMENT: 'ON_SHIPMENT',
  COMPLETED: 'COMPLETED',
  ALL: 'ALL',
};

export const MONITORING_POSTS_TO_STATUS = {
  [MONITORING_POSTS.PRE_SHIPMENT]: [
    FM_SHIPMENT_STATUSES.JOB_UNASSIGNED,
    FM_SHIPMENT_STATUSES.JOB_BIDDING,
    FM_SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED,
    FM_SHIPMENT_STATUSES.PLANNED,
    FM_SHIPMENT_STATUSES.QUEUED_AND_BTMS_PLANNED,
    FM_SHIPMENT_STATUSES.ASSIGNED,
  ],
  [MONITORING_POSTS.ON_SHIPMENT]: [
    FM_SHIPMENT_STATUSES.GATE_IN_DEPO,
    FM_SHIPMENT_STATUSES.GATE_IN_CY,
    FM_SHIPMENT_STATUSES.GATE_IN_STORE,
    FM_SHIPMENT_STATUSES.GATE_OUT_DEPO,
    FM_SHIPMENT_STATUSES.GATE_OUT_CY,
    FM_SHIPMENT_STATUSES.GATE_OUT_STORE,
  ],
  [MONITORING_POSTS.COMPLETED]: [
    FM_SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
    FM_SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL,
  ],
  get [MONITORING_POSTS.ALL]() {
    return [
      ...this[MONITORING_POSTS.PRE_SHIPMENT],
      ...this[MONITORING_POSTS.ON_SHIPMENT],
      ...this[MONITORING_POSTS.COMPLETED],
    ];
  },
};

export const TRUCKING_DTD_SHIPMENT_STATUS_MONITORING_GROUPS = {
  [MONITORING_POSTS.PRE_SHIPMENT]: MONITORING_POSTS_TO_STATUS[MONITORING_POSTS.PRE_SHIPMENT],
  [MONITORING_POSTS.ON_SHIPMENT]: MONITORING_POSTS_TO_STATUS[MONITORING_POSTS.ON_SHIPMENT],
  [MONITORING_POSTS.COMPLETED]: MONITORING_POSTS_TO_STATUS[MONITORING_POSTS.COMPLETED],
};

export const TRUCKING_DTD_SHIPMENT_STATUS_GROUP_MAPPING = {
  [FM_SHIPMENT_STATUSES.JOB_UNASSIGNED]: MONITORING_POSTS.PRE_SHIPMENT,
  [FM_SHIPMENT_STATUSES.PLANNED]: MONITORING_POSTS.PRE_SHIPMENT,
  [FM_SHIPMENT_STATUSES.QUEUED_AND_BTMS_PLANNED]: MONITORING_POSTS.PRE_SHIPMENT,
  [FM_SHIPMENT_STATUSES.JOB_BIDDING]: MONITORING_POSTS.PRE_SHIPMENT,
  [FM_SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED]: MONITORING_POSTS.PRE_SHIPMENT,
  [FM_SHIPMENT_STATUSES.ASSIGNED]: MONITORING_POSTS.PRE_SHIPMENT,
  [FM_SHIPMENT_STATUSES.GATE_IN_DEPO]: MONITORING_POSTS.ON_SHIPMENT,
  [FM_SHIPMENT_STATUSES.GATE_IN_CY]: MONITORING_POSTS.ON_SHIPMENT,
  [FM_SHIPMENT_STATUSES.GATE_IN_STORE]: MONITORING_POSTS.ON_SHIPMENT,
  [FM_SHIPMENT_STATUSES.GATE_OUT_DEPO]: MONITORING_POSTS.ON_SHIPMENT,
  [FM_SHIPMENT_STATUSES.GATE_OUT_CY]: MONITORING_POSTS.ON_SHIPMENT,
  [FM_SHIPMENT_STATUSES.GATE_OUT_STORE]: MONITORING_POSTS.ON_SHIPMENT,
  [FM_SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: MONITORING_POSTS.COMPLETED,
  [FM_SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: MONITORING_POSTS.COMPLETED,
};

export const SHIPMENT_POINT_STATUSES = {
  PENDING: 'PENDING',
  ONGOING_TO_PICKUP: 'ONGOING_TO_PICKUP',
  ONGOING_TO_DESTINATION: 'ONGOING_TO_DESTINATION',
  ARRIVE_AT_PICKUP: 'ARRIVE_AT_PICKUP',
  ARRIVE_AT_DESTINATION: 'ARRIVE_AT_DESTINATION',
  START_LOADING: 'START_LOADING',
  START_UNLOADING: 'START_UNLOADING',
  FINISH_LOADING: 'FINISH_LOADING',
  FINISH_UNLOADING: 'FINISH_UNLOADING',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
  COMPLETE: 'COMPLETE',
  FIRST_START_LOADING: 'FIRST_START_LOADING',
  ON_SHIPMENT: 'ON_SHIPMENT',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
};

export const SURAT_JALAN_TRUCKING_TYPE = [
  FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_EXPORT,
  FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_OUTBOUND,
  FIRST_MILE_TRUCKING_SHIPMENT_TYPE.TRUCKING_DTD,
];
