/**
 * This is translation for designers wanting to have other language thats not the same
 * from the current app language.
 */
export default {
  id_amount: 'Jumlah',
  id_bank_information: 'Informasi Bank',
  id_description: 'Deskripsi',
  id_do_number: 'Nomor DO',
  id_invoice_date: 'Tanggal Invoice',
  id_proforma_invoice_date: 'Tanggal Proforma Invoice',
  id_invoice_number: 'Nomor Invoice',
  id_proforma_invoice_number: 'Nomor Proforma Invoice',
  id_license_plate: 'No. Polisi',
  id_loading_date: 'Tanggal Muat',
  id_payment_detail: 'Rincian Tagihan',
  id_po_number: 'Nomor PO',
  id_pod_number: 'Nomor POD',
  id_summary: 'Ringkasan',
  id_before_DP: 'sebelum DP',
  id_after_DP: 'setelah DP',
  id_invoice_due_date: 'Invoice jatuh tempo {{day}} {{type}} setelah dokumen diterima',
  id_notes: 'Notes',
  id_day: 'hari kalendar',
  id_work_day: 'hari kerja',
  overnight: 'Biaya Inap',
};
