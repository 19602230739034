import { CHARGED_REASON, DOORING_AGENT_STATUS, SHIPMENT_POINT_STATUS } from '~/Configurations/constants';
import {
  SHIPMENT_ACTIVITY_LOG_STATUSES as SHIPMENT_STATUSES,
  SHIPMENT_POINT_TYPE,
} from '~/Pages/Shipment/Utilities/constants';

export const DIALOG_TYPE = {
  PLANNED_DOORING_DATE: 'planned_dooring_date',
  PREVIEW_BOL: 'preview_bill_of_lading',
  MANUAL_ALLOCATION: 'manual_allocation',
  EDIT_SHIPMENT_STATUS: 'edit_shipment_status',
  REALLOCATION: 'reallocation',
  UPDATE_ACTIVITY_LOG: 'update_activity_log',
  VIEW_ACTIVITY_LOG: 'view_activity_log',
  EDIT_REMARK: 'edit_remark',
  EDIT_DOORING_AGENT_TIMESTAMP: 'edit_dooring_agent_timestamp',
};

export const DEFAULT_DIALOG_VAL = {
  type: null,
  additionalInfo: null,
  key: null,
  number: null,
  initValue: null,
};

export const doStatusOptions = t => [
  {
    value: DOORING_AGENT_STATUS.UNASSIGNED,
    label: t(`first_mile:dooring_agent_status_type.${DOORING_AGENT_STATUS.UPCOMING}`),
  },
  {
    value: DOORING_AGENT_STATUS.ASSIGNED,
    label: t(`first_mile:dooring_agent_status_type.${DOORING_AGENT_STATUS.ASSIGNED}`),
  },
  {
    value: DOORING_AGENT_STATUS.ARRIVE_AT_DESTINATION,
    label: t(`first_mile:dooring_agent_status_type.${DOORING_AGENT_STATUS.ARRIVE_AT_DESTINATION}`),
  },
  {
    value: DOORING_AGENT_STATUS.SHIPMENT_COMPLETE,
    label: t(`first_mile:dooring_agent_status_type.${DOORING_AGENT_STATUS.SHIPMENT_COMPLETE}`),
  },
];

export const doEditShipmentStatusOptions = t => [
  {
    value: DOORING_AGENT_STATUS.ARRIVE_AT_DESTINATION,
    label: t(`first_mile:dooring_agent_status_type.${DOORING_AGENT_STATUS.ARRIVE_AT_DESTINATION}`),
  },
  {
    value: DOORING_AGENT_STATUS.SHIPMENT_COMPLETE,
    label: t(`first_mile:dooring_agent_status_type.${DOORING_AGENT_STATUS.SHIPMENT_COMPLETE}`),
  },
];

export const chargedReasonOptions = t => [
  {
    value: CHARGED_REASON.LATE_BILL_OF_LADING,
    label: t(`first_mile:charge_reason_type.${CHARGED_REASON.LATE_BILL_OF_LADING}`),
  },
  {
    value: CHARGED_REASON.DOORING_AGENT_PROBLEM,
    label: t(`first_mile:charge_reason_type.${CHARGED_REASON.DOORING_AGENT_PROBLEM}`),
  },
  {
    value: CHARGED_REASON.BAD_WEATHER,
    label: t(`first_mile:charge_reason_type.${CHARGED_REASON.BAD_WEATHER}`),
  },
  {
    value: CHARGED_REASON.LONG_QUEUE_AT_WAREHOUSE,
    label: t(`first_mile:charge_reason_type.${CHARGED_REASON.LONG_QUEUE_AT_WAREHOUSE}`),
  },
  {
    value: CHARGED_REASON.FULL_AT_DESTINATION_WAREHOUSE,
    label: t(`first_mile:charge_reason_type.${CHARGED_REASON.FULL_AT_DESTINATION_WAREHOUSE}`),
  },
];

export const DEFAULT_STATUS_FILTER_VALUE = [
  DOORING_AGENT_STATUS.UNASSIGNED,
  DOORING_AGENT_STATUS.ASSIGNED,
  DOORING_AGENT_STATUS.ARRIVE_AT_DESTINATION,
  DOORING_AGENT_STATUS.SHIPMENT_COMPLETE,
];

export const DOORING_SEARCH_TYPES = {
  DO_NUMBER: 'DO_NUMBER',
  LICENSE_PLATE: 'LICENSE_PLATE',
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
  CONTAINER_DO_NUMBER: 'CONTAINER_DO_NUMBER',
  VESSEL_OR_VOYAGE_NAME: 'VESSEL_OR_VOYAGE_NAME',
};

export const DEMURRAGE_STATUS = {
  FREE: 'free',
  CHARGED: 'charged',
};

export const BTMS_MULTI_SHIPMENT_TIMESTAMP_STATUS_SEQUENCE = {
  [SHIPMENT_POINT_TYPE.PICKUP]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.FINISH_LOADING,
  ],
  [SHIPMENT_POINT_TYPE.DROPOFF]: [
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
  ],
  LAST_POINT: [SHIPMENT_POINT_STATUS.COMPLETE],
};

export const TIMESTAMP_DOORING_STATUS = [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION, SHIPMENT_STATUSES.SHIPMENT_COMPLETE];
