/* eslint-disable import/prefer-default-export */
export const FORM_SECTION = {
  GENERAL: 0,
  PRICING: 1,
  TNC: 2,
};

export const QUOTATION_TYPE = {
  FM_DTD: 'FM_DTD',
  MID_MILE: 'MID_MILE',
  PROJECT: 'PROJECT',
  RENTAL: 'RENTAL',
};

export const PRICING_SCHEME = {
  PER_CBM: 'PER_CBM',
  PER_KG: 'PER_KG',
  PER_PO: 'PER_PO',
  PER_ROUTE: 'PER_ROUTE',
  PER_UNIT: 'PER_UNIT',
};

export const PRICE_SCALING_TYPE_OPTIONS_BY_QUOTATION_TYPE = {
  [QUOTATION_TYPE.FM_DTD]: {
    disabled: true,
    values: ['PER_ROUTE'],
  },
  [QUOTATION_TYPE.MID_MILE]: {
    disabled: false,
    values: [
      'PER_KG',
      'PER_ROUTE',
      'PER_CBM',
    ],
  },
  [QUOTATION_TYPE.PROJECT]: {
    disabled: false,
    values: [
      'PER_KG',
      'PER_PO',
      'PER_ROUTE',
    ],
  },
  [QUOTATION_TYPE.RENTAL]: {
    disabled: true,
    values: ['PER_UNIT'],
  },
};

export const QUOTATION_FORM_ACTION = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};
